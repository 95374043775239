import React from 'react';

import { DetailsItemProps } from '../../../types';

const DetailsItem: React.FC<DetailsItemProps> = ({
  title,
  value,
  id,
  card,
  children,
}) => {
  return (
    <div className="detailsItem tableFilterCard" id={id}>
      {card ? (
        <>
          <p>{value}</p>
          <p>{title}</p>
          {children}
        </>
      ) : (
        <>
          <p>{title}</p>
          <p>{value}</p>
        </>
      )}
    </div>
  );
};

export default DetailsItem;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Fade, MenuItem } from '@material-ui/core';
import Loader from 'components/Tools/Loader';
import { useParams, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import SearchBox from 'components/Tools/Search';
import {
  Container,
  Search,
  TableFilterCardContainer,
} from 'app/WMS/styles/styles';
import { getCookie } from 'utils/cookies';
import PageMode from 'components/Tools/PageMode';
import DetailsItem from 'components/Tools/DetailsItem';
import getValidationErrors from 'utils/getValidationErrors';
import {
  handleStatusPicking,
  handleSubmitData,
  handleProduct,
  hendleParamentes,
} from './api/followPicking.api';
import SideDishTable from './components/SideDishTable';
import NewShowDetails from './components/Details';
import FixedHeader from '../../../components/Tools/Breadcrumb';
import { SelectItem, transformToSelect } from '../../../utils/toSelect';
import { PickingData, PickingDataOrderDetail, SearchDataProps } from './types';

const PickingFollow: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [orderTypeList, setOrderTypeList] = useState([]);
  const [picking, setPicking] = useState([] as PickingData[]);
  const [detailsPicking, setDetailsPicking] = useState({} as PickingData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [isLoading, setIsLoading] = useState(Boolean);
  const inputsBox = useRef<FormHandles>(null);
  const { codeStatus } = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  const [transpList, setTranspList] = useState({} as SelectItem[]);
  const [statusList, setStatusList] = useState({} as SelectItem[]);
  const [open, setOpen] = useState(false);
  const [detailProduct, setDetailProduct] = useState(
    [] as PickingDataOrderDetail[]
  );

  const [cardsSubmit, setCardsSubmit] = useState<any>([]);
  const [cardProgress, setCardProgress] = useState<number>(0);

  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const searchRef = useRef<FormHandles>(null);
  const idCliente = getCookie('@pdamodules::codigoCliente');
  const idUser = getCookie('@pdamodules::id');
  // eslint-disable-next-line no-shadow
  const pendingFilter = (picking: PickingData[], codigoStatus?: number) => {
    switch (codigoStatus) {
      case 1: {
        const resultsPicking = picking.filter(item => {
          return item.descricaoStatus === 'Aguardando Faturamento';
        });
        return resultsPicking.length;
      }
      case 2: {
        const resultsPicking = picking.filter(item => {
          return item.descricaoStatus === 'Aguardando Expedição';
        });
        return resultsPicking.length;
      }
      case 3: {
        const resultsPicking = picking.filter(item => {
          return item.descricaoStatus === 'Aguardando Conferência';
        });
        return resultsPicking.length;
      }
      case 4: {
        const resultsPicking = picking.filter(item => {
          return item.descricaoStatus === 'Expedição Finalizada';
        });
        return resultsPicking.length;
      }
      case 5: {
        const resultsPicking = picking.filter(item => {
          return item.descricaoStatus === 'Aguardando Integração';
        });
        return resultsPicking.length;
      }
      case 6: {
        const resultsPicking = picking.filter(item => {
          return item.descricaoStatus === 'Aguardando Liberação';
        });
        return resultsPicking.length;
      }
      default:
        return picking.length;
    }
  };

  const handleProgressCard = (pickingData: PickingData[]) => {
    const quantity = pickingData.map((item: any) => {
      return Number(item.quantidadePedido);
    });
    const totalQuantitySum = quantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const receivedQuantity = pickingData.map((item: any) => {
      return Number(item.quantidadePicking);
    });
    const totalReceivedQuantitySum = receivedQuantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const progress = (totalReceivedQuantitySum / totalQuantitySum) * 100;
    const finalProgress: any = Math.floor(progress);
    return finalProgress !== Number
      ? setCardProgress(0)
      : setCardProgress(finalProgress);
  };

  const getStatus = React.useCallback(async () => {
    if (Number(codeStatus)) {
      const itensFiltered: SelectItem[] = Object.values(statusList).filter(
        itensSta => itensSta.value === codeStatus
      );
      const filtered = itensFiltered[0];
      inputsBox.current?.setFieldValue('status', { ...filtered });

      const dataFilter = {
        CodigoCliente: Number(idCliente),
        User: Number(idUser),
        CodigoStatus: codeStatus,
      };
      setPicking([]);
      handleStatusPicking(dataFilter).then((pickingData: AxiosResponse) => {
        if (pickingData.data.length <= 0) {
          setPicking([]);
        } else {
          setPicking([...pickingData.data]);
          const timer = setTimeout(() => {
            setLoading(true);
            return () => {
              clearTimeout(timer);
            };
          }, 1000);
        }
      });
    } else {
      history.push('/wms/picking/');
    }
  }, [codeStatus, statusList, history, idCliente, idUser]);

  const checkFields = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('onda') !== '' ||
      searchRef.current?.getFieldValue('pedido') !== ''
    ) {
      return false;
    }
    return true;
  }, []);

  const daysBetween = () => {
    const startDate = searchRef.current?.getFieldValue('DataInicioPicking');
    const endDate = searchRef.current?.getFieldValue('DataFimPicking');
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / millisecondsPerDay
    );
  };

  const handleSubmit = useCallback(
    async (data, status?, dataInicio?: string, dataFinal?: string) => {
      try {
        setLoading(false);
        setIsLoading(true);
        checkFields();

        const isRequired = checkFields();
        const checkDate = daysBetween();

        if ((isRequired && checkDate > 15) || (isRequired && checkDate < -15)) {
          throw new Yup.ValidationError(
            [],
            'ValidationError',
            'DataInicioPicking'
          );
        }

        const schema = Yup.object()
          .shape({
            onda: Yup.string(),
            pedido: Yup.string(),
            cliente: Yup.string(),
            status: Yup.string(),
            transportadora: Yup.string(),
            DataInicioPicking:
              isRequired === true ? Yup.string().required() : Yup.string(),
            DataFimPicking:
              isRequired === true ? Yup.string().required() : Yup.string(),
          })
          .nullable();

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataFilter: SearchDataProps = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          CodigoOnda: data.onda || null,
          CodigoPedido: data.pedido || null,
          Cliente: data.cliente || null,
          CodigoTransportadora: data.transportadora || null,
          CodigoStatus: data.status || status || null,
          tipoPedido: data.tipoPedido || null,
          DataInicioPicking: data.DataInicioPicking || dataInicio || null,
          DataFimPicking: data.DataFimPicking || dataFinal || null,
        };
        setCardsSubmit(dataFilter);
        handleSubmitData(dataFilter)
          .then((response: AxiosResponse) => {
            if (response.data.length <= 0) {
              enqueueSnackbar(`Resultados não econtrados.`, {
                variant: 'warning',
              });
              setLoading(true);
              setPicking(response.data);
            } else {
              enqueueSnackbar(
                `Foi encontrado ${response.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              setLoading(true);
              setIsLoading(false);
              setPicking(response.data);
              handleProgressCard(response.data);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar(
            `Por favor, insira datas com uma diferença de no máximo 15 dias ou filtre pela onda e pedido`,
            {
              variant: 'warning',
            }
          );
        } else {
          console.error(err);
        }
      }
    },
    [checkFields, enqueueSnackbar, idCliente, idUser]
  );

  const handleSearchCard = useCallback(
    (statusCode: string) => {
      return searchRef.current?.getFieldValue('onda') !== '' ||
        searchRef.current?.getFieldValue('pedido') !== ''
        ? searchRef.current?.submitForm()
        : handleSubmit(
            [],
            statusCode,
            cardsSubmit.DataInicioPicking,
            cardsSubmit.DataFimPicking
          );
    },
    [cardsSubmit.DataFimPicking, cardsSubmit.DataInicioPicking, handleSubmit]
  );

  const setFields = useCallback(() => {
    searchRef.current?.setFieldValue('DataInicioPicking', dateDay);
    searchRef.current?.setFieldValue('DataFimPicking', dateDay);
    searchRef.current?.setFieldValue('status', {
      codigo: 4,
      value: '4',
      label: 'Aguardando Conferência',
      descricao: 'Aguardando Conferência',
    });
    handleSubmit([], 4, dateDay, dateDay);
  }, [dateDay, handleSubmit]);

  const getProduct = async (link: PickingData) => {
    setModalLoading(true);
    setLoading(true);
    const dataDetail = {
      codigoCliente: Number(idCliente),
      User: Number(idUser),
      codigoEndereco: link.codigoPedido,
    };
    await handleProduct(dataDetail).then((orderDetail: AxiosResponse) => {
      if (orderDetail.data.length === 0) {
        setDetailProduct([]);
      } else {
        setDetailProduct([...orderDetail.data]);
        setDetailsPicking(link);
      }
      const timer = setTimeout(() => {
        setModalLoading(false);
        return () => {
          clearTimeout(timer);
        };
      }, 500);
      setOpen(true);
    });
  };

  const getParam = React.useCallback(async () => {
    hendleParamentes(Number(idCliente)).then((response: any) => {
      const shippingCompany = response[0];
      const status = response[1];
      const orderTypes = response[2];

      const orderTypeOptions = transformToSelect(shippingCompany.data);
      setTranspList(orderTypeOptions);

      const statusPickingList = transformToSelect(status.data);
      setStatusList(statusPickingList);

      const formatedOrderTypes = transformToSelect(orderTypes.data);
      setOrderTypeList(formatedOrderTypes);
    });
  }, [idCliente]);

  React.useEffect(() => {
    getParam();
  }, [getParam]);

  useEffect(() => {
    setAnimate(true);
    setFields();
    getStatus();
  }, [getStatus, setFields]);

  return (
    <>
      <FixedHeader title="Picking" subTitle="Acompanhamento" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              searchBoxRef={searchRef}
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'onda',
                  type: 'text',
                  label: 'Onda',
                  placeholder: 'Onda',
                },
                {
                  name: 'pedido',
                  type: 'text',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                },
                {
                  name: 'cliente',
                  type: 'text',
                  label: 'Cliente',
                  placeholder: 'Cliente',
                },
                {
                  name: 'status',
                  type: 'select',
                  options: statusList,
                  isLoading,
                  isDisabled: isLoading,
                  label: 'Status',
                  placeholder: 'Status',
                },
              ]}
              hiddenInputs={[
                {
                  name: 'transportadora',
                  type: 'select',
                  options: transpList,
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                },
                {
                  name: 'tipoPedido',
                  type: 'select',
                  label: 'Tipo Pedido',
                  placeholder: 'Tipo Pedido',
                  options: orderTypeList,
                },
                {
                  name: 'DataInicioPicking',
                  label: 'Data Inicial',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                },
                {
                  name: 'DataFimPicking',
                  label: 'Data Final',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {loading && (
          <>
            <TableFilterCardContainer cardProgressValue={cardProgress}>
              <div>
                {pendingFilter(picking).toString() !== '0' && (
                  <button
                    type="button"
                    onClick={() => searchRef.current?.submitForm()}
                  >
                    <DetailsItem
                      card
                      title="Resultados"
                      value={pendingFilter(picking).toString()}
                      onClick={() => handleSubmit([])}
                    />
                  </button>
                )}
                {pendingFilter(picking, 1).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('12')}>
                    <DetailsItem
                      card
                      title="Aguardando Faturamento"
                      value={pendingFilter(picking, 1).toString()}
                      id="waiting"
                    />
                  </button>
                )}
                {pendingFilter(picking, 2).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('6')}>
                    <DetailsItem
                      card
                      title="Aguardando Expedição"
                      value={pendingFilter(picking, 2).toString()}
                      id="waiting"
                    />
                  </button>
                )}
                {pendingFilter(picking, 3).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('4')}>
                    <DetailsItem
                      card
                      title="Aguardando Conferência"
                      value={pendingFilter(picking, 3).toString()}
                      id="expeditionCompleted"
                    />
                  </button>
                )}
                {pendingFilter(picking, 4).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('7')}>
                    <DetailsItem
                      card
                      title="Expedição Finalizada"
                      value={pendingFilter(picking, 4).toString()}
                      id="expeditionCompleted"
                    />
                  </button>
                )}
                {pendingFilter(picking, 5).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('11')}>
                    <DetailsItem
                      card
                      title="Aguardando Integração"
                      value={pendingFilter(picking, 5).toString()}
                      id="done"
                    />
                  </button>
                )}
                {pendingFilter(picking, 6).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('14')}>
                    <DetailsItem
                      card
                      title="Aguardando Liberação"
                      value={pendingFilter(picking, 6).toString()}
                      id="waiting"
                    />
                  </button>
                )}
              </div>
              <div>
                <DetailsItem
                  card
                  title="Progresso total"
                  value={`${cardProgress.toString()}%`}
                  id="totalProgress"
                >
                  {' '}
                  <span />
                </DetailsItem>
              </div>
            </TableFilterCardContainer>
            <SideDishTable
              animate={animate}
              getProduct={getProduct}
              picking={picking}
            />
          </>
        )}
        <PageMode
          isModal
          modalLoading={modalLoading}
          disableSubmit
          open={open}
          handleClose={() => setOpen(!open)}
          title="Detalhes"
        >
          {/* <DetailsTable detailProduct={detailProduct} /> */}
          <NewShowDetails
            detailProduct={detailProduct}
            picking={detailsPicking}
          />
        </PageMode>
      </Container>
    </>
  );
};
export default PickingFollow;

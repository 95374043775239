import PageMode from 'components/Tools/PageMode';
import { transformToSelect, SelectItem } from 'utils/toSelect';
import api from 'services/apiData';
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from 'hooks/Auth';
import { useSnackbar } from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';
import { getCookie } from 'utils/cookies';
import {
  getDepositList,
  getShippingList,
  getStatusList,
  getTypeList,
} from '../apis/adress.apis';

interface EditModalFormProps {
  openEditModal: boolean;
  closeEditModal: () => Promise<void> | boolean;
  submitEditModal: (data: any) => Promise<void> | boolean;
  editRef: any;
}

const EditModalForm: React.FC<EditModalFormProps> = ({
  openEditModal,
  closeEditModal,
  submitEditModal,
  editRef,
}) => {
  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const idCliente = getCookie('@pdamodules::codigoCliente');

  const { enqueueSnackbar } = useSnackbar();

  const [editTipo, setEditTipo] = useState<SelectItem[]>([]);

  const [editDeposit, setEditDeposit] = useState<SelectItem[]>([]);
  const [editStatus, setEditStatus] = useState<SelectItem[]>([]);
  const [shippingList, setShippingList] = useState<SelectItem[]>([]);

  const [showShippingInput, setShowshippingInput] = useState<boolean>();

  const depositCode = (code?: number | null) => {
    if (String(code) === '21' || String(code) === '15') {
      setShowshippingInput(true);
    } else {
      setShowshippingInput(false);
    }
  };

  const getParam = useCallback(async () => {
    getDepositList(idCliente).then((estruturaEndDataD: AxiosResponse) => {
      const listDeposit = transformToSelect(estruturaEndDataD.data);

      setEditDeposit([...listDeposit]);
    });

    getShippingList(idCliente).then((estruturaEndDataD: AxiosResponse) => {
      const listShipping = transformToSelect(estruturaEndDataD.data);

      setShippingList([...listShipping]);
    });
  }, [idCliente]);

  useEffect(() => {
    const getStatus = async () => {
      getStatusList(idCliente).then((statusData: AxiosResponse) => {
        const listStatus = transformToSelect(statusData.data);

        setEditStatus([...listStatus]);
      });
    };
    const getTipo = async () => {
      getTypeList(idCliente).then((tipoData: AxiosResponse) => {
        const listType = transformToSelect(tipoData.data);
        setEditTipo([...listType]);
      });
    };
    getTipo();
    getStatus();
    getParam();
  }, [getParam, tokenData, idCliente, enqueueSnackbar]);

  useEffect(() => {
    setShowshippingInput(false);
  }, [closeEditModal]);

  return (
    <>
      <PageMode
        isModal
        open={openEditModal}
        title="Editar endereço"
        submitButtonTitle="Salvar"
        modalRefObject={editRef}
        handleSubmit={data => submitEditModal(data)}
        handleClose={closeEditModal}
        inputs={[
          {
            name: 'depositoEdit',
            label: 'Depósito',
            placeholder: 'Depósito',
            type: 'select',
            isRequired: true,
            options: editDeposit,
            onChangeEvent: value => depositCode(value),
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'tipoEdit',
            label: 'Tipo',
            isRequired: true,
            placeholder: 'Tipo',
            type: 'select',
            options: editTipo,
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'statusEdit',
            label: 'Status',
            placeholder: 'Status',
            isRequired: true,
            type: 'select',
            options: editStatus,
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'ressuprimento',
            label: 'Ressuprimento',
            placeholder: 'Ressuprimento',
            type: 'text',
            xl: 6,
            lg: 6,
            xs: 12,
            md: 6,
            sm: 12,
          },
          {
            name: 'capacidade',
            label: 'Capacidade',
            placeholder: 'Capacidade',
            type: 'text',
            xl: 6,
            lg: 6,
            xs: 12,
            md: 6,
            sm: 12,
          },
          {
            name: 'transportadoraEdit',
            label: (showShippingInput && 'Transportadora') || '',
            type: (showShippingInput && 'select') || undefined,
            placeholder: 'Transportadora',
            options: shippingList,
            xl: 6,
            lg: 6,
            xs: 6,
            md: 3,
            sm: 3,
          },
        ]}
      />
    </>
  );
};

export default EditModalForm;

/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from 'react';
import { Fade, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { AxiosError, AxiosResponse } from 'axios';
import { Table, SearchBox } from '@pdasolucoes/web';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import { Container, Search, TableContent } from '../../../styles/styles';
import {
  ConferenceData,
  EanColeta,
} from '../../../../../utils/interfaces/Picking';
import api from '../../../../../services/apiData';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { useAuth } from '../../../../../hooks/Auth';

const ConferenceRaizs: React.FC = () => {
  const [conferenceRaizs, setConferenceRaizs] = React.useState(
    [] as ConferenceData[]
  );
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const [pedido, setPedido] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const inputsBox = React.useRef<FormHandles>(null);
  const [cor, setCor] = React.useState(Boolean);
  const [tamanho, setTamanho] = React.useState(Boolean);
  const [caixa, setCaixa] = React.useState(Boolean);
  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');
  const [finalized, setFinalized] = React.useState(Boolean);
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  const idUser = sessionStorage.getItem('@pdamodules::id');
  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const handleChangeInput = (value: any, id: number | string) => {
    conferenceRaizs.map(p => {
      if (p.produto === id) {
        p.quantidadeConferencia = value;
      }
      return p;
    });
  };

  const handleCancel = React.useCallback(async () => {
    const dataEstorno = {
      codigoCliente: Number(idCliente),
      codigoPedido: pedido,
      user: Number(idUser),
    };

    try {
      await api
        .put(
          `Picking/Conferencia`,
          { ...dataEstorno },
          { headers: { Authorization: `Bearer ${tokenData}` } }
        )
        .then((conferenceData: AxiosResponse) => {
          setLoading(false);
          setPedido('');
          inputsBox.current?.clearField('pedido');
          inputsBox.current?.clearField('embalagem');
          return conferenceData;
        })
        .catch((conferenceError: AxiosError<any>) => {
          if (conferenceError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            conferenceError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }, [pedido, tokenData, idCliente, idUser, enqueueSnackbar]);

  const submitPressEnter = React.useCallback(
    async (event: any, codigoProduto: any) => {
      if (event.which === 13 || event.keyCode === 13) {
        try {
          const putData = {
            codigoCliente: Number(idCliente),
            codigoPedido: pedido,
            embalagem: inputsBox.current?.getFieldValue('embalagem'),
            quantidadeConferencia: event.target.value,
            ean: codigoProduto,
            user: Number(idUser),
          };

          if (putData.embalagem !== ' ' || putData.embalagem !== null) {
            const validaCaixa = await api
              .get(
                `ConsultaCaixa?codigoCliente=${putData.codigoCliente}&caixa=${putData.embalagem}&codigoPedido=${putData.codigoPedido}`,
                { headers: { Authorization: `Bearer ${tokenData}` } }
              )
              .then((validationData: AxiosResponse) => {
                if (validationData.data.length === 0) {
                  return true;
                }

                if (
                  validationData.data[0].mensagem === 'Caixa já endereçada !'
                ) {
                  enqueueSnackbar(validationData.data[0].mensagem, {
                    variant: 'error',
                  });
                  return false;
                }
                if (validationData.data[0].mensagem === 'Caixa não existe !') {
                  enqueueSnackbar(validationData.data[0].mensagem, {
                    variant: 'error',
                  });
                  return false;
                }
                if (
                  validationData.data[0].mensagem ===
                  'Caixa não pertence ao pedido !'
                ) {
                  enqueueSnackbar(validationData.data[0].mensagem, {
                    variant: 'error',
                  });
                  return false;
                }

                return validationData.data.length === 0;
              })
              .catch((validationError: AxiosError<any>) => {
                if (validationError.response?.status === 401 && tokenData) {
                  enqueueSnackbar('Reautenticando, tente novamente.', {
                    variant: 'warning',
                  });
                } else {
                  validationError.response?.data.erros.map((item: any) => {
                    enqueueSnackbar(item.mensagem, { variant: 'error' });
                  });
                }
              });

            if (validaCaixa) {
              const responseColeta = await api
                .put<EanColeta>(
                  `Picking/Conferencia/Coleta`,
                  { ...putData },
                  { headers: { Authorization: `Bearer ${tokenData}` } }
                )
                .then((coletaData: AxiosResponse) => {
                  return coletaData.data;
                })
                .catch((coletadaDataError: AxiosError<any>) => {
                  if (coletadaDataError.response!.status === 401 && tokenData) {
                    enqueueSnackbar('Reautenticando, tente novamente.', {
                      variant: 'warning',
                    });
                  } else {
                    coletadaDataError.response!.data.erros.map((item: any) => {
                      enqueueSnackbar(item.mensagem, { variant: 'error' });
                    });
                  }
                });

              if (responseColeta?.excedido) {
                enqueueSnackbar(`Quantidade excedida!`, { variant: 'error' });
                inputsBox.current?.setFieldValue('codigo', '');
                return;
              }

              if (responseColeta?.finalizado) {
                setLoading(false);
                setVisible(false);
                setFinalized(Boolean(responseColeta?.finalizado));
                setConferenceRaizs([]);
                inputsBox.current?.setFieldValue('pedido', '');
                inputsBox.current?.setFieldValue('embalagem', '');
                inputsBox.current?.getFieldRef('pedido').focus();
                enqueueSnackbar(`Conferência Finalizada!`, {
                  variant: 'success',
                });

                return;
              }
              const responseData = await api
                .get<ConferenceData>(
                  `Picking/Conferencia?codigoCliente=${Number(
                    idCliente
                  )}&codigoPedido=${pedido}`,
                  { headers: { Authorization: `Bearer ${tokenData}` } }
                )
                .then((conferenceDataResponse: AxiosResponse) => {
                  if (
                    conferenceDataResponse.data.findIndex(
                      (item: ConferenceData) => item.cor
                    ) < 0
                  ) {
                    setCor(true);
                  } else {
                    setCor(false);
                  }
                  if (
                    conferenceDataResponse.data.findIndex(
                      (item: ConferenceData) => item.tamanho
                    ) < 0
                  ) {
                    setTamanho(true);
                  } else {
                    setTamanho(false);
                  }
                  if (
                    conferenceDataResponse.data.findIndex(
                      (item: ConferenceData) => item.caixa
                    ) < 0
                  ) {
                    setCaixa(true);
                  } else {
                    setCaixa(false);
                  }
                  return conferenceDataResponse.data;
                })
                .catch((conferenceDataError: AxiosError<any>) => {
                  if (
                    conferenceDataError.response!.status === 401 &&
                    tokenData
                  ) {
                    enqueueSnackbar('Reautenticando, tente novamente.', {
                      variant: 'warning',
                    });
                  } else {
                    conferenceDataError.response!.data.erros.map(
                      (item: any) => {
                        enqueueSnackbar(item.mensagem, { variant: 'error' });
                      }
                    );
                  }
                });

              if (finalized) {
                setLoading(false);
                setVisible(false);
                setFinalized(Boolean(responseColeta?.finalizado));
                setConferenceRaizs([]);
                inputsBox.current?.setFieldValue('pedido', '');
                inputsBox.current?.setFieldValue('embalagem', '');
                inputsBox.current?.getFieldRef('pedido').focus();
                enqueueSnackbar(`Conferência Finalizada!`, {
                  variant: 'success',
                });

                return;
              }
              setConferenceRaizs([...responseData]);
            }
          } else {
            enqueueSnackbar('Favor inserir uma caixa para realizar a coleta!', {
              variant: 'error',
            });
          }
        } catch (err) {
          enqueueSnackbar(String(err), { variant: 'error' });
        }
      }

      return false;
    },
    [enqueueSnackbar, finalized, idCliente, idUser, pedido, tokenData]
  );

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setFinalized(false);

        const schema = Yup.object().shape({
          pedido: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const filterData = {
          codigoCliente: Number(idCliente),
          codigoPedido: data.pedido,
          embalagem: data.embalagem || null,
        };

        setPedido(data.pedido);

        setVisible(true);
        setLoading(false);
        await api
          .get<ConferenceData>(
            `Picking/Conferencia?codigoCliente=${filterData.codigoCliente}&codigoPedido=${filterData.codigoPedido}`,
            { headers: { Authorization: `Bearer ${tokenData}` } }
          )
          .then((conferenceDataResponse: AxiosResponse) => {
            if (conferenceDataResponse.data.length === 0) {
              enqueueSnackbar('Nenhum registro encontrado', {
                variant: 'error',
              });
              setConferenceRaizs([]);
              setVisible(false);
              setLoading(true);
            } else if (conferenceDataResponse.data.length > 0) {
              enqueueSnackbar(
                `Foi encontrado ${conferenceDataResponse.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              inputsBox.current?.getFieldRef('embalagem').focus();

              setConferenceRaizs([...conferenceDataResponse.data]);
              if (
                conferenceDataResponse.data.findIndex(
                  (item: ConferenceData) => item.cor
                ) < 0
              ) {
                setCor(true);
              } else {
                setCor(false);
              }
              if (
                conferenceDataResponse.data.findIndex(
                  (item: ConferenceData) => item.tamanho
                ) < 0
              ) {
                setTamanho(true);
              } else {
                setTamanho(false);
              }
              if (
                conferenceDataResponse.data.findIndex(
                  (item: ConferenceData) => item.caixa
                ) < 0
              ) {
                setCaixa(true);
              } else {
                setCaixa(false);
              }
              setLoading(true);
              setVisible(false);
            }
          })
          .catch((conferenceDataResponseError: AxiosError<any>) => {
            if (
              conferenceDataResponseError.response!.status === 401 &&
              tokenData
            ) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              conferenceDataResponseError.response!.data.erros.map(
                (item: any) => {
                  enqueueSnackbar(item.mensagem, { variant: 'error' });
                }
              );
            }
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          inputsBox.current?.setErrors(errors);
        } else {
          console.error(err);
        }
      }
    },
    [enqueueSnackbar, tokenData, idCliente]
  );

  return (
    <>
      <FixedHeader title="Picking" subTitle="Conferência" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              cancelSubmit
              submitButton
              handleSubmitWithCancel={handleCancel}
              handleSubmit={handleSubmit}
              searchBoxRef={inputsBox}
              inputs={[
                {
                  isRequired: true,
                  name: 'pedido',
                  label: 'Pedido',
                  type: 'text',
                  placeholder: 'Pedido',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'embalagem',
                  label: 'Caixa',
                  type: 'text',
                  placeholder: 'Caixa',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContent>
                    <Table<ConferenceData>
                      exportList
                      rows={[
                        ...conferenceRaizs.map(item => ({
                          ...item,
                        })),
                      ]}
                      columns={[
                        {
                          title: 'Produto',
                          orderable: true,
                          type: 'string',
                          props: ['produto'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Cor',
                          orderable: true,
                          type: 'string',
                          display: cor,
                          props: ['cor'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Tamanho',
                          orderable: true,
                          type: 'string',
                          display: tamanho,
                          props: ['tamanho'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Caixa',
                          orderable: true,
                          type: 'string',
                          display: caixa,
                          props: ['caixa'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Descrição Produto',
                          orderable: true,
                          type: 'string',
                          props: ['descricaoProduto'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Picking',
                          orderable: true,
                          type: 'number',
                          props: ['quantidadePicking'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Conferência',
                          orderable: true,
                          type: 'number',
                          props: ['quantidadeConferencia'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: inputValue => {
                            return (
                              <>
                                <div className="containerInputInTable">
                                  <input
                                    className="inputInTable"
                                    onKeyPress={
                                      event =>
                                        submitPressEnter(
                                          event,
                                          inputValue.produto
                                        )
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                    onChange={
                                      () =>
                                        handleChangeInput(
                                          // @ts-ignore
                                          event?.target?.value,
                                          inputValue.produto
                                        )
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                    name={`input-${inputValue.produto}`}
                                    defaultValue={`${String(
                                      inputValue.quantidadeConferencia
                                    ).trim()}`}
                                  />
                                </div>
                              </>
                            );
                          },
                        },
                        {
                          title: 'Diferença',
                          orderable: true,
                          type: 'number',
                          props: ['quantidadeDiferenca'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                      ]}
                    />
                  </TableContent>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default ConferenceRaizs;

/* eslint-disable react/no-array-index-key */
import React from 'react';

import { IPdfProps } from '../../../types';

const Pdf: React.FC<IPdfProps> = ({ data }) => (
  <div id="page-report">
    <div id="content-report-geral">
      <table id="table-teste" className="table-box">
        <thead>
          <tr>
            <th>Romaneio</th>
            <th>Pedido</th>
            <th>Nota Fiscal</th>
            <th>Volumes</th>
            <th>Caixa</th>
            <th>Cliente</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((detailData, index) => (
              <tr key={index} id={String(detailData.romaneio)}>
                <td>{detailData.romaneio}</td>
                <td>{detailData.codigoPedido}</td>
                <td>{detailData.notaFiscal}</td>
                <td>{detailData.qtdVolumes}</td>
                <td>{detailData.caixa}</td>
                <td>{detailData.cliente ? detailData.cliente : '-'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default Pdf;

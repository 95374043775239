import ConfirmationModal from 'components/Tools/ConfirmationModal';
import React from 'react';

interface DeleteModalFormProps {
  addressTitle: string;
  resetConfirm: () => Promise<void>;
  confirm: () => Promise<void>;
  isConfirmed: boolean;
  handleCloseModal: () => void;
  openModal: boolean;
}

const DeleteModalForm: React.FC<DeleteModalFormProps> = ({
  addressTitle,
  resetConfirm,
  confirm,
  isConfirmed,
  handleCloseModal,
  openModal,
}) => {
  return (
    <ConfirmationModal
      resetConfirm={resetConfirm}
      confirm={confirm}
      isConfirmed={isConfirmed}
      handleCloseModal={handleCloseModal}
      deleteType
      openModal={openModal}
      warningTitle={`Você tem certeza que deseja apagar o endereço
        ${addressTitle}?`}
      doneTitle="Endereço excluído"
    />
  );
};

export default DeleteModalForm;

import React from 'react';
import { Fade } from '@material-ui/core';

import { DetailBoardingData } from 'utils/interfaces/Boarding';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { IDetailsTableProps } from '../../../types';

const pStyles = {
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '21px',
  color: '#b5b5c3',
};

const DetailsTable: React.FC<IDetailsTableProps> = ({ details }) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<DetailBoardingData>
        exportList
        columns={[
          {
            title: 'Transportadora',
            type: 'stringCenter',
            orderable: true,
            props: ['transportadora'],
            cssTitle: {
              width: '1%',
            },
          },
          {
            title: 'Código do pedido',
            type: 'stringCenter',
            props: ['codigoPedido'],
            orderable: true,
          },
          {
            title: 'Nota Fiscal',
            type: 'stringCenter',
            props: ['notaFiscal'],
            orderable: true,
          },
          {
            title: 'Caixa',
            type: 'stringCenter',
            orderable: true,
            props: ['caixa'],
          },
          {
            title: 'Data início',
            type: 'stringCenter',
            orderable: true,
            props: ['dataInicio'],
            renderItem: row => (
              <p style={pStyles}>
                {row.dataInicio
                  ? new Date(row.dataInicio).toLocaleDateString()
                  : '-'}
              </p>
            ),
          },
          {
            title: 'Data final',
            type: 'stringCenter',
            orderable: true,
            props: ['dataFim'],
            renderItem: row => (
              <p style={pStyles}>
                {row.dataFim ? new Date(row.dataFim).toLocaleDateString() : '-'}
              </p>
            ),
          },
          {
            title: 'Usuário',
            type: 'stringCenter',
            orderable: true,
            props: ['usuario'],
          },
        ]}
        rows={details}
      />
    </TableContainer>
  </Fade>
);

export default DetailsTable;

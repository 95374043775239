import api from 'services/api';
import { DetailBoardingData, BoardingData } from 'utils/interfaces/Boarding';

export const getOptions = async (clientId: number) => {
  const [transportadoraRes, periodoEmbarqueRes] = await Promise.all([
    api.get(`Common/Transportadora?codigoCliente=${clientId}`),
    api.get(`Common/PeriodoEmbarque?codigoCliente=${clientId}`),
  ]);

  return [transportadoraRes, periodoEmbarqueRes];
};

export const searchBoxRequest = async (clientId: number, data: any) => {
  const dataFilter = {
    CodigoCliente: clientId,
    romaneio: Number(data.romaneio) || null,
    transportadora: Number(data.transportadora) || null,
    periodo: data.periodo || null,
    pedido: data.pedido || null,
    caixa: data.caixa || null,
    notaFiscal: data.notaFiscal || null,
    dataInicio: data.dataInicial
      ? new Date(data.dataInicial).toISOString()
      : null,
    dataFim: data.dataFinal ? new Date(data.dataFinal).toISOString() : null,
  };

  return api.patch<BoardingData[]>('Embarque', {
    ...dataFilter,
  });
};

export const detailsRequest = async (data: any) =>
  api.patch<DetailBoardingData[]>(`Embarque/Item`, { ...data });

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from 'react';
import { Grid, Fade } from '@material-ui/core';

import { AxiosError, AxiosResponse } from 'axios';

import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { Table, SearchBox } from '@pdasolucoes/web';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import {
  Container,
  Search,
  TableContainer,
  ContainerGraph,
} from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { Storage } from '../../../../../utils/interfaces/Indicators';
import { useAuth } from '../../../../../hooks/Auth';
import Graph, { ILine } from '../../../../../components/Tools/Graph';
import { putOnStreetGraphics } from '../../../../../utils/toGraph';
import { SelectItem, transformToSelect } from '../../../../../utils/toSelect';

const Indic1: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [picking, setPicking] = React.useState([] as Storage[]);
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const inputsBox = React.useRef<FormHandles>(null);
  const [deposit, setDeposit] = React.useState({} as SelectItem[]);
  const [graphic, setGraphic] = React.useState({} as ILine[]);
  const { token } = useAuth();
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  const idUser = sessionStorage.getItem('@pdamodules::id');
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setLoading(false);
        setVisible(true);
        const dataFilter = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          CodigoDeposito: data.deposito || null,
        };

        await api
          .patch<Storage>(
            `Indicadores/Armazenagem`,
            {
              ...dataFilter,
            },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((dataSubmit: AxiosResponse) => {
            if (dataSubmit.data.length === 0) {
              enqueueSnackbar(`Não existe registro!`, { variant: 'error' });
              setPicking([]);
              setGraphic({} as ILine[]);
            } else if (dataSubmit.data.length > 0) {
              enqueueSnackbar(
                `Foi encontrado ${dataSubmit.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              const putTo = putOnStreetGraphics([...dataSubmit.data]);
              setGraphic(putTo);
              setPicking([...dataSubmit.data]);
            }
          })
          .catch((dataSubmitError: AxiosError<any>) => {
            if (dataSubmitError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              dataSubmitError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, tokenData, idCliente, idUser]
  );

  const getParam = React.useCallback(async () => {
    try {
      await api
        .get(`Deposito?CodigoCliente=${Number(idCliente)}`, {
          headers: { Authorization: `Bearer ${tokenData}` },
        })
        .then((depositoData: AxiosResponse) => {
          const listDeposit = transformToSelect(depositoData.data);
          setDeposit([...listDeposit]);
        })
        .catch((depositoDataError: AxiosError<any>) => {
          if (depositoDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            depositoDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }, [enqueueSnackbar, idCliente, tokenData]);

  React.useEffect(() => {
    setAnimate(true);
    getParam();
  }, [getParam]);

  return (
    <>
      <FixedHeader title="Indicadores" subTitle="Indicadores de Armazenagem" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              searchBoxRef={inputsBox}
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'deposito',
                  label: 'Depósito',
                  type: 'select',
                  placeholder: 'Selecione um depósito',
                  options: deposit,
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 400, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && graphic.length > 0 && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <ContainerGraph>
                {graphic.length > 0 && <Graph data={graphic} />}
              </ContainerGraph>
            </Fade>
          </Grid>
        )}
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}

        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ flexGrow: 1 }}>
                    <TableContainer>
                      <Table<Storage>
                        exportList
                        columns={[
                          {
                            title: 'Depósito',
                            type: 'string',
                            orderable: true,
                            props: ['descricaoDeposito'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '2%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Rua',
                            type: 'string',
                            orderable: true,
                            props: ['descricaoRua'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '2%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'End. Cadastrados',
                            type: 'number',
                            orderable: true,
                            props: ['enderecos'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '2%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'End. Utilizados',
                            type: 'number',
                            props: ['ocupados'],
                            orderable: true,
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '2%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'End. Livres',
                            type: 'number',
                            orderable: true,
                            props: ['livres'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                            },
                            cssProps: {
                              width: '2%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                        ]}
                        rows={[
                          ...picking.map(item => ({
                            ...item,
                          })),
                        ]}
                      />
                    </TableContainer>
                  </div>
                </div>
              </>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};
export default Indic1;

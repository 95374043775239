import styled from 'styled-components';

export const ThemeToggleContainer = styled.div<{ isChecked: boolean }>`
  position: relative;
  width: 40px;
  height: 21px;
  background: ${props =>
    props.theme.title === 'light' ? 'rgba(0, 0, 0, 0.2)' : '#464E5F'};
  transition: all 0.5s ease;
  border-radius: 9px;
  position: relative;
  cursor: pointer;
  margin-right: 12px;

  .circle {
    position: absolute;
    top: 50%;
    left: ${props => (props.isChecked ? 'calc(100% - 17px)' : '-5px')};
    transition: left 0.3s;
    transform-origin: center;
    transform: translate3d(0, -50%, 0);
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 50%;
    background: #151521;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .inner-circle {
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #fff 50%, #151521 50%);
      border-radius: 50%;
    }
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { transformToSelect } from 'utils/toSelect';
import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import { Container, Search } from 'app/WMS/styles/styles';
import Loader from 'components/Tools/Loader';
import { InventoryData } from 'utils/interfaces/Log';
import { getCookie } from 'utils/cookies';
import { searchBoxRequest, getAllOptions } from './api/inventory.api';
import { InventoryTable, InventoryDetailsModal } from './components';
import { ILevelProps } from './types';

const Inventory: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [isTableShowing, setIsTableShowing] = useState(false);
  const [brands, setBrands] = useState([]);
  const [deposits, setDeposits] = useState([]);

  const [inventoryStorage, setInventoryStorage] = useState<InventoryData[]>([]);
  const [modalData, setModalData] = useState<InventoryData>(
    {} as InventoryData
  );

  const [codeLevel, setCodeLevel] = useState<ILevelProps[]>([]);
  const [codeLevel2, setCodeLevel2] = useState<ILevelProps[]>([]);
  const [codeLevel3, setCodeLevel3] = useState<ILevelProps[]>([]);
  const [codeLevel4, setCodeLevel4] = useState<ILevelProps[]>([]);
  const [codeLevel5, setCodeLevel5] = useState<ILevelProps[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');

  const userId = getCookie('@pdamodules::id');

  const getOptions = useCallback(async () => {
    setIsLoading(true);

    const [
      depositsRes,
      brandsRes,
      level1Res,
      level2Res,
      level3Res,
      level4Res,
      level5Res,
    ] = await getAllOptions(Number(clientId));

    const formatedDeposits = transformToSelect(depositsRes.data);
    const formatetBrands = transformToSelect(brandsRes.data);
    const formatedFirstLevel = transformToSelect(level1Res.data);
    const formatedSecoundLevel = transformToSelect(level2Res.data);
    const formatedThirdLevel = transformToSelect(level3Res.data);
    const formatedFourthLevel = transformToSelect(level4Res.data);
    const formatedFifthLevel = transformToSelect(level5Res.data);

    setBrands(formatetBrands);
    setDeposits(formatedDeposits);
    setCodeLevel(formatedFirstLevel);
    setCodeLevel2(formatedSecoundLevel);
    setCodeLevel3(formatedThirdLevel);
    setCodeLevel4(formatedFourthLevel);
    setCodeLevel5(formatedFifthLevel);

    setIsLoading(false);
  }, [clientId]);

  const handleSubmit = useCallback(
    async (data: unknown) => {
      setIsLoading(true);
      setIsTableShowing(false);

      await searchBoxRequest(data, Number(clientId), Number(userId))
        .then(res => {
          if (!res.data.length) {
            enqueueSnackbar('Não há registros', {
              variant: 'warning',
            });

            setIsTableShowing(false);

            return;
          }

          enqueueSnackbar(`${res.data.length} resultado(s) encontrados`, {
            variant: 'success',
          });

          setInventoryStorage(res.data);
          setIsTableShowing(true);
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });

          setIsTableShowing(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, userId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <>
      <FixedHeader title="Embarque" subTitle="Acompanhamento" />
      <Fade in timeout={1000}>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={data => handleSubmit(data)}
              inputs={[
                {
                  name: 'endereco',
                  placeholder: 'Endereço',
                  label: 'Endereço',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'deposito',
                  placeholder: 'Depósito',
                  label: 'Depósito',
                  type: 'select',
                  options: deposits,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'pedido',
                  placeholder: 'Pedido',
                  label: 'Pedido',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'produto',
                  placeholder: 'Produto',
                  label: 'Produto',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'marca',
                  placeholder: 'Marca',
                  label: 'Marca',
                  type: 'select',
                  options: brands,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'palete',
                  placeholder: 'Palete',
                  label: 'Palete',
                  type: 'text',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'caixa',
                  placeholder: 'Caixa',
                  label: 'Caixa',
                  type: 'text',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'selectLevel',
                  placeholder: 'Nivel 1',
                  label: 'Nivel 1',
                  type: 'select',
                  isDisabled: isLoading,
                  options: codeLevel,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'selectLevel2',
                  placeholder: 'Nivel 2',
                  label: 'Nivel 2',
                  type: 'select',
                  isDisabled: isLoading,
                  options: codeLevel2,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'selectLevel3',
                  placeholder: 'Nivel 3',
                  label: 'Nivel 3',
                  type: 'select',
                  isDisabled: isLoading,
                  options: codeLevel3,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'selectLevel4',
                  placeholder: 'Nivel 4',
                  label: 'Nivel 4',
                  type: 'select',
                  isDisabled: isLoading,
                  options: codeLevel4,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'selectLevel5',
                  placeholder: 'Nivel 5',
                  label: 'Nivel 5',
                  type: 'select',
                  isDisabled: isLoading,
                  options: codeLevel5,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <Loader />}
          {!isLoading && isTableShowing && (
            <InventoryTable
              rows={inventoryStorage}
              setDetailsModal={setDetailsModal}
              setModalData={setModalData}
            />
          )}
        </Container>
      </Fade>
      {detailsModal && (
        <InventoryDetailsModal
          data={modalData}
          isOpen={detailsModal}
          closeModal={() => setDetailsModal(false)}
        />
      )}
    </>
  );
};

export default Inventory;

import React, { useEffect, useState } from 'react';
import { Fade, MenuItem } from '@material-ui/core';

import OC from 'assets/images/HidroL.jpg';
import Logo from 'assets/images/logo.png';
import { QueryData } from 'utils/interfaces/Expedition';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { exportPdf } from '../../utils';

interface IExpeditionTableProps {
  expeditions: QueryData[];
}

const ExpeditionTable: React.FC<IExpeditionTableProps> = ({ expeditions }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [expedtionsState, setExpeditionsState] = useState<typeof expeditions>(
    []
  );

  useEffect(() => {
    setExpeditionsState(expeditions);
    setIsAnimated(false);
    setTimeout(() => {
      setIsAnimated(true);
    }, 350);
  }, [expeditions]);

  return (
    <Fade in={isAnimated} unmountOnExit>
      <TableContainer>
        <Table<QueryData>
          exportList
          exportOptions={() => (
            <MenuItem onClick={() => exportPdf(OC, Logo, expedtionsState)}>
              PDF
            </MenuItem>
          )}
          columns={[
            {
              title: 'Expedição',
              type: 'number',
              orderable: true,
              props: ['codigoExpedicao'],
              cssProps: {
                width: '1%',
              },
            },
            {
              title: 'Data Expedição',
              type: 'datetime',
              orderable: true,
              props: ['dataExpedicao'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
              renderItem: row => {
                return (
                  <>
                    {row.dataExpedicao ? (
                      <p className="render-string">
                        {new Date(row.dataExpedicao).toLocaleString()}
                      </p>
                    ) : (
                      <p className="render-string">-</p>
                    )}
                  </>
                );
              },
            },
            {
              title: 'Transportadora',
              type: 'string',
              orderable: true,
              props: ['transportadora'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: 'Pedido',
              type: 'stringCenter',
              orderable: true,
              props: ['codigoPedido'],
              cssProps: {
                width: '1%',
              },
            },
            {
              title: 'Nota Fiscal',
              type: 'stringCenter',
              orderable: true,
              props: ['notaFiscal'],
              cssProps: {
                width: '1%',
              },
            },
            {
              title: 'Cliente',
              type: 'stringCenter',
              orderable: true,
              props: ['cliente'],
              cssProps: {
                width: '1%',
              },
            },
            {
              title: 'Usuário Expedição',
              type: 'stringCenter',
              orderable: true,
              props: ['usuario'],
              cssProps: {
                width: '1%',
              },
            },
          ]}
          defaultNumberOfRows={10}
          rows={expedtionsState}
        />
      </TableContainer>
    </Fade>
  );
};

export default ExpeditionTable;

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useContext } from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  Popper,
  PopperPlacementType,
} from '@material-ui/core';
import Notification from 'components/Tools/Notification';
import { getCookie } from 'utils/cookies';
import { useTema } from 'hooks/Theme';
import { SidebarContext } from 'hooks/Sidebar';
import { version } from '../../../../package.json';
import {
  BoxOptions,
  Container,
  Main,
  HeaderContainer,
  CompanyName,
  UserInfo,
} from './styles';

// import Footer from '../Footer';
import Sidebar from '../Sidebar';
import About from '../About';
import { ThemeToggle } from '../../Tools/ThemeToggle';
import { useAuth } from '../../../hooks/Auth';
import { Logo, ArrowLogout, UserIcon } from './icons';

interface Props {
  loged?: boolean;
  children?: React.ReactNode;
}

const SignedLayout: React.FC<Props> = ({ children }) => {
  const sidebarFix = useContext(SidebarContext);
  const [closed, setClosed] = useState(true);
  const [active, setActive] = useState(false);
  const [nameUser, setNameUser] = useState(String);
  const client = getCookie('@pdamodules::descricaoCliente');

  const [mobile, setMobile] = useState(false);
  const [animate, setAnimate] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [date, setDate] = useState(String);
  const data = new Date();
  const { signOut, user } = useAuth();

  const environment_type = String(process.env.REACT_APP_ENVIRONMENTTYPE);

  const { toggleTheme } = useTema();

  const Logout = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(
        `${Zero(data.getUTCDate())}/${Zero(
          data.getMonth() + 1
        )}/${data.getFullYear()} ${Zero(data.getHours())}:${Zero(
          data.getMinutes()
        )}:${Zero(data.getSeconds())}`
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  function Zero(numero: any) {
    if (numero <= 9) {
      return `0${numero}`;
    }

    return numero;
  }
  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(prev => placement !== newPlacement || !prev);
      setAnimate(true);
      setPlacement(newPlacement);
    };

  // const [pageDefault, setPageDefault] = useState('');

  const windowResize = useCallback(() => {
    if (window.innerWidth <= 980) {
      setClosed(false);
      setMobile(true);

      return;
    }

    setClosed(false);
    setMobile(false);
  }, []);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setAnimate(false);
    setOpen(false);
  };
  useEffect(() => {
    windowResize();
    window.addEventListener('resize', windowResize);
  }, [windowResize]);

  return (
    <>
      <Container closed={closed} fixed={sidebarFix.isFixed}>
        <HeaderContainer>
          {mobile && (
            <div className="logo">
              <Logo />
            </div>
          )}
          <div className="invisible-block" />
          <CompanyName>{nameUser}</CompanyName>
          <div className="userInteractions">
            <ThemeToggle toggleTheme={toggleTheme} />
            {/* todo: componente de notificação */}
            <UserInfo environment_type={environment_type}>
              <Notification />
              <div className="greetings">
                <p>
                  Olá, <span className="userName">{user?.data.nome}</span>
                </p>
                <span className="environment-type">
                  {environment_type || 'não informado'}
                </span>
                <span className="version">v{version}</span>
              </div>
              <div className="user">
                <IconButton id="btn" onClick={handleClick('top')}>
                  <UserIcon />
                </IconButton>
                {/* @ts-ignore */}
                <Box className="popper-box">
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    onClick={Logout}
                    placement={placement}
                    transition
                    className="popper"
                    style={{ zIndex: 999999 }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Fade in={animate}>
                        <BoxOptions>
                          <Button className="logoutBtn" ref={anchorRef}>
                            <p>Sign out</p>
                            <ArrowLogout />
                          </Button>
                        </BoxOptions>
                      </Fade>
                    </ClickAwayListener>
                  </Popper>
                </Box>
              </div>
              <About />
            </UserInfo>
          </div>
        </HeaderContainer>
        <Main onClick={() => mobile && setClosed(false)} id="main">
          <>
            {children}
            <div className="shape shapeOne" />
            {/* <footer className="footer">
              <Footer />
            </footer> */}
          </>
        </Main>
      </Container>
      <Sidebar active={active} />
    </>
  );
};

export default SignedLayout;

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useTheme } from 'styled-components';
import { Divider, Fade, Grid, IconButton, Tooltip } from '@material-ui/core';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { AxiosError, AxiosResponse } from 'axios';
import { TagStatus, HyperLink, SearchBox } from '@pdasolucoes/web';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import { Table } from '../../../../../components/Tools/Table';
import {
  OrderData,
  DetailOrderData,
} from '../../../../../utils/interfaces/Log';
import { SelectItem, transformToSelect } from '../../../../../utils/toSelect';
import {
  Actions,
  Container,
  Search,
  TableContainer,
} from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { useAuth } from '../../../../../hooks/Auth';

const OrderFollow: React.FC = () => {
  const [order, setOrder] = React.useState([] as OrderData[]);
  const [detailOrder, setDetailOrder] = React.useState([] as DetailOrderData[]);
  const [status, setStatus] = React.useState({} as SelectItem[]);
  const [visible, setVisible] = React.useState(false);
  const [detail, setDetail] = React.useState(NaN);
  const { codeStatus } = useParams<Record<string, string | undefined>>();
  const { enqueueSnackbar } = useSnackbar();
  const [animate, setAnimate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(Boolean);
  const [table, setTable] = React.useState(true);
  const { colors } = useTheme();
  const [rowSelected, setRowSelected] = React.useState('');
  const [substituir, setSubstituir] = React.useState(Boolean);
  const [description, setDescription] = React.useState('');
  const [cor, setCor] = React.useState(Boolean);
  const [grade, setGrade] = React.useState(Boolean);
  const { token } = useAuth();
  const [replaceLoading, setReplaceLoading] = React.useState(Boolean);
  const inputsBox = React.useRef<FormHandles>(null);
  const [transportadoraCode, setTransportadoraCode] = React.useState(
    {} as SelectItem[]
  );
  const [period, setPeriod] = React.useState({} as SelectItem[]);
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  const idUser = sessionStorage.getItem('@pdamodules::id');
  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');
  const history = useHistory();

  const getStatus = React.useCallback(async () => {
    if (Number(codeStatus)) {
      try {
        const itensFiltered: SelectItem[] = Object.values(status).filter(
          itensSta => itensSta.value === codeStatus
        );
        const filtered = itensFiltered[0];
        inputsBox.current?.setFieldValue('status', { ...filtered });

        const dataFilter = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          CodigoStatus: codeStatus,
        };
        setOrder([]);
        await api
          .patch<OrderData>(
            `Relatorio/Pedido`,
            {
              ...dataFilter,
            },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((data: AxiosResponse) => {
            if (data.data.length === 0) {
              setOrder([]);
            } else {
              setOrder([...data.data]);

              const timer = setTimeout(() => {
                setLoading(true);
                setVisible(false);
                return () => {
                  clearTimeout(timer);
                };
              }, 1000);
            }
          })
          .catch((error: AxiosError<any>) => {
            if (error.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              error.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    } else {
      history.push('/wms/followOrder/');
    }
  }, [
    codeStatus,
    status,
    history,
    tokenData,
    idCliente,
    idUser,
    enqueueSnackbar,
  ]);

  const getData = React.useCallback(
    async (data: any) => {
      try {
        setLoading(false);
        setVisible(true);
        setOrder([]);

        const dataFilter = {
          CodigoStatus: Number(data.status) || null,
          CodigoCliente: Number(idCliente),
          CodigoPedido: data.pedido || null,
          cliente: data.cliente || null,
          codigoTransportadora: data.transportadora || null,
          periodo: data.periodo || null,
          user: Number(idUser),
        };
        await api
          .patch(
            `Relatorio/Pedido`,
            { ...dataFilter },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((dataOrder: AxiosResponse) => {
            if (dataOrder.data.length > 0) {
              setOrder([...dataOrder.data]);
              if (
                dataOrder.data.findIndex((item: OrderData) => item.substituir) <
                0
              ) {
                setSubstituir(true);
              } else {
                setSubstituir(false);
              }
              setTable(true);
              setDetail(NaN);
              enqueueSnackbar(
                `Foi encontrado ${dataOrder.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
            } else if (dataOrder.data.length === 0) {
              setOrder([]);
              setTable(true);
              setDetail(NaN);
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
            }
          })
          .catch((orderError: AxiosError<any>) => {
            if (orderError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              orderError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, tokenData, idUser, idCliente]
  );

  const handleTraceBack = React.useCallback(() => {
    setDetail(NaN);
    setTable(true);
  }, []);

  const getProduct = React.useCallback(
    async (code: number) => {
      const dataDetail = {
        codigoCliente: Number(idCliente),
        codigoPedido: code,
      };
      try {
        await api
          .get(
            `Relatorio/PedidoDetalhe?codigoCliente=${dataDetail.codigoCliente}&codigoPedido=${dataDetail.codigoPedido}`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((detailOrderData: AxiosResponse) => {
            if (detailOrderData.data.length > 0) {
              if (
                detailOrderData.data.findIndex(
                  (item: DetailOrderData) => item.cor
                ) < 0
              ) {
                setCor(true);
              } else {
                setCor(false);
              }
              if (
                detailOrderData.data.findIndex(
                  (item: DetailOrderData) => item.tamanho
                ) < 0
              ) {
                setGrade(true);
              } else {
                setGrade(false);
              }
              setDetailOrder([...detailOrderData.data]);
              setDescription(String(code));
              setTable(false);
              setDetail(code);
              enqueueSnackbar(
                `Foi encontrado ${detailOrderData.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
            } else if (detailOrderData.data.length === 0) {
              setDetailOrder([]);
              setTable(true);
              setDetail(NaN);
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
            }
          })
          .catch((detailError: AxiosError<any>) => {
            if (detailError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              detailError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    },
    [enqueueSnackbar, tokenData, idCliente]
  );

  const getParam = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await api
        .get<SelectItem>(
          `Common/StatusPicking?codigoCliente=${Number(idCliente)}`,
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then((statusPickingData: AxiosResponse) => {
          const listStatus = transformToSelect([...statusPickingData.data]);
          setStatus([...listStatus]);
        })
        .catch((statusPickingError: AxiosError<any>) => {
          if (statusPickingError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            statusPickingError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
      await api
        .get<SelectItem>(
          `Common/Transportadora?codigoCliente=${Number(idCliente)}`,
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then((transportadoraData: AxiosResponse) => {
          const listTransportadora = transformToSelect([
            ...transportadoraData.data,
          ]);
          setTransportadoraCode([...listTransportadora]);
        })
        .catch((transportadoraDataError: AxiosError<any>) => {
          if (transportadoraDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            transportadoraDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
      await api
        .get<SelectItem>(
          `Common/PeriodoEmbarque?codigoCliente=${Number(idCliente)}`,
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then((PeriodoEmbarqueData: AxiosResponse) => {
          const listPeriodEmbarque = transformToSelect([
            ...PeriodoEmbarqueData.data,
          ]);
          setPeriod([...listPeriodEmbarque]);
        })
        .catch((PeriodoEmbarqueDataError: AxiosError<any>) => {
          if (PeriodoEmbarqueDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            PeriodoEmbarqueDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [tokenData, idCliente, enqueueSnackbar]);

  React.useEffect(() => {
    getStatus();
  }, [getStatus]);

  React.useEffect(() => {
    setAnimate(true);
    getParam();
  }, [getParam]);

  const replaceHas = React.useCallback(
    async (pedido: string) => {
      if (pedido) {
        const pedidoSubstituir = {
          codigoPedido: pedido,
          codigoCliente: Number(idCliente),
        };

        try {
          setRowSelected(pedido);
          setReplaceLoading(true);
          enqueueSnackbar('Realizando subtituição...', {
            variant: 'info',
          });
          await api
            .post(
              `Picking/PedidoSubstituir`,
              { ...pedidoSubstituir },
              { headers: { Authorization: `Bearer ${tokenData}` } }
            )
            .then((data: AxiosResponse) => {
              if (Object.values(data.data).length > 1) {
                setRowSelected('');
                setReplaceLoading(false);
                enqueueSnackbar('Substituição solicitada com sucesso!', {
                  variant: 'success',
                });
                getData((res: any) => res);
              }
            })
            .catch((error: AxiosError<any>) => {
              if (error.response!.status === 401 && tokenData) {
                enqueueSnackbar('Reautenticando, tente novamente.', {
                  variant: 'warning',
                });
              } else {
                error.response!.data.erros.map((item: any) => {
                  enqueueSnackbar(item.mensagem, { variant: 'error' });
                });
              }
            });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [enqueueSnackbar, getData, idCliente, tokenData]
  );

  return (
    <>
      <FixedHeader title="Pedido" subTitle="Acompanhamento" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={getData}
              searchBoxRef={inputsBox}
              advancedSearch
              hiddenInputs={[
                {
                  name: 'transportadora',
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                  type: 'select',
                  options: transportadoraCode,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
                {
                  name: 'periodo',
                  label: 'Periodo',
                  placeholder: 'Periodo',
                  type: 'select',
                  options: period,
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
              ]}
              inputs={[
                {
                  name: 'pedido',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'cliente',
                  label: 'Cliente',
                  placeholder: 'Cliente',
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'status',
                  label: 'Status',
                  placeholder: 'Status',
                  type: 'select',
                  options: status,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {table && (
          <>
            {loading && (
              <Grid container>
                <Fade in={animate} unmountOnExit timeout={1000}>
                  <div className="wrapper" style={{ display: 'flex' }}>
                    <div className="wrap" style={{ flexGrow: 1 }}>
                      <TableContainer>
                        <Table<OrderData>
                          exportList
                          columns={[
                            {
                              title: 'Pedido',
                              type: 'string',
                              orderable: true,
                              props: ['codigoPedido'],
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '2%',
                                paddingRight: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                              renderItem: link => {
                                return (
                                  // eslint-disable-next-line react/jsx-curly-newline
                                  <HyperLink
                                    type="detail"
                                    style={{
                                      paddingLeft: '16px',
                                      fontSize: '13px',
                                    }}
                                    title={String(link.codigoPedido)}
                                    onClick={() =>
                                      // eslint-disable-next-line prettier/prettier
                                      getProduct(link.codigoPedido)
                                    }
                                  />
                                );
                              },
                            },
                            {
                              title: 'Cliente',
                              type: 'string',
                              props: ['cliente'],
                              orderable: true,
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '5%',
                                paddingRight: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                            },
                            {
                              title: 'Quantidade',
                              type: 'number',
                              position: 'right',
                              orderable: true,
                              props: ['quantidadePedido'],
                              cssTitle: {
                                justifyContent: 'flex-start',
                              },
                              cssProps: {
                                width: '2%',
                                paddingRight: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                            },
                            {
                              title: 'Período',
                              type: 'string',
                              orderable: true,
                              display: 'notEmpty',
                              props: ['periodo'],
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '5%',
                                paddingRight: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                                textAlign: 'left',
                              },
                            },
                            {
                              title: 'Transportadora',
                              type: 'string',
                              orderable: true,
                              display: 'notEmpty',
                              props: ['transportadora'],
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '5%',
                                paddingRight: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                                textAlign: 'left',
                              },
                            },
                            {
                              title: 'Recebido',
                              type: 'string',
                              orderable: true,
                              props: ['recebido'],
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '3%',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                                textAlign: 'left',
                              },
                              renderItem: row => {
                                return (
                                  <>
                                    {row.recebido ? (
                                      <p className="render-string">
                                        {new Date(
                                          row.recebido
                                        ).toLocaleString()}
                                      </p>
                                    ) : (
                                      <p className="render-string">-</p>
                                    )}
                                  </>
                                );
                              },
                            },
                            {
                              title: 'Planejamento',
                              type: 'string',
                              orderable: true,
                              display: 'notEmpty',
                              props: ['planejamento'],
                              cssTitle: {
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '3%',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                              renderItem: row => {
                                return (
                                  <>
                                    {row.planejamento ? (
                                      <p className="render-string">
                                        {new Date(
                                          row.planejamento
                                        ).toLocaleString()}
                                      </p>
                                    ) : (
                                      <p className="render-string">-</p>
                                    )}
                                  </>
                                );
                              },
                            },
                            {
                              title: 'Finalizado',
                              type: 'string',
                              orderable: true,
                              display: 'notEmpty',
                              props: ['finalizado'],
                              cssTitle: {
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '1%',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                              renderItem: row => {
                                return (
                                  <>
                                    {row.finalizado ? (
                                      <p className="render-string">
                                        {new Date(
                                          row.finalizado
                                        ).toLocaleString()}
                                      </p>
                                    ) : (
                                      <p className="render-string">-</p>
                                    )}
                                  </>
                                );
                              },
                            },
                            {
                              title: 'Status',
                              type: 'string',
                              orderable: true,
                              props: ['descricaoStatus'],
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '3%',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                              renderItem: row => {
                                return (
                                  <TagStatus
                                    status={row.descricaoStatus}
                                    color={
                                      colors.tagTextColors.filter(
                                        tag => tag.name === row.descricaoStatus
                                      ).length > 0
                                        ? colors.tagTextColors.filter(
                                            tag =>
                                              tag.name === row.descricaoStatus
                                          )[0].value
                                        : undefined
                                    }
                                    background={
                                      colors.tagBackgroundColors.filter(
                                        tag => tag.name === row.descricaoStatus
                                      ).length > 0
                                        ? colors.tagBackgroundColors.filter(
                                            tag =>
                                              tag.name === row.descricaoStatus
                                          )[0].value
                                        : undefined
                                    }
                                  />
                                );
                              },
                            },
                            {
                              title: 'Ações',
                              type: undefined,
                              props: ['substituir'],
                              display: substituir,
                              cssTitle: {
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                              },
                              cssProps: {
                                width: '1%',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                                fontFamily: 'sans-serif',
                              },
                              cssText: {
                                paddingLeft: '8px',
                              },
                              renderItem: actions => {
                                return (
                                  <>
                                    {actions.substituir ? (
                                      <Tooltip title="Realizar Substituição">
                                        <Actions>
                                          <IconButton
                                            className={`replaceButton ${
                                              replaceLoading &&
                                              rowSelected ===
                                                String(actions.codigoPedido)
                                                ? 'active'
                                                : null
                                            }`}
                                            onClick={() => {
                                              replaceHas(
                                                String(actions.codigoPedido)
                                              );
                                            }}
                                            aria-label="Editar"
                                          >
                                            <AutorenewRoundedIcon color="disabled" />
                                          </IconButton>
                                        </Actions>
                                      </Tooltip>
                                    ) : null}
                                  </>
                                );
                              },
                            },
                          ]}
                          rows={[
                            ...order.map(item => ({
                              ...item,
                            })),
                          ]}
                        />
                      </TableContainer>
                    </div>
                  </div>
                </Fade>
              </Grid>
            )}
          </>
        )}
        {!!detail && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContainer>
                    <div className="detailTableWrap">
                      <IconButton
                        style={{ padding: '8px' }}
                        onClick={handleTraceBack}
                        aria-label="Voltar"
                      >
                        <KeyboardBackspaceRoundedIcon color="disabled" />
                      </IconButton>
                      <p>Pedido: {description}</p>
                    </div>
                    <Divider
                      orientation="horizontal"
                      style={{ background: colors.line, marginBottom: '1rem' }}
                    />
                    <Table<DetailOrderData>
                      exportList
                      columns={[
                        {
                          title: 'Produto',
                          type: 'string',
                          props: ['produto'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Descrição do Produto',
                          type: 'string',
                          props: ['descricaoProduto'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Cor',
                          type: 'string',
                          props: ['cor'],
                          display: 'notEmpty',
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'left',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Grade',
                          type: 'string',
                          props: ['tamanho'],
                          display: 'notEmpty',
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'left',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Quantidade',
                          type: 'number',
                          props: ['quantidade'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'left',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                      ]}
                      rows={[
                        ...detailOrder.map(item => ({
                          ...item,
                        })),
                      ]}
                    />
                  </TableContainer>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default OrderFollow;

import React, { useCallback, useState } from 'react';

import { getCookie } from 'utils/cookies';
import ConfirmationModal from 'components/Tools/ConfirmationModal';
import { deleteComponentRequest } from '../../../api/components.api';
import { IDeleteComponentModal } from '../../../types';

const DeleteComponentModal: React.FC<IDeleteComponentModal> = ({
  isOpen,
  closeModal,
  modalData,
  clearModalData,
}) => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const clientId = Number(getCookie('@pdamodules::codigoCliente'));

  const handleDeleteComponent = useCallback(async () => {
    await deleteComponentRequest(modalData.codigoComponente, clientId).then(
      () => {
        setIsConfirmed(true);
      }
    );
  }, [clientId, modalData]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    clearModalData();
  }, [closeModal, clearModalData]);

  return (
    <ConfirmationModal
      deleteType
      openModal={isOpen}
      isConfirmed={isConfirmed}
      confirm={handleDeleteComponent}
      handleCloseModal={handleCloseModal}
      resetConfirm={async () => setIsConfirmed(false)}
      warningTitle={`Deseja deletar ${modalData.descricaoComponente}`}
      doneTitle={`${modalData.descricaoComponente} deletado com sucesso!`}
    />
  );
};

export default DeleteComponentModal;

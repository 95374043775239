import api from 'services/api';
import { AddressesData } from 'utils/interfaces/Storage';
import { transformToSelect } from 'utils/toSelect';

export const uploadFile = (tokenData: string, file: any) =>
  api.post('armazenagem/UploadAddress', file, {
    headers: {
      Authorization: `Bearer ${tokenData}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export const submitData = (data: any) =>
  api.patch<AddressesData>(`Armazenagem/Endereco`, { ...data });

export const getAddressList = (idCliente?: string) =>
  api.get(
    `Armazenagem/EstruturaEndereco?codigoCliente=${Number(idCliente)}&tipo=R`
  );

export const getModulesList = (idCliente?: string) =>
  api.get(
    `Armazenagem/EstruturaEndereco?codigoCliente=${Number(idCliente)}&tipo=M`
  );

export const getNivelList = (idCliente?: string) =>
  api.get(
    `Armazenagem/EstruturaEndereco?codigoCliente=${Number(idCliente)}&tipo=N`
  );

export const getToWalkList = (idCliente?: string) =>
  api.get(
    `Armazenagem/EstruturaEndereco?codigoCliente=${Number(idCliente)}&tipo=A`
  );

export const getPositionList = (idCliente?: string) =>
  api.get(
    `Armazenagem/EstruturaEndereco?codigoCliente=${Number(idCliente)}&tipo=P`
  );

export const getDepositList = (idCliente?: string) =>
  api.get(`Common/Deposito?codigoCliente=${Number(idCliente)}`);

export const getStatusList = (idCliente?: string) =>
  api.get(`Common/StatusEndereco?codigoCliente=${Number(idCliente)}`);

export const getTypeList = (idCliente?: string) =>
  api.get(`Common/Tipo?codigoCliente=${Number(idCliente)}`);

export const getShippingList = (idCliente?: string) =>
  api.get(`Common/Transportadora?codigoCliente=${Number(idCliente)}`);

export const editAddress = (dataDetail: any) =>
  api.put(`Armazenagem/Endereco`, {
    ...dataDetail,
  });

export const deleteAddress = (deleteCodigo: number, idCliente?: string) =>
  api.delete(
    `Armazenagem/Endereco?codigo=${deleteCodigo}&codigoCliente=${Number(
      idCliente
    )}`
  );

export const editStatus = (dataRequest: any) =>
  api.put(`Armazenagem/Endereco/Status`, [...dataRequest]);

export const getFiliais = async (clientId: string, query: string) =>
  api.get(`Recebimento/searchbranch?codigoCliente=${clientId}&Filial=${query}`);

type StuctureTypes = 'R' | 'M' | 'N' | 'A' | 'P';

export const getAddressStucture = async (
  clientId: string,
  type: StuctureTypes
) =>
  api.get(
    `Armazenagem/EstruturaEndereco?codigoCliente=${clientId}&tipo=${type}`
  );

export const getAllSelectOptions = async (clientId: string) => {
  const [
    listAddressRes,
    listModulesRes,
    listNivelRes,
    listToWalkeRes,
    listPositionRes,
    listDepositRes,
    listShippingRes,
  ] = await Promise.all([
    getAddressStucture(clientId, 'R'),
    getAddressStucture(clientId, 'M'),
    getAddressStucture(clientId, 'N'),
    getAddressStucture(clientId, 'A'),
    getAddressStucture(clientId, 'P'),
    getAddressStucture(clientId, 'R'),
    api.get(`Common/Deposito?codigoCliente=${clientId}`),
    api.get(`Common/Transportadora?codigoCliente=${clientId}`),
  ]);

  const listAddress = transformToSelect(listAddressRes.data);
  const listModules = transformToSelect(listModulesRes.data);
  const listNivel = transformToSelect(listNivelRes.data);
  const listToWalke = transformToSelect(listToWalkeRes.data);
  const listPosition = transformToSelect(listPositionRes.data);
  const listDeposit = transformToSelect(listDepositRes.data);
  const listShipping = transformToSelect(listShippingRes.data);

  return [
    listAddress,
    listModules,
    listNivel,
    listToWalke,
    listPosition,
    listDeposit,
    listShipping,
  ];
};

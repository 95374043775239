import React, { useCallback, useLayoutEffect, useState } from 'react'; // < ,
import { useSnackbar } from 'notistack';

import PageMode from 'components/Tools/PageMode';
import { DetailInventoryData } from 'utils/interfaces/Log';
import InventoryDetailsTable from './InventoryDetailsTable';
import { detailsRequest } from '../../api/inventory.api';
import { IInventoryDetailsModalProps } from '../../types';

const InventoryDetailsModal: React.FC<IInventoryDetailsModalProps> = ({
  isOpen,
  closeModal,
  data,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [inventoryDetail, setInventoryDetail] = useState<DetailInventoryData[]>(
    []
  );

  const getDetails = useCallback(async () => {
    setIsLoading(true);

    await detailsRequest(data)
      .then(res => {
        if (!res.data.length) {
          enqueueSnackbar('Não há registros', {
            variant: 'warning',
          });

          closeModal();

          return;
        }

        enqueueSnackbar(`${res.data.length} resultado(s) encontrados`, {
          variant: 'success',
        });
        setInventoryDetail(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [data, enqueueSnackbar, closeModal]);

  useLayoutEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <PageMode
      isModal
      disableSubmit
      open={isOpen}
      closeWhenClickOutside
      handleClose={closeModal}
      title="Detalhes"
      modalLoading={isLoading}
    >
      <InventoryDetailsTable rows={inventoryDetail} />
    </PageMode>
  );
};

export default InventoryDetailsModal;

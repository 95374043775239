import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import { transformToSelect } from 'utils/toSelect';
import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import { Container, Search } from 'app/WMS/styles/styles';
import { BoardingData } from 'utils/interfaces/Boarding';
import Loader from 'components/Tools/Loader';
import { getOptions, searchBoxRequest } from './api/boarding.api';
import { BoardingTable, BoardingDetailsModal } from './components';

const Boarding: React.FC = () => {
  const [periods, setPeriods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [isTableShowing, setIsTableShowing] = useState(false);
  const [transportadoras, setTransportadoras] = useState([]);
  const [boardings, setBoardings] = useState<BoardingData[]>([]);
  const [boarding, setBoarding] = useState<BoardingData>({} as BoardingData);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');

  const getAllOptions = useCallback(async () => {
    setIsLoading(true);

    const [transportadoraRes, periodoEmbarqueRes] = await getOptions(
      Number(clientId)
    );

    const formatedTransp = transformToSelect(transportadoraRes.data);
    const formatedPeriods = transformToSelect(periodoEmbarqueRes.data);

    setPeriods(formatedPeriods);
    setTransportadoras(formatedTransp);

    setIsLoading(false);
  }, [clientId]);

  const handleSubmit = useCallback(
    async (data: unknown) => {
      setIsLoading(true);
      setIsTableShowing(false);

      await searchBoxRequest(Number(clientId), data)
        .then(res => {
          if (!res.data.length) {
            enqueueSnackbar('Não há registros', {
              variant: 'warning',
            });

            setBoardings([]);

            return;
          }
          setBoardings(res.data);
          setIsTableShowing(true);
          enqueueSnackbar(`Foi encontrado ${res.data.length} registro(s)!`, {
            variant: 'success',
          });
        })
        .catch(err => {
          setIsTableShowing(false);
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  return (
    <>
      <FixedHeader title="Embarque" subTitle="Acompanhamento" />
      <Fade in timeout={1000}>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={data => handleSubmit(data)}
              hiddenInputs={[
                {
                  name: 'pedido',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'caixa',
                  label: 'Caixa',
                  placeholder: 'Caixa',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataInicial',
                  label: 'Data Inicial',
                  type: 'date',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFinal',
                  label: 'Data Final',
                  type: 'date',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              inputs={[
                {
                  name: 'romaneio',
                  label: 'Romaneio',
                  placeholder: 'Romaneio',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'transportadora',
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                  type: 'select',
                  options: transportadoras,
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'periodo',
                  label: 'Periodo',
                  placeholder: 'Periodo',
                  type: 'select',
                  isDisabled: isLoading,
                  options: periods,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'notaFiscal',
                  label: 'Nota Fiscal',
                  placeholder: 'Nota Fiscal',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <Loader />}
          {!isLoading && isTableShowing && (
            <BoardingTable
              boardings={boardings}
              setBoardingData={setBoarding}
              setModal={setDetailsModal}
            />
          )}
        </Container>
      </Fade>
      {detailsModal && (
        <BoardingDetailsModal
          data={boarding}
          isOpen={detailsModal}
          closeModal={() => setDetailsModal(false)}
        />
      )}
    </>
  );
};

export default Boarding;

import React from 'react';

import { IconContainer } from './styles';

interface IIconProps {
  color?: string;
  icon: string;
}

const Icon: React.FC<IIconProps> = ({ color, icon }) => {
  return <IconContainer color={color} icon={icon} />;
};

export default Icon;

/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import { useSnackbar } from 'notistack';
import { Fade, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { Table } from 'components/Tools/Table';
import SearchBox from 'components/Tools/Search';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Tools/Loader';
import { getCookie } from 'utils/cookies';
import FixedHeader from '../../../../components/Tools/Breadcrumb';
import { ICompositionProduct } from '../../../../utils/interfaces/Composition';
import {
  Container,
  Search,
  TableContainer,
  Actions,
} from '../../styles/styles';
import api from '../../../../services/apiData';
import { useAuth } from '../../../../hooks/Auth';

const Composition: React.FC = () => {
  const [animate, setAnimate] = React.useState(false);
  const [composition, setComposition] = React.useState(
    [] as ICompositionProduct[]
  );
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = useState(Boolean);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const idCliente = getCookie('@pdamodules::codigoCliente');

  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const location = useHistory();

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setVisible(true);
        setLoading(false);
        setIsLoading(true);
        setComposition([]);

        const dataFiltro = {
          produto: data.produto || null,
          descricaoProduto: data.descricaoProduto || null,
          componente: data.componente || null,
          CodigoCliente: Number(idCliente),
        };

        await api
          .get<ICompositionProduct>('/Componentes/Composicao/Produtos', {
            params: dataFiltro,
            headers: { Authorization: `Bearer ${tokenData}` },
          })
          .then((componenteData: AxiosResponse) => {
            if (componenteData.data.length === 0) {
              setComposition([]);
              setIsLoading(false);
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
            } else {
              setComposition([...componenteData.data]);
              setIsLoading(false);
              enqueueSnackbar(
                `Foi encontrado ${componenteData.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
            }
          })
          .catch((estruturaError: AxiosError<any>) => {
            if (estruturaError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              estruturaError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setIsLoading(false);
          setVisible(false);

          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, tokenData, idCliente]
  );

  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const detailsHas = React.useCallback(
    async (codeComposition: string) => {
      location.push(`/wms/composition/create/${codeComposition}`);
    },
    [location]
  );

  return (
    <>
      <FixedHeader title="Cadastros" subTitle="Composição" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'produto',
                  label: 'Produto',
                  placeholder: 'Produto',
                  type: 'text',
                  isLoading,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'descricaoProduto',
                  label: 'Descrição Produto',
                  placeholder: 'Descrição Produto',
                  type: 'text',
                  isLoading,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {loading && (
          <Fade in={animate} unmountOnExit timeout={1000}>
            <div className="wrapper" style={{ display: 'flex' }}>
              <div className="wrap" style={{ flexGrow: 1 }}>
                <TableContainer>
                  <Table<ICompositionProduct>
                    exportList
                    columns={[
                      {
                        title: 'Produto',
                        type: 'string',
                        orderable: true,
                        props: ['produto'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '5%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Descrição Produto',
                        type: 'string',
                        orderable: true,
                        props: ['descricaoProduto'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '10%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Cor',
                        type: 'string',
                        orderable: true,
                        props: ['cor'],
                        display: 'notEmpty',
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '10%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Tamanho',
                        type: 'string',
                        orderable: true,
                        props: ['tamanho'],
                        display: 'notEmpty',
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '10%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Ações',
                        type: undefined,
                        props: [''],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '1%',
                          paddingRight: '8px',
                          paddingLeft: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                        renderItem: actions => {
                          return (
                            <Actions>
                              <IconButton
                                onClick={() => {
                                  detailsHas(actions.produto);
                                }}
                                aria-label="Editar"
                              >
                                <CreateRoundedIcon color="disabled" />
                              </IconButton>
                            </Actions>
                          );
                        },
                      },
                    ]}
                    rows={[
                      ...composition.map(item => ({
                        ...item,
                      })),
                    ]}
                  />
                </TableContainer>
              </div>
            </div>
          </Fade>
        )}
      </Container>
    </>
  );
};

export default Composition;

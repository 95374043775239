import api from 'services/api';

export const getOptions = async (clientId: number) => {
  const [statesRes, transpRes] = await Promise.all([
    api.get('Common/Estados'),
    api.get(`Common/Transportadora?codigoCliente=${clientId}`),
  ]);

  return [statesRes, transpRes];
};

export const submitBoxRequest = async (data: any) =>
  api.patch('/Relatorio/Expedicao', { ...data });

import React from 'react';

import * as C from './styles';
import {
  IPropsChildren,
  IRegisterButtonProps,
  ICadastroHeaderProps,
} from '../types';

export const Header: React.FC<
  React.PropsWithChildren<ICadastroHeaderProps>
> = ({ title, customizePadding, hasMoreThanOne, children }) => (
  <C.HeaderContainer
    customizePadding={customizePadding}
    hasMoreThanOne={hasMoreThanOne}
  >
    <h2>{title}</h2>
    {children}
  </C.HeaderContainer>
);

export const CadastroContainer: React.FC<IPropsChildren> = ({ children }) => (
  <C.RegisterContainer>
    <Header title="Cadastro" customizePadding />
    <C.RegisterContent>
      <C.ControlsContainer>
        <C.ControlsContainerTitle>
          Escolha a forma de cadastro
        </C.ControlsContainerTitle>
        <C.RegisterButtonContainer>{children}</C.RegisterButtonContainer>
      </C.ControlsContainer>
    </C.RegisterContent>
  </C.RegisterContainer>
);

export const RegisterButton: React.FC<IRegisterButtonProps> = ({
  bg,
  text,
  icon,
  size,
  color,
  loading,
  ...rest
}) => (
  <C.RegisterButtonBox
    {...rest}
    bg={bg}
    color={color}
    disabled={loading}
    size={size}
  >
    {loading ? <span>Carr</span> : icon && <img src={icon} alt="icon" />}
    {text}
  </C.RegisterButtonBox>
);

import React, { useState, useLayoutEffect, useCallback } from 'react';
import { ValidateOnlyNumbers } from '@pdasolucoes/web';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import Loader from 'components/Tools/Loader';
import FixedHeader from 'components/Tools/Breadcrumb';
import { ResupplyData } from 'utils/interfaces/Reports';
import { Container, Search } from 'app/WMS/styles/styles';
import SearchBox from 'components/Tools/Search';
import { getOptions, searchBoxRequest } from './api/resupply.api';
import { ResupplyTable } from './components';

const Resupply: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingTable, setIsShowingTable] = useState(false);
  const [transportadoras, setTransportadoras] = useState([]);
  const [resupplys, setResupplys] = useState<ResupplyData[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');
  const userId = getCookie('@pdamodules::id');

  const getAllOptions = useCallback(async () => {
    setIsLoading(true);

    const [tranportadorasRes] = await getOptions(clientId);

    setTransportadoras(tranportadorasRes);
    setIsLoading(false);
  }, [clientId]);

  const handleSearchBoxSubmit = useCallback(
    async (data: unknown) => {
      setIsShowingTable(false);
      setIsLoading(true);

      await searchBoxRequest(data, clientId, userId)
        .then(res => {
          if (!res.data.length) {
            setIsLoading(false);
            setIsShowingTable(false);
            setResupplys([]);

            enqueueSnackbar('Não há registros', {
              variant: 'warning',
            });
            return;
          }
          setResupplys(res.data);
          setIsShowingTable(true);
          enqueueSnackbar(`${res.data.length} resultado(s) encontrados!`, {
            variant: 'success',
          });
        })
        .catch(err => {
          setIsShowingTable(false);

          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, userId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  return (
    <>
      <FixedHeader title="Relatório" subTitle="Ressuprimento" />
      <Fade in timeout={1000} unmountOnExit>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={data => handleSearchBoxSubmit(data)}
              inputs={[
                {
                  name: 'origem',
                  placeholder: 'Origem',
                  type: 'text',
                  label: 'Origem',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 12,
                },
                {
                  name: 'CodigoPedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  label: 'Pedido',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 12,
                },
                {
                  name: 'destino',
                  placeholder: 'Destino',
                  type: 'text',
                  label: 'Destino',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 12,
                },
                {
                  name: 'produto',
                  placeholder: 'Produto',
                  type: 'text',
                  label: 'Produto',
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 12,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'transportadora',
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                  type: 'select',
                  options: transportadoras,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 6,
                  xs: 6,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'prioridade',
                  label: 'Prioridade',
                  placeholder: 'Prioridade',
                  type: 'text',
                  onKeyPress: ValidateOnlyNumbers,
                  xl: 6,
                  lg: 6,
                  xs: 6,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <Loader />}
          {!isLoading && isShowingTable && <ResupplyTable rows={resupplys} />}
        </Container>
      </Fade>
    </>
  );
};

export default Resupply;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';

import PageMode from 'components/Tools/PageMode';
import { IFormProps } from '../types';

const Form: React.FC<IFormProps> = ({
  open,
  title,
  selectOptions,
  handleSubmit,
  closeModal,
  modalLoading,
  buttonTitle,
  modalData,
}) => {
  const inputsRef = useRef<FormHandles>(null);

  const setInputsValues = useCallback(() => {
    inputsRef.current?.setFieldValue('descricao', modalData?.descricao);
    inputsRef.current?.setFieldValue('ativo', modalData?.ativo);
    for (let i = 0; i < selectOptions.length; i += 1) {
      if (
        String(selectOptions[i].description).toLowerCase() ===
        String(modalData?.tipo).toLowerCase()
      ) {
        inputsRef.current?.setFieldValue('tipo', selectOptions[i]);
        return;
      }
    }
  }, [modalData, selectOptions]);

  useEffect(() => {
    setInputsValues();
  }, [setInputsValues]);

  return (
    <>
      <PageMode
        isModal
        open={open}
        title={title}
        submitButtonIcon
        modalLoading={modalLoading}
        submitButtonTitle={buttonTitle}
        modalRefObject={inputsRef}
        inputs={[
          {
            name: 'tipo',
            label: 'Tipo',
            placeholder: 'Tipo',
            type: 'select',
            options: selectOptions,
            xl: 5,
            xs: 5,
            lg: 5,
          },
          {
            name: 'descricao',
            label: 'Descrição',
            placeholder: 'Descrição',
            type: 'text',
          },
          {
            name: 'ativo',
            label: 'Está ativo ?',
            type: 'switch',
          },
        ]}
        handleSubmit={handleSubmit}
        handleClose={() => closeModal(false)}
      />
    </>
  );
};

export default Form;

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const cpfMask = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const rgMask = (value: any) => {
  return value
    .replace(/\DX-x/g, '')
    .replace(/^(\d{2})(\d{3})(\d{3})([\dX-x])$/, '$1.$2.$3-$4');
};

export const ValidateOnlyNumbers = (evt: any) => {
  const theEvent = evt || window.event;
  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

export const placaMask = (value: any) => {
  const mercosul = /([A-Za-z]{3}[0-9]{1}[A-Za-z]{1})/;
  const normal = /([A-Za-z]{3}[0-9]{2})/;
  const replaced = value.replace(/[^\w]/g, '');
  if (normal.exec(replaced)) {
    value = value.replace(/^([A-Za-z]{3})([0-9]{4})$/, '$1-$2');
  } else if (mercosul.exec(replaced)) {
    value = value.replace(
      /^([A-Za-z]{3})([0-9]{1})([A-Za-z]{1})([0-9]{2})$/,
      '$1 $2$3$4'
    );
  }
  return value;
};

export function removeAccents(s: string) {
  let r: string = s;
  const non_asciis = {
    A: '[ÀÁÂÃÄÅ]',
    AE: 'Æ',
    C: 'Ç',
    E: '[ÈÉÊË]',
    I: '[ÌÍÎÏ]',
    N: 'Ñ',
    O: '[ÒÓÔÕÖ]',
    OE: 'Œ',
    U: '[ÙÚÛŰÜ]',
    Y: '[ÝŸ]',
    a: '[àáâãäå]',
    ae: 'æ',
    c: 'ç',
    e: '[èéêë]',
    i: '[ìíîï]',
    n: 'ñ',
    o: '[òóôõö]',
    oe: 'œ',
    u: '[ùúûűü]',
    y: '[ýÿ]',
  };
  for (const i in non_asciis) {
    // @ts-ignore
    r = String(r).replace(new RegExp(non_asciis[i], 'g'), i);
  }
  return r;
}

export const isDateValue = (value: string) => {
  const isoDateRegex = /\d{4}-\d{2}-\d{2}[T]\d{2}:\d{2}:\d{2}/g;
  const universalDateRegex = /\d{4}-\d{2}-\d{2}/g;
  const universalDateRegex2 = /\d{4}-\d{2}-\d{1}/g;

  if (isoDateRegex.test(value)) {
    const d1 = value.split('T')[0].split('-');
    d1[0] = d1[0].slice(2, 4);
    const date = d1.reverse().join('/');
    const time = value.split('T')[1].split(':').slice(0, 2).join(':');
    return `${date}-${time}`;
  }

  if (universalDateRegex.test(value)) {
    return value.split('-').slice(0, 3).reverse().join('/');
  }

  if (universalDateRegex2.test(value)) {
    return value.split('-').slice(0, 3).reverse().join('/');
  }

  return value;
};

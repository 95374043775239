import styled from 'styled-components';

export const CenterIt = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.45rem;
  padding: 2rem 1rem;
`;

export const FileUploadTitle = styled.h3`
  color: #0095e8;
  font-weight: 500;
  font-size: clamp(14px, 1rem, 5vmin);
  text-align: center;
`;

export const FileUploadImage = styled.img`
  max-width: 100%;
  display: block;
  object-fit: cover;
  user-select: none;
`;

export const FileUploadButton = styled.label`
  color: #fff;
  background-color: #0095e8;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 12px 22px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #0095e8bb;
  }
`;

export const FileUploadControls = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  border-top: 1px solid #ebedf2;
  padding: 1.25rem;
`;

export const FileNamesBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
`;

export const FileName = styled.span`
  color: #0095e8bb;
  opacity: 0.825;
  font-size: 12px;
  font-weight: 500;
`;

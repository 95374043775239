import React from 'react';
import { Grid } from '@material-ui/core';
import TagStatus from 'components/Tools/TagStatus';
import ProgressBar from 'components/Tools/ProgressBar';
import { TableFilterCardContainer } from 'app/WMS/styles/styles';
import DetailsItem from 'components/Tools/DetailsItem';
import DetailsTable from './DetailsTable';
import { IDetailProps } from '../../types';

const NewShowDetails: React.FC<IDetailProps> = ({ detailProduct, picking }) => {
  return (
    <>
      <div className="wrapper">
        <div className="contentDetail">
          <Grid container spacing={3} className="detailHeader">
            <div className="details">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="detailsItemsContainer" id="firstRow">
                  <DetailsItem
                    title="Documento"
                    value={String(picking.codigoPedido)}
                  />

                  <DetailsItem
                    title="Nota Fiscal"
                    value={
                      String(picking.notaFiscal) === 'undefined'
                        ? ''
                        : String(picking.notaFiscal)
                    }
                  />

                  <DetailsItem title="Cliente" value={picking.cliente} />
                </div>
                <div style={{ width: '40%' }}>
                  <ProgressBar
                    value={
                      picking.progresso === undefined ? 0 : picking.progresso
                    }
                    showTitle
                    style={{ marginRight: '11px' }}
                  />
                </div>
              </div>
              <div className="detailsItemsContainer">
                <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap' }}>
                  <DetailsItem
                    title="Data de recebimento:"
                    value={
                      new Date(
                        picking.dataPlanejamento
                      ).toLocaleDateString() === 'Invalid Date'
                        ? ''
                        : new Date(picking.dataPlanejamento).toLocaleDateString(
                            'pt-BR'
                          )
                    }
                  />
                  <DetailsItem
                    title="Hora de início"
                    value={
                      new Date(
                        picking.dataPlanejamento
                      ).toLocaleTimeString() === 'Invalid Date'
                        ? ''
                        : new Date(
                            picking.dataPlanejamento
                          ).toLocaleTimeString()
                    }
                  />
                  <div className="detailsItem" style={{ paddingTop: '10px' }}>
                    <TagStatus
                      status={picking.descricaoStatus}
                      width="fit-content"
                    />
                  </div>
                  <DetailsItem
                    title="Data de fim:"
                    value={
                      new Date(picking.dataFimPicking).toLocaleDateString() ===
                      'Invalid Date'
                        ? ''
                        : new Date(picking.dataFimPicking).toLocaleDateString()
                    }
                  />
                  <DetailsItem
                    title="Hora de fim"
                    value={
                      new Date(picking.dataFimPicking).toLocaleTimeString() ===
                      'Invalid Date'
                        ? ''
                        : new Date(picking.dataFimPicking).toLocaleTimeString()
                    }
                  />
                </div>
                <div className="totalTimeResume">
                  <div className="detailsItem">
                    <p>Tempo total:</p>
                    <p>{picking.tempoTotal || ''}</p>
                  </div>
                </div>
              </div>
            </div>
            <TableFilterCardContainer withPadding>
              <div>
                <DetailsItem
                  card
                  title="Quantidade Total"
                  value={String(
                    picking.quantidadePedido === undefined
                      ? 0
                      : picking.quantidadePedido
                  )}
                />

                <DetailsItem
                  card
                  id="divergent"
                  title="Quantidade Divergente"
                  value={String(
                    picking.quantidadeDivergente === undefined
                      ? 0
                      : picking.quantidadeDivergente
                  )}
                />
                <DetailsItem
                  card
                  id="done"
                  title="Quantidade Conferida"
                  value={String(
                    picking.quantidadeConferida === undefined
                      ? 0
                      : picking.quantidadeConferida
                  )}
                />
              </div>
            </TableFilterCardContainer>
          </Grid>
        </div>
      </div>
      <DetailsTable detailProduct={detailProduct} />
    </>
  );
};

export default NewShowDetails;

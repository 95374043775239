import React from 'react';
import { Fade } from '@material-ui/core';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { IDetailsItemsProps, DetailLogData } from '../../types';

const DetailsItems: React.FC<IDetailsItemsProps> = ({ animate, detailLog }) => {
  return (
    <Fade in={animate} unmountOnExit timeout={1000}>
      <TableContainer>
        <Table<DetailLogData>
          exportList
          columns={[
            {
              title: 'Endereço',
              type: 'string',
              props: ['endereco'],
              cssProps: {
                width: '5%',
                paddingRight: '18px',
              },
              renderItem: row => {
                return (
                  <div className="code-item">
                    <p style={{ marginLeft: '5%' }}>{row.endereco}</p>
                  </div>
                );
              },
            },
            {
              title: 'Tipo',
              type: 'stringCenter',
              orderable: true,
              props: ['tipoEndereco'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Palete',
              type: 'stringCenter',
              orderable: true,
              props: ['palete'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Caixa',
              type: 'stringCenter',
              orderable: true,
              props: ['caixa'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Fabricante',
              type: 'stringCenter',
              orderable: true,
              props: ['fabricante'],
              cssProps: {
                width: '2%',
              },
            },
            {
              title: 'Produto',
              type: 'string',
              orderable: true,
              props: ['produto'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Descrição do Produto',
              type: 'string',
              orderable: true,
              props: ['descricaoProduto'],
            },
            {
              title: 'Quantidade',
              type: 'number',
              orderable: true,
              props: ['quantidade'],
            },
            {
              title: 'Usuário',
              type: 'stringCenter',
              orderable: true,
              props: ['usuario'],
            },
            {
              title: 'Data Armazenagem',
              type: 'string',
              orderable: true,
              props: ['dataArmazenagem'],
              formatter: (row: any) =>
                new Date(row.dataArmazenagem).toLocaleString(),
            },
          ]}
          rows={[
            ...detailLog.map((item: any) => ({
              ...item,
            })),
          ]}
        />
      </TableContainer>
    </Fade>
  );
};
export default DetailsItems;

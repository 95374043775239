import React, { useCallback, useState, useLayoutEffect } from 'react';
import { Fade } from '@material-ui/core';
import { SelectItem, transformToSelect } from 'utils/toSelect';
import { useSnackbar } from 'notistack';

import SearchBox from 'components/Tools/Search';
import FixedHeader from 'components/Tools/Breadcrumb';
import { StructureData } from 'utils/interfaces/Storage';
import { getCookie } from 'utils/cookies';
import { Container, Search } from 'app/WMS/styles/styles';
import Loader from 'components/Tools/Loader';
import Modal from 'components/Tools/Modal';
import { AxiosResponse } from 'axios';
import Form from './components/form';
import { IconCreate } from './utils/icon';
import StructureTable from './components/table';
import {
  handleStructure,
  handleDelete,
  handleOptionsEstructure,
  handleStructureData,
  handleSearchBox,
  handleCreate,
} from './api/structure.api';

const Follow: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [modalLoading, setModalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [structures, setStructures] = useState([] as StructureData[]);
  const [modalData, setModalData] = useState<StructureData>(
    {} as StructureData
  );
  const [structureOptions, setStructureOptions] = useState({} as SelectItem[]);

  const clientId = getCookie('@pdamodules::codigoCliente');

  const userId = getCookie('@pdamodules::id');

  const getStructureOptions = useCallback(async () => {
    setModalLoading(true);
    await handleOptionsEstructure(clientId).then(({ data }) => {
      const options = transformToSelect(data);
      setStructureOptions(options);
      setModalLoading(false);
    });
  }, [clientId]);

  const getStructures = useCallback(async () => {
    setIsLoading(true);

    await handleStructureData(clientId).then(({ data }) => {
      if (!data.length) {
        enqueueSnackbar(`Nenhum endereço encontrado`, {
          variant: 'warning',
        });
        setStructures([]);
        return;
      }
      setStructures(data);
      enqueueSnackbar(`${data.length} endereços encontrados`, {
        variant: 'success',
      });
      setIsLoading(false);
    });
  }, [clientId, enqueueSnackbar]);

  const handleEditStructure = useCallback(
    async data => {
      console.log(data);
      setIsLoading(true);
      const dataDetail = {
        codigo: modalData.codigo,
        descricao: data.descricao,
        tipo: data.tipo,
        ativo: data.ativo,
        CodigoCliente: Number(clientId),
        user: Number(userId),
      };
      handleStructure(dataDetail).then((response: AxiosResponse) => {
        enqueueSnackbar('Estrutura editada com sucesso!', {
          variant: 'success',
        });
        getStructures();
        setIsLoading(false);
      });
      setEditMode(false);
    },
    [modalData.codigo, clientId, userId, enqueueSnackbar, getStructures]
  );

  const handleDeleteStructure = useCallback(async () => {
    handleDelete(modalData, clientId, userId).then((res: AxiosResponse) => {
      enqueueSnackbar(`Registro deletado com sucesso`, {
        variant: 'success',
      });
      getStructures();
    });
    setDeleteModal(false);
  }, [modalData, userId, clientId, enqueueSnackbar, getStructures]);

  const handleSearchBoxSubmit = useCallback(
    async data => {
      setModalLoading(true);
      setIsLoading(true);
      if (data) {
        const dataFilters = {
          codigo: data.codigo || null,
          descricao: data.descricao || null,
          tipo: data.tipo || null,
          ativo: data.ativo || false,
          CodigoCliente: Number(clientId),
          badRequest: data.badRequest || null,
        };

        await handleSearchBox(dataFilters).then(res => {
          setIsLoading(false);
          if (!res.data.length) {
            setStructures([]);
            setModalLoading(false);
            enqueueSnackbar('Nenhum registro foi encontrado.', {
              variant: 'warning',
            });

            return;
          }
          setModalLoading(false);
          setStructures(res.data);
          enqueueSnackbar(`Foi encontrado ${res.data.length} registro(s).`, {
            variant: 'success',
          });
        });

        return;
      }
      getStructures();
    },
    [getStructures, clientId, enqueueSnackbar]
  );

  const handleCreateStructure = useCallback(
    async data => {
      const dataCreate = {
        Descricao: data.descricao,
        ativo: data.ativo || false,
        Tipo: data.tipo,
        user: Number(userId),
        CodigoCliente: Number(clientId),
      };

      await handleCreate(dataCreate).then(res => {
        if (res.data.badRequest) {
          enqueueSnackbar('Algo deu errado com a requisição', {
            variant: 'error',
          });

          return;
        }

        getStructures();

        enqueueSnackbar('Estrutura criada com sucesso!', {
          variant: 'success',
        });

        setCreateModal(false);
      });
    },
    [userId, clientId, enqueueSnackbar, getStructures]
  );

  useLayoutEffect(() => {
    getStructures();
    getStructureOptions();
  }, [getStructureOptions, getStructures]);

  return (
    <>
      <FixedHeader title="Estrutura" subTitle="Criar Estrutura" />
      <Fade in timeout={1000}>
        <Container>
          <Search>
            <SearchBox
              submitButton
              searchDisabled={isLoading}
              titleButtonOnCreate="Novo Estrutura"
              createButton={() => setCreateModal(true)}
              handleSubmit={handleSearchBoxSubmit}
              createButtonContent={
                <>
                  <IconCreate />
                  <p>Nova Estrutura</p>
                </>
              }
              inputs={[
                {
                  name: 'tipo',
                  label: 'Tipo',
                  placeholder: 'Tipo',
                  type: 'select',
                  options: structureOptions,
                  isDisabled: isLoading,
                },
                {
                  name: 'descricao',
                  label: 'Descrição',
                  placeholder: 'Descrição',
                  type: 'text',
                  isDisabled: isLoading,
                },
                {
                  name: 'ativo',
                  label: 'Está ativo ?',
                  placeholder: 'Ativo',
                  type: 'switch',
                  isDisabled: isLoading,
                  defaultChecked: true,
                },
              ]}
            />
          </Search>
          {isLoading ? (
            <Loader />
          ) : (
            <StructureTable
              adresses={structures}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditMode}
              setModalData={setModalData}
            />
          )}
        </Container>
      </Fade>

      <Form
        buttonTitle="Criar"
        open={createModal}
        title="Criar uma estrutura"
        handleSubmit={handleCreateStructure}
        closeModal={setCreateModal}
        selectOptions={structureOptions}
      />
      <Form
        buttonTitle="Editar"
        open={editMode}
        title={`Editar ${modalData.descricao}`}
        handleSubmit={handleEditStructure}
        closeModal={setEditMode}
        selectOptions={structureOptions}
        modalLoading={modalLoading}
        modalData={modalData}
      />
      <Modal
        type="delete"
        handleSubmit={handleDeleteStructure}
        open={deleteModal}
        handleCancel={() => {
          setDeleteModal(false);
          setModalData({} as StructureData);
        }}
      >
        Deseja deletar a estrutura <strong>{modalData.descricao}</strong>
      </Modal>
    </>
  );
};

export default Follow;

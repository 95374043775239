import React from 'react';
import { Fade } from '@material-ui/core';
import { formatToNumber } from '@pdasolucoes/web';
import deleteIcon from 'assets/svg/deleteActionsIcon.svg';
import editIcon from 'assets/svg/editActionsIcon.svg';
import TagStatus from 'components/Tools/TagStatus';
import { Table } from 'components/Tools/Table';
import { AddressesData } from 'utils/interfaces/Storage';
import { TableContainer } from 'app/WMS/styles/styles';

export interface AdressTableProps {
  detailsHas: (actions: any) => void;
  deleteHas: (
    dataCodigo: number,
    dataTipo: string,
    dataDescription: string
  ) => Promise<void>;
  Structures: AddressesData[];
  editLines: (linesItem: any) => void;
}

const AdressTable: React.FC<AdressTableProps> = ({
  detailsHas,
  deleteHas,
  Structures,
  editLines,
}) => {
  const animate = true;

  return (
    <Fade in={animate} unmountOnExit timeout={1000}>
      <TableContainer>
        <Table<AddressesData>
          exportList
          columns={[
            {
              title: 'Depósito',
              type: 'stringCenter',
              orderable: true,
              props: ['deposito'],
              cssProps: {
                width: '4%',
              },
            },
            {
              title: 'Tipo',
              type: 'stringCenter',
              orderable: true,
              props: ['tipo'],
              cssProps: {
                width: '4%',
              },
            },
            {
              title: 'Status',
              type: 'string',
              orderable: true,
              props: ['status'],
              cssProps: {
                width: '3%',
              },
              renderItem: row => {
                return <TagStatus status={row.status} />;
              },
            },
            {
              title: 'Andar',
              type: 'stringCenter',
              orderable: true,
              props: ['andar'],
              display: 'notEmpty',
              cssProps: {
                width: '3%',
              },
            },
            {
              title: 'Rua',
              type: 'stringCenter',
              orderable: true,
              props: ['rua'],
              cssProps: {
                width: '3%',
              },
            },
            {
              title: 'Módulo',
              orderable: true,
              type: 'stringCenter',
              props: ['modulo'],
              cssProps: {
                width: '3%',
              },
            },
            {
              title: 'Nível',
              type: 'stringCenter',
              orderable: true,
              props: ['nivel'],
              cssProps: {
                width: '3%',
              },
            },
            {
              title: 'Posição',
              orderable: true,
              type: 'stringCenter',
              props: ['posicao'],
              cssProps: {
                width: '3%',
              },
            },
            {
              title: 'Endereço',
              type: 'stringCenter',
              orderable: true,
              props: ['descricaoEndereco'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Ressu.',
              orderable: true,
              type: 'number',
              props: ['ressuprimento'],
              cssTitle: {
                justifyContent: 'flex-start',
              },
              cssProps: {
                width: '5%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: row => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {row.ressuprimento ? (
                        <p className="render-number">
                          {formatToNumber(row.ressuprimento)}
                        </p>
                      ) : (
                        <p className="render-number">0</p>
                      )}
                    </div>
                  </>
                );
              },
            },
            {
              title: 'Cap.',
              orderable: true,
              type: 'number',
              props: ['capacidade'],
              cssTitle: {
                justifyContent: 'flex-start',
              },
              cssProps: {
                width: '5%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: row => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {row.capacidade ? (
                        <p className="render-number">
                          {formatToNumber(row.capacidade)}
                        </p>
                      ) : (
                        <p className="render-number">0</p>
                      )}
                    </div>
                  </>
                );
              },
            },
            {
              title: 'Ações',
              type: undefined,
              props: [''],
              cssProps: {
                width: '5%',
                paddingRight: '8px',
                paddingLeft: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: actions => {
                return (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        deleteHas(
                          actions.codigo,
                          actions.tipo,
                          actions.descricaoEndereco
                        );
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Excluir</p>
                    </button>
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        detailsHas(actions);
                      }}
                    >
                      <img
                        src={editIcon}
                        alt="edit"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Editar</p>
                    </button>
                  </div>
                );
              },
            },
          ]}
          defaultNumberOfRows={10}
          defaultSort="codigoPedido"
          rows={[
            ...Structures.map((item: any) => ({
              ...item,
            })),
          ]}
          selectBox
          selectBoxActions={[
            {
              onClick: listAddress => editLines(listAddress),
              renderItem: () => (
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.21927 3.65856L3.6639 0.212295C3.94682 -0.0707651 4.43487 -0.0707651 4.7178 0.212295L14.9173 10.4166C15.0234 10.5228 15.0941 10.6502 15.1224 10.7988L15.9853 15.1084C16.0349 15.356 15.9571 15.6037 15.7802 15.7806C15.6388 15.9222 15.4478 16 15.2568 16C15.2073 16 15.1578 15.9929 15.1083 15.9858L10.8007 15.1225C10.6593 15.0942 10.5249 15.0234 10.4188 14.9173L0.21927 4.71296C0.0778065 4.57143 9.53674e-07 4.38744 9.53674e-07 4.18222C9.53674e-07 3.99115 0.0778065 3.80009 0.21927 3.65856ZM10.631 13.0703L13.0783 10.5723L4.22268 1.71252L4.47024 1.46484L4.16609 1.71252L1.71878 4.2176L10.631 13.0703ZM13.8351 11.8673L11.8688 13.8346L11.89 13.8983L14.3939 14.3441L13.8351 11.8673Z"
                      fill="#0095E8"
                    />
                  </svg>

                  <p>Editar Status</p>
                </>
              ),
            },
          ]}
        />
      </TableContainer>
    </Fade>
  );
};

export default AdressTable;

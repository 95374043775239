import React, { useCallback, useState } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { SimpleTabs } from '@pdasolucoes/web';

import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import { Container, Search, TableContainer } from 'app/WMS/styles/styles';
import { ProdutivityConferenceData } from 'utils/interfaces/Reports';
import Loader from 'components/Tools/Loader';
import { getCookie } from 'utils/cookies';
import { AnalyticTable, SyntheticTable } from './components';
import { searchBoxRequest } from './api/productivityconference.api';

const ProductivityConference: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingTable, setIsShowingTable] = useState(false);
  const [produtivityConferenceData, setProdutivityConferenceData] =
    useState<ProdutivityConferenceData>({} as ProdutivityConferenceData);

  const { enqueueSnackbar } = useSnackbar();

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const clientId = getCookie('@pdamodules::codigoCliente');

  const handleSearchBoxSubmit = useCallback(
    async (dataForm: unknown) => {
      setIsShowingTable(false);
      setIsLoading(true);

      await searchBoxRequest(dataForm, clientId)
        .then(({ data }) => {
          if (!data.analitico.length && !data.sintetico.length) {
            enqueueSnackbar('Resultados não encontrados', {
              variant: 'warning',
            });

            setProdutivityConferenceData({} as ProdutivityConferenceData);

            return;
          }
          setProdutivityConferenceData(data);
          setIsShowingTable(true);
          enqueueSnackbar(
            `${data.analitico.length} resultado(s) foram encontrados no analítico e ${data.sintetico.length} resultado(s) encontrados no sintético`,
            {
              variant: 'success',
            }
          );
        })
        .catch(err => {
          setIsShowingTable(false);
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  return (
    <>
      <FixedHeader title="Produtividade" subTitle="Conferência" />
      <Fade in timeout={1000} unmountOnExit>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={data => handleSearchBoxSubmit(data)}
              inputs={[
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: date,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: date,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'nome',
                  placeholder: 'Nome',
                  type: 'text',
                  label: 'Nome',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <Loader />}
          {!isLoading && isShowingTable && (
            <TableContainer>
              <SimpleTabs
                tabs={[
                  { anchorTab: 0, label: 'Tabela Sintética' },
                  { anchorTab: 1, label: 'Tabela Analítica' },
                ]}
                content={[
                  {
                    anchorContent: 0,
                    children: () => (
                      <SyntheticTable
                        rows={produtivityConferenceData.sintetico}
                      />
                    ),
                  },
                  {
                    anchorContent: 1,
                    children: () => (
                      <AnalyticTable
                        rows={produtivityConferenceData.analitico}
                      />
                    ),
                  },
                ]}
              />
            </TableContainer>
          )}
        </Container>
      </Fade>
    </>
  );
};

export default ProductivityConference;

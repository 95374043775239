import Jspdf from 'jspdf';

import { IPdf } from '../types';

export function renderPdfSignatures(context: Jspdf, pdfData: IPdf) {
  context.setFontSize(8);
  context.text(
    `Declaro que acompanhei o carregamento das Notas e Volumes constantes neste documento:`,
    20,
    570,
    {
      charSpace: 1,
    }
  );

  context.setFillColor(0, 0, 0);

  context.rect(
    450,

    570,
    context.internal.pageSize.width / 2 - 175,
    1,
    'FD'
  );

  context.text(
    `Notas com pendência de Volumes: ${pdfData.details.length}`,
    20,
    600,
    {
      charSpace: 1,
    }
  );

  context.text(`Notas Completas: ${pdfData.details.length}`, 200, 600, {
    charSpace: 1,
  });

  context.text(`Qtde Pedidos:`, 20, 630, {
    charSpace: 1,
  });

  context.rect(90, 630, context.internal.pageSize.width / 2 - 175, 1, 'FD');
  context.text(`Responsavel:`, 225, 630, {
    charSpace: 1,
  });

  context.rect(290, 630, context.internal.pageSize.width / 2 - 190, 1, 'FD');
  context.text(`RG:`, 415, 630, {
    charSpace: 1,
  });

  context.rect(440, 630, context.internal.pageSize.width / 2 - 150, 1, 'FD');
  context.text(`Responsavel Transportadora:`, 20, 660, {
    charSpace: 1,
  });

  context.rect(160, 660, context.internal.pageSize.width / 2 - 175, 1, 'FD');
  context.text(`Placa:`, 300, 660, {
    charSpace: 1,
  });

  context.rect(340, 660, context.internal.pageSize.width / 2 - 175, 1, 'FD');
  context.text(`Data:`, 115, 710, {
    charSpace: 1,
  });
  context.rect(145, 710, context.internal.pageSize.width / 2 - 175, 1, 'FD');

  context.text(`Data:`, 335, 710, {
    charSpace: 1,
  });

  context.rect(365, 710, context.internal.pageSize.width / 2 - 175, 1, 'FD');
}

/* eslint-disable dot-notation */
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie/es6/Cookies';
import { getCookie } from 'utils/cookies';
import config from '../config';

const cookies = new Cookies();
const login = getCookie('@pdamodules::login');
const refreshToken = cookies.get('@pdamodules::token');
const access_token = cookies.get('@pdamodules::token');

const api = axios.create({
  baseURL: config.apiConfig.baseUrl,
  headers: { Authorization: `Bearer ${access_token?.replace(/"/g, '')}` },
});

api.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },

  error => {
    if (error.response.status === 401 && access_token) {
      reAuthentication();
    }

    if (error.response.status !== 401) {
      toast.error('Ops! algo deu errado');
    }

    return Promise.reject(error);
  }
);

function reAuthentication() {
  api
    .post(`${config.apiConfig.baseUrl}Autenticacao/Refresh-Token`, {
      login,
      refreshToken,
    })
    .then(response => {
      const token = response.data.accessToken;
      api.defaults.headers.Authorization = `Bearer ${token}`;
      localStorage.setItem('@pdamodules::token', JSON.stringify(token));
      window.location.reload();
    })
    .catch(() => {
      sessionStorage.clear();
      window.location.replace(
        `${
          process.env.REACT_APP_ENV === 'dev'
            ? process.env.REACT_APP_LOGOUT_DEV_URL
            : process.env.REACT_APP_LOGOUT_PROD_URL
        }`
      );
    });
}

export default api;

import React from 'react';
import { Fade } from '@material-ui/core';

import { ResupplyData } from 'utils/interfaces/Reports';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { IResupplyTableProps } from '../../types';

const ResupplyTable: React.FC<IResupplyTableProps> = ({ rows }) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<ResupplyData>
        columns={[
          {
            title: 'Origem',
            type: 'string',
            orderable: true,
            props: ['origem'],
          },
          {
            title: 'Destino',
            type: 'string',
            orderable: true,
            props: ['destino'],
          },
          {
            title: 'Caixa',
            type: 'string',
            orderable: true,
            props: ['caixa'],
          },
          {
            title: 'Produto',
            type: 'string',
            orderable: true,
            props: ['produto'],
          },
          {
            title: 'Cor',
            type: 'string',
            orderable: true,
            props: ['cor'],
          },
          {
            title: 'Tamanho',
            type: 'string',
            orderable: true,
            props: ['tamanho'],
          },
          {
            title: 'Quantidade',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
          },
        ]}
        rows={rows}
        exportList
      />
    </TableContainer>
  </Fade>
);

export default ResupplyTable;

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from 'react';
import { Grid, Fade } from '@material-ui/core';

import { AxiosError, AxiosResponse } from 'axios';

import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { Table, SearchBox } from '@pdasolucoes/web';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import {
  Container,
  Search,
  TableContainer,
  ContainerGraph,
} from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { TableIndicatorPicking } from '../../../../../utils/interfaces/Indicators';
import { useAuth } from '../../../../../hooks/Auth';
import Graph, { ILine } from '../../../../../components/Tools/Graph';
import { putOnPickingGraphics } from '../../../../../utils/toGraph';

const Indic2: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [Indicator, setIndicator] = React.useState(
    [] as TableIndicatorPicking[]
  );

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const inputsBox = React.useRef<FormHandles>(null);
  const [graphic, setGraphic] = React.useState({} as ILine[]);
  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setLoading(false);
        setVisible(true);
        const dataFilter = {
          data_incial: data.dataInicial ? data.dataInicial : null,
          data_final: data.dataFinal ? data.dataFinal : null,
        };

        await api
          .get<TableIndicatorPicking>(`Indicadores/Separacao`, {
            params: dataFilter,
            headers: { Authorization: `Bearer ${tokenData}` },
          })
          .then((dataSubmit: AxiosResponse) => {
            if (dataSubmit.data.length === 0) {
              enqueueSnackbar(`Não existe registro!`, { variant: 'error' });
              setIndicator([]);
              setGraphic({} as ILine[]);
            } else if (dataSubmit.data.length > 0) {
              enqueueSnackbar(
                `Foi encontrado ${dataSubmit.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              const putTo = putOnPickingGraphics([...dataSubmit.data]);
              console.log(putTo);
              setGraphic(putTo);
              setIndicator([...dataSubmit.data]);
            }
          })
          .catch((dataSubmitError: AxiosError<any>) => {
            if (dataSubmitError.response?.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              dataSubmitError.response?.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, tokenData]
  );

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  React.useEffect(() => {
    console.log({ table: Indicator });
  }, [Indicator]);

  return (
    <>
      <FixedHeader title="Indicadores" subTitle="Indicador de Separação" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              searchBoxRef={inputsBox}
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'dataInicial',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: date,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFinal',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: date,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 400, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && graphic.length > 0 && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <ContainerGraph>
                {graphic.length > 0 && <Graph data={graphic} />}
              </ContainerGraph>
            </Fade>
          </Grid>
        )}

        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}

        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ flexGrow: 1 }}>
                    <TableContainer>
                      <Table<TableIndicatorPicking>
                        exportList
                        columns={[
                          {
                            title: 'Data',
                            type: 'date',
                            orderable: true,
                            props: ['dia'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Pedidos',
                            type: 'string',
                            orderable: true,
                            props: ['qtdePedidos'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Usuários',
                            type: 'string',
                            orderable: true,
                            props: ['qtdeUsuarios'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Peças',
                            type: 'string',
                            props: ['qtdePecas'],
                            orderable: true,
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Tempo (min)',
                            type: 'string',
                            props: ['tempoMedio'],
                            orderable: true,
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                            formatter: row => {
                              return `${row.tempoMedio.toFixed(2)} min`;
                            },
                          },
                        ]}
                        rows={[
                          ...Indicator.map(item => ({
                            ...item,
                          })),
                        ]}
                      />
                    </TableContainer>
                  </div>
                </div>
              </>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};
export default Indic2;

import React from 'react';
import { Fade } from '@material-ui/core';

import { useAuth } from 'hooks/Auth';

import editIcon from 'assets/svg/editActionsIcon.svg';
import { TableContainer } from 'app/WMS/styles/styles';

import SearchIconDetails from 'assets/svg/searchIconDetails.svg';

import {
  ReceivementTableProps,
  ReceivementData,
} from 'app/WMS/resources/NewReceivement/types';
import { getCookie } from 'utils/cookies';
import TagStatus from 'components/Tools/TagStatus';
import { Table } from 'components/Tools/Table';
import ProgressBar from 'components/Tools/ProgressBar';
import ActionsMenu from 'components/Tools/ActionsMenu';
import CustomizedImgCell from '../CustomizedImgCell';

import { updateOrderStatus } from '../../apis/receivement.apis';

const NewReceivementTable: React.FC<ReceivementTableProps> = ({
  receivement,
  handleDetail,
  handleSearch,
}) => {
  const [animate, setAnimate] = React.useState(false);

  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');

  const clientId = getCookie('@pdamodules::codigoCliente');

  const updateNote = async (row: ReceivementData) => {
    const data = {
      codigoCliente: Number(clientId),
      codigoPedido: row.codigoPedido,
    };
    updateOrderStatus({ tokenData, data });
    handleSearch([]);
  };

  const handleUpdate = (row: ReceivementData) => updateNote(row);

  React.useEffect(() => {
    setAnimate(false);
    setInterval(() => {
      setAnimate(true);
    }, 350);
  }, [receivement]);

  return (
    <>
      <Fade in={animate} timeout={1000}>
        <TableContainer>
          <Table<ReceivementData>
            exportList
            rows={receivement}
            columns={[
              {
                title: 'Pedido',
                orderable: true,
                type: 'string',
                props: ['codigoPedido'],
                cssProps: {
                  width: '5%',
                },
                renderItem: row => (
                  <div className="code-item">
                    <CustomizedImgCell status={row.status} />
                    <p style={{ marginLeft: '10px' }}>{row.codigoPedido}</p>
                  </div>
                ),
              },
              {
                title: 'Nota Fiscal',
                type: 'stringCenter',
                orderable: true,
                props: ['notaFiscal'],
                cssProps: {
                  width: '10%',
                },
              },
              {
                title: 'Tipo Pedido',
                type: 'string',
                orderable: true,
                props: ['tipoPedido'],
                display: 'notEmpty',
                cssProps: {
                  width: '5%',
                },
              },
              {
                title: 'Serie',
                type: 'stringCenter',
                orderable: true,
                props: ['serie'],
                cssProps: {
                  width: '5%',
                },
              },
              {
                title: 'Data Aprov.',
                type: 'date',
                orderable: true,
                props: ['dataIntegracao'],
                cssProps: {
                  width: '10%',
                },
              },
              {
                title: 'Qtde. SKU',
                type: 'number',
                orderable: true,
                props: ['quantidadeSku'],
                cssProps: {
                  width: '5%',
                },
              },
              {
                title: 'Qtde.',
                type: 'number',
                orderable: true,
                props: ['quantidade'],
                cssProps: {
                  width: '5%',
                },
              },
              {
                title: 'Status',
                type: 'string',
                orderable: true,
                props: ['status'],
                renderItem: row => (
                  <div className="statusContainer">
                    <TagStatus status={row.status} width="fit-content" />
                  </div>
                ),
              },
              {
                title: 'Data Rec.',
                type: 'date',
                orderable: true,
                display: 'notEmpty',
                props: ['dataRecebimento'],
                cssProps: {
                  width: '10%',
                },
              },
              {
                title: 'Progresso',
                orderable: false,
                type: 'number',
                props: ['porcentagem'],
                formatter: row => `${row.porcentagem}%`,
                cssProps: {
                  width: '10%',
                },
                renderItem: row => (
                  <div className="">
                    <ProgressBar
                      style={{
                        width: '100%',
                        display: 'inline-block',
                      }}
                      value={row.porcentagem || 0}
                    />
                  </div>
                ),
              },
              {
                title: 'Ações',
                orderable: false,
                type: 'string',
                cssProps: {
                  width: '7%',
                },
                props: ['codigoPedido'],
                renderItem: row => (
                  <div className="row-actions-button-detail">
                    <ActionsMenu
                      status={row.status}
                      row={row}
                      action={() => handleUpdate(row)}
                    />
                    <button
                      type="button"
                      className="action"
                      onClick={() => handleDetail(row)}
                    >
                      <img
                        src={SearchIconDetails}
                        alt="Detalhes"
                        style={{ width: '30px' }}
                      />
                      <p className="hover-item">Detalhe</p>
                    </button>
                  </div>
                ),
              },
            ]}
            defaultNumberOfRows={10}
            defaultSort="codigoPedido"
          />
        </TableContainer>
      </Fade>
    </>
  );
};

export default NewReceivementTable;

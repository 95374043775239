export interface LogData {
  caixa: string;
  descricaoProduto: string;
  codigoEndereco: number;
  codigoTipoEndereco: string;
  endereco: string;
  codigoNivel1: number;
  codigoNivel2: number;
  codigoNivel3: number;
  codigoNivel4: number;
  codigoNivel5: number;
  tipoEndereco: string;
  produto: string;
  quantidade: number;
  usuario: string;
  palete: string;
  badRequest: boolean;
  message: string;
  error: string;
  quantidadePalete: number;
  quantidadeCaixa: number;
}

export interface DetailLogData {
  codigoEndereco: number;
  endereco: string;
  tipoEndereco: string;
  palete: string;
  caixa: string;
  fabricante: string;
  produto: string;
  descricaoProduto: string;
  quantidade: number;
  usuario: string;
  dataArmazenagem: string;
}

export interface BoxesData {
  caixa: string;
  message: string;
  badRequest: boolean;
  descricaoProduto: string;
  endereco: string;
  montagem: Date;
  produto: string;
  quantidade: number;
  usuario: string;
}

export interface DetailInventoryData {
  cor: string;
  DescricaoEndereco: string;
  descricaoProduto: string;
  disponivel: number;
  ean: string;
  grade: string;
  produto: string;
  quantidade: number;
  reservado: number;
  tamanho: string;
  codigoPedido: string;
  descricaoCaixa: string;
  descricaoPalete: string;
}
export interface InventoryData {
  codigoCliente: number;
  codigoCodigoNivel1: number;
  codigoCodigoNivel2: number;
  codigoCodigoNivel3: number;
  codigoCodigoNivel4: number;
  codigoCodigoNivel5: number;
  codigoMarca: number;
  codigoPedido: string;
  descricaoCodigoNivel1: string;
  descricaoCodigoNivel2: string;
  descricaoCodigoNivel3: string;
  descricaoCodigoNivel4: string;
  descricaoCodigoNivel5: string;
  descricaoProduto: string;
  codigoDeposito: number;
  marca: string;
  produto: string;
  endereco: string;
  Quantidade: number;
  badRequest: boolean;
  message: string;
  descricaoPalete: string;
  descricaoCaixa: string;
}

export interface PalletsData {
  badRequest: boolean;
  message: string;
  caixa: string;
  descricaoProduto: string;
  endereco: string;
  montagem: string;
  produto: string;
  quantidade: number;
  usuario: string;
  palete: string;
}

export interface OrderData {
  codigoPedido: number;
  cliente: string;
  quantidadePedido: number;
  recebido: string;
  planejamento: string;
  descricaoStatus: string;
  finalizado: string;
  periodo: string;
  transportadora: string;
  substituir: boolean;
}

export interface DetailOrderData {
  codigoPedigo: string;
  produto: string;
  descricaoProduto: string;
  cor: string;
  tamanho: string;
  quantidade: number;
}

export interface ReverseOrder {
  codigoOnda: number;
  codigoPedido: string;
  dataPedido: string;
  codigoEcCliente: number;
  cliente: string;
  codigoTransportadora: number;
  transportadora: string;
  descricaoStatus: string;
  quantidade: number;
}

export interface IReverseTableProps {
  detailsHas: (codigoPedido: string, descricaoStatus: string) => Promise<void>;
  animate: boolean;
  reverseHas: (pedidoData: string) => Promise<void>;
  order: ReverseOrder[];
}

export interface IDataFilterProps {
  CodigoStatus: Number | null;
  CodigoCliente: Number;
  CodigoPedido: any | null;
  CodigoOnda: any | null;
  cliente: any | null;
  user: Number;
  dataInicio: Date | null;
  dataFim: Date | null;
}

export interface SelectItem {
  codigo?: number | string | boolean;
  description?: string;
  descricao?: string;
  label?: string;
  value?: number | string | boolean;
  uf?: string;
  nome?: string;
  codigoUsuario?: number | string | boolean;
  codigoTipo?: number | string;
  periodo?: number | string;
  codigoFilial?: number | string;
  codigoFilialERP?: number | string;
  tipoPedido?: string;
}

export const transformToSelect = (array: any) => {
  return array.map((item: SelectItem) => ({
    code:
      item.codigo ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo,
    description:
      item.descricao || item.nome || item.periodo || item.codigoFilialERP,
    label: item.descricao || item.nome || item.periodo || item.codigoFilialERP,
    value:
      item.codigo ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo ||
      item.tipoPedido,
  }));
};

export interface IHandleEditProps {
  codigoPedido: string;
  codigoCliente: Number;
  codigoStatus: Number;
}

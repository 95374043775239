import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import editIcon from 'assets/svg/editActionsIcon.svg';
import ActionsIcon from 'assets/svg/actionsIcon.svg';
import ConfirmationModal from '../ConfirmationModal';

type ActionsMenuProps = {
  menuItem?: string;
  status?: string;
  action?: () => void;
  handleEstornar?: () => Promise<void> | void;
  row?: any;
};

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  menuItem,
  status,
  action = () => {},
  handleEstornar,
  row,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickProgress = () => {
    if (handleEstornar) {
      handleEstornar();
    } else {
      console.log(row);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = () => {
    handleClose();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const resetConfirm = React.useCallback(async () => {
    await setOpenModal(false);
    setIsConfirmed(false);
  }, []);

  const confirm = React.useCallback(async () => {
    await setOpenModal(false);
    await setIsConfirmed(true);
    action();
    setOpenModal(true);
  }, [action]);

  const handleClassName =
    status !== 'Divergente' && status !== 'Em andamento'
      ? 'actionDisable'
      : 'actionNone';

  return (
    <>
      <div>
        {/* status === 'Pendente' && (
          <>
            <ConfirmationModal
              resetConfirm={resetConfirm}
              confirm={confirm}
              isConfirmed={isConfirmed}
              handleCloseModal={handleCloseModal}
              openModal={openModal}
              warningTitle="Tem certeza que deseja gerar conferência?"
              doneTitle="Conferência gerada com sucesso"
            />
            <button type="button" className="action" onClick={handleClick}>
              <img
                src={ActionsIcon}
                alt="Ações"
                style={{ width: '100%', height: '100%' }}
              />
              <p className="hover-item">Ações</p>
            </button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem
                onClick={() => {
                  handleModal();
                }}
              >
                {menuItem || 'Gerar conferência'}
              </MenuItem>
            </Menu>
          </>
              ) */}
        {status === 'Divergente' && (
          <>
            <ConfirmationModal
              deleteType
              resetConfirm={resetConfirm}
              confirm={confirm}
              isConfirmed={isConfirmed}
              handleCloseModal={handleCloseModal}
              openModal={openModal}
              warningTitle="Tem certeza que deseja finalizar com divergência?"
              doneTitle="Item finalizado com divergência"
            />
            <button type="button" className="action" onClick={handleClick}>
              <img
                src={ActionsIcon}
                alt="Ações"
                style={{ width: '100%', height: '100%' }}
              />
              <p className="hover-item">Ações</p>
            </button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem onClick={() => handleModal()}>
                {menuItem || 'Finalizar com divergência'}
              </MenuItem>
            </Menu>
          </>
        )}
        {status === 'Em andamento' && (
          <button
            type="button"
            className="action"
            onClick={handleClickProgress}
          >
            <img
              src={editIcon}
              alt="Ações"
              style={{ width: '100%', height: '100%' }}
            />
            <p className="hover-item">Estornar</p>
          </button>
        )}
        {status !== 'Divergente' && (
          <>
            <div className={handleClassName}>
              <img
                src={ActionsIcon}
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'none',
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ActionsMenu;

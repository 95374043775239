import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { getCookie } from 'utils/cookies';
import { useAuth } from 'hooks/Auth';
import SearchBox from 'components/Tools/Search';
import { transformToSelect } from 'utils/toSelect';
import { Container, Search } from 'app/WMS/styles/styles';
import FixedHeader from 'components/Tools/Breadcrumb';
import { TransferenceData } from 'utils/interfaces/Transference';
import { TransferenceTable } from './components';
import { getOptions, searchBoxRequest } from './api/transference.api';

const Transference: React.FC = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const searchBoxRef = useRef<FormHandles>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isTableShowing, setIsTableShowing] = useState(false);
  const [unityStorageTypes, setUnityStorageTypes] = useState([]);
  const [transferences, setTransferences] = useState<TransferenceData[]>([]);

  const userId = getCookie('@pdamodules::id');
  const clientId = getCookie('@pdamodules::codigoCliente');

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateDay = new Date(Date.now() - tzoffset).toLocaleDateString();

  const setFields = useCallback(() => {
    searchBoxRef.current?.setFieldValue('dataInicio', dateDay);
    searchBoxRef.current?.setFieldValue('dataFim', dateDay);
  }, [dateDay]);

  const getAllOptions = useCallback(async () => {
    setIsLoading(true);

    const [unityTypeRes] = await getOptions(clientId);

    const formatedUnityType = transformToSelect(unityTypeRes.data);

    setUnityStorageTypes(formatedUnityType);

    setIsLoading(false);
  }, [clientId]);

  useLayoutEffect(() => {
    setFields();
    getAllOptions();
  }, [getAllOptions, setFields]);

  const handleSearchBoxSubmit = useCallback(
    async (data: unknown) => {
      setIsTableShowing(false);
      setIsLoading(true);

      await searchBoxRequest(data, clientId, userId)
        .then(res => {
          if (!res.data.length) {
            enqueueSnackbar('Resultados não encontrados', {
              variant: 'warning',
            });

            setIsTableShowing(false);

            return;
          }
          setTransferences(res.data);
          setIsTableShowing(true);
          enqueueSnackbar(`${res.data.length} resultado(s) encontrados`, {
            variant: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, userId, enqueueSnackbar]
  );

  return (
    <>
      <FixedHeader title="Transferência" subTitle="Acompanhamento" />
      <Fade in timeout={100}>
        <Container>
          <Search>
            <SearchBox
              searchBoxRef={searchBoxRef}
              handleSubmit={data => handleSearchBoxSubmit(data)}
              hiddenInputs={[
                {
                  name: 'origem',
                  placeholder: 'Origem',
                  type: 'text',
                  label: 'Origem',
                  xl: 4,
                  lg: 4,
                  xs: 6,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'destino',
                  placeholder: 'Destino',
                  type: 'text',
                  label: 'Destino',
                  xl: 4,
                  lg: 4,
                  xs: 6,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'typeStorage',
                  placeholder: 'Tipo',
                  type: 'select',
                  label: 'Tipo',
                  options: unityStorageTypes,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 4,
                  lg: 4,
                  xs: 6,
                  md: 6,
                  sm: 12,
                },
              ]}
              inputs={[
                {
                  name: 'unityStorage',
                  placeholder: 'Unidade',
                  type: 'text',
                  label: 'Unidade',
                  isLoading,
                  isDisabled: isLoading,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: dateDay,
                  xl: 4,
                  lg: 4,
                  xs: 6,
                  md: 4,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: dateDay,
                  xl: 4,
                  lg: 4,
                  xs: 6,
                  md: 4,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {!isLoading && isTableShowing && (
            <TransferenceTable rows={transferences} />
          )}
        </Container>
      </Fade>
    </>
  );
};

export default Transference;

import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import greenBox from 'assets/svg/caixa-verde.svg';
import addQtdTableIcon from 'assets/svg/addQtdTableIcon.svg';
import removeQtdTableIcon from 'assets/svg/removeQtdTableIcon.svg';
import trashWhiteIcon from 'assets/svg/trashWhiteIcon.svg';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import Icon from 'components/Tools/Icon';
import { CustomizedCell, Qtd } from './styles';
import { ITableProps, IRegisterFormProduct } from '../../types';

const OrderTable: React.FC<ITableProps> = ({ rows, setRows }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [tableRows, setTableRows] = useState<IRegisterFormProduct[]>([]);

  useLayoutEffect(() => {
    setTableRows(rows);
  }, [rows]);

  const handleDeleteRows = useCallback(
    (linhas: IRegisterFormProduct[]) => {
      setRows(prevState => ({
        details: prevState.details,
        items: tableRows.filter(
          (_row, index) =>
            linhas.map(linha => linha.index).indexOf(index) === -1
        ),
      }));

      enqueueSnackbar(
        `${linhas.length > 1 ? 'Items deletados' : 'Item deletado'} com êxito`,
        {
          variant: 'success',
        }
      );
    },
    [setRows, tableRows, enqueueSnackbar]
  );

  const handleQtd = useCallback(
    (linha: IRegisterFormProduct, isAdd?: boolean, value?: number) => {
      if (value) {
        setRows(prevState => ({
          details: prevState.details,
          items: tableRows.map((row, index) => {
            if (index === linha.index) {
              return { ...linha, quantidade: Number(value) };
            }
            return row;
          }),
        }));
        return;
      }
      if (isAdd) {
        setRows(prevState => ({
          details: prevState.details,
          items: tableRows.map((row, index) => {
            if (index === linha.index) {
              return { ...linha, quantidade: Number(linha.quantidade) + 1 };
            }
            return row;
          }),
        }));
        return;
      }
      setRows(prevState => ({
        details: prevState.details,
        items: tableRows.map((row, index) => {
          if (index === linha.index) {
            return { ...linha, quantidade: Number(linha.quantidade) - 1 };
          }
          return row;
        }),
      }));
    },
    [setRows, tableRows]
  );

  return (
    <TableContainer inPopUp>
      <Table<IRegisterFormProduct>
        selectBox
        columns={[
          {
            title: 'Ordem',
            type: 'number',
            orderable: true,
            props: ['ordem'],
            cssProps: {
              width: '10%',
            },
            renderItem: actions => (
              <CustomizedCell justify="start">
                <img src={greenBox} alt="Caixa Verde" />
                <span className="bold">{Number(actions.index) + 1}</span>
              </CustomizedCell>
            ),
          },
          {
            title: 'Produto',
            type: 'string',
            orderable: true,
            props: ['produto'],
            cssProps: {
              width: '80%',
            },
            renderItem: actions => (
              <CustomizedCell>
                <span className="default-text-table">
                  {actions.produto.produto} -{' '}
                  {actions.produto.descricao.length >= 20
                    ? `${actions.produto.descricao.substring(0, 15)}...`
                    : actions.produto.descricao}
                </span>
              </CustomizedCell>
            ),
          },
          {
            title: 'Quantidade',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
            cssProps: {
              width: '10%',
            },
            renderItem: actions => (
              <CustomizedCell gap="5px">
                <button type="button" onClick={() => handleQtd(actions, false)}>
                  <img src={removeQtdTableIcon} alt="Tirar Um" />
                </button>
                <Qtd
                  type="number"
                  placeholder={`${actions.quantidade}`}
                  value={actions.quantidade}
                  onChange={e => {
                    handleQtd(actions, undefined, Number(e.target.value));
                  }}
                  autoFocus
                  min="1"
                />
                <button type="button" onClick={() => handleQtd(actions, true)}>
                  <img src={addQtdTableIcon} alt="Add Um" />
                </button>
              </CustomizedCell>
            ),
          },
        ]}
        rows={tableRows.map((row, index) => ({ ...row, index }))}
        selectBoxColor="#D9214E"
        selectBoxHoverColor="#D9214Edd"
        selectBoxTitleColor="#fff"
        selectBoxActions={[
          {
            onClick: list => handleDeleteRows(list),
            renderItem: () => (
              <>
                <Icon icon={trashWhiteIcon} color="#fff" />
                <p>Deletar</p>
              </>
            ),
          },
        ]}
        defaultNumberOfRows={5}
      />
    </TableContainer>
  );
};

export default OrderTable;

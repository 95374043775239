/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatDate } from '@pdasolucoes/web';
import { ILine } from '../components/Tools/Graph';

export interface IGraph {
  perfeito?: number;
  parcial?: number;
  criado?: number;
  pedidoList?: {
    perfeito?: number;
    parcial?: number;
    criado?: number;
    dia?: string;
  }[];
}

export const putOnGraphic = (array: any) => {
  const keys = array[0].pedidoList?.map((day: any) =>
    Object.entries(day)
      .filter(item => item[0] !== 'dia')
      .map(item => item[0])
  )[0];

  const data = keys.map((key: string) => {
    const info: ILine = {
      id: key,
      data: [],
    };
    array[0].pedidoList?.map((day: any) =>
      info.data.push({
        x: formatDate(day.dia),
        y: day[key],
      })
    );
    return info;
  });
  return data;
};

export const putOnStreetGraphics = (array: any) => {
  const keys = array.map((obj: any) =>
    Object.entries(obj)
      .filter(
        item =>
          item[0] === 'enderecos' ||
          item[0] === 'ocupados' ||
          item[0] === 'livres'
      )
      .map(item => item[0])
  )[0];

  const data = keys.map((key: string) => {
    const info: ILine = {
      id: key,
      data: [],
      // hidden: false,
    };
    array.map((obj: any) =>
      info.data.push({
        x: obj.descricaoRua,
        y: obj[key],
      })
    );
    return info;
  });
  return data;
};

export const putOnPickingGraphics = (array: any) => {
  const keys = array.map((day: any) =>
    Object.entries(day)
      .filter(item => item[0] !== 'dia')
      .map(item => item[0])
  )[0];
  const data = keys.map((key: string) => {
    const info: ILine = {
      id: key,
      data: [],
      // hidden: false,
    };
    array.map((obj: any) =>
      info.data.push({
        x: formatDate(obj.dia),
        y: obj[key],
      })
    );
    return info;
  });
  return data;
};

import React from 'react';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import TagStatus from 'components/Tools/TagStatus';
import ProgressBar from 'components/Tools/ProgressBar';
import {
  DetailOrderData,
  IDetailFollowProps,
  IShowDetailProps,
} from '../../types';

const ShowDetailItem: React.FC<IDetailFollowProps> = ({ detailOrder }) => {
  return (
    <TableContainer>
      <div className="detailTableWrap" />
      <Table<DetailOrderData>
        exportList
        columns={[
          {
            title: 'Produto',
            type: 'string',
            orderable: true,
            props: ['produto'],
            cssProps: {
              width: '0.5%',
            },
            renderItem: row => {
              return (
                <div className="code-item">
                  <p style={{ marginLeft: '10px' }}>{row.produto}</p>
                </div>
              );
            },
          },
          {
            title: 'Descrição do Produto',
            type: 'string',
            orderable: true,
            props: ['descricaoProduto'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Caixa',
            type: 'stringCenter',
            orderable: true,
            props: ['caixa'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Pelete',
            type: 'stringCenter',
            orderable: true,
            props: ['palete'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Cor',
            type: 'stringCenter',
            orderable: true,
            props: ['cor'],
            display: 'notEmpty',
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Grade',
            type: 'stringCenter',
            orderable: true,
            props: ['grade'],
            display: 'notEmpty',
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Tamando',
            type: 'stringCenter',
            orderable: true,
            props: ['tamanho'],
            display: 'notEmpty',
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Quantidade',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Status',
            type: 'string',
            orderable: true,
            props: ['descricaoStatus'],
            renderItem: row => (
              <div className="statusContainer">
                <TagStatus status={row.descricaoStatus} width="fit-content" />
              </div>
            ),
          },
          {
            title: 'Progresso Pedido',
            orderable: false,
            type: 'number',
            props: ['porcentagem'],
            formatter: row => `${0}%`,
            cssProps: {
              width: '5%',
            },
            renderItem: row => (
              <div>
                <ProgressBar
                  style={{
                    width: '100%',
                    marginLeft: '8px',
                    display: 'inline-block',
                  }}
                  value={row.porcentagem || 0}
                />
              </div>
            ),
          },
        ]}
        rows={[
          ...detailOrder.map(item => ({
            ...item,
          })),
        ]}
      />
    </TableContainer>
  );
};
export default ShowDetailItem;

import React, { useState, useLayoutEffect, useCallback } from 'react';
import { ValidateOnlyNumbers } from '@pdasolucoes/web/lib/functions/validator';
import { useSnackbar } from 'notistack';
import { Fade } from '@material-ui/core';
import { getCookie } from 'utils/cookies';

import { Container, Search } from 'app/WMS/styles/styles';
import { InsufficientData } from 'utils/interfaces/Reports';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import FixedHeader from 'components/Tools/Breadcrumb';
import { InsufficientTable } from './components';
import { getOptions, searchBoxRequest } from './api/insufficient.api';

const Insufficient: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTableShowing, setIsTableShowing] = useState(false);
  const [transportadoras, setTransportadoras] = useState([]);
  const [insufficients, setInsufficients] = useState<InsufficientData[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');
  const userId = getCookie('@pdamodules::id');

  const getAllOptions = useCallback(async () => {
    setIsLoading(true);

    const [transportadorasRes] = await getOptions(clientId);

    setTransportadoras(transportadorasRes);
    setIsLoading(false);
  }, [clientId]);

  const handleSearchBoxSubmit = useCallback(
    async (data: unknown) => {
      setIsTableShowing(false);
      setIsLoading(true);

      await searchBoxRequest(data, userId, clientId)
        .then(res => {
          if (!res.data.length) {
            setInsufficients([]);
            setIsTableShowing(false);

            enqueueSnackbar('Não há registros', {
              variant: 'warning',
            });

            return;
          }

          setInsufficients(res.data);
          setIsTableShowing(true);
          enqueueSnackbar(`${res.data.length} resultado(s) encontrados!`, {
            variant: 'success',
          });
        })
        .catch(err => {
          setIsTableShowing(false);

          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, userId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  return (
    <>
      <FixedHeader title="Relatório" subTitle="Insuficiente" />
      <Fade in timeout={1000} unmountOnExit>
        <Container>
          <Search>
            <SearchBox
              searchDisabled={isLoading}
              handleSubmit={data => handleSearchBoxSubmit(data)}
              inputs={[
                {
                  name: 'pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  label: 'Pedido',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'produto',
                  placeholder: 'Produto',
                  type: 'text',
                  label: 'Produto',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'descricao',
                  placeholder: 'Descrição',
                  type: 'text',
                  label: 'Descrição',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'transportadora',
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                  type: 'select',
                  options: transportadoras,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 6,
                  xs: 6,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'prioridade',
                  label: 'Prioridade',
                  placeholder: 'Prioridade',
                  type: 'text',
                  onKeyDown: ValidateOnlyNumbers,
                  xl: 6,
                  lg: 6,
                  xs: 6,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
          {isLoading && <Loader />}
          {!isLoading && isTableShowing && (
            <InsufficientTable rows={insufficients} />
          )}
        </Container>
      </Fade>
    </>
  );
};

export default Insufficient;

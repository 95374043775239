import React, { useState, useCallback, useLayoutEffect } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { getCookie } from 'utils/cookies';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import FixedHeader from 'components/Tools/Breadcrumb';
import { Container, Search } from 'app/WMS/styles/styles';
import { IComponents } from 'utils/interfaces/Components';
import { ComponentsTable, CreateComponentModal } from './components';
import { IconCreate } from './utils/icon';
import { componentsRequest } from './api/components.api';

const Components: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [components, setComponents] = useState<IComponents[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = Number(getCookie('@pdamodules::codigoCliente'));

  const handleGetComponents = useCallback(
    async (data?: object) => {
      setIsLoading(true);

      await componentsRequest(clientId, data)
        .then(res => {
          if (!res.data.length) {
            enqueueSnackbar('Não há componentes', {
              variant: 'warning',
            });

            setComponents([]);

            return;
          }

          setComponents(res.data);
          enqueueSnackbar(`${res.data.length} resultado(s) encontrados!`, {
            variant: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    handleGetComponents();
  }, [handleGetComponents]);

  return (
    <>
      <FixedHeader title="Cadastros" subTitle="Componentes" />
      <Fade in timeout={1000} unmountOnExit>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={data => handleGetComponents(data)}
              createButton={() => setIsCreateModalOpen(true)}
              createButtonContent={
                <>
                  <IconCreate />
                  <p>Novo Componente</p>
                </>
              }
              inputs={[
                {
                  name: 'codigoComponente',
                  label: 'Componente',
                  placeholder: 'Componente',
                  type: 'text',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'descricaoComponente',
                  label: 'Descrição Componente',
                  placeholder: 'Descrição Componente',
                  type: 'text',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <Loader />}
        </Container>
      </Fade>
      {!isLoading && <ComponentsTable rows={components} />}
      <CreateComponentModal
        isOpen={isCreateModalOpen}
        closeModal={() => setIsCreateModalOpen(false)}
      />
    </>
  );
};

export default Components;

import React, { useCallback, useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import SearchBox from 'components/Tools/Search';
import { getCookie } from 'utils/cookies';
import { Container, Search } from 'app/WMS/styles/styles';
import FixedHeader from 'components/Tools/Breadcrumb';
import Loader from 'components/Tools/Loader';
import ReportTable from './components/ReportTable';
import { handleReports } from './apis/report.api';
import { IReportDataProps } from './types/index';

const ReportReceivement: React.FC = () => {
  const idCliente = getCookie('@pdamodules::codigoCliente');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [report, setReport] = useState([] as IReportDataProps[]);
  const [animate, setAnimate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async data => {
      try {
        setShowTable(false);
        setIsLoading(true);
        const dataFilter = {
          codigoCliente: Number(idCliente),
          codigoPedido: data.codigoPedido || null,
          produto: data.produto || null,
          notaFiscal: data.notaFiscal || null,
          serie: data.serie || null,
          dataInico: data.dataInicio || null,
          dataFim: data.dataFim || null,
        };
        await handleReports(dataFilter).then((response: AxiosResponse) => {
          if (response.data.length <= 0) {
            enqueueSnackbar('Nenhum registro encontrado', {
              variant: 'error',
            });
            setShowTable(true);
          } else {
            enqueueSnackbar(
              `Foi encontrado ${response.data.length} registro(s)!`,
              {
                variant: 'success',
              }
            );
            setReport(response.data);
            setIsLoading(false);

            setShowTable(true);
          }
        });
      } finally {
        const timer = setTimeout(() => {
          setIsLoading(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, idCliente]
  );

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <>
      <FixedHeader title="Relatorios" subTitle="Pedido Divergente" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  label: 'Pedido',
                },
                {
                  name: 'produto',
                  placeholder: 'Produto',
                  type: 'text',
                  label: 'Produto',
                },
                {
                  name: 'notaFiscal',
                  label: 'Nota Fiscal',
                  placeholder: 'Nota Fiscal',
                  type: 'text',
                },
                {
                  name: 'serie',
                  placeholder: 'Serie',
                  type: 'text',
                  label: 'Serie',
                },
              ]}
              hiddenInputs={[
                {
                  name: 'dataInicio',
                  label: 'Data Inicio',
                  type: 'date',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Fim',
                  type: 'date',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <>
            <ReportTable report={report} />
          </>
        )}
      </Container>
    </>
  );
};
export default ReportReceivement;

import { Checkbox, Divider, FormControlLabel, Grid } from '@material-ui/core';
import SwitchButton from 'components/Tools/Form/SwitchButton';
import PageMode from 'components/Tools/PageMode';
import React from 'react';
import { useTheme } from 'styled-components';
import { CreateWaveFormProps } from '../types';

const CreateWaveForm: React.FC<CreateWaveFormProps> = ({
  openCreateModal,
  lineItens,
  inputsBox,
  handleCancel,
  submitCreateModal = () => {},
  handleChange,
  handleChangeDivider,
  checked,
  isCheckedValue,
  users,
}) => {
  const { colors } = useTheme();

  return (
    <PageMode
      isModal
      inLineChildren
      open={openCreateModal}
      title={`Criar Onda - ${lineItens} pedido(s) selecionado(s)`}
      modalRefObject={inputsBox}
      handleClose={handleCancel}
      submitButtonTitle="Criar"
      handleSubmit={data => submitCreateModal(data)}
      inputs={[
        {
          name: 'operador',
          label: 'Usuário',
          type: 'select',
          placeholder: 'Usuário',
          options: users,
          isDisabled: isCheckedValue,
          xl: 9,
          lg: 9,
          xs: 9,
          md: 9,
          sm: 9,
        },
      ]}
    >
      <Grid item xl={2} lg={2} xs={3} md={3} sm={3}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'block' }}>
            <p className="labelInput">Prioridade</p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="ativo"
                  color="primary"
                />
              }
              value="ativo"
              label={checked ? 'Sim' : 'Não'}
              labelPlacement="end"
              style={{ color: colors.title }}
            />
          </div>
          <div style={{ display: 'inline-block' }}>
            <p className="labelInput">Dividir Volume?</p>
            <SwitchButton
              name="andar"
              type="checkebox"
              onChangeAction={handleChangeDivider}
            />
          </div>
        </div>
      </Grid>
    </PageMode>
  );
};

export default CreateWaveForm;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  Container,
  Search,
  TableFilterCardContainer,
} from 'app/WMS/styles/styles';
import DetailsItem from 'components/Tools/DetailsItem';
import FixedHeader from 'components/Tools/Breadcrumb';
import { Fade } from '@material-ui/core';
import SearchBox from 'components/Tools/Search';
import { SelectItem, transformToSelect } from 'utils/toSelect';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import PageMode from 'components/Tools/PageMode';
import { getCookie } from 'utils/cookies';
import Loader from 'components/Tools/Loader';
import getValidationErrors from 'utils/getValidationErrors';
import {
  DetailOrderData,
  OrderData,
  SearchDetailDataProps,
  SearchDataProps,
  SearchRequestPicking,
} from './types';

import {
  handleSearchData,
  handleDetailsData,
  handleProduct,
  getPickingRequest,
  handleClienteData,
} from './apis';

import ShowDetails from './components/ShowDetails';
import ShowDetailTable from './components/DetailsTable';

const NewFollow: React.FC = () => {
  const [order, setOrder] = useState<OrderData[]>([]);
  const [typeList, setTypeList] = useState([]);
  const [detailOrder, setDetailOrder] = useState([] as DetailOrderData[]);
  const [requestDetail, setRequestDetail] = useState({} as OrderData);
  const [statusList, setStatus] = useState({} as SelectItem[]);
  const { codeStatus } = useParams<Record<string, string | undefined>>();
  const { enqueueSnackbar } = useSnackbar();
  const [animate, setAnimate] = useState(false);
  const [isLoading, setIsLoading] = useState(Boolean);
  const [toReplace, setToReplace] = useState(Boolean);
  const inputsBox = React.useRef<FormHandles>(null);
  const [transportadoraCode, setTransportadoraCode] = useState(
    {} as SelectItem[]
  );

  const [showTable, setShowTable] = useState<boolean>(false);
  const [period, setPeriod] = useState({} as SelectItem[]);
  const idCliente = getCookie('@pdamodules::codigoCliente');
  const idUser = getCookie('@pdamodules::id');

  const [cardsSubmit, setCardsSubmit] = useState<any>([]);

  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const searchRef = useRef<FormHandles>(null);
  const [cardProgress, setCardProgress] = useState<number>(0);
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const [modalLoading, setModalLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const pendingFilter = (receive: OrderData[], descricaoStatus?: number) => {
    switch (descricaoStatus) {
      case 1: {
        const resultsOrder = receive.filter(item => {
          return item.descricaoStatus === 'Criado';
        });
        return resultsOrder.length;
      }
      case 2: {
        const resultsOrder = receive.filter(item => {
          return item.descricaoStatus === 'Planejado';
        });
        return resultsOrder.length;
      }
      case 3: {
        const resultsOrder = receive.filter(item => {
          return item.descricaoStatus === 'Andamento';
        });
        return resultsOrder.length;
      }
      case 4: {
        const resultsOrder = receive.filter(item => {
          return item.descricaoStatus === 'Ressuprimento';
        });
        return resultsOrder.length;
      }
      case 5: {
        const resultsOrder = receive.filter(item => {
          return item.descricaoStatus === 'Embarque Finalizado';
        });
        return resultsOrder.length;
      }
      default:
        return receive.length;
    }
  };

  const handleProgressCard = (receive: OrderData[]) => {
    const quantity = receive.map((item: any) => {
      return Number(item.quantidade);
    });
    const totalQuantitySum = quantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const receivedQuantity = receive.map((item: any) => {
      return Number(item.quantidadeDivergencia);
    });
    const totalReceivedQuantitySum = receivedQuantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const progress = (totalReceivedQuantitySum / totalQuantitySum) * 100;
    const finalProgress = Math.floor(progress);
    return setCardProgress(0);
  };

  const handleStatus = React.useCallback(async () => {
    if (Number(codeStatus)) {
      const itensFiltered: SelectItem[] = Object.values(statusList).filter(
        itensSta => itensSta.value === codeStatus
      );
      const filtered = itensFiltered[0];
      inputsBox.current?.setFieldValue('status', { ...filtered });

      const dataFilter: SearchDataProps = {
        CodigoCliente: Number(idCliente),
        User: Number(idUser),
        CodigoStatus: codeStatus,
      };
      setOrder([]);
      await handleSearchData(dataFilter).then((data: AxiosResponse) => {
        if (data.data.length === 0) {
          setOrder([]);
        } else {
          setOrder([...data.data]);
          const timer = setTimeout(() => {
            setIsLoading(true);
            return () => {
              clearTimeout(timer);
            };
          }, 1000);
        }
      });
    }
  }, [codeStatus, statusList, idCliente, idUser]);

  const checkFields = useCallback(() => {
    if (searchRef.current?.getFieldValue('pedido') !== '') {
      return false;
    }
    return true;
  }, []);

  const daysBetween = () => {
    const startDate = searchRef.current?.getFieldValue('inicio');
    const endDate = searchRef.current?.getFieldValue('fim');
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / millisecondsPerDay
    );
  };

  const handleSearch = useCallback(
    async (data, status?, inicio?: string, fim?: string) => {
      try {
        setIsLoading(true);
        setShowTable(false);
        checkFields();
        // setOrder([]);

        const isRequired = checkFields();
        const checkDate = daysBetween();

        if ((isRequired && checkDate > 15) || (isRequired && checkDate < -15)) {
          throw new Yup.ValidationError([], 'ValidationError', 'inicio');
        }

        const schema = Yup.object()
          .shape({
            pedido: Yup.string(),
            tipoPedido: Yup.string(),
            cliente: Yup.string(),
            descricaoStatus: Yup.string(),
            transportadora: Yup.string(),
            periodo: Yup.string(),
            inicio:
              isRequired === true ? Yup.string().required() : Yup.string(),
            fim: isRequired === true ? Yup.string().required() : Yup.string(),
          })
          .nullable();

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataFilter: SearchDetailDataProps = {
          CodigoStatus: data.descricaoStatus || status || null,
          CodigoCliente: Number(idCliente),
          CodigoPedido: data.pedido || null,
          cliente: data.cliente || null,
          codigoTransportadora: data.transportadora || null,
          tipoPedido: data.tipoPedido || null,
          inicio: data.inicio || inicio || null,
          fim: data.fim || fim || null,
          periodo: data.periodo || null,
          user: Number(idUser),
        };
        setCardsSubmit(dataFilter);
        handleDetailsData(dataFilter)
          .then(response => {
            setIsLoading(false);
            setModalLoading(false);
            if (response.data.length === 0) {
              enqueueSnackbar('Resultados não econtrados.', {
                variant: 'warning',
              });
              setShowTable(true);
            } else {
              setIsLoading(false);
              setShowTable(true);
              setOrder([...response.data]);
              setOrderData(response.data);
              handleProgressCard(response.data);
              if (
                response.data.findIndex((item: OrderData) => item.substituir) <
                0
              ) {
                setToReplace(true);
              } else {
                setToReplace(false);
              }
              enqueueSnackbar(
                `Foi encontrado ${response.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
            }
          })
          .finally(() => {
            const timer = setTimeout(() => {
              setIsLoading(false);
              return () => {
                clearTimeout(timer);
              };
            }, 1000);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar(
            `Por favor, insira datas com uma diferença de no máximo 15 dias ou filtre pelo pedido`,
            {
              variant: 'warning',
            }
          );
        } else {
          console.error(err);
        }
      }
    },
    [checkFields, idCliente, idUser, enqueueSnackbar]
  );

  const handleSearchCard = useCallback(
    (statusCode: string) => {
      return searchRef.current?.getFieldValue('pedido') !== ''
        ? searchRef.current?.submitForm()
        : handleSearch([], statusCode, cardsSubmit.inicio, cardsSubmit.fim);
    },
    [cardsSubmit.fim, cardsSubmit.inicio, handleSearch]
  );

  const setFields = useCallback(() => {
    searchRef.current?.setFieldValue('inicio', dateDay);
    searchRef.current?.setFieldValue('fim', dateDay);
    searchRef.current?.setFieldValue('descricaoStatus', {
      codigo: 3,
      value: '3',
      label: 'Andamento',
      descricao: 'Andamento',
    });
    handleSearch([], 3, dateDay, dateDay);
  }, [dateDay, handleSearch]);

  const getProduct = async (itemOrder: OrderData) => {
    setModalLoading(true);
    setOpen(true);

    const dataDetail = {
      codigoCliente: Number(idCliente),
      codigoPedido: itemOrder.codigoPedido,
    };
    await handleProduct(dataDetail.codigoCliente, dataDetail.codigoPedido).then(
      (detailOrderData: AxiosResponse) => {
        setRequestDetail(itemOrder);
        if (detailOrderData.data.length === 0) {
          enqueueSnackbar('Resultados não encontrados.', {
            variant: 'warning',
          });
        } else {
          setDetailOrder([...detailOrderData.data]);
        }
      }
    );

    setModalLoading(false);
  };

  const handleParam = React.useCallback(async () => {
    handleClienteData(Number(idCliente)).then((response: any) => {
      const statusOptions = response[0];
      const shippingCompany = response[1];
      const periodType = response[2];
      const orderTypes = response[3];

      const statusPickingList = transformToSelect(statusOptions.data);
      setStatus(statusPickingList);

      const orderTypeOptions = transformToSelect(shippingCompany.data);
      setTransportadoraCode(orderTypeOptions);

      const periodTypeList = transformToSelect(periodType.data);
      setPeriod(periodTypeList);

      const formatedOrderTypes = transformToSelect(orderTypes.data);
      setTypeList(formatedOrderTypes);
    });
  }, [idCliente]);

  useEffect(() => {
    handleStatus();
  }, [handleStatus]);

  useEffect(() => {
    setAnimate(true);
    handleParam();
    setFields();
  }, [handleParam, setFields]);

  return (
    <>
      <FixedHeader title="Pedido" subTitle="Acompanhamento" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSearch}
              searchBoxRef={searchRef}
              inputs={[
                {
                  name: 'pedido',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 3,
                },
                {
                  name: 'tipoPedido',
                  label: 'Tipo Pedido',
                  placeholder: 'Tipo Pedido',
                  type: 'select',
                  options: typeList,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 3,
                },
                {
                  name: 'cliente',
                  label: 'Cliente',
                  placeholder: 'Cliente',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 3,
                },
                {
                  name: 'descricaoStatus',
                  label: 'Status',
                  placeholder: 'Status',
                  type: 'select',
                  options: statusList,
                  isLoading,
                  isDisabled: isLoading,
                  defaultValue: 3,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 3,
                  sm: 3,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'transportadora',
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                  type: 'select',
                  options: transportadoraCode,
                },
                {
                  name: 'periodo',
                  label: 'Periodo',
                  placeholder: 'Periodo',
                  type: 'select',
                  options: period,
                },
                {
                  name: 'inicio',
                  label: 'Data Inicial',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                },
                {
                  name: 'fim',
                  label: 'Data Final',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <>
            <TableFilterCardContainer cardProgressValue={cardProgress}>
              <div>
                {pendingFilter(orderData).toString() !== '0' && (
                  <button
                    type="button"
                    onClick={() => searchRef.current?.submitForm()}
                  >
                    <DetailsItem
                      card
                      title="Resultados"
                      value={pendingFilter(orderData).toString()}
                    />
                  </button>
                )}
                {pendingFilter(orderData, 1).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('1')}>
                    <DetailsItem
                      card
                      title="Criado"
                      value={pendingFilter(orderData, 1).toString()}
                      id="pending"
                    />
                  </button>
                )}
                {pendingFilter(orderData, 2).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('2')}>
                    <DetailsItem
                      card
                      title="Planejado"
                      value={pendingFilter(orderData, 2).toString()}
                      id="ongoing"
                    />
                  </button>
                )}
                {pendingFilter(orderData, 3).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('3')}>
                    <DetailsItem
                      card
                      title="Andamento"
                      value={pendingFilter(orderData, 3).toString()}
                      id="ongoing"
                    />
                  </button>
                )}
                {pendingFilter(orderData, 4).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('9')}>
                    <DetailsItem
                      card
                      title="Ressuprimento"
                      value={pendingFilter(orderData, 4).toString()}
                      id="resupply"
                    />
                  </button>
                )}
                {pendingFilter(orderData, 5).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('10')}>
                    <DetailsItem
                      card
                      title="Embarque Finalizado"
                      value={pendingFilter(orderData, 5).toString()}
                      id="done"
                    />
                  </button>
                )}
              </div>
              <div>
                <DetailsItem
                  card
                  title="Progresso total"
                  value={`${cardProgress.toString()}%`}
                  id="totalProgress"
                >
                  {' '}
                  <span />
                </DetailsItem>
              </div>
            </TableFilterCardContainer>
            <ShowDetailTable getProduct={getProduct} order={order} />
          </>
        )}
        <PageMode
          isModal
          modalLoading={modalLoading}
          disableSubmit
          open={open}
          handleClose={() => setOpen(!open)}
          title="Detalhes"
        >
          <ShowDetails detailOrderList={detailOrder} request={requestDetail} />
        </PageMode>
      </Container>
    </>
  );
};
export default NewFollow;

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import TagStatus from 'components/Tools/TagStatus';
import { TableFilterCardContainer } from 'app/WMS/styles/styles';
import ProgressBar from 'components/Tools/ProgressBar';
import DetailsItem from 'components/Tools/DetailsItem';
import { IShowDetailProps, OrderData } from '../../types';
import ShowDetailItem from '../DetailsItem';

const ShowDetails: React.FC<IShowDetailProps> = ({
  detailOrderList,
  request,
}) => {
  return (
    <>
      <div className="wrapper">
        <div className="contentDetail">
          <Grid container spacing={3} className="detailHeader">
            <div className="details">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="detailsItemsContainer" id="firstRow">
                  <DetailsItem
                    title="Pedido"
                    value={String(request.codigoPedido)}
                  />

                  <DetailsItem
                    title="Nota fiscal"
                    value={
                      String(request.notaFiscal) !== 'undefined'
                        ? String(request.notaFiscal)
                        : ''
                    }
                  />
                  <DetailsItem
                    title="Cliente"
                    value={String(request.cliente)}
                  />
                  <DetailsItem
                    title="Transportadora"
                    value={String(request.transportadora)}
                  />
                </div>
                <div style={{ width: '30%' }}>
                  <ProgressBar
                    value={
                      request.progresso === undefined ? 0 : request.progresso
                    }
                    showTitle
                    style={{ marginRight: '11px' }}
                  />
                </div>
              </div>
              <div className="detailsItemsContainer">
                <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap' }}>
                  <DetailsItem
                    title="Periodo"
                    value={String(request.periodo)}
                  />
                  <DetailsItem
                    title="Início Separação"
                    value={
                      String(request.inicioSeparacao) !== 'undefined'
                        ? String(request.inicioSeparacao)
                        : ''
                    }
                  />
                  <DetailsItem
                    title="Fim do Embarque"
                    value={
                      String(request.fimEmbarque) !== 'undefined'
                        ? String(request.fimEmbarque)
                        : ''
                    }
                  />
                  <div
                    className="statusContainer"
                    style={{ marginTop: '10px' }}
                  >
                    <TagStatus
                      status={request.descricaoStatus}
                      width="fit-content"
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableFilterCardContainer withPadding>
              <div>
                <DetailsItem
                  card
                  title="Quantidade Total"
                  value={String(
                    request.quantidadePedido === undefined
                      ? ''
                      : request.quantidadePedido
                  )}
                />

                <DetailsItem
                  card
                  id="divergent"
                  title="Quantidade Divergente"
                  value={String(
                    request.quantidadeDivergente === undefined
                      ? 0
                      : request.quantidadeDivergente
                  )}
                />
                <DetailsItem
                  card
                  id="done"
                  title="Quantidade Conferida"
                  value={String(
                    request.quantidadeConferida === undefined
                      ? 0
                      : request.quantidadeConferida
                  )}
                />
              </div>
            </TableFilterCardContainer>
          </Grid>
          <ShowDetailItem detailOrder={detailOrderList} />
        </div>
      </div>
    </>
  );
};
export default ShowDetails;

import React, { useState, useCallback } from 'react';
import { Fade } from '@material-ui/core';

import deleteIcon from 'assets/svg/deleteActionsIcon.svg';
import editIcon from 'assets/svg/editActionsIcon.svg';
import { IComponents } from 'utils/interfaces/Components';
import { TableContainer, Container } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import { IComponentsTableProps } from '../../types';
import EditComponentModal from './EditComponentModal';
import DeleteComponentModal from './DeleteComponentModal';

const ComponentsTable: React.FC<IComponentsTableProps> = ({ rows }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState<IComponents>(
    {} as IComponents
  );

  const clearCurrentComponent = useCallback(() => {
    setCurrentComponent({} as IComponents);
  }, []);

  return (
    <>
      <Container withOutPadding>
        <Fade in timeout={1000} unmountOnExit>
          <TableContainer>
            <Table<IComponents>
              columns={[
                {
                  title: 'Código',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['codigoComponente'],
                },
                {
                  title: 'Descrição Componente',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['descricaoComponente'],
                },
                {
                  title: 'Quantidade',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['quantidade'],
                },
                {
                  title: 'Ações',
                  type: undefined,
                  props: [''],
                  renderItem: row => (
                    <div className="row-actions-button-detail">
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setCurrentComponent(row);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete"
                          style={{ width: '20px' }}
                        />
                        <p className="hover-item">Excluir</p>
                      </button>
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setCurrentComponent(row);
                          setIsEditModalOpen(true);
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="editar"
                          style={{ width: '20px' }}
                        />
                        <p className="hover-item">Editar</p>
                      </button>
                    </div>
                  ),
                },
              ]}
              rows={rows}
              exportList
            />
          </TableContainer>
        </Fade>
      </Container>
      <EditComponentModal
        isOpen={isEditModalOpen}
        modalData={currentComponent}
        closeModal={() => setIsEditModalOpen(false)}
        clearModalData={clearCurrentComponent}
      />
      <DeleteComponentModal
        closeModal={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        modalData={currentComponent}
        clearModalData={clearCurrentComponent}
      />
    </>
  );
};

export default ComponentsTable;

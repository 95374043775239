import PageMode from 'components/Tools/PageMode';
import React, { useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';

import { transformToSelect, SelectItem } from 'utils/toSelect';
import { useAuth } from 'hooks/Auth';
import { getCookie } from 'utils/cookies';
import {
  getAllSelectOptions,
  getStatusList,
  getTypeList,
  getFiliais,
} from '../apis/adress.apis';

interface CreateModalFormProps {
  openCreateModal: boolean;
  closeCreateModal: () => void | boolean;
  submitCreateModal?(data: object): void;
  createRef: React.RefObject<FormHandles>;
}

const CreateModalForm: React.FC<CreateModalFormProps> = ({
  openCreateModal,
  closeCreateModal,
  submitCreateModal = () => {},
  createRef,
}) => {
  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const clientId = String(getCookie('@pdamodules::codigoCliente'));

  const { enqueueSnackbar } = useSnackbar();

  const [showShippingInput, setShowShippingInput] = useState<boolean>(false);
  const [showFiliasInput, setShowFilialInput] = useState<boolean>(false);
  const [isFilialInputLoading, setIsFilialInputLoading] =
    useState<boolean>(false);

  const [filialList, setFilialList] = useState<SelectItem[]>([]);
  const [depositList, setDepositList] = useState<SelectItem[]>([]);
  const [statusList, setStatusList] = useState<SelectItem[]>([]);
  const [addressList, setAddressList] = useState<SelectItem[]>([]);
  const [modulesList, setModulesList] = useState<SelectItem[]>([]);
  const [toWalk, setToWalk] = useState<SelectItem[]>([]);
  const [nivelList, setNivelList] = useState<SelectItem[]>([]);
  const [positionList, setPositionList] = useState<SelectItem[]>([]);
  const [editTipo, setEditTipo] = useState<SelectItem[]>([]);
  const [shippingList, setShippingList] = useState<SelectItem[]>([]);

  const depositCode = (code?: number | null) => {
    const codeInStr = String(code) || code?.toString();

    setShowFilialInput(() => {
      if (code !== undefined) return true;
      return false;
    });

    if (codeInStr === '21' || codeInStr === '15') {
      setShowShippingInput(true);
    } else {
      setShowShippingInput(false);
    }
  };

  const getParam = useCallback(async () => {
    const [
      listAddress,
      listModules,
      listNivel,
      listToWalke,
      listPosition,
      listDeposit,
      listShipping,
    ] = await getAllSelectOptions(clientId);

    setAddressList(listAddress);
    setModulesList(listModules);
    setNivelList(listNivel);
    setToWalk(listToWalke);
    setPositionList(listPosition);
    setDepositList(listDeposit);
    setShippingList(listShipping);
  }, [clientId]);

  const getFiliaisOptions = useCallback(
    async (query: string) => {
      setIsFilialInputLoading(true);

      if (query.length < 2) return;

      await getFiliais(clientId, query)
        .then(({ data }) => {
          if (!data.length) {
            enqueueSnackbar('Não há filiais com esse nome', {
              variant: 'warning',
            });

            setFilialList([]);

            return;
          }
          const formatedFilials = transformToSelect(data);

          setFilialList(formatedFilials);
        })
        .finally(() => {
          setIsFilialInputLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  useEffect(() => {
    const getStatus = async () => {
      getStatusList(clientId).then(statusData => {
        const listStatus = transformToSelect(statusData.data);

        setStatusList(listStatus);
      });
    };
    const getTipo = async () => {
      getTypeList(clientId).then(tipoData => {
        const listType = transformToSelect(tipoData.data);
        setEditTipo(listType);
      });
    };
    getTipo();
    getStatus();
    getParam();
  }, [getParam, tokenData, clientId, enqueueSnackbar]);

  useEffect(() => {
    setShowShippingInput(false);
  }, [closeCreateModal]);

  return (
    <>
      <PageMode
        isModal
        open={openCreateModal}
        title="Criar Endereço"
        submitButtonTitle="Salvar"
        submitButtonIcon
        handleSubmit={data => submitCreateModal(data)}
        handleClose={closeCreateModal}
        modalRefObject={createRef}
        inputs={[
          {
            name: 'depositoCreate',
            label: 'Depósito',
            placeholder: 'Depósito',
            type: 'select',
            options: depositList,
            onChangeEvent: value => depositCode(value),
            isRequired: true,
            xl: 3,
            lg: 3,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'tipoCreate',
            label: 'Tipo',
            placeholder: 'Tipo',
            type: 'select',
            options: editTipo,
            isRequired: true,
            xl: 3,
            lg: 3,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'statusCreate',
            label: 'Status',
            placeholder: 'Status',
            type: 'select',
            options: statusList,
            isRequired: true,
            xl: 3,
            lg: 3,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'andarCreate',
            label: 'Andar',
            placeholder: 'Andar',
            type: 'select',
            options: toWalk,
            isRequired: true,
            xl: 3,
            lg: 3,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'ruaCreate',
            label: 'Rua',
            placeholder: 'Rua',
            type: 'select',
            options: addressList,
            isRequired: true,
            xl: 4,
            lg: 4,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'moduloCreate',
            label: 'Módulo',
            placeholder: 'Módulo',
            type: 'select',
            options: modulesList,
            isRequired: true,
            xl: 4,
            lg: 4,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'nivelCreate',
            label: 'Nível',
            placeholder: 'Nível',
            type: 'select',
            options: nivelList,
            isRequired: true,
            xl: 4,
            lg: 4,
            xs: 6,
            md: 4,
            sm: 4,
          },
          {
            name: 'posicaoCreate',
            label: 'Posição',
            placeholder: 'Posição',
            type: 'select',
            options: positionList,
            isRequired: true,
            xl: 6,
            lg: 6,
            xs: 6,
            md: 3,
            sm: 3,
          },
          {
            name: 'capacidadeCreate',
            label: 'Capacidade',
            placeholder: 'Capacidade',
            type: 'text',
            xl: 6,
            lg: 6,
            xs: 6,
            md: 3,
            sm: 3,
          },
          {
            name: 'ressuprimentoCreate',
            label: 'Ressuprimento',
            placeholder: 'Ressuprimento',
            type: 'text',
            xl: 6,
            lg: 6,
            xs: 6,
            md: 3,
            sm: 3,
          },
          {
            name: 'filialCreate',
            label: showFiliasInput ? 'Filial' : '',
            placeholder: showFiliasInput ? 'Filial' : '',
            type: showFiliasInput ? 'select' : undefined,
            options: filialList,
            isLoading: isFilialInputLoading,
            isApiSearch: true,
            handleInputChange: (query, action) =>
              action === 'input-change' && getFiliaisOptions(query),
            xl: 6,
            lg: 6,
            xs: 6,
            md: 3,
            sm: 3,
          },
          {
            name: 'transportadoraCreate',
            label: showShippingInput ? 'Transportadora' : '',
            type: showShippingInput ? 'select' : undefined,
            placeholder: 'Transportadora',
            options: shippingList,
            xl: 6,
            lg: 6,
            xs: 6,
            md: 3,
            sm: 3,
          },
        ]}
      />
    </>
  );
};

export default CreateModalForm;

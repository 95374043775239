import api from 'services/api';
import {
  SearchDataProps,
  SearchDetailsDataProps,
  ISeacheDetailProps,
} from '../types';

type IUpdateOrderStatusData = {
  tokenData: string;
  data: { codigoCliente: number; codigoPedido: string | number };
};

export const getSearchData = async (dataFilter: SearchDataProps) =>
  api.patch('Recebimento', { ...dataFilter });

export const getSearchItensData = (detailsDataFilter: SearchDetailsDataProps) =>
  api.patch(`Recebimento/RecebimentoItem`, { ...detailsDataFilter });

// export const getSearchDataDetail = (detailsFilter: SearchDetailsDataProps) =>
//   api.patch(`Recebimento/RecebimentoItem`, { ...detailsFilter });

// // export const getSearchDataDetail = (detailsFilter?: string) =>
// //   api.get(`Recebimento/RecebimentoItem?codigoPedido=${detailsFilter}`);

export const updateOrderStatus = ({ data }: IUpdateOrderStatusData) => {
  api.put('Recebimento/FinalizarDivergente', { ...data });
};

export const getDetailData = (dataFilter: SearchDetailsDataProps) =>
  api.patch(`Recebimento/RecebimentoItem`, { ...dataFilter });

export const getStatusOptionsData = async (clientId?: string) => {
  const [statusOptions, orderType] = await Promise.all([
    api.get('Common/StatusRecebimento'),
    api.get(`Common/TipoPedido?CodigoCliente=${clientId}`),
  ]);
  return [statusOptions, orderType];
};

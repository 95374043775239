import React, { useState, createContext } from 'react';

interface CustomizationProviderProps {
  children: React.ReactNode;
}

interface SidebarContextProps {
  isFixed: boolean;
  fixSidebar: () => void;
}

export const SidebarContext = createContext({} as SidebarContextProps);

export const SidebarContextProvider = ({
  children,
}: CustomizationProviderProps) => {
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const fixSidebar = () => {
    setIsFixed(!isFixed);
  };

  return (
    <SidebarContext.Provider
      value={{
        isFixed,
        fixSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

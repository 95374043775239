import { Form } from '@unform/web';
import styled from 'styled-components';

export const ModalContainer = styled(Form)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(14, 14, 14, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  @media screen and (max-width: 1600px) {
    overflow-y: scroll;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  background: ${props => props.theme.colors.dashboard_card_bg};
  border-radius: 12px;
  .footerButtons {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    padding: 17px 20px 15px 20px;

    button {
      all: unset;
      cursor: pointer;

      display: flex;
      align-items: center;

      padding: 13px 16px;

      border-radius: 6px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
    .submitButton {
      background: #04c8c8;
      color: #fff;

      &:hover {
        background: #dcfdfd;
        color: #04c8c8;
        transition: 0.5s;
      }
    }

    .backButton {
      background: #eff2f5;
      color: #2b2b40;

      &:hover {
        transition: 0.5s;
        opacity: 0.7;
      }

      img {
        padding-right: 7px;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 10px 15px 20px;
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: ${props => props.theme.colors.container_title};
    }
  }
`;

interface ModalContentContainerProps {
  buttonBackground?: string;
  submitButtonHover?: string;
  submitButtonTitleColor?: string;
}

export const ModalContentContainer = styled.div<ModalContentContainerProps>`
  max-width: 1200px;
  margin: 0 1rem;
  width: 100%;
  height: fit-content;
  max-height: 890.15px;
  background: ${props => props.theme.colors.dashboard_card_bg};
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  .labelInput {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: ${props => (props.theme.title === 'light' ? '#464e5f' : '#b5b5c3')};
  }

  .sel__menu {
    background: ${props => props.theme.colors.input};
    color: ${props => props.theme.colors.select_text};

    .sel__menu-list {
      font-size: 13px;
      .sel__option {
        color: ${props => props.theme.colors.select_text};
      }
      .sel__option--is-selected {
        background: ${props => props.theme.colors.primary};
        color: #ffffff;
      }

      .sel__option--is-focused {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.text};
      }
    }
  }

  .input-wrapper {
    input {
      padding: 2px 8px;
    }
  }

  .MuiBadge-badge {
    color: red;
    margin: 5px -4px;
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    font-size: 13px;
    z-index: initial;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 10px 15px 20px;
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;

      color: ${props => props.theme.colors.container_title};
    }

    .modalCloseButton {
      transition: 0.15s ease;
      &:hover {
        background-color: ${props => props.theme.colors.body};
      }
    }
  }
  .modalFooter {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 30px 15px 15px;

    button {
      padding: 14px;
      border-radius: 6px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-transform: none !important;
    }
    .closeModal {
      background: #0095e8;
      color: #fff5f8;

      &:hover {
        background: #3fbaff;
      }
    }
    .newButton {
      background: #ecf8ff;
      color: #0095e8;

      &:hover {
        background: #0095e8;
        p {
          color: #fff5f8;
        }
        path {
          fill: #fff5f8;
        }
      }
    }
    .submitModal {
      background: ${props => {
        const color = props.buttonBackground || '#46be7d';
        return color;
      }};
      color: #fff5f8;

      svg {
        margin-right: 7px;
      }

      &:hover {
        background: ${props => {
          const color = props.submitButtonHover || '#E8FFF3';
          return color;
        }};
        path {
          fill: ${props => {
            const color = props.submitButtonTitleColor || '#46be7d';
            return color;
          }};
        }
        p {
          color: ${props => {
            const color = props.submitButtonTitleColor || '#46be7d';
            return color;
          }};
        }
      }
    }
  }
`;

/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Jspdf from 'jspdf';
import 'jspdf-autotable';

import { DetailBoardingData } from 'utils/interfaces/Boarding';
import { IPdf } from '../types';
import { renderPdfHeader } from './pdfHeader';
import { renderPdfSignatures } from './pdfSignatures';

export function filterDuplicated(arr: Array<any>, key: string) {
  const newArr: typeof arr = [];
  arr.map(item => {
    if (newArr.indexOf(item[key]) === -1) newArr.push(item[key]);
  });
  return newArr;
}

export function exportPdf(
  pdfData: IPdf,
  dispatcherData: React.Dispatch<
    React.SetStateAction<DetailBoardingData[] | undefined>
  >
) {
  const doc = new Jspdf('p', 'pt', 'a4');

  const romaneios = filterDuplicated(pdfData.romaneios, 'romaneio');

  const filteredData = romaneios.map(romaneio => ({
    romaneio,
    detalhes: pdfData.details.filter(d => d.romaneio === romaneio),
  }));

  filteredData.map((data, index) => {
    if (index !== 0) doc.addPage();
    dispatcherData(data.detalhes);
    renderPdfHeader(
      doc,
      data.detalhes,
      pdfData.totalVolume,
      data.romaneio,
      filterDuplicated(data.detalhes, 'transportadora')[0]
    );

    // @ts-ignore
    doc.autoTable({
      html: '#table-teste',
      startY: 250,
      showHead: 'firstPage',
      pageBreak: 'auto',
      didDrawPage: () => {
        doc.text(
          `Página: ${doc.getNumberOfPages()}`,
          doc.internal.pageSize.width - 70,
          doc.internal.pageSize.height - 25
        );
      },
      didDrawCell: (dataTable: any) => {
        if (
          dataTable.row.cells[0] ===
            dataTable.table.body[dataTable.table.body.length - 1].cells[0] &&
          dataTable.column.index === dataTable.table.columns.length - 1
        ) {
          if (dataTable.row.cells[0].y > 550) {
            doc.addPage();
            renderPdfSignatures(doc, pdfData);
          } else {
            renderPdfSignatures(doc, pdfData);
          }
        }
      },

      margin: { horizontal: 20 },
      headStyles: {
        fillColor: '#606060',
      },
      bodyStyles: {
        lineColor: '#262626',
      },
    });
  });

  doc.save('romaneio.pdf');
}

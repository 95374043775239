import api from 'services/api';

export const createReceiviment = async (data: any, isEntry: boolean) =>
  api.post(`${isEntry ? '/Integration/Recebimento' : 'Integration/Pedido'}`, [
    data,
  ]);

export const getOptions = async (clientId: string) => {
  const [suplierRes, orderTypes, transportadoraRes] = await Promise.all([
    api.get(`Common/Fornecedor?CodigoCliente=${clientId}`),
    api.get(`Common/TipoPedido?CodigoCliente=${clientId}`),
    api.get(`Common/Transportadora?CodigoCliente=${clientId}`),
  ]);

  return [suplierRes, orderTypes, transportadoraRes];
};

export const productRequest = async (query: string, clientId: string) =>
  api.get(`Common/BuscarProduto?CodigoCliente=${clientId}&Produto=${query}`);

export const clientRequest = async (query: string, clientId: string) =>
  api.get(
    `Common/ConsultaFornecedorERP?CodigoCliente=${clientId}&Cliente=${query}`
  );

import React from 'react';
// import { IconButton } from '@material-ui/core';
import { useTheme } from 'styled-components';
// import Brightness4OutlinedIcon from '@material-ui/icons/Brightness4Outlined';

import { ThemeToggleContainer } from './styles';

interface Props {
  toggleTheme(): void;
  children?: React.ReactNode;
}

export const ThemeToggle: React.FC<Props> = ({ toggleTheme }) => {
  const { title } = useTheme();

  const isChecked = title === 'dark';

  return (
    <ThemeToggleContainer onClick={toggleTheme} isChecked={isChecked}>
      <div className="circle">
        <div className="inner-circle" />
      </div>
      <div className="switch-content">
        <input
          type="checkbox"
          checked={isChecked}
          readOnly
          name="toggleTheme"
        />
      </div>
    </ThemeToggleContainer>
  );
};

/* 
  <IconButton
      onClick={toggleTheme}
      aria-label="theme"
      className="switchTheme"
    >
      <Brightness4OutlinedIcon
        color={title === 'light' ? 'disabled' : 'primary'}
      />
    </IconButton>
*/

import React from 'react';
import { Fade } from '@material-ui/core';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import SearchIconDetails from 'assets/svg/searchIconDetails.svg';
import { LogData, IResultLogProps } from '../../types';

const ShowDetailTable: React.FC<IResultLogProps> = ({
  handleProduct,
  logStorage,
  animate,
}) => {
  return (
    <Fade in={animate} unmountOnExit timeout={1000}>
      <TableContainer>
        <Table<LogData>
          exportList
          columns={[
            {
              title: 'Endereço',
              type: 'string',
              orderable: true,
              props: ['endereco'],
              cssProps: {
                width: '1%',
              },

              renderItem: row => {
                return (
                  <div className="code-item">
                    <p>{row.endereco}</p>
                  </div>
                );
              },
            },
            {
              title: 'Tipo',
              type: 'stringCenter',
              props: ['tipoEndereco'],
              orderable: true,
              cssProps: {
                width: '10%',
              },
            },
            {
              title: 'Quantidade Palete',
              type: 'stringCenter',
              orderable: true,
              props: ['quantidadePalete'],
              cssProps: {
                width: '15%',
              },
            },
            {
              title: 'Quantidade Caixa',
              type: 'stringCenter',
              orderable: true,
              props: ['quantidadeCaixa'],
              cssProps: {
                width: '15%',
              },
            },
            {
              title: 'Quantidade',
              type: 'number',
              orderable: true,
              props: ['quantidade'],
              cssProps: {
                width: '15%',
              },
            },
            {
              title: 'Ações',
              orderable: true,
              type: undefined,
              cssProps: {
                width: '1%',
              },
              props: ['substituir'],
              renderItem: row => {
                return (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => handleProduct(row)}
                    >
                      <img
                        src={SearchIconDetails}
                        alt="Detalhes"
                        style={{ width: '30px' }}
                      />
                      <p className="hover-item">Detalhe</p>
                    </button>
                  </div>
                );
              },
            },
          ]}
          rows={[
            ...logStorage.map((item: any) => ({
              ...item,
            })),
          ]}
        />
      </TableContainer>
    </Fade>
  );
};
export default ShowDetailTable;

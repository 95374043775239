import { IRegisterFormProduct, IRegisterForm } from '../types';

export function getRandomFiveDigitNumber(): number {
  const num = Math.floor(Math.random() * 99999 + 1);

  if (String(num).length < 5) return getRandomFiveDigitNumber();

  return num;
}

export function formatFormDataToApi(
  detailsData: IRegisterForm,
  products: IRegisterFormProduct[],
  isEntry: boolean
) {
  const codigoPedido = getRandomFiveDigitNumber().toString();

  const formatedData = isEntry
    ? {
        codigoPedido,
        emissao: detailsData.data || null,
        fornecedor: detailsData.fornecedor || null,
        notaFiscal: detailsData.notaFiscal || codigoPedido,
        tipoPedido: detailsData.tipoPedido || null,
        codigoClienteErp: detailsData.codigoClienteErp,
        operacao: detailsData.operacao || null,
        itens: products.map(prod => ({
          codigoItemErp: prod.produto.ean,
          produto: prod.produto.produto,
          quantidade: prod.quantidade,
          ean: prod.produto.ean,
          cor: prod.produto.cor,
          tamanho: prod.produto.tamanho,
          grade: prod.produto.grade,
          substituivel: true,
        })),
      }
    : {
        codigoPedido,
        codigoPedidoEcom: codigoPedido,
        dataVenda: detailsData.data || null,
        fornecedor: detailsData.fornecedor || null,
        codigoClienteErp: detailsData.codigoClienteErp,
        notaFiscal: detailsData.notaFiscal || codigoPedido,
        tipoPedido: detailsData.tipoPedido || null,
        codigoTransportadora: detailsData.codigoTransportadora || null,
        operacao: detailsData.operacao || null,
        pedidoItens: products.map(prod => ({
          codigoPedidoItem: prod.produto.ean,
          produto: prod.produto.produto,
          quantidade: prod.quantidade,
          ean: prod.produto.ean,
          corProduto: prod.produto.cor,
          tamanho: prod.produto.tamanho,
          grade: prod.produto.grade,
          substituivel: true,
        })),
      };

  return formatedData;
}

export function formatOptions(apiResponse: any, isProduct?: boolean) {
  const formatedData = apiResponse.map((r: any) => ({
    label: isProduct
      ? `${r.produto} - ${r.ean} - ${r.descricao}`
      : r.nomeFornecedor || r.ean || r.descricao || r.nomeCliente,
    value:
      r.codigoFornecedor ||
      r.codigo ||
      r.produto ||
      r.tipoPedido ||
      r.codigoClienteErp,
    ...r,
  }));

  return formatedData;
}

import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';

import cadastro_manual_icon from 'assets/svg/cadastro_manual_icon.svg';
import upload_cadastro_icon from 'assets/svg/upload_cadastro_icon.svg';
import FixedHeader from 'components/Tools/Breadcrumb';
import FileUpload from 'components/Tools/FileUpload';
import { Container } from '../../../styles/styles';
import { CadastroContainer, RegisterButton } from './styles';
import CreateOrder from './components';

const RegisterOrder: React.FC = () => {
  const [animate, setAnimate] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setAnimate(true);
    }, 550);
  }, []);

  return (
    <>
      <FixedHeader title="Pedido" subTitle="Cadastro" />
      <Fade in={animate}>
        <Container>
          <CadastroContainer>
            <RegisterButton
              text="Cadastro manual"
              icon={cadastro_manual_icon}
              onClick={() => setCreateModal(true)}
            />
            <RegisterButton
              text="Upload de arquivo"
              icon={upload_cadastro_icon}
              onClick={() => setUploadModal(true)}
            />
          </CadastroContainer>
        </Container>
      </Fade>
      {createModal && (
        <CreateOrder
          isOpen={createModal}
          closePopup={() => setCreateModal(false)}
        />
      )}
      {uploadModal && (
        <FileUpload
          isOpen={uploadModal}
          cancelFunction={() => setUploadModal(false)}
        />
      )}
    </>
  );
};

export default RegisterOrder;

import React from 'react';
import Modal from 'components/Tools/_Modal';
import { Fade } from '@material-ui/core';
import WarningIcon from 'assets/svg/warningIcon.svg';
import ConfirmedIcon from 'assets/svg/confirmedIcon.svg';

type ConfirmationModalProps = {
  deleteType?: boolean;
  resetConfirm: () => Promise<void>;
  confirm: () => Promise<void> | Promise<any>;
  isConfirmed: boolean;
  handleCloseModal: () => void;
  openModal: boolean;
  warningTitle: string;
  doneTitle: string;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  deleteType,
  resetConfirm,
  confirm,
  isConfirmed,
  handleCloseModal,
  openModal,
  warningTitle,
  doneTitle,
}) => {
  const animate = true;
  return (
    <Modal type="confirmation" open={openModal}>
      {isConfirmed ? (
        <Fade in={animate} timeout={3000}>
          <>
            <img
              src={ConfirmedIcon}
              alt="confirmed"
              style={{ paddingTop: '53px' }}
            />
            <p>{doneTitle}</p>
            <button
              type="button"
              className="closeButton"
              onClick={resetConfirm}
            >
              Fechar
            </button>
          </>
        </Fade>
      ) : (
        <>
          {deleteType ? (
            <>
              <img src={WarningIcon} alt="warning" />
              <p>{warningTitle}</p>
              <div style={{ gap: '10px' }}>
                <button
                  type="button"
                  className="confirmButtonDelete"
                  onClick={confirm}
                >
                  Confirmar
                </button>
                <button
                  type="button"
                  className="cancelButtonDelete"
                  onClick={() => handleCloseModal()}
                >
                  Cancelar
                </button>
              </div>
            </>
          ) : (
            <>
              <img src={WarningIcon} alt="warning" />
              <p>{warningTitle}</p>
              <div style={{ gap: '10px' }}>
                <button
                  type="button"
                  className="confirmButton"
                  onClick={confirm}
                >
                  Confirmar
                </button>
                <button
                  type="button"
                  className="cancelButton"
                  onClick={() => handleCloseModal()}
                >
                  Cancelar
                </button>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ConfirmationModal;

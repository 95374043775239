import api from 'services/api';
import {
  PickingData,
  IStatusProps,
  IHandleSubmitProp,
  SearchDataProps,
  IHandleProduct,
} from '../types';

export const handleStatusPicking = (dataFilter: IStatusProps) =>
  api.patch<PickingData>(`Picking/Acompanhamento`, { ...dataFilter });

export const handleSubmitData = (dataFilter: SearchDataProps) =>
  api.patch<PickingData>(`Picking/Acompanhamento`, { ...dataFilter });

export const handleProduct = (dataDetail: IHandleProduct) =>
  api.get(
    `Relatorio/PedidoDetalhe?codigoCliente=${dataDetail.codigoCliente}&codigoPedido=${dataDetail.codigoEndereco}`
  );

export const hendleParamentes = async (idCliente: Number) => {
  const [shippingCompany, status, orderType] = await Promise.all([
    api.get(`Common/Transportadora?codigoCliente=${Number(idCliente)}`),
    api.get(`Common/StatusPicking?codigoCliente=${Number(idCliente)}`),
    api.get(`Common/TipoPedido?CodigoCliente=${idCliente}`),
  ]);
  return [shippingCompany, status, orderType];
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import SearchIconDetails from 'assets/svg/searchIconDetails.svg';
import { BoardingData, DetailBoardingData } from 'utils/interfaces/Boarding';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { exportPdf } from '../../utils';
import { detailsRequest } from '../../api/boarding.api';
import { IBoardingTableProps, IPdf } from '../../types';
import Pdf from './Pdf';

const BoardingTable: React.FC<IBoardingTableProps> = ({
  boardings,
  setBoardingData,
  setModal,
}) => {
  const [pdf, setPdf] = useState<DetailBoardingData[]>();

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');

  const handleSelecedRows = useCallback(
    async (rows: BoardingData[]) => {
      const requestData = {
        codigoCliente: Number(clientId),
        romaneios: rows.map(row => ({ romaneio: row.romaneio })),
      };

      await detailsRequest(requestData)
        .then(({ data }) => {
          const newPdf: IPdf = {
            details: data,
            totalVolume: data.reduce(
              (totalUnity, unity) => totalUnity + unity.qtdVolumes,
              0
            ),
            romaneios: data.map(r => ({ romaneio: r.romaneio })),
            transportadora: data.map(t => ({
              transportadora: t.transportadora,
            })),
          };

          exportPdf(newPdf, setPdf);
          setTimeout(() => {
            setPdf(undefined);
          }, 500);
        })
        .catch(err => {
          setPdf(undefined);
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        });
    },
    [clientId, enqueueSnackbar]
  );

  useEffect(() => {
    setBoardingData({} as BoardingData);
  }, [boardings, setBoardingData]);

  return (
    <Fade in unmountOnExit timeout={1000}>
      <TableContainer>
        <Table<BoardingData>
          exportList
          selectBox
          selectBoxActions={[
            {
              onClick: rows => handleSelecedRows(rows),
              renderItem: () => <p>Exportar Romaneio(s) em PDF</p>,
            },
          ]}
          columns={[
            {
              title: 'Romaneio',
              type: 'stringCenter',
              orderable: true,
              props: ['romaneio'],
            },
            {
              title: 'Transportadora',
              type: 'stringCenter',
              props: ['transportadora'],
              orderable: true,
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: 'Qtde. NF',
              type: 'stringCenter',
              orderable: true,
              props: ['qtdNotaFiscal'],
            },
            {
              title: 'Qtde. Volumes',
              type: 'stringCenter',
              orderable: true,
              props: ['qtdVolumes'],
            },
            {
              title: 'Período',
              type: 'stringCenter',
              orderable: true,
              props: ['periodo'],
            },
            {
              title: 'Data início',
              type: 'stringCenter',
              orderable: true,
              props: ['dataInicio'],
              renderItem: row => (
                <p className="render-string">
                  {row.dataInicio
                    ? new Date(row.dataInicio).toLocaleString()
                    : '-'}
                </p>
              ),
            },
            {
              title: 'Data final',
              type: 'stringCenter',
              orderable: true,
              props: ['dataFim'],
              renderItem: row => (
                <p className="render-string">
                  {row.dataFim ? new Date(row.dataFim).toLocaleString() : '-'}
                </p>
              ),
            },
            {
              title: 'Usuário',
              type: 'stringCenter',
              orderable: true,
              props: ['usuario'],
            },
            {
              title: 'Ações',
              type: undefined,
              props: [''],
              renderItem: row => (
                <div className="row-actions-button-detail">
                  <button
                    type="button"
                    className="action"
                    onClick={() => {
                      setBoardingData(row);
                      setModal(true);
                    }}
                  >
                    <img
                      src={SearchIconDetails}
                      alt="Detalhes"
                      style={{ width: '30px' }}
                    />
                    <p className="hover-item">Detalhes</p>
                  </button>
                </div>
              ),
            },
          ]}
          defaultNumberOfRows={10}
          rows={boardings}
        />
        {pdf && <Pdf data={pdf} />}
      </TableContainer>
    </Fade>
  );
};

export default BoardingTable;

import api from 'services/api';
import { IExtractDataProps } from '../types/index';

export const handleReports = (data: IExtractDataProps) => {
  const params: IExtractDataProps = {
    bandeira: data.bandeira || null,
    regional: data.regional || null,
    CodigoFilial: data.CodigoFilial || null,
    quantidade: data.quantidade || null,
    dataInicio: data.dataInicio || null,
    dataFim: data.dataFim || null,
  };
  return api.get('/extract', { params });
};

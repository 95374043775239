/* eslint-disable @typescript-eslint/ban-ts-comment */
import PageMode from 'components/Tools/PageMode';
import React, { useState, useCallback } from 'react';
import uploadLgImg from 'assets/svg/uploadLgImg.svg';
import upload_cadastro_icon from 'assets/svg/upload_cadastro_icon.svg';

import * as C from 'components/Tools/FileUpload/styles';

interface ImportModalFormProps {
  openImportModal: boolean;
  closeImportModal: () => void | boolean;
  submitImportModal: (data: any) => Promise<void> | boolean;
}

const ImportModalForm: React.FC<ImportModalFormProps> = ({
  openImportModal,
  closeImportModal,
  submitImportModal,
}) => {
  const [file, setFile] = useState();

  const saveImage = useCallback(async e => {
    console.log('teste');
    const fileDate = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      const arrayBuffer = fileDate;
      const array = new Uint8Array(arrayBuffer);
      // const binaryString = String.fromCharCode.apply(null, array);
      console.log(array);
    };
    setFile(fileDate);
  }, []);

  const clearFile = () => {
    setFile(undefined);
  };

  const wrapperFunction = () => {
    closeImportModal();
    clearFile();
  };

  const wrapperFunctionSubmit = () => {
    submitImportModal(file);
    clearFile();
  };

  return (
    <>
      <PageMode
        isModal
        submitButtonIcon
        open={openImportModal}
        title="Importar Arquivo"
        submitButtonTitle="Salvar"
        handleSubmit={wrapperFunctionSubmit}
        handleClose={wrapperFunction}
      >
        <C.CenterIt>
          <C.ContentWrapper>
            <C.FileUploadTitle>
              Escolha o arquivo para fazer upload.
            </C.FileUploadTitle>
            <C.FileUploadImage src={uploadLgImg} alt="Upload Image" />
            <input
              type="file"
              multiple
              id="inputFiles"
              style={{ display: 'none' }}
              onChange={saveImage}
            />
            {/* 'accept' -> define o tipo de arquivo que pode ser selecionado. Nesse caso excel */}
            <C.FileUploadButton htmlFor="inputFiles">
              <C.FileUploadImage
                src={upload_cadastro_icon}
                alt="Button icon"
                loading="eager"
              />
              Subir Arquivo
            </C.FileUploadButton>
            {file && (
              // eslint-disable-next-line react/jsx-no-comment-textnodes
              <C.FileNamesBox>
                {/* @ts-ignore */}
                <C.FileName>{file.name}</C.FileName>
              </C.FileNamesBox>
            )}
          </C.ContentWrapper>
        </C.CenterIt>
      </PageMode>
    </>
  );
};

export default ImportModalForm;

import React from 'react';
import { Fade } from '@material-ui/core';

import { DetailInventoryData } from 'utils/interfaces/Log';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { IInventoryDetailsTableProps } from '../../../types';

const InventoryDetailsTable: React.FC<IInventoryDetailsTableProps> = ({
  rows,
}) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<DetailInventoryData>
        columns={[
          {
            title: 'Endereço',
            orderable: true,
            type: 'stringCenter',
            props: ['descricaoEndereco'],
          },
          {
            title: 'Palete',
            type: 'stringCenter',
            orderable: true,
            display: 'notEmpty',
            props: ['descricaoPalete'],
          },

          {
            title: 'Caixa',
            orderable: true,
            display: 'notEmpty',
            type: 'stringCenter',
            props: ['caixa'],
          },
          {
            title: 'Codigo do Pedido',
            orderable: true,
            type: 'stringCenter',
            props: ['codigoPedido'],
          },
          {
            title: 'EAN',
            orderable: true,
            type: 'stringCenter',
            props: ['ean'],
          },
          {
            title: 'Produto',
            orderable: true,
            type: 'stringCenter',
            props: ['produto'],
          },
          {
            title: 'Descrição Produto',
            orderable: true,
            type: 'stringCenter',
            props: ['descricaoProduto'],
          },
          {
            title: 'Cor',
            type: 'stringCenter',
            orderable: true,
            display: 'notEmpty',
            props: ['cor'],
          },
          {
            title: 'Tamanho',
            type: 'stringCenter',
            orderable: true,
            display: 'notEmpty',
            props: ['tamanho'],
          },
          {
            title: 'Estoque',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
          },
          {
            title: 'Reservado',
            orderable: true,
            type: 'number',
            props: ['reservado'],
          },
          {
            title: 'Disponível',
            orderable: true,
            type: 'number',
            props: ['disponivel'],
          },
        ]}
        rows={rows}
        exportList
      />
    </TableContainer>
  </Fade>
);

export default InventoryDetailsTable;

import Cookies from 'universal-cookie/es6/Cookies';

const cookies = new Cookies();

type PDACookies =
  | '@pdamodules::codigoCliente'
  | '@pdamodules::id'
  | '@pdamodules::CodigoSistema'
  | '@pdamodules::active'
  | '@pdamodules::login'
  | '@pdamodules::descricaoCliente'
  | '@pdamodules::refreshtoken'
  | '@pdamodules::token'
  | '@pdamodules::user';

export const getCookie = (cookieName: PDACookies) => {
  const searchCookie = cookies.get(cookieName);

  return searchCookie;
};

export const removeCookie = (cookieName: PDACookies) => {
  cookies.remove(cookieName);
};

export const removeAllCookies = () => {
  cookies.remove('@pdamodules::codigoCliente');
  cookies.remove('@pdamodules::id');
  cookies.remove('@pdamodules::CodigoSistema');
  cookies.remove('@pdamodules::active');
  cookies.remove('@pdamodules::login');
  cookies.remove('@pdamodules::descricaoCliente');
  cookies.remove('@pdamodules::refreshtoken');
  cookies.remove('@pdamodules::token');
  cookies.remove('@pdamodules::user');
};

import React from 'react';

import IconCaixaCancelado from 'assets/svg/caixa-preta.svg';
import Icon from 'components/Tools/Icon';
import { CustomizedImgCellContainer } from './styles';

interface ICustomizedImgCellProps {
  status: string;
}

const CustomizedImgCell: React.FC<ICustomizedImgCellProps> = ({ status }) => {
  const handleColor = (stats: string) => {
    switch (stats) {
      case 'Aguardando Expedição':
        return '#F69B11';
      case 'Planejado':
        return '#F69B11';
      case 'Aguardando Liberação':
        return '#F69B11';
      case 'Aguardando Faturamento':
        return '#F69B11';
      case 'Aguardando Integração':
        return '#F69B11';
      case 'Andamento':
        return '#F1BC00';
      case 'Pendente':
        return '#A1A5B7';
      case 'Embarque Finalizado':
        return '#46BE7D';
      case 'Finalizado':
        return '#46BE7D';
      case 'Ok':
        return '#46BE7D';
      case 'Finalizado c/divergência':
        return '#D9214E';
      case 'Estoque Insuficiente':
        return '#D9214E';
      case 'Pedido Cancelado':
        return '#3F4254';
      case 'Aguardando Conferência':
        return '#4A7DFF';
      case 'Ressuprimento':
        return '#58244B';
      case 'Expedição Finalizada':
        return '#4A7DFF';
      case 'Criado':
        return '#B5B5C3';
      default:
        return '#3F4254';
    }
  };

  return status !== '' ? (
    <CustomizedImgCellContainer>
      <Icon icon={IconCaixaCancelado} color={handleColor(status)} />
    </CustomizedImgCellContainer>
  ) : (
    <></>
  );
};

export default CustomizedImgCell;

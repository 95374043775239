import { format } from 'date-fns';

const isValidDate = (d: any) => {
  return d instanceof Date && !Number.isNaN(d);
};

const formatDate = (value: Date | string) => {
  console.log(isValidDate(value), value);

  // if (value) {
  //   const formatedDate = new Date(value)
  //     .toISOString()
  //     .split('T')[0]
  //     .split('-')
  //     .slice(0, 3)
  //     .reverse()
  //     .join('/');

  //   return formatedDate;
  // }

  return '';
};

const formatDateHours = (date: Date) => {
  const formatedHours = format(new Date(date), 'h:m a');

  return formatedHours;
};

export { formatDate, formatDateHours };

import React from 'react';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import { PickingWave } from 'utils/interfaces/Picking';
import { WaveTableProps } from 'features/Picking/Wave/types';

const WaveTable: React.FC<WaveTableProps> = ({ wave, openCreateModal }) => {
  return (
    <TableContainer>
      <Table<PickingWave>
        exportList
        selectAllRows="perPage"
        columns={[
          {
            title: 'Pedido',
            type: 'stringCenter',
            orderable: true,
            props: ['codigoPedido'],
            display: 'notEmpty',
            cssProps: {
              width: '3%',
            },
            renderItem: row => {
              return (
                <div className="code-item">
                  <p style={{ marginLeft: '10px' }}>{row.codigoPedido}</p>
                </div>
              );
            },
          },
          {
            title: 'Data Pedido',
            type: 'datetime',
            props: ['dataPedido'],
            orderable: true,
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Cliente',
            type: 'stringCenter',
            orderable: true,
            props: ['cliente'],
            cssProps: {
              width: '10%',
            },
          },
          {
            title: 'Periodo',
            type: 'stringCenter',
            orderable: true,
            props: ['periodo'],
            cssProps: {
              width: '10%',
            },
          },
          {
            title: 'Transportadora',
            type: 'string',
            orderable: true,
            props: ['transportadora'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Qtde Peças',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
            cssProps: {
              width: '2%',
            },
          },
        ]}
        selectBox
        selectBoxActions={[
          {
            onClick: linesItem => openCreateModal(linesItem),
            renderItem: () => <p>Nova Onda</p>,
          },
        ]}
        rows={[
          ...wave.map(item => ({
            ...item,
          })),
        ]}
      />
    </TableContainer>
  );
};

export default WaveTable;

import api from 'services/api';
import { StructureData } from 'utils/interfaces/Storage';
import {
  IEditStructureProps,
  ISearchBoxProps,
  ICreateStrutureProps,
} from '../types/index';

export const handleStructure = (dataDetail: IEditStructureProps) =>
  api.put('Armazenagem/EstruturaEndereco', { ...dataDetail });

export const handleDelete = (
  modalData: StructureData,
  clientId: string | undefined,
  userId: string | null
) =>
  api.delete(
    `Armazenagem/EstruturaEndereco?codigo=${
      modalData.codigo
    }&codigoCliente=${Number(clientId)}&tipo=${modalData.tipo}&user=${Number(
      userId
    )}`
  );

export const handleOptionsEstructure = (clientId: string | undefined) =>
  api.get(`Common/TipoEstruturaEndereco?codigoCliente=${Number(clientId)}`);

export const handleStructureData = (clientId: string | undefined) =>
  api.get(`Armazenagem/EstruturaEndereco?codigoCliente=${Number(clientId)}`);

export const handleSearchBox = (dataFilters: ISearchBoxProps) =>
  api.patch('Armazenagem/EstruturaEndereco', { ...dataFilters });

export const handleCreate = (dataCreate: ICreateStrutureProps) =>
  api.post('Armazenagem/EstruturaEndereco', {
    ...dataCreate,
  });

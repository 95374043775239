import Jspdf from 'jspdf';

import Logo from 'assets/images/logo.png';
import { DetailBoardingData } from 'utils/interfaces/Boarding';

const date = new Date();

export function renderPdfHeader(
  context: Jspdf,
  details: DetailBoardingData[],
  totalVolume: number,
  romaneio?: number,
  transportadora?: string
) {
  context.addImage(Logo, 'png', 440, 30, 130, 30);

  context.setFontSize(16);
  context.text('Relatório de Expedição', 190, 50, { charSpace: 1 });

  context.setFontSize(10);
  context.text(`Carregamento Nº: ${romaneio}`, 20, 85, {
    charSpace: 1,
  });

  context.text(`Transportadora: ${transportadora}`, 20, 100, {
    charSpace: 1,
  });

  context.text(`Notas: ${details.length}`, 20, 115, {
    charSpace: 1,
  });

  context.text(`Volumes: ${totalVolume}`, 90, 125, {
    charSpace: 1,
  });

  context.text(
    `Data: ${date.toLocaleDateString()} Horário: ${date.getHours()}:${date.getMinutes()}`,
    175,
    125,
    {
      charSpace: 1,
    }
  );

  context.setFillColor(0, 0, 0);

  context.rect(20, 140, context.internal.pageSize.width - 40, 1, 'FD');
}

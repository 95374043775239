import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';

import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import TagStatus from 'components/Tools/TagStatus';
import { ReceivementDataDetail } from '../../../types';
import CustomizedImgCell from '../../CustomizedImgCell';

export type IDetailsSearchPopupData = {
  codigoPedido?: string;
  Produto: string;
  box: string;
  pallet: string;
  date?: Date | string;
};

interface IDetailsTableProps {
  receivementItemData: ReceivementDataDetail[];
  hendleSearchDetail?: (data: Object) => void;
}

const DetailsTable: React.FC<IDetailsTableProps> = ({
  receivementItemData,
  hendleSearchDetail,
}) => {
  const [animate, setAnimate] = useState(false);
  const [Produto, setProduto] = useState<string>('');
  const [box, setBox] = useState<string>('');
  const [pallet, setPallet] = useState<string>('');
  const [date, setDate] = useState<Date | string>();

  useEffect(() => {
    setAnimate(false);
    setInterval(() => {
      setAnimate(true);
    }, 350);
    console.log(receivementItemData);
  }, [receivementItemData]);

  const onSearchFilterReceivments = () => {
    const data: IDetailsSearchPopupData = {
      Produto,
      box,
      pallet,
      date,
    };

    if (hendleSearchDetail) {
      hendleSearchDetail(data);
    }
  };

  return (
    <Fade in={animate} unmountOnExit timeout={1000}>
      <TableContainer inPopUp>
        <Table<ReceivementDataDetail>
          filtersDestails
          hendleSearchDetail={() => onSearchFilterReceivments()}
          inputsPopUpFilter={[
            {
              name: 'Produto',
              type: 'text',
              label: 'Produto',
              placeholder: 'Digite o produto',
              onChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) =>
                setProduto(e.target.value),
            },
            {
              name: 'pedido',
              type: 'text',
              label: 'Caixa',
              placeholder: 'Digite a caixa',
              onChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) =>
                setBox(e.target.value),
            },
            {
              name: 'palete',
              type: 'text',
              label: 'Palete',
              placeholder: 'Digite o palete',
              onChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) =>
                setPallet(e.target.value),
            },
            {
              name: 'data',
              type: 'date',
              label: 'Data conferida',
              onChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value),
            },
          ]}
          exportList
          detailTable
          defaultSort="status"
          columns={[
            {
              title: 'Produto',
              type: 'string',
              orderable: true,
              props: ['codigoProduto'],
              renderItem: row => (
                <div className="code-item">
                  <CustomizedImgCell status={row.status} />
                  <p style={{ marginLeft: '10px' }}>{row.codigoProduto}</p>
                </div>
              ),
            },
            {
              title: 'Caixa',
              type: 'string',
              orderable: true,
              props: ['caixa'],
            },
            {
              title: 'Palete',
              type: 'string',
              orderable: true,
              props: ['palete'],
            },
            {
              title: 'Descrição',
              type: 'string',
              orderable: true,
              props: ['produto'],
            },
            {
              title: 'Status',
              type: 'string',
              props: ['status'],
              orderable: true,
              renderItem: row => (
                <TagStatus status={row.status} width="fit-content" />
              ),
            },
            {
              title: 'Tam.',
              type: 'string',
              orderable: true,
              props: ['tamanho'],
            },
            {
              title: 'Cor',
              type: 'string',
              orderable: true,
              props: ['cor'],
            },
            {
              title: 'Qtde NF',
              type: 'string',
              orderable: true,
              props: ['quantidade'],
            },
            {
              title: 'Receb.',
              type: 'number',
              orderable: true,
              props: ['quantidadeRecebimento'],
            },
            {
              title: 'Divergência.',
              type: 'number',
              orderable: true,
              props: ['quantidadeDivergencia'],
            },
            {
              title: 'Usuário.',
              type: 'string',
              orderable: true,
              props: ['usuario'],
            },
            {
              title: 'Data Rec.',
              type: 'date',
              orderable: true,
              props: ['dataRecebimento'],
            },
          ]}
          rows={receivementItemData?.map(item => ({
            ...item,
          }))}
        />
      </TableContainer>
    </Fade>
  );
};

export default DetailsTable;

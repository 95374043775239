import React from 'react';
import { Fade } from '@material-ui/core';

import { TransferenceData } from 'utils/interfaces/Transference';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { ITransferenceTableProps } from '../../types';

const TransferenceTable: React.FC<ITransferenceTableProps> = ({ rows }) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<TransferenceData>
        columns={[
          {
            title: 'Origem',
            type: 'stringCenter',
            orderable: true,
            props: ['unidadeOrigem'],
          },
          {
            title: 'Destino',
            type: 'stringCenter',
            orderable: true,
            props: ['unidadeDestino'],
          },
          {
            title: 'Unidade',
            type: 'stringCenter',
            orderable: true,
            props: ['unidadeArmazenagem'],
          },
          {
            title: 'Descrição',
            type: 'string',
            orderable: true,
            props: ['descricao'],
          },
          {
            title: 'Quantidade',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
          },
          {
            title: 'Usuário',
            type: 'stringCenter',
            orderable: true,
            props: ['usuario'],
          },
        ]}
        rows={rows}
        exportList
      />
    </TableContainer>
  </Fade>
);

export default TransferenceTable;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: center;

  .buttonContent {
    width: 100%;
    padding: 5px 9px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
`;

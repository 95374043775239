import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import { getCookie } from 'utils/cookies';
import { useNotification } from 'hooks/Notification';
import PageMode from 'components/Tools/PageMode';
import { createComponentRequest } from '../../api/components.api';
import { ICreateComponentModal } from '../../types';

const CreateComponentModal: React.FC<ICreateComponentModal> = ({
  isOpen,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { pushNotification } = useNotification();

  const clientId = Number(getCookie('@pdamodules::codigoCliente'));

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleCreateComponent = useCallback(
    async (data: any) => {
      setIsLoading(true);

      const { descricaoComponente } = data;

      await createComponentRequest(descricaoComponente, clientId)
        .then(res => {
          console.log(res.data);
          handleCloseModal();
          enqueueSnackbar('Componente criado com sucesso!', {
            variant: 'success',
          });
          pushNotification({
            message: `Componente ${descricaoComponente} criado com sucesso`,
            type: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar, handleCloseModal, pushNotification]
  );

  return (
    <PageMode
      isModal
      open={isOpen}
      submitButtonIcon
      modalLoading={isLoading}
      handleClose={handleCloseModal}
      submitButtonTitle="Criar"
      title="Criar Componente"
      handleSubmit={data => handleCreateComponent(data)}
      inputs={[
        // {
        //   name: 'codigoComponente',
        //   label: 'Componente',
        //   placeholder: 'Componente',
        //   type: 'text',
        //   xl: 4,
        //   lg: 4,
        //   xs: 12,
        //   md: 4,
        //   sm: 4,
        // },
        {
          name: 'descricaoComponente',
          label: 'Descrição Componente',
          placeholder: 'Descrição Componente',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 4,
        },
        // {
        //   name: 'quantidade',
        //   label: 'Quantidade',
        //   placeholder: 'Quantidade',
        //   type: 'text',
        //   xl: 4,
        //   lg: 4,
        //   xs: 12,
        //   md: 4,
        //   sm: 4,
        // },
      ]}
    />
  );
};

export default CreateComponentModal;

import React, { useCallback, useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Fade } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import { useAuth } from 'hooks/Auth';

import getValidationErrors from 'utils/getValidationErrors';
import { SelectItem, transformToSelect } from 'utils/toSelect';
import { getCookie } from 'utils/cookies';

import {
  Container,
  Search,
  TableFilterCardContainer,
} from 'app/WMS/styles/styles';

import { FormHandles } from '@unform/core';

import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import PageMode from 'components/Tools/PageMode';
import ConfirmationModal from 'components/Tools/ConfirmationModal';
import DetailsItem from 'components/Tools/DetailsItem';
import ShowDetails from './components/ShowDetails';
import ReceivementTable from './components/ReceivementTable';
import { IDetailsSearchPopupData } from './components/ShowDetails/DetailsTable';

import { ReceivementData, ReceivementItemData, SearchDataProps } from './types';

import {
  getDetailData,
  getSearchData,
  getStatusOptionsData,
  updateOrderStatus,
  getSearchItensData,
} from './apis/receivement.apis';

const Receivement: React.FC = () => {
  const [showTable, setShowTable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [orderCode, setOrderCode] = useState<string>('');
  const [openDivergentModal, setOpenDivergentModal] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const searchRef = useRef<FormHandles>(null);

  const [cardProgress, setCardProgress] = useState<number>(0);

  const [receivementData, setReceivementData] = useState<ReceivementData[]>([]);

  const [receivementItemData, setReceivementItemData] = useState<
    ReceivementItemData[]
  >([]);

  const [receivement, setReceivement] = useState({} as ReceivementData);

  const [statusList, setStatusList] = useState<SelectItem[]>([]);
  const [typeList, setTypeList] = useState<SelectItem[]>([]);

  const [animate, setAnimate] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const [cardsSubmit, setCardsSubmit] = useState<any>([]);

  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');

  const clientId = getCookie('@pdamodules::codigoCliente');
  const userId = getCookie('@pdamodules::id');

  const [requestCode, setRequestCode] = useState<number>(0);

  const pendingFilter = (receive: ReceivementData[], codigoStatus?: number) => {
    switch (codigoStatus) {
      case 1: {
        const resultsReceive = receive.filter(item => {
          return item.status === 'Finalizado';
        });
        return resultsReceive.length;
      }
      case 2: {
        const resultsReceive = receive.filter(item => {
          return item.status === 'Finalizado divergente';
        });
        return resultsReceive.length;
      }
      case 3: {
        const resultsReceive = receive.filter(item => {
          return item.status === 'Em andamento';
        });
        return resultsReceive.length;
      }
      case 4: {
        const resultsReceive = receive.filter(item => {
          return item.status === 'Divergente';
        });
        return resultsReceive.length;
      }
      case 5: {
        const resultsReceive = receive.filter(item => {
          return item.status === 'Pendente';
        });
        return resultsReceive.length;
      }
      default:
        return receive.length;
    }
  };

  const handleProgressCard = (receive: ReceivementData[]) => {
    const quantity = receive.map((item: any) => {
      return Number(item.quantidade);
    });
    const totalQuantitySum = quantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const receivedQuantity = receive.map((item: any) => {
      return Number(item.quantidadeRecebimento);
    });
    const totalReceivedQuantitySum = receivedQuantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const progress = (totalReceivedQuantitySum / totalQuantitySum) * 100;
    const finalProgress = Math.floor(progress);
    return setCardProgress(finalProgress);
  };

  const handleSearchFilterPopup = useCallback(
    (data: IDetailsSearchPopupData) => {
      const { box, date, pallet, Produto } = data;

      getSearchItensData({
        Produto,
        caixa: box,
        palete: pallet,
        dataFim: date,
        codigoCliente: Number(clientId),
        User: Number(userId),
        codigoPedido: String(requestCode),
      }).then(response => {
        setReceivementItemData(response.data);
      });
    },
    [clientId, requestCode, userId]
  );

  const checkFields = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('pedido') !== '' ||
      searchRef.current?.getFieldValue('notaFiscal') !== ''
    ) {
      return false;
    }
    return true;
  }, []);

  const daysBetween = () => {
    const startDate = searchRef.current?.getFieldValue('dataInicio');
    const endDate = searchRef.current?.getFieldValue('dataFinal');
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / millisecondsPerDay
    );
  };

  const handleSearch = useCallback(
    async (data, status?, dataInicio?: string, dataFinal?: string) => {
      try {
        setShowTable(false);
        setIsLoading(true);
        checkFields();

        const isRequired = checkFields();
        const checkDate = daysBetween();

        if ((isRequired && checkDate > 15) || (isRequired && checkDate < -15)) {
          throw new Yup.ValidationError([], 'ValidationError', 'dataInicio');
        }

        const schema = Yup.object()
          .shape({
            tipoPedido: Yup.string(),
            pedido: Yup.string(),
            notaFiscal: Yup.string(),
            status: Yup.string(),
            serie: Yup.string(),
            produto: Yup.string(),
            fornecedor: Yup.string(),
            dataInicio:
              isRequired === true ? Yup.string().required() : Yup.string(),
            dataFinal:
              isRequired === true ? Yup.string().required() : Yup.string(),
          })
          .nullable();

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataFilter: SearchDataProps = {
          CodigoCliente: Number(clientId),
          User: Number(userId),
          codigoPedido: data.pedido || null,
          notaFiscal: data.notaFiscal || null,
          fornecedor: data.fornecedor || null,
          tipoPedido: data.tipoPedido || null,
          codigoStatus: data.status || status || null,
          produto: data.produto || null,
          serie: data.serie || null,
          dataInicio: data.dataInicio || dataInicio || null,
          dataFim: data.dataFinal || dataFinal || null,
        };
        setCardsSubmit(dataFilter);
        getSearchData(dataFilter)
          .then(response => {
            if (response.data.length <= 0) {
              enqueueSnackbar(`Resultados não encontrados`, {
                variant: 'warning',
              });
            } else {
              enqueueSnackbar(
                `${response.data.length} resultados encontrados`,
                {
                  variant: 'success',
                }
              );
              setIsLoading(false);
              setShowTable(true);
              setReceivementData(response.data);
              handleProgressCard(response.data);
            }
          })
          .finally(() => {
            const timer = setTimeout(() => {
              setIsLoading(false);
              return () => {
                clearTimeout(timer);
              };
            }, 1000);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar(
            `Por favor, insira datas com uma diferença de no máximo 15 dias ou filtre pelo pedido e nota fiscal`,
            {
              variant: 'warning',
            }
          );
        } else {
          console.error(err);
        }
      }
    },
    [clientId, userId, enqueueSnackbar, checkFields]
  );

  const handleSearchCard = useCallback(
    (statusCode: string) => {
      return searchRef.current?.getFieldValue('notaFiscal') !== '' ||
        searchRef.current?.getFieldValue('pedido') !== ''
        ? searchRef.current?.submitForm()
        : handleSearch(
            [],
            statusCode,
            cardsSubmit.dataInicio,
            cardsSubmit.dataFim
          );
    },
    [cardsSubmit.dataFim, cardsSubmit.dataInicio, handleSearch]
  );

  const setFields = useCallback(() => {
    searchRef.current?.setFieldValue('dataInicio', dateDay);
    searchRef.current?.setFieldValue('dataFinal', dateDay);
    searchRef.current?.setFieldValue('status', {
      codigo: 1,
      value: '1',
      label: 'Pendente',
      descricao: 'Pendente',
    });
    handleSearch([], 1, dateDay, dateDay);
  }, [dateDay, handleSearch]);

  const handleDetail = async (rowOrder: ReceivementData) => {
    setModalLoading(true);
    setOpen(true);
    setRequestCode(rowOrder.codigoPedido);
    const dataFilter = {
      codigoCliente: Number(clientId),
      User: Number(userId),
      codigoPedido: String(rowOrder.codigoPedido),
    };

    await getDetailData(dataFilter).then((itemResponse: AxiosResponse) => {
      setOrderCode(String(rowOrder.codigoPedido));

      if (itemResponse.data.length === 0) {
        enqueueSnackbar('Resultados não encontrados', {
          variant: 'warning',
        });
      } else {
        setReceivement(rowOrder);
        setReceivementItemData(itemResponse.data);
      }
    });

    setModalLoading(false);
  };

  const finishDivergent = async (code: string) => {
    const data = {
      codigoCliente: Number(clientId),
      codigoPedido: code,
    };
    updateOrderStatus({ tokenData, data });

    setIsConfirmed(true);
  };

  const resetConfirm = useCallback(async () => {
    setOpenDivergentModal(false);
    setIsConfirmed(false);
    setOpen(false);
    handleSearch([]);
  }, [handleSearch]);

  const getStatusOptions = useCallback(async () => {
    setIsLoading(true);
    getStatusOptionsData(clientId).then((response: any) => {
      const statusOptions = response[0];
      const orderType = response[1];

      const formatStatusList = transformToSelect(statusOptions.data);
      setStatusList(formatStatusList);

      const orderTypeOptions = transformToSelect(orderType.data);
      setTypeList(orderTypeOptions);

      setIsLoading(false);
    });
  }, [clientId]);

  useEffect(() => {
    setAnimate(true);
    getStatusOptions();
    setFields();
  }, [getStatusOptions, setFields]);

  return (
    <>
      <FixedHeader subTitle="Acompanhamento" title="Recebimento" />
      <ConfirmationModal
        confirm={() => finishDivergent(orderCode)}
        resetConfirm={resetConfirm}
        isConfirmed={isConfirmed}
        handleCloseModal={() => setOpenDivergentModal(false)}
        openModal={openDivergentModal}
        warningTitle="Você tem certeza que deseja finalizar com divergência?"
        doneTitle="Pedido finalizado com divergência"
      />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSearch || handleSearch}
              searchBoxRef={searchRef}
              inputs={[
                {
                  name: 'tipoPedido',
                  type: 'select',
                  label: 'Tipo pedido',
                  placeholder: 'Tipo pedido',
                  options: typeList,
                },
                {
                  name: 'pedido',
                  type: 'text',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                },
                {
                  name: 'notaFiscal',
                  type: 'text',
                  label: 'Nota Fiscal',
                  placeholder: 'Nota Fiscal',
                },
                {
                  name: 'status',
                  type: 'select',
                  options: statusList,
                  label: 'Status',
                  placeholder: 'Status',
                },
              ]}
              hiddenInputs={[
                {
                  name: 'serie',
                  type: 'text',
                  label: 'Serie',
                  placeholder: 'Serie',
                },
                {
                  name: 'produto',
                  type: 'text',
                  label: 'Produto',
                  placeholder: 'Produto',
                },
                {
                  name: 'fornecedor',
                  type: 'text',
                  label: 'Fornecedor',
                  placeholder: 'Fornecedor',
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  isRequired: checkFields(),
                },
                {
                  name: 'dataFinal',
                  label: 'Data Final',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <>
            <TableFilterCardContainer cardProgressValue={cardProgress}>
              <div>
                {pendingFilter(receivementData).toString() !== '0' && (
                  <button
                    type="button"
                    onClick={() => searchRef.current?.submitForm()}
                  >
                    <DetailsItem
                      card
                      title="Resultados"
                      value={pendingFilter(receivementData).toString()}
                    />
                  </button>
                )}
                {pendingFilter(receivementData, 1).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('4')}>
                    <DetailsItem
                      card
                      title="Finalizado"
                      value={pendingFilter(receivementData, 1).toString()}
                      id="done"
                    />
                  </button>
                )}
                {pendingFilter(receivementData, 2).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('5')}>
                    <DetailsItem
                      card
                      title="Finalizado Divergente"
                      value={pendingFilter(receivementData, 2).toString()}
                      id="divergent"
                    />
                  </button>
                )}
                {pendingFilter(receivementData, 3).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('2')}>
                    <DetailsItem
                      card
                      title="Em andamento"
                      value={pendingFilter(receivementData, 3).toString()}
                      id="ongoing"
                    />
                  </button>
                )}
                {pendingFilter(receivementData, 4).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('3')}>
                    <DetailsItem
                      card
                      title="Divergente"
                      value={pendingFilter(receivementData, 4).toString()}
                      id="canceled"
                    />
                  </button>
                )}
                {pendingFilter(receivementData, 5).toString() !== '0' && (
                  <button type="button" onClick={() => handleSearchCard('1')}>
                    <DetailsItem
                      card
                      title="Pendente"
                      value={pendingFilter(receivementData, 5).toString()}
                      id="pending"
                    />
                  </button>
                )}
              </div>
              <div>
                <DetailsItem
                  card
                  title="Progresso total"
                  value={`${cardProgress.toString()}%`}
                  id="totalProgress"
                >
                  {' '}
                  <span />
                </DetailsItem>
              </div>
            </TableFilterCardContainer>
            <ReceivementTable
              receivement={receivementData}
              handleDetail={handleDetail}
              handleSearch={handleSearch}
            />
          </>
        )}
        <PageMode
          isModal
          modalLoading={modalLoading}
          disableSubmit
          open={open}
          handleClose={() => setOpen(!open)}
          title="Detalhes"
          newButton={receivement.status === 'Divergente'}
          newButtonActions={[
            {
              onClick: () => {},
              renderItem: () => <p>Gerar Conferência</p>,
            },
            {
              onClick: () => {
                setOpenDivergentModal(true);
              },
              renderItem: () => <p>Finalizar com Divergência</p>,
            },
          ]}
        >
          <ShowDetails
            detailReceivement={receivement}
            detailReceivementItem={receivementItemData}
            hendleSearchDetail={(data: IDetailsSearchPopupData) => {
              handleSearchFilterPopup(data);
            }}
          />
        </PageMode>
      </Container>
    </>
  );
};

export default Receivement;

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from 'react';
import Jspdf from 'jspdf';
import { Grid, Fade, MenuItem } from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';

import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { Table, SearchBox, HyperLink, TagStatus } from '@pdasolucoes/web';
import { useTheme } from 'styled-components';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import { Container, Search, TableContainer } from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { SelectItem, transformToSelect } from '../../../../../utils/toSelect';
import {
  ConferenceDataOrderDetail,
  ConferenceDataTable,
} from '../../../../../utils/interfaces/Conference';
import Modal from '../../../../../components/Tools/_Modal';
import { useAuth } from '../../../../../hooks/Auth';

const ConferenceFollow: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [conference, setConference] = React.useState(
    [] as ConferenceDataTable[]
  );
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(Boolean);
  const [codeOrder, setCodeOrder] = React.useState(NaN);
  const inputsBox = React.useRef<FormHandles>(null);
  const { codeStatus } = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  // selects
  const [transpList, setTranspList] = React.useState({} as SelectItem[]);
  const [statusList, setStatusList] = React.useState({} as SelectItem[]);
  const [productModal, setProductModal] = React.useState(false);
  const [detailProduct, setDetailProduct] = React.useState(
    [] as ConferenceDataOrderDetail[]
  );
  const { colors } = useTheme();

  const { token } = useAuth();
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  const idUser = sessionStorage.getItem('@pdamodules::id');
  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');
  const getStatus = React.useCallback(async () => {
    if (Number(codeStatus)) {
      try {
        const itensFiltered: SelectItem[] = Object.values(statusList).filter(
          itensSta => itensSta.value === codeStatus
        );
        const filtered = itensFiltered[0];
        inputsBox.current?.setFieldValue('status', { ...filtered });

        const dataFilter = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          CodigoStatus: codeStatus,
        };
        setConference([]);
        await api
          .patch<ConferenceDataTable>(
            `Conferencia/Acompanhamento`,
            {
              ...dataFilter,
            },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((conferenceData: AxiosResponse) => {
            if (conferenceData.data.length <= 0) {
              setConference([]);
            } else {
              setConference([...conferenceData.data]);
              const timer = setTimeout(() => {
                setLoading(true);
                setVisible(false);
                return () => {
                  clearTimeout(timer);
                };
              }, 1000);
            }
          })
          .catch((pickingDataError: AxiosError<any>) => {
            if (pickingDataError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              pickingDataError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    } else {
      // history.push('/wms/picking/');
      console.log('');
    }
  }, [codeStatus, statusList, tokenData, idCliente, idUser, enqueueSnackbar]);

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setLoading(true);
        setVisible(false);
        const dataFilter = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          CodigoOnda: data.onda || null,
          CodigoPedido: data.pedido || null,
          Cliente: data.cliente || null,
          CodigoTransportadora: data.transportadora || null,
          CodigoStatus: data.status || null,
          DataInicioPicking: data.dataInicio
            ? new Date(data.dataInicio).toISOString()
            : null,
          DataFimPicking: data.dataFim
            ? new Date(data.dataFim).toISOString()
            : null,
        };

        await api
          .patch<ConferenceDataTable>(
            `Conferencia/Acompanhamento`,
            {
              ...dataFilter,
            },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((dataSubmit: AxiosResponse) => {
            if (dataSubmit.data.length === 0) {
              enqueueSnackbar(`Não existe registro!`, { variant: 'error' });
              setConference([]);
            } else if (dataSubmit.data.length > 0) {
              enqueueSnackbar(
                `Foi encontrado ${dataSubmit.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              setConference([...dataSubmit.data]);
            }
            setLoading(true);
            setVisible(false);
          })
          .catch((dataSubmitError: AxiosError<any>) => {
            if (dataSubmitError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              dataSubmitError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    [enqueueSnackbar, tokenData, idCliente, idUser]
  );
  const getDetail = React.useCallback(
    async (code: number, onda: number) => {
      setCodeOrder(code);

      const dataDetail = {
        codigoCliente: Number(idCliente),
        codigoPedido: code,
        CodigoOnda: onda.toString(),
      };
      setProductModal(true);
      try {
        await api
          .get(`Conferencia/Acompanhamento/Detalhe`, {
            headers: { Authorization: `Bearer ${tokenData}` },
            params: dataDetail,
          })
          .then((orderDetail: AxiosResponse) => {
            if (orderDetail.data.length > 0) {
              setDetailProduct([...orderDetail.data]);

              setProductModal(true);
              enqueueSnackbar(
                `${
                  orderDetail.data.length > 1
                    ? 'Foram encontrados'
                    : 'Foi encontrado'
                } ${orderDetail.data.length} Produto(s)!`,
                {
                  variant: 'success',
                }
              );
            } else if (orderDetail.data.length === 0) {
              setDetailProduct([]);
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
            }
          })
          .catch((orderDetailError: AxiosError<any>) => {
            if (orderDetailError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              orderDetailError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    },
    [enqueueSnackbar, tokenData, idCliente]
  );
  const getParam = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await api
        .get(`Common/Transportadora?codigoCliente=${Number(idCliente)}`, {
          headers: { Authorization: `Bearer ${tokenData}` },
        })
        .then((transportData: AxiosResponse) => {
          const listTransport = transformToSelect(transportData.data);
          setTranspList([...listTransport]);
        })
        .catch((transportDataError: AxiosError<any>) => {
          if (transportDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            transportDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
      await api
        .get(`Common/StatusPicking?codigoCliente=${Number(idCliente)}`, {
          headers: { Authorization: `Bearer ${tokenData}` },
        })
        .then((statusData: AxiosResponse) => {
          const listStatus = transformToSelect(statusData.data);
          setStatusList([...listStatus]);
        })
        .catch((statusDataError: AxiosError<any>) => {
          if (statusDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            statusDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [tokenData, idCliente, enqueueSnackbar]);
  const exportPDF = React.useCallback(async () => {
    const doc = new Jspdf('p', 'pt', 'a4');
    doc.html(document.querySelector('#screenModel') as HTMLElement, {
      callback(pdf) {
        pdf.save('document.pdf');
      },
    });
  }, []);

  React.useEffect(() => {
    getParam();
  }, [getParam]);

  React.useEffect(() => {
    setAnimate(true);
    getStatus();
  }, [getStatus]);

  return (
    <>
      <Modal
        type="detail"
        title={`Pedido - ${codeOrder}`}
        open={productModal}
        handleCancel={() => setProductModal(false)}
        isTable
        tableContent={() => (
          <Grid container style={{ display: 'block' }}>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <Table<ConferenceDataOrderDetail>
                exportList
                columns={[
                  {
                    title: 'Produto',
                    type: 'string',
                    props: ['produto'],
                    cssTitle: {
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '1%',
                      paddingRight: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                  {
                    title: 'Descrição do Produto',
                    type: 'string',
                    props: ['descricaoProduto'],
                    cssTitle: {
                      display: 'flex',
                      justifyContent: 'left',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '1%',
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                  {
                    title: 'Qtde. Pedido',
                    type: 'number',
                    props: ['quantidadePedido'],
                    cssTitle: {
                      display: 'flex',
                      justifyContent: 'left',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '1%',
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                  {
                    title: 'Qtde. Picking',
                    type: 'number',
                    props: ['quantidadePicking'],
                    cssTitle: {
                      display: 'flex',
                      justifyContent: 'left',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '1%',
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                  {
                    title: 'Qtde. Conferência',
                    type: 'number',
                    props: ['quantidadeConferencia'],
                    cssTitle: {
                      display: 'flex',
                      justifyContent: 'left',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '1%',
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                ]}
                rows={[
                  ...detailProduct.map(item => ({
                    ...item,
                  })),
                ]}
              />
            </Fade>
          </Grid>
        )}
      />
      <FixedHeader title="Conferência" subTitle="Acompanhamento" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              searchBoxRef={inputsBox}
              handleSubmit={handleSubmit}
              advancedSearch
              hiddenInputs={[
                {
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                  name: 'transportadora',
                  type: 'select',
                  options: transpList,
                  isLoading,
                  isDisabled: isLoading,
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              inputs={[
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'onda',
                  type: 'text',
                  label: 'Onda',
                  placeholder: 'Onda',
                },
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'pedido',
                  type: 'text',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                },
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'cliente',
                  type: 'text',
                  label: 'Cliente',
                  placeholder: 'Cliente',
                },
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'status',
                  type: 'select',
                  options: statusList,
                  isLoading,
                  isDisabled: isLoading,
                  label: 'Status',
                  placeholder: 'Status',
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}

        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContainer>
                    <Table<ConferenceDataTable>
                      exportList
                      exportOptions={() => (
                        <MenuItem onClick={exportPDF}>PDF</MenuItem>
                      )}
                      columns={[
                        {
                          title: 'Onda',
                          type: 'string',
                          orderable: true,
                          props: ['codigoOnda'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Prioridade',
                          type: 'string',
                          orderable: true,
                          props: ['prioridade'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Pedido',
                          type: 'string',
                          orderable: true,
                          props: ['codigoPedido'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: link => {
                            return (
                              // eslint-disable-next-line react/jsx-curly-newline
                              <HyperLink
                                type="detail"
                                style={{
                                  paddingLeft: '16px',
                                  fontSize: '13px',
                                }}
                                title={String(link.codigoPedido)}
                                onClick={() =>
                                  // eslint-disable-next-line prettier/prettier
                                  getDetail(link.codigoPedido, link.codigoOnda)
                                }
                              />
                            );
                          },
                        },
                        {
                          title: 'Cliente',
                          type: 'string',
                          props: ['cliente'],
                          orderable: true,
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Qtde. Peças',
                          type: 'number',
                          orderable: true,
                          props: ['quantidadePedido'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Qtde. Picking',
                          type: 'string',
                          orderable: true,
                          props: ['quantidadePicking'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Data Pedido',
                          type: 'string',
                          orderable: true,
                          props: ['dataPedido'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                          renderItem: row => {
                            return (
                              <>
                                {row.dataPedido ? (
                                  <p className="render-string">
                                    {new Date(row.dataPedido).toLocaleString()}
                                  </p>
                                ) : (
                                  <p className="render-string">-</p>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: 'Planejado',
                          type: 'string',
                          display: 'notEmpty',
                          orderable: true,
                          props: ['dataPedido'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          // renderItem: row => {
                          //   return (
                          //     <>
                          //       {row.dataPlanejamento ? (
                          //         <p className="render-string">
                          //           {new Date(
                          //             row.dataPlanejamento
                          //           ).toLocaleString()}
                          //         </p>
                          //       ) : (
                          //         <p className="render-string">-</p>
                          //       )}
                          //     </>
                          //   );
                          // },
                        },
                        {
                          title: 'Início Coleta',
                          type: 'string',
                          orderable: true,
                          props: ['dataInicioConferencia'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          // renderItem: row => {
                          //   return (
                          //     <>
                          //       {row.dataInicioPicking ? (
                          //         <p className="render-string">
                          //           {new Date(
                          //             row.dataInicioPicking
                          //           ).toLocaleString()}
                          //         </p>
                          //       ) : (
                          //         <p className="render-string">-</p>
                          //       )}
                          //     </>
                          //   );
                          // },
                        },
                        {
                          title: 'Fim Coleta',
                          type: 'string',
                          orderable: true,
                          props: ['dataFimPicking'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: row => {
                            return (
                              <>
                                {row.dataFimPicking ? (
                                  <p className="render-string">
                                    {new Date(
                                      row.dataFimPicking
                                    ).toLocaleString()}
                                  </p>
                                ) : (
                                  <p className="render-string">-</p>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: 'Usuário',
                          type: 'string',
                          display: 'notEmpty',
                          orderable: true,
                          props: ['usuarioConference'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Status',
                          type: 'string',
                          orderable: true,
                          props: ['descricaoStatus'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '3%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: row => {
                            return (
                              <TagStatus
                                status={row.descricaoStatus}
                                color={
                                  colors.tagTextColors.filter(
                                    tag => tag.name === row.descricaoStatus
                                  ).length > 0
                                    ? colors.tagTextColors.filter(
                                        tag => tag.name === row.descricaoStatus
                                      )[0].value
                                    : undefined
                                }
                                background={
                                  colors.tagBackgroundColors.filter(
                                    tag => tag.name === row.descricaoStatus
                                  ).length > 0
                                    ? colors.tagBackgroundColors.filter(
                                        tag => tag.name === row.descricaoStatus
                                      )[0].value
                                    : undefined
                                }
                              />
                            );
                          },
                        },
                        {
                          title: 'Nota',
                          type: 'string',
                          orderable: true,
                          props: ['notaFiscal'],
                          cssTitle: {
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                      ]}
                      rows={[
                        ...conference.map(item => ({
                          ...item,
                        })),
                      ]}
                    />
                  </TableContainer>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};
export default ConferenceFollow;

import React from 'react';

import Logo from 'assets/images/logo.png';
import { QueryData } from 'utils/interfaces/Expedition';

interface IPdfProps {
  data: QueryData[];
}

const Pdf: React.FC<IPdfProps> = ({ data }) => (
  <div id="page-report">
    <div id="content-report-geral">
      <div className="content-report">
        <div className="headReport">
          <img src={Logo} alt="PDA" />
          <div className="titleReport">
            <h1 style={{ marginRight: '5px' }}>Relatorio</h1>
            <h1>de</h1>
            <h1>Expedição</h1>
          </div>
          <div className="printDate">
            <p>
              <strong>Data impressão</strong>
            </p>
            <p>{new Date().toLocaleDateString()}</p>
          </div>
        </div>
        <div className="volume">
          <p>
            <strong>Volumes:</strong>
          </p>
          <p>4</p>
        </div>
        <table id="table-teste" className="table-box">
          <thead>
            <tr>
              <th>Transportadora</th>
              <th>Pedido</th>
              <th>Cliente</th>
              <th>Nota fiscal</th>
              <th>Expedição</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.codigoExpedicao} id={String(item.codigoExpedicao)}>
                <td>{item.transportadora}</td>
                <td>{item.codigoPedido}</td>
                <td>{item.cliente}</td>
                <td>{item.notaFiscal}</td>
                <td>{item.codigoExpedicao}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="content-report-footer">
          <div className="content-report-footer-item">
            <p>Ass. Transportadora</p>
          </div>
          <div className="content-report-footer-item">
            <p>Ass. e-Commerce</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Pdf;

import api from 'services/api';
import { EanColeta } from 'utils/interfaces/Picking';
import { SelectItem } from 'utils/toSelect';
import {
  ConferenceData,
  DataEstornoProps,
  FilterDataProps,
  PutConferenceDataProps,
  SearchDataProps,
  PickingData,
  PutDataProps,
} from '../types';

export const getEnabledBoxType = (idCliente?: string) =>
  api.get<string>(
    `Common/ParametroGenerico?codigoCliente=${Number(
      idCliente
    )}&Chave=FILTRO_CAIXA`
  );

export const getBoxType = (idCliente?: string) =>
  api.get<SelectItem>(`Common/TipoCaixa?codigoCliente=${Number(idCliente)}`);

export const cancelConference = (dataEstorno: DataEstornoProps) =>
  api.put(`Picking/Conferencia`, { ...dataEstorno });

export const finishConference = (putData: PutDataProps) =>
  api.put<EanColeta>(`Picking/Conferencia/FinalizarParcial`, { ...putData });

export const getConferenceData = (filterData: FilterDataProps) =>
  api.get<ConferenceData>(
    `Picking/Conferencia?codigoCliente=${filterData.codigoCliente}&codigoPedido=${filterData.codigoPedido}`
  );

export const validateBox = (filterData: FilterDataProps) =>
  api.get(
    `Common/ConsultaCaixa?codigoCliente=${filterData.codigoCliente}&caixa=${filterData.embalagem}&codigoPedido=${filterData.codigoPedido}`
  );

export const validateBarCode = (filterData: FilterDataProps) =>
  api.get<EanColeta>(
    `Picking/Conferencia/ValidaEan?codigoCliente=${filterData.codigoCliente}&codigoPedido=${filterData.codigoPedido}&ean=${filterData.ean}`
  );

export const validateAmount = (putData: PutConferenceDataProps) =>
  api.put<EanColeta>(`Picking/Conferencia/Coleta`, { ...putData });

export const hendleDetailData = (dataFilter: PutDataProps) =>
  api.patch<PickingData>(`Picking/Coferencia/acompanhamento`, {
    ...dataFilter,
  });

import React, { useCallback, useState, memo } from 'react';
import { useSnackbar } from 'notistack';

import uploadLgImg from 'assets/svg/uploadLgImg.svg';
import upload_cadastro_icon from 'assets/svg/upload_cadastro_icon.svg';
import PageMode from '../PageMode';
import { IFileUploadProps } from './types';
import { mapFiles } from './utils';
import * as C from './styles';

const FileUpload: React.FC<IFileUploadProps> = ({
  isOpen,
  rota,
  cancelFunction,
  handleSubmit = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();

  const saveImage = React.useCallback(async e => {
    console.log('teste');
    const fileDate = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      const arrayBuffer = fileDate;
      const array = new Uint8Array(arrayBuffer);
      // const binaryString = String.fromCharCode.apply(null, array);
      console.log(array);
    };
    setFile(fileDate);
  }, []);

  /* const handleSubmit = useCallback(async () => {
    if (!files) {
      enqueueSnackbar('Selecione 1 ou mais arquivos', {
        variant: 'error',
      });

      return;
    }

    console.log(files);
  }, [files, enqueueSnackbar]); */

  return (
    <PageMode
      isModal
      open={isOpen}
      submitButtonIcon
      submitButtonTitle="Salvar"
      title="Upload de Arquivo"
      handleSubmit={() => handleSubmit(file)}
      handleClose={cancelFunction}
    >
      <C.CenterIt>
        <C.ContentWrapper>
          <C.FileUploadTitle>
            Escolha o arquivo para fazer upload.
          </C.FileUploadTitle>
          <C.FileUploadImage
            src={uploadLgImg}
            alt="Upload Image"
            loading="eager"
          />
          <input
            type="file"
            multiple
            id="inputFiles"
            style={{ display: 'none' }}
            onChange={saveImage}
          />
          {/* 'accept' -> define o tipo de arquivo que pode ser selecionado. Nesse caso excel */}
          <C.FileUploadButton htmlFor="inputFiles">
            <C.FileUploadImage
              src={upload_cadastro_icon}
              alt="Button icon"
              loading="eager"
            />
            Subir Arquivo
          </C.FileUploadButton>
        </C.ContentWrapper>
      </C.CenterIt>
    </PageMode>
  );
};

export default memo(FileUpload);

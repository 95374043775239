/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React, { useCallback } from 'react';
import Jspdf from 'jspdf';
import {
  Grid,
  Fade,
  Button,
  IconButton,
  Divider,
  MenuItem,
} from '@material-ui/core';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Select from 'react-select';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { Table, SearchBox, HyperLink } from '@pdasolucoes/web';
import { useTheme } from 'styled-components';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import { Container, Search, TableContent } from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { SelectItem, transformToSelect } from '../../../../../utils/toSelect';
import {
  InvoicingProps,
  InvoicingDetailProps,
  InvoicingTableDetail,
} from '../../../../../utils/interfaces/Picking';
import { useAuth } from '../../../../../hooks/Auth';
import Logo from '../../../../../assets/images/logo.png';
import OC from '../../../../../assets/images/HidroL.jpg';
import { formatDate } from '../../../../../utils/formatDate';

interface Cods {
  codigoCliente: number;
  codigoPedido: string;
}

// const api = axios.create({
//   baseURL: 'http://189.113.15.118:4253/api/',
// });
const Invoicing: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [invoicing, setInvoicing] = React.useState([] as InvoicingProps[]);
  const [details, setDetails] = React.useState({} as InvoicingDetailProps);
  const [dateDetails, setDateDetails] = React.useState<string | number>();
  const [invoicingDetail, setInvoicingDetail] = React.useState(
    [] as InvoicingTableDetail[]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const printDate = new Date();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const { colors } = useTheme();
  const [animate, setAnimate] = React.useState(false);
  const [animateDetail, setAnimateDetail] = React.useState(false);
  const [animateInvoicing, setAnimateInvoicing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(Boolean);
  const inputsBox = React.useRef<FormHandles>(null);
  // selects
  const [transpList, setTranspList] = React.useState({} as SelectItem[]);
  const [selectedCarrier, setSelectedCarrier] = React.useState({
    value: '',
    label: '',
  } as unknown as SelectItem);
  const [detail, setDetail] = React.useState(false);
  const { token } = useAuth();
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  // const idCliente = 6;
  const idUser = sessionStorage.getItem('@pdamodules::id');
  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        // setLoading(false);
        // setVisible(true);
        setAnimateInvoicing(true);
        const dataFilter = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          CodigoPedido: data.pedido || null,
          Cliente: data.cliente || null,
          CodigoTransportadora: data.transportadora || null,
          DataInicio: data.dataInicio
            ? new Date(data.dataInicio).toISOString()
            : null,
          DataFim: data.dataFim ? new Date(data.dataFim).toISOString() : null,
        };

        await api
          .patch<InvoicingProps>(
            `Faturamento`,
            {
              ...dataFilter,
            },
            { headers: { Authorization: `Bearer ${tokenData}` } }
          )
          .then((InvoicingData: AxiosResponse) => {
            if (InvoicingData.data.length === 0) {
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
              setInvoicing([]);
            } else if (InvoicingData.data.length > 0) {
              enqueueSnackbar(
                `${
                  InvoicingData.data.length > 1 ? 'Foram' : 'Foi'
                } encontrado ${InvoicingData.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              setInvoicing([...InvoicingData.data]);
            }
          })
          .catch((waveDataError: AxiosError<any>) => {
            if (waveDataError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              waveDataError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [idCliente, idUser, enqueueSnackbar, tokenData]
  );
  const handleReload = useCallback(async () => {
    try {
      // setLoading(false);
      // setVisible(true);
      setAnimateInvoicing(true);
      const dataFilter = {
        CodigoCliente: Number(idCliente),
        User: Number(idUser),
      };

      await api
        .patch<InvoicingProps>(
          `Faturamento`,
          {
            ...dataFilter,
          },
          { headers: { Authorization: `Bearer ${tokenData}` } }
        )
        .then((InvoicingData: AxiosResponse) => {
          if (InvoicingData.data.length === 0) {
            enqueueSnackbar('Nenhum registro foi encontrado.', {
              variant: 'error',
            });
            setInvoicing([]);
          } else if (InvoicingData.data.length > 0) {
            enqueueSnackbar(
              `${InvoicingData.data.length > 1 ? 'Foram' : 'Foi'} encontrado ${
                InvoicingData.data.length
              } registro(s)!`,
              {
                variant: 'success',
              }
            );
            setInvoicing([...InvoicingData.data]);
          }
        })
        .catch((waveDataError: AxiosError<any>) => {
          if (waveDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            waveDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      const timer = setTimeout(() => {
        setLoading(true);
        setVisible(false);
        return () => {
          clearTimeout(timer);
        };
      }, 1000);
    }
  }, [idCliente, idUser, enqueueSnackbar, tokenData]);
  const getParam = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await api
        .get(`Common/Transportadora?codigoCliente=${Number(6)}`, {
          headers: { Authorization: `Bearer ${tokenData}` },
        })
        .then((transportData: AxiosResponse) => {
          const listTransport = transformToSelect(transportData.data);
          setTranspList([...listTransport]);
        })
        .catch((transportDataError: AxiosError<any>) => {
          if (transportDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            transportDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [tokenData, enqueueSnackbar]);

  const exportPDF = React.useCallback(async () => {
    const doc = new Jspdf('p', 'pt', 'a4');
    // @ts-ignore
    doc.addImage(OC, 'png', 30, 20, 150, 50);
    doc.setFontSize(16);
    doc.text('Relatório de Faturamento', 220, 50, { charSpace: 1 });
    doc.addImage(Logo, 'png', 440, 30, 130, 30);
    doc.setFontSize(10);
    doc.text(`${printDate.toLocaleDateString()}`, 235, 75, { charSpace: 1 });
    doc.text(`- Pedidos: ${invoicing.length}`, 300, 75, { charSpace: 1 });
    // @ts-ignore
    doc.autoTable({
      html: '#table-teste',
      startY: 100,
      showHead: 'firstPage',
      pageBreak: 'auto',
      didDrawCell: (data: any) => {
        if (
          data.row.cells[0] ===
            data.table.body[data.table.body.length - 1].cells[0] &&
          data.column.index === data.table.columns.length - 1
        ) {
          doc.addPage();
          doc.addImage(OC, 'png', 30, 20, 150, 50);
          doc.setFontSize(16);
          doc.text('Relatório de Faturamento', 220, 50, { charSpace: 1 });
          doc.addImage(Logo, 'png', 440, 30, 130, 30);
          doc.setFontSize(10);
          doc.text(`${printDate.toLocaleDateString()}`, 235, 75, {
            charSpace: 1,
          });
          doc.setFontSize(10);
          doc.text(`- Pedidos: ${invoicing.length}`, 300, 75, {
            charSpace: 1,
          });
          doc.text(
            `Página: ${doc.getNumberOfPages()}`,
            doc.internal.pageSize.width - 70,
            doc.internal.pageSize.height - 25
          );
        }
      },
      didDrawPage: () => {
        doc.text(
          `Página: ${doc.getNumberOfPages()}`,
          doc.internal.pageSize.width - 70,
          doc.internal.pageSize.height - 25
        );
      },

      margin: { horizontal: 20 },
      headStyles: {
        fillColor: '#606060',
      },
      bodyStyles: {
        lineColor: '#262626',
      },
    });

    doc.save('Faturamento.pdf');
  }, [printDate, invoicing.length]);

  const exportPDFDetail = React.useCallback(async () => {
    const doc = new Jspdf('l', 'pt', 'a4');
    // @ts-ignore
    doc.addImage(OC, 'jpg', 30, 20, 130, 50);
    doc.setFontSize(16);
    doc.text('Relatório dos detalhes do pedido', 320, 50, { charSpace: 1 });
    doc.addImage(Logo, 'png', doc.internal.pageSize.width - 170, 30, 130, 30);
    doc.setFontSize(10);
    doc.text(`Pedido: ${details.codigoPedido}`, 50, 95, { charSpace: 1 });
    doc.text(`Data: ${details.dataPedido}`, 230, 95, { charSpace: 1 });
    doc.text(`Total: $${details.valorTotal}`, 385, 95, { charSpace: 1 });
    doc.line(20, 100, doc.internal.pageSize.width - 20, 100, 'FD');
    doc.text(`Cód. Cliente: ${details.codigoClienteErp}`, 50, 120, {
      charSpace: 1,
    });
    doc.text(`Cliente: ${details.cliente}`, 230, 120, { charSpace: 1 });
    doc.text(`CNPJ: ${details.cpfCnpj}`, 385, 120, {
      charSpace: 1,
    });
    doc.text(`End: ${details.endereco}`, 50, 150, {
      charSpace: 1,
    });
    doc.text(`Cep: ${details.cep} `, 230, 150, {
      charSpace: 1,
    });
    doc.text(`Cidade: ${details.cidade}`, 385, 150, {
      charSpace: 1,
    });
    doc.text(`UF: ${details.uf} `, 580, 150, {
      charSpace: 1,
    });
    doc.line(20, 160, doc.internal.pageSize.width - 20, 160, 'FD');
    // @ts-ignore
    doc.autoTable({
      html: '#table-testeDetail',
      startY: 180,
      finally: 580,
      showHead: 'firstPage',
      pageBreak: 'auto',
      didDrawPage: () => {
        doc.text(
          `Página: ${doc.getNumberOfPages()}`,
          doc.internal.pageSize.width - 70,
          doc.internal.pageSize.height - 25
        );
        doc.text(
          `Data de emissão: ${printDate.toLocaleDateString()}`,
          doc.internal.pageSize.width - 440,
          doc.internal.pageSize.height - 25
        );
      },

      margin: { horizontal: 20 },
      headStyles: {
        fillColor: '#606060',
      },
      bodyStyles: {
        lineColor: '#262626',
      },
    });
    doc.text(`Total Volumes: ${details.faturamentoDetalhe?.length}`, 50, 490, {
      charSpace: 1,
    });
    doc.text(`Total Cubagem: ${details.cubagemTotal}`, 230, 490, {
      charSpace: 1,
    });
    doc.text(`Total Peso Liquido: ${details.pesoTotal}`, 480, 490, {
      charSpace: 1,
    });
    doc.line(20, 500, doc.internal.pageSize.width - 20, 500, 'FD');
    //
    doc.save('Detalhe Faturamento.pdf');
  }, [printDate, details]);
  //
  //
  const getDetail = React.useCallback(
    async (
      codigoPedido: string,
      codTransportadora: string,
      transportadora: string
    ) => {
      try {
        setAnimateDetail(true);
        const transportadoraSelecionada = {
          label: transportadora,
          value: codTransportadora,
        };
        if (codTransportadora !== undefined || codTransportadora !== null) {
          setSelectedCarrier(transportadoraSelecionada);
        }
        const dataFilter = {
          codigoCliente: Number(idCliente),
          codigoPedido: codigoPedido || null,
        };
        await api
          .patch<InvoicingDetailProps>(
            `Faturamento/Detalhe`,
            {
              ...dataFilter,
            },
            { headers: { Authorization: `Bearer ${tokenData}` } }
          )
          .then((InvoicingDetailData: AxiosResponse) => {
            if (InvoicingDetailData.data.length === 0) {
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
              setDetails({} as InvoicingDetailProps);
              setInvoicingDetail([]);
            } else if (InvoicingDetailData.data.length > 0) {
              setDetail(true);
              setDetails(InvoicingDetailData.data[0]);
              setInvoicingDetail([
                ...InvoicingDetailData.data[0].faturamentoDetalhe,
              ]);
              const date = formatDate(
                `${InvoicingDetailData.data[0].dataPedido}`
              );
              setDateDetails(date);
              if (InvoicingDetailData.data.faturamentoDetalhe.length > 0) {
                enqueueSnackbar(
                  `${
                    InvoicingDetailData.data.length > 1 ? 'Foram' : 'Foi'
                  } encontrado ${InvoicingDetailData.data.length} registro(s)!`,
                  {
                    variant: 'success',
                  }
                );
              }
            }
          })
          .catch((InvoicingDetailError: AxiosError<any>) => {
            if (InvoicingDetailError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              InvoicingDetailError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [idCliente, tokenData, enqueueSnackbar]
  );

  const handleLiberarFaturamento = React.useCallback(
    (rows: InvoicingProps[]) => {
      const Liberar = async () => {
        const dataFilter = rows.map(item => ({
          codigoCliente: Number(idCliente),
          codigoPedido: item.codigoPedido,
        }));

        await api
          .put(
            `/Faturamento/Liberar`,

            dataFilter,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((LiberarData: AxiosResponse) => {
            if (LiberarData.status === 200) {
              enqueueSnackbar(
                `Pedido ${LiberarData.data.codigoPedido} Liberado !`,
                {
                  variant: 'success',
                }
              );
            }
          })
          .catch((LiberarDataDataError: AxiosError<any>) => {
            if (LiberarDataDataError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              LiberarDataDataError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      };
      Liberar();
    },
    [idCliente, tokenData, enqueueSnackbar]
  );

  const handleChangeShippingCompany = React.useCallback(
    async (shippingCompany: SelectItem) => {
      const { value, label } = shippingCompany;
      const dataFilter = {
        codigoCliente: Number(idCliente),
        codigoPedido: details.codigoPedido,
        codigoTransportadora: Number(value),
      };
      const transportadoraSelecionada = {
        label,
        value,
      };
      setSelectedCarrier(transportadoraSelecionada);
      await api
        .put(
          `/Faturamento`,
          {
            ...dataFilter,
          },
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then((LiberarData: AxiosResponse) => {
          if (LiberarData.status === 200) {
            enqueueSnackbar(`Transportadora alterada com sucesso!`, {
              variant: 'success',
            });
          }
        })
        .catch((LiberarDataDataError: AxiosError<any>) => {
          if (LiberarDataDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            console.log(LiberarDataDataError);
            LiberarDataDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    },
    [enqueueSnackbar, details, tokenData, idCliente]
  );
  React.useEffect(() => {
    getParam();
    setLoading(false);
    setAnimate(true);
  }, [getParam]);

  const handleTraceBack = React.useCallback(() => {
    setDetail(false);
    handleReload();
  }, [handleReload]);
  return (
    <>
      <FixedHeader title="Picking" subTitle="Envio Para Faturamento" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              searchBoxRef={inputsBox}
              handleSubmit={handleSubmit}
              inputs={[
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'pedido',
                  type: 'text',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                },
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'client',
                  type: 'text',
                  label: 'Cliente',
                  placeholder: 'Cliente',
                },
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'dataInicio',
                  type: 'date',
                  label: 'Data Inicial',
                  placeholder: 'Data Inicial',
                },
                {
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                  name: 'dataFim',
                  type: 'date',
                  label: 'Data Final',
                  placeholder: 'Data Final',
                },
              ]}
            />
          </Search>
        </Fade>
        {detail ? (
          <>
            <div className="wrapper" style={{ marginBottom: '0' }}>
              <div className="contentDetail">
                <Grid container spacing={3} className="detailHeader">
                  <div className="headDetail">
                    <div className="detailTableWrap">
                      <IconButton
                        style={{ padding: '8px' }}
                        onClick={() => handleTraceBack()}
                        aria-label="Voltar"
                      >
                        <KeyboardBackspaceRoundedIcon color="disabled" />
                      </IconButton>
                      <p>
                        Pedido:
                        {details.codigoPedido}
                      </p>
                    </div>
                    {/* <div id="status">
                      <p>Status:</p>
                      <TagStatus
                        status={detail.status}
                        color={
                          colors.tagTextColors.filter(
                            tag => tag.name === detail.status
                          ).length > 0
                            ? colors.tagTextColors.filter(
                                tag => tag.name === detail.status
                              )[0].value
                            : undefined
                        }
                        background={
                          colors.tagBackgroundColors.filter(
                            tag => tag.name === detail.status
                          ).length > 0
                            ? colors.tagBackgroundColors.filter(
                                tag => tag.name === detail.status
                              )[0].value
                            : undefined
                        }
                      />
                    </div> */}
                  </div>
                  <div className="divider" />
                  <div className="details">
                    <p className="titleDetail">
                      Pedido :
                      <span className="primary">{details.codigoPedido}</span>
                    </p>
                    <p className="titleDetail">
                      Data :
                      <span className="primary">
                        {!dateDetails ? '' : dateDetails}
                      </span>
                    </p>
                    <p className="titleDetail">
                      Valor Total :
                      <span className="primary">
                        {!details.valorTotal ? '' : `$${details.valorTotal}`}
                      </span>
                    </p>
                  </div>
                  <div className="details">
                    <p className="titleDetail">
                      Total Cubagem :
                      <span className="primary">{details.cubagemTotal}</span>
                    </p>
                    <p className="titleDetail">
                      Total Volumes :
                      <span className="primary">
                        {!invoicingDetail.length ? '' : invoicingDetail.length}
                      </span>
                    </p>
                    <p className="titleDetail">
                      Total Peso Liquido :
                      <span className="primary">{details.pesoTotal}</span>
                    </p>
                  </div>
                  <div className="divider" />

                  <div className="volume">
                    <p>
                      Cód. Cliente:
                      <span>{details.codigoClienteErp}</span>
                    </p>
                    <p>
                      Cliente:
                      <span>{details.cliente}</span>
                    </p>
                    <p>
                      CNPJ:
                      <span>{details.cpfCnpj}</span>
                    </p>
                    <p>
                      End:
                      <span>{details.endereco}</span>
                    </p>
                    <p>
                      Cidade:
                      <span>{details.cidade}</span>
                    </p>
                    <p>
                      UF:
                      <span>{details.uf}</span>
                    </p>
                    <p>
                      Cep:
                      <span>{details.cep}</span>
                    </p>
                  </div>
                </Grid>
              </div>
            </div>
            <Grid container style={{ display: 'block' }}>
              <Fade in={animateDetail} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ flexGrow: 1 }}>
                    <TableContent>
                      <Table<InvoicingTableDetail>
                        beforeExport={() => (
                          <div className="beforeExport">
                            <p />
                            <Select
                              className="select"
                              required
                              name="nv2"
                              placeholder="Transportadora"
                              options={transpList}
                              value={selectedCarrier}
                              onChange={(currentValue: any) => {
                                handleChangeShippingCompany(currentValue);
                              }}
                            />
                          </div>
                        )}
                        exportList
                        exportOptions={() => (
                          <MenuItem onClick={exportPDFDetail}>PDF</MenuItem>
                        )}
                        columns={[
                          {
                            title: 'Caixa',
                            type: 'string',
                            props: ['caixa'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Tipo de Embalagem',
                            type: 'string',
                            props: ['tipoCaixa'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Quantidade',
                            type: 'string',
                            props: ['quantidade'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Cubagem',
                            type: 'string',
                            formatter: row => row.cubagem,
                            position: 'right',
                            props: ['cubagem'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Peso Liquido',
                            type: 'number',
                            props: ['peso'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                        ]}
                        rows={[
                          ...invoicingDetail.map(item => ({
                            ...item,
                          })),
                        ]}
                      />
                    </TableContent>
                  </div>
                </div>
              </Fade>
              <div id="page-report">
                <div id="content-report-geral">
                  <div className="content-report">
                    <div className="headReport">
                      <img src={Logo} alt="PDA" />
                      <div className="titleReport">
                        <h1 style={{ marginRight: '5px' }}>Relatorio</h1>
                        <h1>de</h1>
                        <h1>Faturamento</h1>
                      </div>
                      <div className="printDate">
                        <p>
                          <strong>Data impressão</strong>
                        </p>
                        <p>{printDate.toLocaleDateString()}</p>
                      </div>
                    </div>
                    <table id="table-testeDetail" className="table-box">
                      <thead>
                        <tr>
                          <th>Volumes</th>
                          <th>Caixa</th>
                          <th>Tipo Embalagem</th>
                          <th>Cubagem</th>
                          <th>Peso Liquido</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicingDetail.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <tr key={index} id={String(index)}>
                            <td>{item.quantidade}</td>
                            <td>{item.caixa}</td>
                            <td>{item.tipoCaixa}</td>
                            <td>{item.cubagem}</td>
                            <td>{item.peso}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
          </>
        ) : (
          <>
            {/* {visible && (
              <>
                {!loading && (
                  <Fade in={animate} unmountOnExit timeout={1000}>
                    <div className="wrapper" style={{ display: 'flex' }}>
                      <div
                        className="wrap"
                        style={{ height: 410, flexGrow: 1 }}
                      >
                        <Skeleton
                          style={{ borderRadius: '5px' }}
                          variant="rect"
                          height={400}
                        />
                      </div>
                    </div>
                  </Fade>
                )}
              </>
            )} */}

            <Grid container>
              <Fade in={animateInvoicing} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ flexGrow: 1 }}>
                    <TableContent>
                      <Table<InvoicingProps>
                        exportList
                        exportOptions={() => (
                          <MenuItem onClick={exportPDF}>PDF</MenuItem>
                        )}
                        selectBox
                        selectBoxActions={[
                          {
                            onClick: rows => handleLiberarFaturamento(rows),
                            renderItem: () => (
                              <Button
                                className="secundaryButton"
                                type="button"
                                variant="contained"
                              >
                                Liberar
                              </Button>
                            ),
                          },
                        ]}
                        columns={[
                          {
                            title: 'Pedido',
                            type: 'string',
                            orderable: true,
                            props: ['codigoPedido'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                            renderItem: link => {
                              return (
                                // eslint-disable-next-line react/jsx-curly-newline
                                <HyperLink
                                  type="detail"
                                  style={{
                                    paddingLeft: '16px',
                                    fontSize: '13px',
                                  }}
                                  title={String(link.codigoPedido)}
                                  onClick={() =>
                                    // eslint-disable-next-line prettier/prettier
                                    getDetail(
                                      link.codigoPedido,
                                      link.codigoTransportadoraErp,
                                      link.transportadora
                                    )
                                  }
                                />
                              );
                            },
                          },
                          {
                            title: 'Cod. Transportadora',
                            type: 'string',
                            orderable: true,
                            props: ['codigoTransportadoraErp'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Nome Transportadora',
                            type: 'string',
                            orderable: true,
                            props: ['transportadora'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Data Pedido ',
                            type: 'date',
                            orderable: true,
                            props: ['dataPedido'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Cod. Cliente',
                            type: 'string',
                            orderable: true,
                            props: ['codigoClienteErp'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Cliente',
                            type: 'string',
                            props: ['cliente'],
                            orderable: true,
                            cssTitle: {
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'UF',
                            type: 'string',
                            orderable: true,
                            props: ['uf'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Status Expedição',
                            type: 'string',
                            orderable: true,
                            props: ['status'],
                            cssTitle: {
                              justifyContent: 'flex-start',
                            },
                            cssProps: {
                              width: '5%',
                              paddingRight: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Qtd Embalagem',
                            type: 'number',
                            props: ['quantidadeEmbalagem'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                          {
                            title: 'Valor Total',
                            type: 'number',
                            props: ['valorTotal'],
                            cssTitle: {
                              display: 'flex',
                              justifyContent: 'left',
                              textAlign: 'left',
                            },
                            cssProps: {
                              width: '1%',
                              paddingRight: '8px',
                              paddingLeft: '8px',
                              fontFamily: 'sans-serif',
                            },
                            cssText: {
                              paddingLeft: '8px',
                            },
                          },
                        ]}
                        rows={[
                          ...invoicing.map(item => ({
                            ...item,
                          })),
                        ]}
                      />
                    </TableContent>
                  </div>
                </div>
              </Fade>
              <div id="page-report">
                <div id="content-report-geral">
                  <div className="content-report">
                    <div className="headReport">
                      <img src={Logo} alt="PDA" />
                      <div className="titleReport">
                        <h1 style={{ marginRight: '5px' }}>Relatorio</h1>
                        <h1>de</h1>
                        <h1>Faturamento</h1>
                      </div>
                      <div className="printDate">
                        <p>
                          <strong>Data impressão</strong>
                        </p>
                        <p>{printDate.toLocaleDateString()}</p>
                      </div>
                    </div>
                    <table id="table-teste" className="table-box">
                      <thead>
                        <tr>
                          <th>Pedido</th>
                          <th>Data</th>
                          <th>Cód Cliente</th>
                          <th>Cliente</th>
                          <th>UF</th>
                          <th>Status</th>
                          <th>Qtd Embalagem</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicing.map(item => (
                          <tr
                            key={item.codigoPedido}
                            id={String(item.codigoPedido)}
                          >
                            <td>{item.codigoPedido}</td>
                            <td>{item.dataPedido}</td>
                            <td>{item.codigoClienteErp}</td>
                            <td>{item.cliente}</td>
                            <td>{item.uf}</td>
                            <td>{item.status}</td>
                            <td>{item.quantidadeEmbalagem}</td>
                            <td>{item.valorTotal}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};
export default Invoicing;

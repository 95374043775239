import React from 'react';
import { Grid, Fade } from '@material-ui/core';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import TagStatus from 'components/Tools/TagStatus';
import { useTheme } from 'styled-components';
import SearchIconDetails from 'assets/svg/searchIconDetails.svg';
import ProgressBar from 'components/Tools/ProgressBar';
import CustomizedImgCell from '../CustomizedImgCell';
import { PickingData, ISideDishTableProps } from '../../types';

const SideDishTable: React.FC<ISideDishTableProps> = ({
  animate,
  getProduct,
  picking,
}) => {
  return (
    <Fade in={animate} unmountOnExit timeout={1000}>
      <TableContainer>
        <Table<PickingData>
          exportList
          columns={[
            {
              title: 'Pedido',
              type: 'stringCenter',
              orderable: true,
              props: ['codigoPedido'],
              cssProps: {
                width: '5%',
              },
              renderItem: row => {
                return (
                  <div className="code-item">
                    <CustomizedImgCell status={row.descricaoStatus} />
                    <p style={{ marginLeft: '10px' }}>{row.codigoPedido}</p>
                  </div>
                );
              },
            },
            {
              title: 'Onda',
              type: 'stringCenter',
              orderable: true,
              props: ['codigoOnda'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Prioridade',
              type: 'stringCenter',
              orderable: true,
              props: ['prioridade'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Qtde. Peças',
              type: 'number',
              orderable: true,
              props: ['quantidadePedido'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Qtde. Picking',
              type: 'number',
              orderable: true,
              props: ['quantidadePicking'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Data Pedido',
              type: 'datetime',
              orderable: true,
              props: ['dataPedido'],
              cssProps: {
                width: '5%',
              },
            },
            {
              title: 'Progresso',
              orderable: false,
              type: 'number',
              props: ['porcentagem'],
              formatter: row => `${row.porcentagem}%`,
              cssProps: {
                width: '10%',
              },
              renderItem: row => (
                <div className="">
                  <ProgressBar
                    style={{
                      width: '100%',
                      display: 'inline-block',
                    }}
                    value={row.porcentagem || 0}
                  />
                </div>
              ),
            },
            {
              title: 'Status',
              type: 'string',
              orderable: true,
              props: ['descricaoStatus'],
              cssProps: {
                width: '2%',
                marginLeft: '8px',
              },
              renderItem: row => (
                <div className="statusContainer">
                  <TagStatus status={row.descricaoStatus} width="fit-content" />
                </div>
              ),
            },
            {
              title: 'Ações',
              type: undefined,
              cssProps: {
                width: '1%',
              },
              props: ['substituir'],
              renderItem: link => {
                return (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => getProduct(link)}
                      aria-label="Editar"
                    >
                      <img
                        src={SearchIconDetails}
                        alt="Detalhes"
                        style={{ width: '30px' }}
                      />
                      <p className="hover-item">Detalhe</p>
                    </button>
                  </div>
                );
              },
            },
          ]}
          defaultNumberOfRows={10}
          defaultSort="codigoPedido"
          rows={[
            // eslint-disable-next-line no-shadow
            ...picking.map(item => ({
              ...item,
            })),
          ]}
        />
      </TableContainer>
    </Fade>
  );
};
export default SideDishTable;

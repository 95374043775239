import api from 'services/api';
import { TransferenceData } from 'utils/interfaces/Transference';

export const searchBoxRequest = async (
  data: any,
  clientId: number,
  userId: number
) => {
  const reqData = {
    CodigoCliente: clientId,
    user: userId,
    unidadeOrigem: data.origem || null,
    unidadeDestino: data.destino || null,
    tipoUnidadeArmazenagem: data.typeStorage || null,
    unidadeArmazenagem: data.unityStorage || null,
    dataInicio: data.dataInicio
      ? new Date(data.dataInicio).toISOString()
      : null,
    dataFim: data.dataFim ? new Date(data.dataFim).toISOString() : null,
  };

  return api.patch<TransferenceData[]>('Relatorio/Transferencia', {
    ...reqData,
  });
};

export const getOptions = async (clientId: number) => {
  const [unityType] = await Promise.all([
    api.get(`Common/TipoUnidadeArmazenagem?codigoCliente=${clientId}`),
  ]);

  return [unityType];
};

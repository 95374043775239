import React from 'react';
import { DetailsItemProps } from 'app/WMS/resources/NewReceivement/types';

const DetailsItem: React.FC<DetailsItemProps> = ({
  title,
  value,
  id,
  card,
  children,
}) => {
  return (
    <>
      {value !== '0' && (
        <div className="detailsItem tableFilterCard" id={id}>
          {card ? (
            <>
              <p>{value}</p>
              <p>{title}</p>
              {children}
            </>
          ) : (
            <>
              <p>{title}</p>
              <p>{value}</p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DetailsItem;

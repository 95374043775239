/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { Table, SearchBox, SimpleTabs } from '@pdasolucoes/web';
import {
  ProdutivityConferenceData,
  ProdutivityExpeditionAnalitic,
  ProdutivityExpeditionSintetic,
} from '../../../../../utils/interfaces/Reports/homol';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import {
  Container,
  Search,
  TableContent,
  ContainerTab,
} from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { useAuth } from '../../../../../hooks/Auth';

const ProdutivityPicking: React.FC = () => {
  const [sintetic, setSintetic] = React.useState(
    [] as ProdutivityExpeditionSintetic[]
  );
  const [analitic, setAnalitic] = React.useState(
    [] as ProdutivityExpeditionAnalitic[]
  );
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const [animate, setAnimate] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');
  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setLoading(false);
        setVisible(true);

        // TODO: Ideia
        /*
          {
            "usuario": "string",
            "datainicio": "string",
            "datafim": "string",
            "tipoRelatorio": 1 = `Expedição` || 2 = `` || 3 = ``  || 4 = ``,
          }
        */
        const dataParams = {
          CodigoCliente: Number(idCliente),
          Usuario: data.nome || null,
          tipoRelatorio: Number(4),
        };

        const dataFilter = {
          datainicio: data.dataInicio
            ? new Date(`${data.dataInicio}T01:00:00`).toISOString()
            : null,
          datafim: data.dataFim
            ? new Date(`${data.dataFim}T23:59:00`).toISOString()
            : null,
        };

        const match = {
          usuario: '0',
          totalTempo: 'H M S',
        };

        await api
          .get<ProdutivityConferenceData>(
            `Relatorio/RelatorioProdutividade/${dataFilter.datainicio}/${dataFilter.datafim}`,
            {
              params: dataParams,
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((produtividadeData: AxiosResponse) => {
            if (produtividadeData.data.analitico.length === 0) {
              if (
                JSON.stringify(produtividadeData.data.sintetico[0]) ===
                JSON.stringify(match)
              ) {
                setSintetic([]);
                setAnalitic([]);
              } else {
                setSintetic(produtividadeData.data.sintetico);
                setAnalitic([]);
              }
            } else if (produtividadeData.data.analitico.length > 0) {
              if (
                JSON.stringify(produtividadeData.data.sintetico[0]) ===
                JSON.stringify(match)
              ) {
                setSintetic([]);
                setAnalitic(produtividadeData.data.analitico);
              } else {
                setSintetic(produtividadeData.data.sintetico);
                setAnalitic(produtividadeData.data.analitico);
              }
            }
          })
          .catch((produtividadeDataError: AxiosError<any>) => {
            if (produtividadeDataError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              produtividadeDataError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, tokenData, idCliente]
  );
  return (
    <>
      <FixedHeader title="Produtividade" subTitle="Picking" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: date,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: date,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'nome',
                  placeholder: 'Nome',
                  type: 'text',
                  label: 'Nome',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContent>
                    <SimpleTabs
                      tabs={[
                        { anchorTab: 0, label: 'Tabela Sintética' },
                        { anchorTab: 1, label: 'Tabela Analítica' },
                      ]}
                      content={[
                        {
                          anchorContent: 0,
                          children: () => (
                            <>
                              <ContainerTab>
                                <Table<ProdutivityExpeditionSintetic>
                                  exportList
                                  columns={[
                                    {
                                      title: 'Usuário',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['usuario'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '5%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                    },
                                    {
                                      title: 'Média Horas Ini.',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['mediahoraini'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '5%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                        textAlign: 'left',
                                      },
                                    },
                                    {
                                      title: 'Média Horas Fim',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['mediahorafim'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                      },
                                      cssProps: {
                                        width: '1%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                    },
                                    {
                                      title: 'Tempo total',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['totalTempo'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '5%',
                                        paddingRight: '8px',
                                        paddingLeft: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                        textAlign: 'left',
                                      },
                                    },
                                    {
                                      title: 'Pedidos Hora Total',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['pedidosHoraTotal'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '5%',
                                        paddingRight: '8px',
                                        paddingLeft: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                        textAlign: 'left',
                                      },
                                      formatter: data =>
                                        data.pedidosHoraTotal
                                          ? Number(
                                              data.pedidosHoraTotal
                                            ).toFixed(2)
                                          : '-',
                                    },
                                    {
                                      title: 'Qtde. Pedidos Hora T.',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['qtdePedidosTotal'],
                                      cssTitle: {
                                        justifyContent: 'center',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '1%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                    },
                                    {
                                      title: 'Qtde. End. Hora T.',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['qtdeEndHoraTotal'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '2%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                      formatter: data =>
                                        data.qtdeEndHoraTotal
                                          ? Number(
                                              data.qtdeEndHoraTotal
                                            ).toFixed(2)
                                          : '-',
                                    },
                                    {
                                      title: 'Qtde. Endereços T.',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['qtdeEnderecosTotal'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '2%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                    },
                                    {
                                      title: 'Produto Hora Total',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['produtoHoraTotal'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '2%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                      formatter: data =>
                                        data.produtoHoraTotal
                                          ? Number(
                                              data.produtoHoraTotal
                                            ).toFixed(2)
                                          : '-',
                                    },
                                    {
                                      title: 'Qtde. Produto Total',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['qtdeProdutoTotal'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '2%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                    },
                                    {
                                      title: 'Data Pesquisa',
                                      type: 'string',
                                      display: 'notEmpty',
                                      orderable: true,
                                      props: ['dataPesquisa'],
                                      cssTitle: {
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                      },
                                      cssProps: {
                                        width: '2%',
                                        paddingRight: '8px',
                                        fontFamily: 'sans-serif',
                                      },
                                      cssText: {
                                        paddingLeft: '8px',
                                      },
                                    },
                                  ]}
                                  rows={[
                                    ...sintetic.map(item => ({
                                      ...item,
                                    })),
                                  ]}
                                />
                              </ContainerTab>
                            </>
                          ),
                        },
                        {
                          anchorContent: 1,
                          children: () => (
                            <ContainerTab>
                              <Table<ProdutivityExpeditionAnalitic>
                                exportList
                                columns={[
                                  {
                                    title: 'Usuário',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['usuario'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '5%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                  },
                                  {
                                    title: 'Hora início',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['horaInicio'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '5%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                      textAlign: 'left',
                                    },
                                  },
                                  {
                                    title: 'Hora fim',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['horaFim'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                    },
                                    cssProps: {
                                      width: '1%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                  },
                                  {
                                    title: 'Total horas',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['totalHoras'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '5%',
                                      paddingRight: '8px',
                                      paddingLeft: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                      textAlign: 'left',
                                    },
                                  },
                                  {
                                    title: 'Pedidos hora',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['pedidosHora'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '5%',
                                      paddingRight: '8px',
                                      paddingLeft: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                      textAlign: 'left',
                                    },
                                    formatter: data =>
                                      data.pedidosHora
                                        ? Number(data.pedidosHora).toFixed(2)
                                        : '-',
                                  },
                                  {
                                    title: 'Qtde. Pedidos',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['qtdePedidos'],
                                    cssTitle: {
                                      justifyContent: 'center',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '1%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                  },
                                  {
                                    title: 'Qtde. Endereços Hora',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['qtdeEndHora'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '2%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                    formatter: data =>
                                      data.qtdeEndHora
                                        ? Number(data.qtdeEndHora).toFixed(2)
                                        : '-',
                                  },
                                  {
                                    title: 'Qtde. Endereços',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['qtdeEnderecos'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '2%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                  },
                                  {
                                    title: 'Produto Hora',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['produtoHora'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '2%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                    formatter: data =>
                                      data.produtoHora
                                        ? Number(data.produtoHora).toFixed(2)
                                        : '-',
                                  },
                                  {
                                    title: 'Qtde. Produto',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['qtdeProduto'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '2%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                  },
                                  {
                                    title: 'Data Pesquisa',
                                    type: 'string',
                                    display: 'notEmpty',
                                    orderable: true,
                                    props: ['dataPesquisa'],
                                    cssTitle: {
                                      justifyContent: 'flex-start',
                                      textAlign: 'left',
                                    },
                                    cssProps: {
                                      width: '2%',
                                      paddingRight: '8px',
                                      fontFamily: 'sans-serif',
                                    },
                                    cssText: {
                                      paddingLeft: '8px',
                                    },
                                  },
                                ]}
                                rows={[
                                  ...analitic.map(item => ({
                                    ...item,
                                  })),
                                ]}
                              />
                            </ContainerTab>
                          ),
                        },
                      ]}
                    />
                  </TableContent>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};
export default ProdutivityPicking;

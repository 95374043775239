/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Table } from '@pdasolucoes/web';
import { Skeleton } from '@material-ui/lab';
import { Grid, Fade, Button } from '@material-ui/core';
import api from '../../../../../services/api';
import { IParamsLabel } from '../../../../../utils/interfaces/Params';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import { useAuth } from '../../../../../hooks/Auth';
import { TableContent, Container } from '../../../styles/styles';
import { ValidateOnlyNumbers } from '../../../../../utils/validator';

const ParamsLabelPrinter: React.FC = () => {
  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const { enqueueSnackbar } = useSnackbar();
  const [visible, setVisible] = React.useState(Boolean);
  const [loading, setLoading] = React.useState(Boolean);
  const [animate, setAnimate] = React.useState(Boolean);
  const [tableParams, setTableParams] = React.useState([] as IParamsLabel[]);
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');

  const handleChangeInput = (value: any, id: number) => {
    tableParams.map(p => {
      if (p.codigo === id) {
        p.valor = value;
      }
      return p;
    });
  };

  const getTableParams = React.useCallback(async () => {
    const params = {
      codigoCliente: Number(idCliente),
    };
    try {
      setLoading(false);
      setVisible(true);
      await api
        .get(`Common/ParametroGenerico/UnidadeArmazenagem`, {
          headers: { Authorization: `Bearer ${tokenData}` },
          params,
        })
        .then((data: AxiosResponse) => {
          if (data.data.length === 0) {
            setTableParams([]);

            enqueueSnackbar('Nenhum parâmetro foi encontrado.', {
              variant: 'error',
            });
            setLoading(true);
            setVisible(false);
          } else if (data.data.length > 0) {
            enqueueSnackbar(
              `Foi encontrado ${data.data.length} parâmetro(s)!`,
              {
                variant: 'success',
              }
            );
            setTableParams([...data.data]);
            setLoading(true);
            setVisible(false);
          }
        })
        .catch((error: AxiosError<any>) => {
          if (error.response?.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            error.response?.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
              return item;
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }, [enqueueSnackbar, idCliente, tokenData]);

  const saveThis = React.useCallback(async () => {
    const paramemetroList = tableParams.map((item: IParamsLabel) => ({
      codigo: item.codigo,
      valor: item.valor,
    }));
    const params = {
      codigoCliente: Number(idCliente),
      paramemetroList,
    };
    try {
      await api
        .put(
          `Common/ParametroGenerico/UnidadeArmazenagem`,
          { ...params },
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then(() => {
          enqueueSnackbar('Parâmetros salvos com sucesso!', {
            variant: 'success',
          });
          getTableParams();
        })
        .catch((error: AxiosError<any>) => {
          if (error.response?.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            error.response?.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
              return item;
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }, [enqueueSnackbar, getTableParams, idCliente, tableParams, tokenData]);

  React.useEffect(() => {
    setAnimate(true);
    getTableParams();
  }, [getTableParams]);

  return (
    <>
      <FixedHeader title="Parametrização" subTitle="Etiquetas" />
      <Container>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div
                  className="wrapper"
                  style={{ display: 'flex', margin: '0' }}
                >
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex', margin: '0' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContent>
                    <Table<IParamsLabel>
                      getRows={[
                        {
                          onClick: () => {
                            saveThis();
                          },
                          renderItem: () => (
                            <Button
                              className="secundaryButton"
                              type="button"
                              variant="contained"
                            >
                              Salvar
                            </Button>
                          ),
                        },
                      ]}
                      columns={[
                        {
                          title: 'Codigo',
                          props: ['codigo'],
                          orderable: true,
                          type: 'string',
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '2%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Descrição',
                          props: ['descricao'],
                          orderable: true,
                          type: 'string',
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '2%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Valor',
                          props: ['valor'],
                          type: 'string',
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '2%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: inputValue => {
                            return (
                              <>
                                {inputValue.descricao.indexOf('SIGLAS') !==
                                -1 ? (
                                  <div className="containerInputInTable">
                                    <input
                                      className="inputInTable"
                                      onChange={() =>
                                        handleChangeInput(
                                          String(
                                            // @ts-ignore
                                            event?.target?.value
                                          ).toUpperCase(),
                                          inputValue.codigo
                                        )
                                      }
                                      maxLength={2}
                                      name={`input-${inputValue.codigo}`}
                                      defaultValue={`${inputValue.valor}`}
                                    />
                                  </div>
                                ) : (
                                  <div className="containerInputInTable">
                                    <input
                                      className="inputInTable"
                                      onChange={() =>
                                        handleChangeInput(
                                          //   @ts-ignore
                                          event?.target?.value,
                                          inputValue.codigo
                                        )
                                      }
                                      onKeyPress={ValidateOnlyNumbers}
                                      name={`input-${inputValue.codigo}`}
                                      defaultValue={`${String(
                                        inputValue.valor
                                      ).trim()}`}
                                    />
                                  </div>
                                )}
                              </>
                            );
                          },
                        },
                      ]}
                      hidePagination
                      rows={[
                        ...tableParams.map(item => ({
                          ...item,
                        })),
                      ]}
                    />
                  </TableContent>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default ParamsLabelPrinter;

import React from 'react';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import TagStatus from 'components/Tools/TagStatus';
import { PickingDataOrderDetail, IDetailTableProps } from '../../../types';
import CustomizedImgCell from '../../CustomizedImgCell';

const DetailsTable: React.FC<IDetailTableProps> = ({ detailProduct }) => {
  return (
    <TableContainer>
      <Table<PickingDataOrderDetail>
        exportList
        rows={[
          ...detailProduct.map(item => ({
            ...item,
          })),
        ]}
        columns={[
          {
            title: 'Produto',
            type: 'string',
            orderable: true,
            props: ['produto'],
            cssProps: {
              width: '1%',
            },
            renderItem: row => (
              <div className="code-item">
                <CustomizedImgCell status={row.produto} />
                <p style={{ marginLeft: '10px' }}>{row.produto}</p>
              </div>
            ),
          },
          {
            title: 'Descrição do Produto',
            type: 'string',
            orderable: true,
            props: ['descricaoProduto'],
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Status',
            type: 'string',
            props: ['descricaoStatus'],
            renderItem: row => (
              <div className="statusContainer">
                <TagStatus status={row.descricaoStatus} width="fit-content" />
              </div>
            ),
          },
          {
            title: 'Cor',
            type: 'string',
            orderable: true,
            props: ['cor'],
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Grade',
            type: 'stringCenter',
            orderable: true,
            props: ['tamanho'],
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Quantidade',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Qtde. Conferida',
            type: 'number',
            orderable: true,
            props: ['quantidadeConferida'],
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Usuário',
            type: 'string',
            orderable: true,
            props: ['codigoUsuario'],
            cssProps: {
              width: '5%',
            },
          },
        ]}
      />
    </TableContainer>
  );
};
export default DetailsTable;

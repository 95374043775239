import api from 'services/api';
import { PickingWave } from 'utils/interfaces/Picking';
import { SelectItem } from 'utils/toSelect';
import { dataCreateProps, dataFilterProps } from '../types';

export const createWave = (dataCreate: dataCreateProps) =>
  api.post('Picking/Onda', {
    ...dataCreate,
  });

export const getWaveData = (dataFilter: dataFilterProps) =>
  api.patch<PickingWave>(`Picking/Onda`, {
    ...dataFilter,
  });

export const getShippingCode = (idCliente?: string) =>
  api.get<SelectItem>(
    `Common/Transportadora?codigoCliente=${Number(idCliente)}`
  );

export const getUsers = (idCliente?: string) =>
  api.get<SelectItem>(`Usuario/Cliente?CodigoCliente=${Number(idCliente)}`);

export const getUfCode = () => api.get<SelectItem>(`Common/Estados`);

export const getPeriod = (idCliente?: string) =>
  api.get<SelectItem>(
    `Common/PeriodoEmbarque?codigoCliente=${Number(idCliente)}`
  );

export const getOrderTypes = (clientId?: string) =>
  api.get(`Common/TipoPedido?CodigoCliente=${clientId}`);

import api from 'services/api';
import { SelectItem } from 'utils/toSelect';
import {
  SearchDataProps,
  SearchDetailDataProps,
  OrderData,
  SearchRequestPicking,
} from '../types';

export const handleSearchData = (dataFilter: SearchDataProps) =>
  api.patch<OrderData>('Relatorio/Pedido', { ...dataFilter });

export const handleDetailsData = (dataFilter: SearchDetailDataProps) =>
  api.patch('Relatorio/Pedido', { ...dataFilter });

export const handleProduct = (codigoCliente: Number, codigoPedido: Number) =>
  api.get(
    `Relatorio/PedidoDetalhe?codigoCliente=${codigoCliente}&codigoPedido=${codigoPedido}`
  );

export const handleClienteData = async (idCliente: Number) => {
  const [statusOptions, shippingCompany, periodType, orderType] =
    await Promise.all([
      api.get<SelectItem>(
        `Common/StatusPicking?codigoCliente=${Number(idCliente)}`
      ),
      api.get<SelectItem>(`Common/Transportadora?codigoCliente=${idCliente}`),
      api.get<SelectItem>(`Common/PeriodoEmbarque?codigoCliente=${idCliente}`),
      api.get(`Common/TipoPedido?CodigoCliente=${idCliente}`),
    ]);
  return [statusOptions, shippingCompany, periodType, orderType];
};

export const getPickingRequest = (pedidoSubstituir: SearchRequestPicking) =>
  api.post(`Picking/PedidoSubstituir`, { ...pedidoSubstituir });

import api from 'services/api';
import { ResupplyData } from 'utils/interfaces/Reports';
import { transformToSelect } from 'utils/toSelect';

export const getOptions = async (clientId: number) => {
  const [transportadoras] = await Promise.all([
    api.get(`Common/Transportadora?codigoCliente=${clientId}`),
  ]);

  const formatedTransportadoras = transformToSelect(transportadoras.data);

  return [formatedTransportadoras];
};

export const searchBoxRequest = async (
  data: any,
  clientId: number,
  userId: number
) => {
  const requestData = {
    CodigoCliente: clientId,
    user: userId,
    badRequest: null,
    origem: data.origem || null,
    destino: data.destino || null,
    descricaoProduto: data.produto || null,
    CodigoPedido: data.CodigoPedido || null,
    prioridade: data.prioridade || null,
    codigoTransportadora: data.transportadora || null,
  };

  return api.patch<ResupplyData[]>('Relatorio/Ressuprimento', {
    ...requestData,
  });
};

import api from 'services/api';
import { IDataFilterProps, SelectItem, IHandleEditProps } from '../types';

export const handleDataFilter = (dataFilter: IDataFilterProps) =>
  api.patch(`Picking/Estornar`, { ...dataFilter });

export const handleParamiters = (idCliente: Number) =>
  api.get<SelectItem>(
    `Common/StatusPicking?codigoCliente=${Number(idCliente)}`
  );

export const handleDelete = (idCliente: Number, codigoDelete: String) =>
  api.delete(
    `Picking/Estornar?codigoCliente=${Number(idCliente)}&codigoPedido=${String(
      codigoDelete
    )}`
  );

export const handleEdit = (dataRequest: IHandleEditProps) =>
  api.put(`Common/PedidoStatus`, dataRequest);

import { useSnackbar } from 'notistack';
import { Fade } from '@material-ui/core';
import FixedHeader from 'components/Tools/Breadcrumb';
import React, { useCallback, useEffect, useState } from 'react';
import { SelectItem, transformToSelect } from 'utils/toSelect';
import { AxiosError, AxiosResponse } from 'axios';
import SearchBox from 'components/Tools/Search';
import { Container, Search } from 'app/WMS/styles/styles';
import PageMode from 'components/Tools/PageMode';
import Loader from 'components/Tools/Loader';
import { getCookie } from 'utils/cookies';
import {
  LogData,
  DetailLogData,
  ISearchDataFilterProps,
  ISearchDetailTableProps,
  NivelProps,
} from './types/index';
import ShowDetailTable from './components/DetailsItem';
import ShowDetails from './components/ShowDetails';
import {
  handleData,
  handleDetailLog,
  hendleDetailData,
  handleParamitersTypes,
} from './apis';

const NewLog: React.FC = () => {
  const [logStorage, setLogStorage] = React.useState([] as LogData[]);
  const [detailLog, setDetailLog] = React.useState([] as DetailLogData[]);
  const [detail, setDetail] = React.useState(NaN);
  const [table, setTable] = React.useState<boolean>(false);
  const [editTipo, setEditTipo] = React.useState({} as SelectItem[]);
  const { enqueueSnackbar } = useSnackbar();
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [animate, setAnimate] = React.useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [detailLogTable, setDetailLogTable] = useState({} as LogData);

  const [nivelSelect, setNivelSelect] = React.useState(Number);
  const [nivelSelect2, setNivelSelect2] = React.useState(Number);
  const [nivelSelect3, setNivelSelect3] = React.useState(Number);
  const [nivelSelect4, setNivelSelect4] = React.useState(Number);
  const [nivelSelect5, setNivelSelect5] = React.useState(Number);
  const idCliente = getCookie('@pdamodules::codigoCliente');

  const idUser = getCookie('@pdamodules::id');
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const handleSubmit = React.useCallback(
    async data => {
      setTable(false);
      setIsLoading(true);
      setLogStorage([]);
      const dataFilter: ISearchDataFilterProps = {
        CodigoCliente: Number(idCliente),
        user: Number(idUser),
        palete: data.palete || null,
        caixa: data.caixa || null,
        produto: data.produto || null,
        endereco: data.endereco || null,
        CodigoTipoEndereco: data.tipo || null,
        CodigoCodigoNivel1: nivelSelect || null,
        CodigoCodigoNivel2: nivelSelect2 || null,
        CodigoCodigoNivel3: nivelSelect3 || null,
        CodigoCodigoNivel4: nivelSelect4 || null,
        CodigoCodigoNivel5: nivelSelect5 || null,
        dataInicio: data.dataInicio
          ? new Date(data.dataInicio).toISOString()
          : null,
        dataFim: data.dataFim ? new Date(data.dataFim).toISOString() : null,
        data: undefined,
      };
      if (dataFilter.produto === ' ' || dataFilter.produto === null) {
        await handleData(dataFilter)
          .then((logData: AxiosResponse) => {
            if (logData.data.length > 0) {
              setIsLoading(false);
              setTable(true);
              setLogStorage([...logData.data]);
              enqueueSnackbar(
                `Foi encontrado ${logData.data.length} registro(s)!`,
                { variant: 'success' }
              );
            } else {
              setIsLoading(false);
              setLogStorage([]);
              setTable(true);
              enqueueSnackbar(`Não foi encontrado nenhum registro!`, {
                variant: 'error',
              });
            }
          })
          .finally(() => {
            const timer = setTimeout(() => {
              setIsLoading(false);
              return () => {
                clearTimeout(timer);
              };
            }, 1000);
          });
      } else {
        const dataDetail: ISearchDetailTableProps = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          produto: data.produto,
        };
        await handleDetailLog(dataDetail).then((detailLogData: any) => {
          if (detailLogData.data.length === 0) {
            setDetailLog([]);
            setTable(true);
            setIsLoading(false);
            enqueueSnackbar('Nenhum registro foi encontrado.', {
              variant: 'error',
            });
          } else {
            setIsLoading(false);
            setTable(true);
            setLogStorage(detailLogData.data);
            enqueueSnackbar(
              `Foi encontrado ${detailLogData.data.length} registro(s)!`,
              { variant: 'success' }
            );
          }
        });
      }
    },
    [
      enqueueSnackbar,
      idCliente,
      idUser,
      nivelSelect,
      nivelSelect2,
      nivelSelect3,
      nivelSelect4,
      nivelSelect5,
    ]
  );

  const handleProduct = async (row: LogData) => {
    setModalLoading(true);
    setOpen(true);
    const dataDetail = {
      codigoCliente: Number(idCliente),
      User: Number(idUser),
      codigoEndereco: row.codigoEndereco,
    };
    await hendleDetailData(dataDetail).then((logDetailData: AxiosResponse) => {
      setDetailLogTable(row);
      if (logDetailData.data.length === 0) {
        setDetailLog([]);
        setDetail(NaN);
      } else {
        setDetail(row.codigoEndereco);
        setDetailLog(logDetailData.data);
      }
    });
    setModalLoading(false);
  };

  const handleParamiters = React.useCallback(async () => {
    await handleParamitersTypes(Number(idCliente)).then(
      (tipoData: AxiosResponse) => {
        const listType = transformToSelect(tipoData.data);
        setEditTipo([...listType]);
      }
    );
  }, [idCliente]);

  React.useEffect(() => {
    handleParamiters();
  }, [handleParamiters]);

  return (
    <>
      <FixedHeader title="Armazenagem" subTitle="Log Armazenagem" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'produto',
                  label: 'Produto',
                  placeholder: 'Produto',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'endereco',
                  label: 'Endereço',
                  placeholder: 'Endereço',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: date,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: date,
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'caixa',
                  label: 'Caixa',
                  placeholder: 'Caixa',
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'palete',
                  label: 'Palete',
                  placeholder: 'Palete',
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'tipo',
                  label: 'Tipo',
                  placeholder: 'Tipo',
                  type: 'select',
                  options: editTipo,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {table && (
          <>
            <ShowDetailTable
              handleProduct={handleProduct}
              logStorage={logStorage}
              animate={animate}
            />
          </>
        )}
        <PageMode
          isModal
          modalLoading={modalLoading}
          disableSubmit
          open={open}
          handleClose={() => setOpen(!open)}
          title="Detalhes"
        >
          <ShowDetails
            log={detailLogTable}
            animate={animate}
            detailLog={detailLog}
          />
        </PageMode>
      </Container>
    </>
  );
};
export default NewLog;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  action?: () => any;
  onChangeAction?: (event?: any) => any;
}

const SwitchButton: React.FC<InputProps> = ({
  name,
  action = () => {},
  onChangeAction = (e?: any) => {},
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [checked, setChecked] = useState(false);
  const onClickAction = () => {
    action();
    onChangeAction(!checked);
    setChecked(!checked);
  };

  const onChangeValue = (e: any) => {
    action();
    setChecked(!checked);
    onChangeAction(checked);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue: (ref, value) => {
        if (value) {
          setChecked(!!value);
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      onClick={onClickAction}
      checked={checked}
      title={checked ? 'Marcado' : 'Desmarcado'}
    >
      <div className="circle" />
      <div className="switch-content">
        {/* @ts-ignore */}
        <input
          type="checkbox"
          checked={checked}
          onChange={onChangeValue}
          name={name}
          ref={inputRef}
          defaultValue={defaultValue}
          readOnly
          {...rest}
        />
      </div>
    </Container>
  );
};

export default SwitchButton;

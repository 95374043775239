import React, { useLayoutEffect, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import { DetailBoardingData } from 'utils/interfaces/Boarding';
import PageMode from 'components/Tools/PageMode';
import DetailsTable from './DetailsTable';
import { detailsRequest } from '../../api/boarding.api';
import { IBoardingDetailsModalProps } from '../../types';

const BoardingDetailsModal: React.FC<IBoardingDetailsModalProps> = ({
  data,
  closeModal,
  isOpen,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [details, setDetails] = useState<DetailBoardingData[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');

  const getDetails = useCallback(async () => {
    setIsModalLoading(true);

    const requestData = {
      codigoCliente: Number(clientId),
      romaneios: [{ romaneio: Number(data.romaneio) }],
    };

    await detailsRequest(requestData)
      .then(res => {
        setDetails(res.data);
        enqueueSnackbar(`${res.data.length} registro(s) encontrados`, {
          variant: 'success',
        });
      })
      .catch(err => {
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
        closeModal();
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  }, [data, clientId, enqueueSnackbar, closeModal]);

  useLayoutEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <PageMode
      isModal
      disableSubmit
      open={isOpen}
      closeWhenClickOutside
      handleClose={closeModal}
      modalLoading={isModalLoading}
      title={`Detalhes Romaneio ${data.romaneio}`}
    >
      <DetailsTable details={details} />
    </PageMode>
  );
};

export default BoardingDetailsModal;

import api from 'services/api';
import {
  LogData,
  ISearchDataFilterProps,
  ISearchDetailTableProps,
  ILogDetailDataProps,
  NivelProps,
} from '../types';

export const handleData = async (dataFilter: ISearchDataFilterProps) =>
  api.patch<LogData>(`Armazenagem/LogArmazenagem`, { ...dataFilter });

export const handleDetailLog = async (dataDetail: ISearchDetailTableProps) =>
  api.patch(`Armazenagem/LogArmazenagemDetalhe`, { ...dataDetail });

export const hendleDetailData = (dataDetail: ILogDetailDataProps) =>
  api.patch(`Armazenagem/LogArmazenagemDetalhe`, { ...dataDetail });

export const handleParamitersTypes = (idClient: Number) =>
  api.get(`Common/Tipo?codigoCliente=${idClient}`);

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import FixedHeader from 'components/Tools/Breadcrumb';
import { Container, Search } from 'app/WMS/styles/styles';
import { transformToSelect } from 'utils/toSelect';
import { QueryData } from 'utils/interfaces/Expedition';
import { getOptions, submitBoxRequest } from './api/expedition.api';
import { ExpeditionTable, Pdf } from './components';
import { IOption } from './types';
import { formatDataToApi } from './utils';

const Query: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState<IOption[]>([]);
  const [isTableShowing, setIsTableShowing] = useState(false);
  const [expeditions, setExpeditions] = useState<QueryData[]>([]);
  const [transportadoras, setTranportadoras] = useState<IOption[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');

  const getAllSelectOptions = useCallback(async () => {
    setIsLoading(true);

    const [statesRes, transpRes] = await getOptions(Number(clientId));

    const formatedStates = transformToSelect(statesRes.data);
    const formatedTransp = transformToSelect(transpRes.data);

    setStates(formatedStates);
    setTranportadoras(formatedTransp);

    setIsLoading(false);
  }, [clientId]);

  const handleSubmit = useCallback(
    async (data: any) => {
      setIsTableShowing(false);
      setIsLoading(true);

      const formatedData = formatDataToApi(data, Number(clientId));

      await submitBoxRequest(formatedData)
        .then(res => {
          if (!res.data.length) {
            setExpeditions([]);
            enqueueSnackbar('Nenhum registro foi encontrado.', {
              variant: 'warning',
            });

            return;
          }
          setExpeditions(res.data);
          setIsTableShowing(true);
          enqueueSnackbar(`${res.data.length} registros foram encontrados`, {
            variant: 'success',
          });
        })
        .catch(err => {
          console.log(err);
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getAllSelectOptions();
  }, [getAllSelectOptions]);

  return (
    <>
      <FixedHeader title="Expediçao" subTitle="Consulta" />
      <Fade in timeout={1000}>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={handleSubmit}
              hiddenInputs={[
                {
                  name: 'uf',
                  label: 'Estado',
                  type: 'select',
                  placeholder: 'Estado',
                  options: states,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 6,
                  sm: 12,
                },
              ]}
              inputs={[
                {
                  name: 'pedido',
                  label: 'Pedido',
                  type: 'text',
                  placeholder: 'Pedido',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 4,
                },
                {
                  name: 'expedicao',
                  label: 'Expedição',
                  type: 'text',
                  placeholder: 'Expedição',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 4,
                },
                {
                  name: 'codigoTransportadora',
                  label: 'Transportadora',
                  type: 'select',
                  placeholder: 'Transportadora',
                  options: transportadoras,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 4,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading ? (
            <Loader />
          ) : (
            isTableShowing && <ExpeditionTable expeditions={expeditions} />
          )}
          {expeditions.length > 0 && <Pdf data={expeditions} />}
        </Container>
      </Fade>
    </>
  );
};

export default Query;

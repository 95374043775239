/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';

import { Themes } from 'styles/styled';
import light from 'styles/themes/light';
import dark from 'styles/themes/dark';
import usePersistedState from 'utils/usePersistedState';

interface ITheme {
  toggleTheme: () => void;
}

const ThemeContext = createContext<ITheme>({} as ITheme);

export const ProviderTheme: React.FC = ({ children }) => {
  const [theme, setTheme] = usePersistedState<Themes>('theme', 'light');

  const toggleTheme = useCallback(() => {
    setTheme(prevState => (prevState === 'light' ? 'dark' : 'light'));
  }, [setTheme]);

  return (
    <ThemeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={theme === 'light' ? light : dark}>
        {/* @ts-ignore */}
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useTema(): ITheme {
  const context = useContext(ThemeContext);

  if (!context)
    throw new Error('useTema deve ser usado dentro do providerTheme');

  return context;
}

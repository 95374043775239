/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Divider, Fade, Grid, IconButton, MenuItem } from '@material-ui/core';
import React from 'react';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { Table, TagStatus } from '@pdasolucoes/web';
import { useTheme } from 'styled-components';
import Jspdf from 'jspdf';
import { TableContainer } from '../../../../WMS/styles/styles';
import { ReceivementDataDetail } from '../../../../../utils/interfaces/StoreReceivement';
import { Container } from './styles';

interface DetailProps {
  datatable: ReceivementDataDetail[];
  dataDetail: any;
  onClickBack: () => void;
}
const Detail: React.FC<DetailProps> = ({
  datatable,
  dataDetail,
  onClickBack,
}) => {
  const { colors } = useTheme();
  const [detailPedido, setDetailPedido] = React.useState(
    [] as ReceivementDataDetail[]
  );
  const [exportPerfil, setExport] = React.useState(Boolean);
  const [animate, setAnimate] = React.useState(true);
  const [description, setDescription] = React.useState('');
  const [details, setDetails] = React.useState({
    nf: '',
    serie: '',
    totalPecas: NaN,
    totalRecebido: NaN,
    recebimentoInicial: '',
    recebimentoFim: '',
    status: '',
    quantidadeSKU: NaN,
    quantidadeSkuRecebido: NaN,
    totalTempo: '',
  });
  const exportPDF = React.useCallback(async () => {
    const doc = new Jspdf('p', 'pt', 'a4');
    doc.html(document.querySelector('#screenModel') as HTMLElement, {
      callback(pdf) {
        pdf.save('document.pdf');
      },
    });
  }, []);
  return (
    <Container>
      <div className="wrapper" style={{ marginBottom: '0' }}>
        <div className="contentDetail">
          <Grid container spacing={3} className="detailHeader">
            <div className="headDetail">
              <p className="titleNF">
                Nota Fiscal: {`${dataDetail.nf}-${dataDetail.serie}`}
              </p>
              <div id="status">
                <p>Status:</p>
                <TagStatus
                  status={dataDetail.status}
                  color={
                    colors.tagTextColors.filter(
                      tag => tag.name === dataDetail.status
                    ).length > 0
                      ? colors.tagTextColors.filter(
                          tag => tag.name === dataDetail.status
                        )[0].value
                      : undefined
                  }
                  background={
                    colors.tagBackgroundColors.filter(
                      tag => tag.name === dataDetail.status
                    ).length > 0
                      ? colors.tagBackgroundColors.filter(
                          tag => tag.name === dataDetail.status
                        )[0].value
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="details">
              <p>
                Início:
                <span>
                  {!dataDetail.recebimentoInicial
                    ? '--:--'
                    : dataDetail.recebimentoInicial}
                </span>
              </p>
              <p>
                Fim:
                <span>
                  {!dataDetail.recebimentoFim
                    ? '--:--'
                    : dataDetail.recebimentoFim}
                </span>
              </p>
              <p>
                Tempo Total:
                <span className="secundary">
                  {!dataDetail.totalTempo ? '--:--' : dataDetail.totalTempo}
                </span>
              </p>
            </div>
            <div className="divider" />

            <div className="volume">
              <p>
                Quantidade Total (SKU):
                <span>{dataDetail.quantidadeSKU}</span>
              </p>
              <p className="primary">
                Quantidade Recebido (SKU REC):
                <span className="secundary">
                  {dataDetail.quantidadeSkuRecebido}
                </span>
              </p>
              <p className="danger">
                Quantidade Divergência:
                <span>
                  {dataDetail.quantidadeSKU - dataDetail.quantidadeSkuRecebido}
                </span>
              </p>
              <p>
                Total Peças (UND):
                <span>{dataDetail.totalPecas}</span>
              </p>
              <p className="primary">
                Peças Recebidas (UND):
                <span>{dataDetail.totalRecebido}</span>
              </p>
              <p className="danger">
                Divergência Peças (UND):
                <span>{dataDetail.totalPecas - dataDetail.totalRecebido}</span>
              </p>
            </div>
          </Grid>
        </div>
      </div>
      <Grid container>
        <Fade in={animate} unmountOnExit timeout={1000}>
          <TableContainer>
            <div className="detailTableWrap">
              <IconButton
                style={{ padding: '8px' }}
                onClick={onClickBack}
                aria-label="Voltar"
              >
                <KeyboardBackspaceRoundedIcon color="disabled" />
              </IconButton>
              <p>
                Pedido:
                {description}
              </p>
            </div>
            <Divider
              orientation="horizontal"
              style={{
                background: colors.line,
                marginBottom: '1rem',
              }}
            />

            <Table<ReceivementDataDetail>
              exportList={!!exportPerfil}
              exportOptions={() => <MenuItem onClick={exportPDF}>PDF</MenuItem>}
              columns={[
                {
                  title: 'Produto',
                  type: 'string',
                  orderable: true,
                  props: ['codigoProduto'],
                  cssTitle: {
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Descrição',
                  type: 'string',
                  orderable: true,
                  props: ['produto'],
                  cssTitle: {
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Tam.',
                  type: 'string',
                  orderable: true,
                  props: ['tamanho'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Cor',
                  type: 'string',
                  orderable: true,
                  props: ['cor'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Qtde NF',
                  type: 'string',
                  orderable: true,
                  props: ['quantidade'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Receb.',
                  type: 'number',
                  orderable: true,
                  props: ['quantidadeRecebimento'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Divergência.',
                  type: 'number',
                  orderable: true,
                  props: ['quantidadeDivergencia'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Usuário.',
                  type: 'string',
                  orderable: true,
                  props: ['usuario'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                },
                {
                  title: 'Data Rec.',
                  type: 'string',
                  orderable: true,
                  props: ['dataRecebimento'],
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                  formatter: row =>
                    new Date(row.dataRecebimento).toLocaleString(),
                },
                {
                  title: 'Status',
                  type: 'string',
                  props: ['status'],
                  orderable: true,
                  cssTitle: {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  cssProps: {
                    width: '1%',
                    paddingRight: '8px',
                    paddingLeft: '8px',
                    fontFamily: 'sans-serif',
                  },
                  cssText: {
                    paddingLeft: '8px',
                  },
                  renderItem: row => {
                    return (
                      <TagStatus
                        status={row.status}
                        // @ts-ignore
                        color={
                          // eslint-disable-next-line consistent-return
                          colors.tagTextColors.filter(tag => {
                            if (tag.name === row.status) {
                              console.log(tag, row.status);
                              return tag;
                            }
                          }).length > 0
                            ? colors.tagTextColors.filter(
                                tag => tag.name === row.status
                              )[0].value
                            : '#ff0000'
                        }
                        background={
                          colors.tagBackgroundColors.filter(
                            tag => tag.name === row.status
                          ).length > 0
                            ? colors.tagBackgroundColors.filter(
                                tag => tag.name === row.status
                              )[0].value
                            : undefined
                        }
                      />
                    );
                  },
                },
              ]}
              rows={[
                ...datatable.map(item => ({
                  ...item,
                })),
              ]}
            />
          </TableContainer>
        </Fade>
      </Grid>
    </Container>
  );
};

export default Detail;

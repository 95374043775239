import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import SearchBox from 'components/Tools/Search';
import { getCookie } from 'utils/cookies';
import { Container, Search } from 'app/WMS/styles/styles';
import FixedHeader from 'components/Tools/Breadcrumb';
import Loader from 'components/Tools/Loader';
import { AxiosResponse } from 'axios';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'utils/getValidationErrors';
import ReportTable from './components/ReportTable';
import { handleReports } from './apis/report.api';
import { IExtractDataProps } from './types/index';

const Extract: React.FC = () => {
  const idCliente = getCookie('@pdamodules::codigoCliente');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [report, setReport] = useState<IExtractDataProps[]>([]);
  const [inputCurrent, setInputCurrent] = useState('');
  const [animate, setAnimate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const searchRef = useRef<FormHandles>(null);

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const checkFields = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('bandeira') !== '' ||
      searchRef.current?.getFieldValue('regional') !== '' ||
      searchRef.current?.getFieldValue('filial') !== ''
    ) {
      return false;
    }
    return true;
  }, []);

  const daysBetween = () => {
    const startDate = searchRef.current?.getFieldValue('dataInicio');
    const endDate = searchRef.current?.getFieldValue('dataFim');
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / millisecondsPerDay
    );
  };

  const handleSubmit = useCallback(
    async data => {
      try {
        setShowTable(false);
        setIsLoading(true);
        checkFields();

        const isRequired = checkFields();
        const checkDate = daysBetween();

        if ((isRequired && checkDate > 30) || (isRequired && checkDate < -30)) {
          throw new Yup.ValidationError([], 'ValidationError', 'dataInicio');
        }

        const schema = Yup.object()
          .shape({
            quantidade: Yup.string(),
            CodigoFilial: Yup.string(),
            regional: Yup.string(),
            bandeira: Yup.string(),
            dataInicio:
              isRequired === true ? Yup.string().required() : Yup.string(),
            dataFim:
              isRequired === true ? Yup.string().required() : Yup.string(),
          })
          .nullable();

        await schema.validate(data, {
          abortEarly: false,
        });

        const params: IExtractDataProps = {
          bandeira: data.bandeira || null,
          regional: data.regional || null,
          CodigoFilial: data.filial || null,
          quantidade: data.quantidade || null,
          dataInicio: data.dataInicio || null,
          dataFim: data.dataFim || null,
        };

        handleReports(params).then((response: AxiosResponse) => {
          if (!response.data.length) {
            enqueueSnackbar('Nenhum registro encontrado', {
              variant: 'error',
            });
            setReport([]);
            setIsLoading(false);
            setShowTable(true);
          } else {
            enqueueSnackbar(
              `Foi encontrado ${response.data.length} registro(s)!`,
              {
                variant: 'success',
              }
            );
            setReport(response.data);
            setIsLoading(false);

            setShowTable(true);
          }
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar(
            `Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pela bandeira, região ou filial!`,
            {
              variant: 'warning',
            }
          );
        } else {
          console.error(err);
        }
      } finally {
        const timer = setTimeout(() => {
          setIsLoading(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [checkFields, enqueueSnackbar]
  );

  const resultsInputsList = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('bandeira') !== '' ||
      searchRef.current?.getFieldValue('regional') !== '' ||
      searchRef.current?.getFieldValue('filial') !== ''
    ) {
      searchRef.current?.clearField('dataInicio');
      searchRef.current?.clearField('dataFim');
    } else {
      searchRef.current?.setFieldValue('dataInicio', dateDay);
      searchRef.current?.setFieldValue('dataFim', dateDay);
    }
  }, [dateDay]);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    resultsInputsList();
  }, [resultsInputsList, inputCurrent]);

  return (
    <>
      <FixedHeader title="Relatorios" subTitle="Extrato Loja" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              searchBoxRef={searchRef}
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'bandeira',
                  placeholder: 'Bandeira',
                  type: 'text',
                  label: 'Bandeira',
                  onChange: e => setInputCurrent(e.target.value),
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 4,
                },
                {
                  name: 'regional',
                  placeholder: 'Regional',
                  type: 'text',
                  label: 'Regional',
                  onChange: e => setInputCurrent(e.target.value),
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 4,
                },
                {
                  name: 'filial',
                  label: 'Filial',
                  placeholder: 'Filial',
                  type: 'text',
                  onChange: e => setInputCurrent(e.target.value),
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 4,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'dataInicio',
                  label: 'Data Inicio',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Fim',
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <>
            <ReportTable report={report} />
          </>
        )}
      </Container>
    </>
  );
};
export default Extract;

import React from 'react';
import { Grid } from '@material-ui/core';

import DetailsItem from './Details';
import { IShowDetail } from '../../types';
import DetailsItems from '../DetailsTable';

const ShowDetails: React.FC<IShowDetail> = ({ log, animate, detailLog }) => {
  return (
    <>
      <div className="wrapper">
        <div className="contentDetail">
          <Grid container spacing={3} className="detailHeader">
            <div className="details">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '30px',
                }}
              >
                <div className="detailsItemsContainer" id="firstRow">
                  <DetailsItem title="Endereço" value={String(log.endereco)} />

                  <DetailsItem title="Tipo" value={String(log.tipoEndereco)} />
                  <DetailsItem
                    title="Quantidade Palete"
                    value={String(log.quantidadePalete)}
                  />
                  <DetailsItem
                    title="Quantidade"
                    value={String(log.quantidade)}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <DetailsItems animate={animate} detailLog={detailLog} />
        </div>
      </div>
    </>
  );
};
export default ShowDetails;

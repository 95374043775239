import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import Cookies from 'universal-cookie/es6/Cookies';

interface SignInCredentials {
  token_acc: string | null;
  cliente: number | string | null;
}

interface AuthState {
  access_token: string;
  user: User | null;
}

interface User {
  data: {
    accessToken: string;
    codigoUsuario: number;
    login: string;
    nome: string;
    email: string;
    ativo: boolean;
    alterarSenha: boolean;
    codigoPerfil: number;
    descricaoPerfil: string;
    lider: boolean;
  };
}

interface Token {
  data: {
    accessToken: string;
    codigoUsuario: number;
    login: string;
    nome: string;
    email: string;
    ativo: boolean;
    alterarSenha: boolean;
    codigoPerfil: number;
    descricaoPerfil: string;
    lider: boolean;
  };
}

interface AuthContextData {
  user: User | null;
  token: string;
  signIn(credentials: SignInCredentials): Promise<User | undefined>;
  signOut(): void;
}

type AuthProviderProps = {
  children?: any;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [data, setData] = useState(() => {
    const cookies = new Cookies();
    const access_token = cookies.get('@pdamodules::token');
    const user = cookies.get('@pdamodules::user');

    if (access_token && user) {
      return { access_token, user };
    }

    return {
      access_token: 'void',
      user: null,
    } as AuthState;
  });

  // eslint-disable-next-line consistent-return
  const signIn = useCallback(async ({ token_acc, cliente }: any) => {
    const cookieAge = 90000;
    const cookies = new Cookies();

    try {
      const responseLogin: Token = await api.get(
        `Autenticacao?refreshToken=${token_acc}`
      );

      if (responseLogin.data.accessToken) {
        const user = { data: responseLogin.data };
        const { accessToken, codigoUsuario, ativo, login } = responseLogin.data;

        api.defaults.headers.Authorization = `Bearer ${accessToken}`;

        cookies.set('@pdamodules::token', accessToken, { maxAge: cookieAge });
        cookies.set('@pdamodules::user', user, { maxAge: cookieAge });
        cookies.set('@pdamodules::codigoCliente', cliente, {
          maxAge: cookieAge,
        });
        cookies.set('@pdamodules::id', codigoUsuario, { maxAge: cookieAge });
        cookies.set('@pdamodules::active', ativo, { maxAge: cookieAge });
        cookies.set('@pdamodules::login', login, { maxAge: cookieAge });

        setData({
          access_token: responseLogin.data.accessToken,
          user,
        });

        window.location.replace('/extract');

        return user as User;
      }
      throw new Error(responseLogin.data.accessToken);
    } catch (err) {
      toast.warning('Sua autenticação falhou!');
    }
  }, []);

  const signOut = useCallback(() => {
    const cookies = new Cookies();

    cookies.remove('@pdamodules::token');
    cookies.remove('@pdamodules::user');

    window.location.replace(
      `${
        process.env.REACT_APP_ENV === 'dev'
          ? process.env.REACT_APP_LOGOUT_DEV_URL
          : process.env.REACT_APP_LOGOUT_PROD_URL
      }`
    );
    sessionStorage.clear();
    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ token: data.access_token, user: data.user, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };

import PageMode from 'components/Tools/PageMode';
import { transformToSelect, SelectItem } from 'utils/toSelect';
import api from 'services/apiData';
import React, { useState, useEffect } from 'react';
import { useAuth } from 'hooks/Auth';
import { useSnackbar } from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';
import { getCookie } from 'utils/cookies';
import { getStatusList } from '../apis/adress.apis';

interface EditStatusModalFormProps {
  openEditStatusModal: boolean;
  closeEditStatusModal: () => void | boolean;
  submitEditStatusModal: (data: any) => Promise<void> | boolean;
  editStatusRef: any;
}

const EditStatusModalForm: React.FC<EditStatusModalFormProps> = ({
  openEditStatusModal,
  closeEditStatusModal,
  submitEditStatusModal,
  editStatusRef,
}) => {
  const { token } = useAuth();
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const idCliente = getCookie('@pdamodules::codigoCliente');

  const { enqueueSnackbar } = useSnackbar();

  const [editStatusModalList, setEditStatusModalList] = useState<SelectItem[]>(
    []
  );

  useEffect(() => {
    const getStatus = async () => {
      getStatusList(idCliente).then((statusData: AxiosResponse) => {
        const listStatus = transformToSelect(statusData.data);

        setEditStatusModalList([...listStatus]);
      });
    };
    getStatus();
  }, [tokenData, idCliente, enqueueSnackbar]);

  return (
    <>
      <PageMode
        isModal
        open={openEditStatusModal}
        title="Editar Status"
        submitButtonTitle="Salvar"
        modalRefObject={editStatusRef}
        handleSubmit={data => submitEditStatusModal(data)}
        handleClose={closeEditStatusModal}
        inputs={[
          {
            name: 'statusMulti',
            label: 'Status',
            placeholder: 'Status',
            isRequired: true,
            type: 'select',
            options: editStatusModalList,
            xl: 12,
            lg: 12,
            xs: 12,
            md: 12,
            sm: 12,
          },
        ]}
      />
    </>
  );
};

export default EditStatusModalForm;

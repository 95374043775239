import api from 'services/api';
import { IComponents } from 'utils/interfaces/Components';

export const componentsRequest = async (clientId: number, data: any) => {
  const params = {
    codigoComponente: data.codigoComponente || '',
    descricaoComponente: data.descricaoComponente || '',
    CodigoCliente: clientId,
  };

  return api.get<IComponents[]>('Componente', { params });
};

export const createComponentRequest = async (
  componentDescription: string,
  clientId: number
) => {
  const requestData = {
    codigoCliente: clientId,
    descricaoComponente: componentDescription,
  };

  return api.post('Componente', { ...requestData });
};

export const editComponentRequest = async (data: any, clientId: number) => {
  const editRequestData = {
    codigoCliente: clientId,
    codigoComponente: data.codigoComponente,
    descricaoComponente: data.descricaoComponente,
  };

  return api.put('Componente', { ...editRequestData });
};

export const deleteComponentRequest = async (
  codigoComponente: string,
  clientId: number
) => {
  const deleteRequestData = {
    codigoCliente: clientId,
    codigoComponente,
  };

  return api.delete('Componente', { params: deleteRequestData });
};

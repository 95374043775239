import React, { useState, useRef, useCallback, useEffect } from 'react';

import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { AxiosError, AxiosResponse } from 'axios';

import { AddressesData } from 'utils/interfaces/Storage';
import { transformToSelect, SelectItem } from 'utils/toSelect';
import getValidationErrors from 'utils/getValidationErrors';

import { useAuth } from 'hooks/Auth';
import api from 'services/apiData';

import { Container, Search } from 'app/WMS/styles/styles';

import SearchBox from 'components/Tools/Search';
import FixedHeader from 'components/Tools/Breadcrumb';
import Loader from 'components/Tools/Loader';

import { getCookie } from 'utils/cookies';

import { StatusSend } from './types';

import AdressTable from './AdressTable';

import CreateModalForm from './Forms/createModalForm';
import EditModalForm from './Forms/editModalForm';
import EditStatusModalForm from './Forms/editStatusModalForm';
import ImportModalForm from './Forms/importModalForm';
import DeleteModalForm from './Forms/deleteModalForm';

import {
  deleteAddress,
  editAddress,
  editStatus,
  getAddressList,
  getDepositList,
  getModulesList,
  getNivelList,
  getToWalkList,
  getPositionList,
  getStatusList,
  getTypeList,
  submitData,
  uploadFile,
} from './apis/adress.apis';

const Structure: React.FC = () => {
  const [Structures, setStructures] = useState<AddressesData[]>([]);
  const [create, setCreate] = useState<boolean>(false);
  const [importModal, setImportModal] = useState<boolean>(false);
  const { token } = useAuth();

  const clientId = getCookie('@pdamodules::codigoCliente');
  const userId = getCookie('@pdamodules::id');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showTable, setShowTable] = useState<boolean>(false);
  const inputsBox = useRef<FormHandles>(null);
  const inputsEdit = useRef<FormHandles>(null);
  const inputsEditMulti = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [edit, setEdit] = useState<number>(NaN);
  const [editModal, setEditModal] = useState<boolean>(false);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const [depositList, setDepositList] = useState<SelectItem[]>([]);
  const [statusList, setStatusList] = useState<SelectItem[]>([]);
  const [addressList, setAddressList] = useState<SelectItem[]>([]);
  const [modulesList, setModulesList] = useState<SelectItem[]>([]);
  const [nivelList, setNivelList] = useState<SelectItem[]>([]);
  const [toWalk, setToWalk] = useState<SelectItem[]>([]);
  const [positionList, setPositionList] = useState<SelectItem[]>([]);
  const [editTipo, setEditTipo] = useState<SelectItem[]>([]);

  const [deleteCodigo, setDeleteCodigo] = useState<number>(NaN);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteTipo, setDeleteTipo] = useState<string>('');
  const [animate, setAnimate] = useState<boolean>(false);

  const [addressTitle, setAddressTitle] = useState<string>('');

  const [modifiedStatusSend, setModifiedStatusSend] = useState(
    {} as StatusSend
  );
  const [editStatusModal, setEditStatusModal] = useState<boolean>(false);

  const [inputEditStatusModal, setInputEditStatusModal] = useState<number>(NaN);
  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');

  const handleSubmit = useCallback(
    async data => {
      setIsLoading(true);
      const dataFilter = {
        CodigoCliente: Number(clientId),
        User: Number(userId),
        codigoDeposito: data.deposito || null,
        codigoTipo: data.tipo || null,
        codigoStatus: data.status || null,
        codigoRua: data.rua || null,
        codigoAndar: data.andar || null,
        codigoModulo: data.modulo || null,
        codigoNivel: data.nivel || null,
        codigoPosicao: data.posicao || null,
        DescricaoEndereco: data.endereco || null,
      };

      submitData(dataFilter).then((addressesDataResponse: AxiosResponse) => {
        if (addressesDataResponse.data.length === 0) {
          enqueueSnackbar(`Resultados não encontrados`, {
            variant: 'warning',
          });
          setIsLoading(false);
        } else if (addressesDataResponse.data.length > 0) {
          setIsLoading(false);
          enqueueSnackbar(
            `${addressesDataResponse.data.length} resultados encontrados`,
            {
              variant: 'success',
            }
          );
          setStructures(addressesDataResponse.data);
          setShowTable(true);
        }
      });
    },
    [enqueueSnackbar, clientId, userId]
  );

  const rollBack = useCallback(async () => {
    setEditModal(false);
    setEdit(NaN);
  }, []);

  const resetConfirm = useCallback(async () => {
    setDeleteModal(false);
    setIsConfirmed(false);
  }, []);

  const importFile = useCallback(
    async data => {
      const fileData = new FormData();
      fileData.append('formFile', data);
      uploadFile(tokenData, fileData).then(response => {
        if (response.data === true) {
          enqueueSnackbar(`Arquivo importado com sucesso!`, {
            variant: 'success',
          });
        }
      });
      setImportModal(false);
    },
    [tokenData, enqueueSnackbar]
  );

  const getParam = useCallback(async () => {
    setIsLoading(true);
    getAddressList(clientId).then((estruturaEndData: AxiosResponse) => {
      const listAddress = transformToSelect(estruturaEndData.data);
      setAddressList([...listAddress]);
    });

    getModulesList(clientId).then((estruturaEndDataM: AxiosResponse) => {
      const listModules = transformToSelect(estruturaEndDataM.data);
      setModulesList([...listModules]);
    });

    getNivelList(clientId).then((estruturaEndDataN: AxiosResponse) => {
      const listNivel = transformToSelect(estruturaEndDataN.data);
      setNivelList([...listNivel]);
    });

    getToWalkList(clientId).then((estruturaEndDataA: AxiosResponse) => {
      const listToWalke = transformToSelect(estruturaEndDataA.data);
      setToWalk([...listToWalke]);
    });

    getPositionList(clientId).then((estruturaEndDataP: AxiosResponse) => {
      const listPosition = transformToSelect(estruturaEndDataP.data);
      setPositionList([...listPosition]);
    });

    getDepositList(clientId).then((estruturaEndDataD: AxiosResponse) => {
      const listDeposit = transformToSelect(estruturaEndDataD.data);

      setDepositList([...listDeposit]);
    });

    setIsLoading(false);
  }, [clientId]);

  const handleCreate = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          depositoCreate: Yup.string().required(),
          tipoCreate: Yup.string().required(),
          statusCreate: Yup.string().required(),
          andarCreate: Yup.string().required(),
          ruaCreate: Yup.string().required(),
          moduloCreate: Yup.string().required(),
          nivelCreate: Yup.string().required(),
          posicaoCreate: Yup.string().required(),
          transportadoraCreate: Yup.string(),
          capacidade: Yup.string(),
          ressuprimento: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataCreate = {
          codigoCliente: Number(clientId),
          User: Number(userId),
          codigoDeposito: Number(data.depositoCreate),
          codigoTipo: Number(data.tipoCreate),
          codigoStatus: Number(data.statusCreate),
          codigoAndar: data.andarCreate,
          codigoRua: data.ruaCreate,
          codigoModulo: data.moduloCreate,
          codigoNivel: data.nivelCreate,
          codigoPosicao: data.posicaoCreate,
          codigoTransportadora: Number(data.transportadoraCreate),
          capacidade: Number(data.capacidadeCreate),
          ressuprimento: Number(data.ressuprimentoCreate),
          descricaoEndereco: data.descricaoCreate,
          filial: data.filialCreate || null,
        };
        console.log(data);
        await api
          .post('Armazenagem/Endereco', {
            ...dataCreate,
          })
          .then((dataC: AxiosResponse) => {
            enqueueSnackbar(`Endereço criado com sucesso`, {
              variant: 'success',
            });
            setCreate(false);
          })
          .catch((error: AxiosError<any>) => {
            error.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          })
          .finally(() => console.log('api para criação de endereço chamada'));
        setCreate(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          inputsBox.current?.setErrors(erros);
        } else {
          console.error(err);
        }
      }
    },
    [enqueueSnackbar, clientId, userId]
  );

  useEffect(() => {
    const getStatus = async () => {
      getStatusList(clientId).then((statusData: AxiosResponse) => {
        const listStatus = transformToSelect(statusData.data);

        setStatusList([...listStatus]);
      });
    };
    const getTipo = async () => {
      getTypeList(clientId).then((tipoData: AxiosResponse) => {
        const listType = transformToSelect(tipoData.data);
        setEditTipo([...listType]);
      });
    };
    getTipo();
    getStatus();
    getParam();
  }, [getParam, tokenData, clientId, enqueueSnackbar]);

  const detailsHas = useCallback(
    async (
      codigoData: number,
      depositoData: number,
      tipoData: number,
      statusData: number,
      ruaData: number,
      moduloData: number,
      nivelData: number,
      posicaoData: number,
      capacidadeData: number,
      ressuprimentoData: number,
      transportadoraData: number,
      descricaoData: string,
      depositoLabel: string,
      statusLabel: string,
      tipoLabel: string,
      transportadoraLabel: string
    ) => {
      const data = {
        codigo: codigoData,
        codigoDeposito: depositoData,
        codigoTipo: tipoData,
        codigoStatus: statusData,
        codigoRua: ruaData,
        codigoModulo: moduloData,
        codigoNivel: nivelData,
        codigoPosicao: posicaoData,
        codigoTransportadora: transportadoraData,
        capacidade: capacidadeData,
        ressuprimento: ressuprimentoData,
        descricaoEndereco: descricaoData,
        CodigoCliente: Number(clientId),
        user: Number(userId),
      };
      // TODO: refact this api call;
      await api
        .patch(`Armazenagem/Endereco`, {
          ...data,
        })
        .then((patchData: AxiosResponse) => {
          return patchData;
        });

      setAddressTitle(descricaoData);
      setEdit(codigoData);
      setEditModal(true);
      inputsEdit.current?.setFieldValue('depositoEdit', {
        codigo: depositoData,
        value: depositoData,
        label: depositoLabel,
        descricao: depositoLabel,
      });
      inputsEdit.current?.setFieldValue('tipoEdit', {
        codigo: tipoData,
        value: tipoData,
        label: tipoLabel,
        descricao: tipoLabel,
      });
      inputsEdit.current?.setFieldValue('statusEdit', {
        codigo: statusData,
        value: statusData,
        label: statusLabel,
        descricao: statusLabel,
      });
      inputsEdit.current?.setFieldValue('transportadoraEdit', {
        codigo: transportadoraData,
        value: transportadoraData,
        label: transportadoraLabel,
        descricao: transportadoraLabel,
      });
      if (!capacidadeData) {
        inputsEdit.current?.setFieldValue('capacidade', '');
      } else {
        inputsEdit.current?.setFieldValue('capacidade', capacidadeData);
      }
      if (!ressuprimentoData) {
        inputsEdit.current?.setFieldValue('ressuprimento', '');
      } else {
        inputsEdit.current?.setFieldValue('ressuprimento', ressuprimentoData);
      }
    },
    [clientId, userId]
  );

  const formatDetailsHas = (actions: AddressesData) => {
    detailsHas(
      actions.codigo,
      actions.codigoDeposito,
      actions.codigoTipo,
      actions.codigoStatus,
      actions.codigoRua,
      actions.codigoModulo,
      actions.codigoNivel,
      actions.codigoPosicao,
      actions.codigoTransportadora,
      actions.capacidade,
      actions.ressuprimento,
      actions.descricaoEndereco,
      actions.deposito,
      actions.status,
      actions.tipo,
      actions.transportadora
    );
  };

  const handleDetail = useCallback(
    // try to pass the entire data object;
    async data => {
      const dataDetail = {
        codigo: edit,
        User: Number(userId),
        codigoCliente: Number(clientId),
        codigoDeposito: data.depositoEdit,
        codigoTipo: data.tipoEdit,
        codigoStatus: data.statusEdit,
        codigoTransportadora: data.transportadoraEdit,
        capacidade: Number(data.capacidade),
        ressuprimento: Number(data.ressuprimento),
      };
      try {
        const schema = Yup.object().shape({
          depositoEdit: Yup.string().required(),
          tipoEdit: Yup.string().required(),
          statusEdit: Yup.string().required(),
          transportadoraEdit: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setShowTable(true);

        editAddress(dataDetail).then((dataC: AxiosResponse) => {
          enqueueSnackbar(`Endereço editado com sucesso!`, {
            variant: 'success',
          });
          setEditModal(false);
          handleSubmit([]);
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          inputsEdit.current?.setErrors(erros);
        } else {
          console.error(err);
        }
      }
    },
    [edit, enqueueSnackbar, clientId, userId, handleSubmit]
  );

  const editLines = useCallback(
    linesItem => {
      setEditStatusModal(true);

      const modifiedItemList = linesItem.map((item: AddressesData) => ({
        Codigo: item.codigo,
        codigoCliente: Number(clientId),
        codigoStatus: inputEditStatusModal,
        User: Number(userId),
      }));

      const modifiedStatusList = {
        statusItens: modifiedItemList,
      };

      setModifiedStatusSend(modifiedStatusList);
    },
    [inputEditStatusModal, clientId, userId]
  );

  const handleEditStatus = useCallback(
    async data => {
      setModifiedStatusSend({} as StatusSend);
      try {
        const schema = Yup.object().shape({
          statusMulti: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        if (
          modifiedStatusSend &&
          modifiedStatusSend.statusItens &&
          modifiedStatusSend.statusItens.length > 0
        ) {
          const dataRequest = modifiedStatusSend.statusItens.map(item => ({
            Codigo: item.Codigo,
            codigoCliente: item.codigoCliente,
            codigoStatus: Number(data.statusMulti),
            User: item.User,
          }));

          editStatus(dataRequest).then((statusData: AxiosResponse) => {
            enqueueSnackbar(
              `Foi editado ${modifiedStatusSend.statusItens.length} registro(s)!`,
              {
                variant: 'success',
              }
            );

            setModifiedStatusSend({} as StatusSend);
            setInputEditStatusModal(NaN);
            setEditStatusModal(false);
            handleSubmit([]);
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);

          inputsEditMulti.current?.setErrors(erros);
        } else {
          console.error(err);
        }
      } finally {
        setModifiedStatusSend({} as StatusSend);
        setInputEditStatusModal(NaN);
      }
    },
    [modifiedStatusSend, enqueueSnackbar, handleSubmit]
  );

  const deleteHas = useCallback(
    async (dataCodigo: number, dataTipo: string, dataDescription: string) => {
      setDeleteCodigo(dataCodigo);
      setDeleteTipo(dataTipo);
      setDeleteModal(true);
      setAddressTitle(dataDescription);
    },
    []
  );

  const handleDeleteAddress = useCallback(async () => {
    deleteAddress(deleteCodigo, clientId)
      .then((data: AxiosResponse) => {
        enqueueSnackbar(`Registro deletado com sucesso`, {
          variant: 'success',
        });
        handleSubmit([]);
        setDeleteModal(false);
      })
      .finally(() => {
        setDeleteModal(false);
      });
  }, [deleteCodigo, enqueueSnackbar, handleSubmit, clientId]);

  useEffect(() => {
    setAddressTitle('');
    setAnimate(true);
  }, []);

  return (
    <>
      <CreateModalForm
        openCreateModal={create}
        closeCreateModal={() => setCreate(false)}
        submitCreateModal={data => handleCreate(data)}
        createRef={inputsBox}
      />
      <EditModalForm
        openEditModal={editModal}
        closeEditModal={rollBack}
        submitEditModal={data => handleDetail(data)}
        editRef={inputsEdit}
      />
      <EditStatusModalForm
        openEditStatusModal={editStatusModal}
        closeEditStatusModal={() => setEditStatusModal(false)}
        submitEditStatusModal={data => handleEditStatus(data)}
        editStatusRef={inputsEditMulti}
      />
      <ImportModalForm
        openImportModal={importModal}
        closeImportModal={() => setImportModal(false)}
        submitImportModal={data => importFile(data)}
      />
      <DeleteModalForm
        addressTitle={addressTitle}
        resetConfirm={resetConfirm}
        confirm={handleDeleteAddress}
        isConfirmed={isConfirmed}
        handleCloseModal={() => setDeleteModal(false)}
        openModal={deleteModal}
      />
      <FixedHeader title="Armazenagem" subTitle="Endereço" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              // buttons
              searchBoxRef={inputsBox}
              handleSubmit={handleSubmit}
              titleButtonOnCreate="Novo Endereço"
              createButton={() => {
                setCreate(true);
              }}
              createButtonContent={
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7692 14.7692H2.25641C1.91672 14.7692 1.64103 15.0449 1.64103 15.3846C1.64103 15.7243 1.91672 16 2.25641 16H15.1795C15.6144 16 16 15.6144 16 15.1795V2.25641C16 1.91672 15.7243 1.64103 15.3846 1.64103C15.0449 1.64103 14.7692 1.91672 14.7692 2.25641V14.7692ZM13.9487 0.820513C13.9487 0.428308 13.6377 0 13.1282 0H0.820513C0.311795 0 0 0.425846 0 0.820513V13.1282C0 13.6377 0.426667 13.9487 0.820513 13.9487H13.1282C13.5204 13.9487 13.9487 13.6377 13.9487 13.1282V0.820513ZM1.23077 1.23077H12.7179V12.7179H1.23077V1.23077ZM6.35897 6.35897H3.89744C3.55774 6.35897 3.28205 6.63467 3.28205 6.97436C3.28205 7.31405 3.55774 7.58974 3.89744 7.58974H6.35897V10.0513C6.35897 10.391 6.63467 10.6667 6.97436 10.6667C7.31405 10.6667 7.58974 10.391 7.58974 10.0513V7.58974H10.0513C10.391 7.58974 10.6667 7.31405 10.6667 6.97436C10.6667 6.63467 10.391 6.35897 10.0513 6.35897H7.58974V3.89744C7.58974 3.55774 7.31405 3.28205 6.97436 3.28205C6.63467 3.28205 6.35897 3.55774 6.35897 3.89744V6.35897Z"
                      fill="white"
                    />
                  </svg>

                  <p>Novo Endereço</p>
                </>
              }
              importButton={() => {
                setImportModal(true);
              }}
              inputs={[
                {
                  name: 'deposito',
                  label: 'Depósito',
                  placeholder: 'Depósito',
                  type: 'select',
                  options: depositList,
                  messageErrorOnBlur: 'erro',
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'tipo',
                  label: 'Tipo',
                  placeholder: 'Tipo',
                  type: 'select',
                  options: editTipo,
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'status',
                  label: 'Status',
                  placeholder: 'Status',
                  type: 'select',
                  options: statusList,
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'endereco',
                  label: 'Endereço',
                  placeholder: 'Endereço',
                  type: 'text',
                },
              ]}
              hiddenInputs={[
                {
                  name: 'andar',
                  label: 'Andar',
                  placeholder: 'Andar',
                  type: 'select',
                  options: toWalk,
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'rua',
                  label: 'Rua',
                  placeholder: 'Rua',
                  type: 'select',
                  options: addressList,
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'modulo',
                  label: 'Modulos',
                  placeholder: 'Modulos',
                  type: 'select',
                  options: modulesList,
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'nivel',
                  label: 'Nivel',
                  placeholder: 'Nivel',
                  type: 'select',
                  options: nivelList,
                  isLoading,
                  isDisabled: isLoading,
                },
                {
                  name: 'posicao',
                  label: 'Posição',
                  placeholder: 'Posição',
                  type: 'select',
                  options: positionList,
                  isLoading,
                  isDisabled: isLoading,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>

        {isLoading && <Loader />}
        {showTable && (
          <AdressTable
            Structures={Structures}
            editLines={linesItem => editLines(linesItem)}
            detailsHas={formatDetailsHas}
            deleteHas={deleteHas}
          />
        )}
      </Container>
    </>
  );
};

export default Structure;

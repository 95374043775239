import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import TagStatus from 'components/Tools/TagStatus';

import deleteIcon from 'assets/svg/deleteActionsIcon.svg';
import editIcon from 'assets/svg/editActionsIcon.svg';
import { StructureData } from 'utils/interfaces/Storage';
import { Table } from 'components/Tools/Table';
import { IStructureTableProps } from '../types';
import { TableContainer } from '../../../../styles/styles';
import paginationOptions from '../utils/paginationOptions';

const StructureTable: React.FC<IStructureTableProps> = ({
  adresses,
  setModalData,
  setDeleteModal,
  setEditModal,
}) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setAnimate(true);
    }, 250);
    setModalData({} as StructureData);
  }, [adresses, setModalData]);

  return (
    <>
      <Fade in={animate} timeout={1000}>
        <TableContainer>
          <Table<StructureData>
            exportList
            columns={[
              {
                title: 'Descrição',
                type: 'stringCenter',
                orderable: true,
                props: ['descricao'],
                cssProps: {
                  width: '3%',
                },
              },
              {
                title: 'Tipo',
                type: 'stringCenter',
                orderable: true,
                props: ['tipo'],
                cssProps: {
                  width: '8%',
                },
              },
              {
                title: 'Status',
                type: 'boolean',
                orderable: true,
                props: ['ativo'],
                cssProps: {
                  width: '8%',
                },
                renderItem: action => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <TagStatus
                      status={action.ativo ? 'Ativo' : 'Inativo'}
                      width="fit-content"
                    />
                  </div>
                ),
              },
              {
                title: 'Ações',
                type: undefined,
                props: [''],
                cssProps: {
                  width: '4%',
                },
                renderItem: actions => (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setDeleteModal(true);
                        setModalData(actions);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Excluir</p>
                    </button>
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setEditModal(true);
                        setModalData(actions);
                      }}
                    >
                      <img
                        src={editIcon}
                        alt="editar"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Editar</p>
                    </button>
                  </div>
                ),
              },
            ]}
            rows={[
              ...adresses.map((adress: any) => ({
                ...adress,
              })),
            ]}
          />
        </TableContainer>
      </Fade>
    </>
  );
};

export default StructureTable;

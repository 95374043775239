import React from 'react';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import SearchIconDetails from 'assets/svg/searchIconDetails.svg';
import ProgressBar from 'components/Tools/ProgressBar';
import TagStatus from 'components/Tools/TagStatus';
import { OrderData, ITableFollowProps } from '../../types';
import CustomizedImgCell from '../CustomizedImgCell';

const ShowDetailTable: React.FC<ITableFollowProps> = ({
  order,
  getProduct,
}) => {
  return (
    <TableContainer>
      <Table<OrderData>
        exportList
        columns={[
          {
            title: 'Pedido',
            orderable: true,
            type: 'string',
            cssProps: {
              width: '1%',
            },
            props: ['codigoPedido'],
            renderItem: row => {
              return (
                <div className="code-item">
                  <CustomizedImgCell status={row.descricaoStatus} />
                  <p style={{ marginLeft: '10px' }}>{row.codigoPedido}</p>
                </div>
              );
            },
          },
          {
            title: 'Cliente',
            orderable: true,
            type: 'stringCenter',
            props: ['cliente'],
            cssProps: {
              width: '1%',
            },
          },
          {
            title: 'Quantidade',
            orderable: true,
            type: 'number',
            cssProps: {
              width: '1%',
            },
            props: ['quantidadePedido'],
          },
          {
            title: 'Período',
            orderable: true,
            type: 'string',
            cssProps: {
              width: '0.5%',
            },
            props: ['periodo'],
          },
          {
            title: 'Transportadora',
            orderable: true,
            display: 'notEmpty',
            type: 'string',
            cssTitle: {
              alignItems: 'right',
              width: '1%',
            },
            cssProps: {
              width: '1%',
            },
            props: ['transportadora'],
          },
          {
            title: 'Recebido',
            orderable: true,
            type: 'datetime',
            cssProps: {
              width: '0.3%',
            },
            props: ['recebido'],
          },
          {
            title: 'Planejamento',
            orderable: true,
            display: 'notEmpty',
            type: 'datetime',
            cssProps: {
              width: '0.3%',
            },
            props: ['planejamento'],
          },
          {
            title: 'Finalizado',
            orderable: true,
            display: 'notEmpty',
            type: 'string',
            cssProps: {
              width: '5%',
            },
            props: ['finalizado'],
            renderItem: row => {
              return (
                <>
                  {row.finalizado ? (
                    <p className="render-string">
                      {new Date(row.finalizado).toLocaleString()}
                    </p>
                  ) : (
                    <p className="render-string">-</p>
                  )}
                </>
              );
            },
          },
          {
            title: 'Progresso',
            orderable: false,
            type: 'number',
            props: ['porcentagem'],
            formatter: row => `${row.progresso}%`,
            cssProps: {
              width: '10%',
            },
            renderItem: row => (
              <div className="">
                <ProgressBar
                  style={{
                    width: '100%',
                    display: 'inline-block',
                  }}
                  value={row.progresso || 0}
                />
              </div>
            ),
          },
          {
            title: 'Status',
            orderable: true,
            type: 'stringCenter',
            cssProps: {
              width: '2%',
            },
            props: ['descricaoStatus'],
            renderItem: row => (
              <div className="statusContainer">
                <TagStatus status={row.descricaoStatus} width="fit-content" />
              </div>
            ),
          },
          {
            title: 'Ações',
            type: undefined,
            cssProps: {
              width: '1%',
            },
            props: ['substituir'],
            renderItem: row => {
              return (
                <div className="row-actions-button-detail">
                  <button
                    type="button"
                    className="action"
                    onClick={() => getProduct(row)}
                    aria-label="Editar"
                  >
                    <img
                      src={SearchIconDetails}
                      alt="Detalhes"
                      style={{ width: '30px' }}
                    />
                    <p className="hover-item">Detalhe</p>
                  </button>
                </div>
              );
            },
          },
        ]}
        defaultNumberOfRows={10}
        rows={[
          ...order.map(item => ({
            ...item,
          })),
        ]}
      />
    </TableContainer>
  );
};
export default ShowDetailTable;

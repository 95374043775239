import React, { useState, useCallback, useEffect, useRef } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';

import { Container, Search } from 'app/WMS/styles/styles';

import { useAuth } from 'hooks/Auth';

import { SelectItem, transformToSelect } from 'utils/toSelect';
import { PickingWave } from 'utils/interfaces/Picking';

import SearchBox from 'components/Tools/Search';
import FixedHeader from 'components/Tools/Breadcrumb';
import Loader from 'components/Tools/Loader';
import SnackMessage from 'components/Tools/SnackMessage';
import { getCookie } from 'utils/cookies';
import WaveTable from './WaveTable';

import { dataCreateProps, dataFilterProps, WaveSend } from './types';
import {
  createWave,
  getPeriod,
  getShippingCode,
  getUfCode,
  getUsers,
  getWaveData,
  getOrderTypes,
} from './apis/wave.apis';
import CreateWaveForm from './Forms/CreateWaveForm';

const Wave: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const [wave, setWave] = useState<PickingWave[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [orderTypeList, setOrderTypeList] = useState([]);
  const [animate, setAnimate] = useState<boolean>(false);
  const [create, setCreate] = useState<boolean>(false);
  const [shippingCode, setShippingCode] = useState<SelectItem[]>([]);
  const [lineItens, setLineItens] = useState<number>(Number);
  const [checked, setChecked] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [period, setPeriod] = useState<SelectItem[]>([]);

  const inputsBox = useRef<FormHandles>(null);
  const [users, setUsers] = useState<SelectItem[]>([]);
  const [ufCode, setUfCode] = useState<SelectItem[]>([]);
  const [createWaveSend, setCreateWaveSend] = useState({} as WaveSend);
  const [isLoading, setIsLoading] = useState<boolean>(Boolean);

  const idCliente = getCookie('@pdamodules::codigoCliente');

  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');

  const handleChange = (event: any) => {
    setShowTable(true);
    setChecked(event.target.checked);
  };

  const handleWaveDividers = (event: any) => {
    setShowTable(true);
    setIsChecked(event);
  };

  const getOptions = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      getShippingCode(idCliente)
        .then((transportadoraData: AxiosResponse) => {
          const listTransportadora = transformToSelect([
            ...transportadoraData.data,
          ]);
          setShippingCode([...listTransportadora]);
        })
        .catch((transportadoraDataError: AxiosError<any>) => {
          if (transportadoraDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          }
        }),
      getUsers(idCliente)
        .then((usersData: AxiosResponse) => {
          const listUsers = transformToSelect([...usersData.data]);
          setUsers([...listUsers]);
        })
        .catch((usersErros: AxiosError<any>) => {
          if (usersErros.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          }
        }),
      getUfCode()
        .then((ufData: AxiosResponse) => {
          const listUf = transformToSelect([...ufData.data]);
          setUfCode([...listUf]);
        })
        .catch((ufDataError: AxiosError<any>) => {
          if (ufDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          }
        }),
      getPeriod(idCliente)
        .then((PeriodoEmbarqueData: AxiosResponse) => {
          const listPeriodEmbarque = transformToSelect([
            ...PeriodoEmbarqueData.data,
          ]);
          setPeriod([...listPeriodEmbarque]);
        })
        .catch((PeriodoEmbarqueDataError: AxiosError<any>) => {
          if (PeriodoEmbarqueDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          }
        }),
      getOrderTypes(idCliente).then(res => {
        const formatedOrderTypes = transformToSelect(res.data);

        setOrderTypeList(formatedOrderTypes);
      }),
    ]);

    setIsLoading(false);
  }, [tokenData, idCliente, enqueueSnackbar]);

  const openCreateModal = useCallback(linesItem => {
    setCreate(true);
    setIsChecked(false);
    setChecked(false);
    const modifiedItemList = linesItem.map((item: PickingWave) => ({
      codigoPedido: item.codigoPedido,
    }));

    const modifiedStatusList = {
      waveItens: modifiedItemList,
    };

    setCreateWaveSend(modifiedStatusList);
    setLineItens(linesItem.length);
  }, []);

  const handleSearch = useCallback(
    async data => {
      setIsLoading(true);
      setShowTable(false);

      const dataFilter: dataFilterProps = {
        codigoCliente: Number(idCliente),
        codigoPedido: data.pedido || null,
        cliente: data.cliente || null,
        codigoTransportadora: data.codigo || null,
        uf: data.uf || null,
        produto: data.produto || null,
        periodo: data.periodo || null,
        tipoPedido: data.tipoPedido || null,
        dataInicio: data.dataInicio
          ? new Date(data.dataInicio).toISOString()
          : null,
        dataFim: data.dataFim ? new Date(data.dataFim).toISOString() : null,
      };

      getWaveData(dataFilter)
        .then((waveData: AxiosResponse) => {
          if (waveData.data.length === 0) {
            enqueueSnackbar(`Resultados não econtrados.`, {
              variant: 'warning',
            });
            setWave([]);
            setIsLoading(false);
            setShowTable(false);
          } else if (waveData.data.length > 0) {
            enqueueSnackbar(
              `Foi encontrado ${waveData.data.length} registro(s)!`,
              {
                variant: 'success',
              }
            );
            setIsLoading(false);
            setWave([...waveData.data]);
            setShowTable(true);
          }
        })
        .catch((waveDataError: AxiosError<any>) => {
          if (waveDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
            setIsLoading(false);
          }
        });
    },
    [enqueueSnackbar, tokenData, idCliente]
  );

  const handleCancel = useCallback(async () => {
    setCreateWaveSend({} as WaveSend);
    setCreate(false);
    setChecked(false);
    setIsChecked(false);
  }, []);

  const handleCreate = useCallback(
    async data => {
      const dataCreate: dataCreateProps = {
        codigoCliente: Number(idCliente),
        prioridade: checked,
        ondaPorLocal: isChecked,
        pedidos: createWaveSend.waveItens,
        codigoUsuarioOnda: data.operador || null,
      };
      setIsLoading(true);
      createWave(dataCreate)
        .then((waveCreateData: AxiosResponse) => {
          if (Object.values(waveCreateData.data).length > 0) {
            const onda: PickingWave = waveCreateData.data;
            const arfars = onda.pedidos.filter(item => item.sucesso === false);
            const arsus = onda.pedidos.filter(item => item.sucesso === true);
            if (arsus.length > 0) {
              enqueueSnackbar(
                `${arsus.length} Pedido${
                  arfars.length > 1 ? 's' : ''
                } incluido${arfars.length > 1 ? 's' : ''} na onda.`,
                { variant: 'success' }
              );
            }
            if (arfars.length > 0) {
              enqueueSnackbar(
                `Pedido${arfars.length > 1 ? 's' : ''} não incluido${
                  arfars.length > 1 ? 's' : ''
                } na onda.`,
                {
                  content: (key, message) => (
                    <SnackMessage
                      id={key}
                      array={arfars.map(item => item.codigoPedido)}
                      message={message}
                    />
                  ),
                  variant: 'warning',
                }
              );
            }
          }
          const timer = setTimeout(() => {
            setIsLoading(false);
            return () => {
              clearTimeout(timer);
            };
          }, 1000);
          setCreate(false);
          setShowTable(false);
          setCreateWaveSend({} as WaveSend);
        })
        .catch((waveCreateDataError: AxiosError<any>) => {
          if (waveCreateDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          }
        });
    },
    [
      idCliente,
      checked,
      isChecked,
      createWaveSend.waveItens,
      enqueueSnackbar,
      tokenData,
    ]
  );

  useEffect(() => {
    setAnimate(true);
    getOptions();
  }, [getOptions]);

  return (
    <>
      <CreateWaveForm
        openCreateModal={create}
        lineItens={lineItens}
        inputsBox={inputsBox}
        handleCancel={handleCancel}
        submitCreateModal={handleCreate}
        handleChange={handleChange}
        handleChangeDivider={handleWaveDividers}
        checked={checked}
        isCheckedValue={isChecked}
        users={users}
      />
      <FixedHeader title="Picking" subTitle="Criar onda" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSearch}
              inputs={[
                {
                  name: 'pedido',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'cliente',
                  label: 'Cliente',
                  placeholder: 'Cliente',
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'periodo',
                  label: 'Periodo',
                  placeholder: 'Periodo',
                  type: 'select',
                  options: period,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'codigo',
                  label: 'Transportadora',
                  placeholder: 'Transportadora',
                  type: 'select',
                  options: shippingCode,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'produto',
                  label: 'Produto',
                  placeholder: 'Produto',
                  type: 'text',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'uf',
                  label: 'UF',
                  placeholder: 'UF',
                  type: 'select',
                  options: ufCode,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'tipoPedido',
                  label: 'Tipo Pedido',
                  type: 'select',
                  placeholder: 'Tipo Pedido',
                  options: orderTypeList,
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <WaveTable wave={wave} openCreateModal={openCreateModal} />
        )}
      </Container>
    </>
  );
};

export default Wave;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useCallback, useRef } from 'react';
import ReactSelect from 'react-select';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import Icon from 'components/Tools/Icon';
import addIcon from 'assets/svg/addIcon.svg';

import { productRequest } from '../../apis/registerOrder.api';
import {
  InputsContainer,
  InputBox,
  InputLabel,
  SelectBox,
  AddButton,
} from './styles';
import { formatOptions } from '../../utils';
import { IFormItemsProps, IProduct } from '../../types';

const OrderForm: React.FC<IFormItemsProps> = ({ setFormItemsData }) => {
  const selectRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const clientId = getCookie('@pdamodules::codigoCliente');

  const [isFetching, setIsFetching] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [apiProducts, setApiProducts] = useState<IProduct[]>([]);
  const [formData, setFormData] = useState({
    produto: {} as IProduct,
    quantidade: NaN,
  });

  const handleAddProduct = useCallback(() => {
    if (
      !formData.produto ||
      formData.quantidade < 1 ||
      Number.isNaN(formData.quantidade)
    ) {
      enqueueSnackbar('Insira dados válidos', {
        variant: 'warning',
      });

      return;
    }

    setFormItemsData(prevState => ({
      details: prevState.details,
      items: [...prevState.items, formData],
    }));

    enqueueSnackbar(
      `Produto: ${formData.produto.produto}. Adicionado com sucesso!`,
      {
        variant: 'success',
      }
    );

    // @ts-ignore
    selectRef.current.state.value = null;
    setFormData({ produto: {} as IProduct, quantidade: NaN });
  }, [formData, setFormItemsData, enqueueSnackbar]);

  const handleGetProducts = useCallback(
    async (query: string) => {
      setIsFetching(true);
      setIsMenuOpen(true);
      await productRequest(query, String(clientId))
        .then(({ data }) => {
          if (!data.length) {
            enqueueSnackbar('Não há produtos com esse nome/código', {
              variant: 'warning',
            });

            setApiProducts([]);

            return;
          }
          const formatedData = formatOptions(data, true);
          setApiProducts(formatedData);
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [enqueueSnackbar, clientId]
  );

  return (
    <InputsContainer>
      <SelectBox>
        <InputLabel>Produto</InputLabel>
        <ReactSelect
          classNamePrefix="sel"
          blurInputOnSelect
          closeMenuOnSelect
          placeholder="Escolha um produto"
          components={{
            IndicatorsContainer: () => null,
          }}
          options={apiProducts}
          onInputChange={(query, { action }) => {
            if (action === 'input-change' && query.length >= 2) {
              handleGetProducts(query);
            } else {
              setIsMenuOpen(false);
            }
          }}
          onChange={e => {
            setFormData(prevState => ({
              ...prevState,
              produto: {
                produto: e!.produto,
                descricao: e!.descricao,
                tamanho: e?.tamanho,
                ean: e!.ean,
                cor: e?.cor,
                grade: e?.grade,
                label: e!.produto,
              },
            }));
          }}
          isSearchable
          isLoading={isFetching}
          menuIsOpen={isMenuOpen}
          ref={selectRef}
        />
      </SelectBox>
      <InputBox>
        <InputLabel htmlFor="quantidade">Quantidade</InputLabel>
        <input
          type="number"
          name="quantidade"
          id="quantidade"
          placeholder="Digite uma quantidade"
          value={formData.quantidade}
          onChange={e => {
            setFormData(prevState => ({
              ...prevState,
              quantidade: Number(e.target.value),
            }));
          }}
          min="1"
        />
      </InputBox>
      <AddButton
        type="button"
        onClick={handleAddProduct}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Icon icon={addIcon} color={isHover ? '#fff' : '#0095e8'} />
        Adicionar
      </AddButton>
    </InputsContainer>
  );
};

export default OrderForm;

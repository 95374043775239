/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import React from 'react';
import { ButtonBase, Fade, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { getCookie } from 'utils/cookies';
import { ProgressBar } from '@pdasolucoes/web';
import { useTheme } from 'styled-components';

import {
  BaseIcon,
  Container,
  DashboardCard,
  CardContainer,
} from '../../styles/styles';
import FixedHeader from '../../../../components/Tools/Breadcrumb';
import api from '../../../../services/api';
import { getIcon } from '../../../../utils/getImage';
import { useAuth } from '../../../../hooks/Auth';

interface Common {
  titulo: string;
  pagina: string;
  valor: string;
  icone: string;
  dashboard?: Array<ItemProps>;
  items: ItemProps[];
  progresso: number;
  reverse: boolean;
  totalProgresso: number;
  codigoStatus?: number;
  ordem: number;
}

interface ItemProps {
  titulo: string;
  valor: string;
  icone: string;
  ordem: number;
  reverse: Boolean;
}

type ItemData = Common;

const Dashboard: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { title } = useTheme();
  // const { user } = useAuth();
  const user = getCookie('@pdamodules::user');
  const history = useHistory();

  const [items, setItems] = React.useState([] as ItemData[]);
  const [animate, setAnimate] = React.useState(false);

  const idCliente = getCookie('@pdamodules::codigoCliente');

  const idPerfil = user.data.codigoPerfil;
  const token = getCookie('@pdamodules::token');
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');

  const widthLocation = window.innerWidth;

  const getItems = React.useCallback(async () => {
    try {
      await api
        .get(
          `Dashboard?codigoCliente=${Number(idCliente)}&codigoPerfil=${Number(
            idPerfil
          )}`
        )
        .then((res: AxiosResponse) => {
          setItems(res.data);
        });
      // .catch((error: AxiosError<any>) => {
      //   if (error.response?.status === 401 && tokenData) {
      //     enqueueSnackbar('Reautenticando, tente novamente.', {
      //       variant: 'warning',
      //     });
      //   } else {
      //     error.response?.data.erros.map((item: any) => {
      //       enqueueSnackbar(item.mensagem, { variant: 'error' });
      //     });
      //   }
      // });
    } catch (err) {
      console.error(err);
    }
  }, [tokenData, idCliente, idPerfil, enqueueSnackbar]);

  React.useEffect(() => {
    setAnimate(true);
    getItems();
  }, [getItems]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      getItems();
      return () => {
        clearTimeout(timer);
      };
    }, 300000);
  }, [getItems]);

  const hyper = React.useCallback(
    async (codeStatus: any, page: any) => {
      try {
        if (!codeStatus) {
          history.push(`${page}`);
        } else if (codeStatus) {
          history.push(`${page}${codeStatus}`);
        }
      } catch (err) {
        enqueueSnackbar('Reautenticando, tente novamente.', {
          variant: 'warning',
        });
        console.error(err);
      }
    },
    [history]
  );

  return (
    <>
      <FixedHeader title="Dashboard" />
      <Container>
        <DashboardCard dashboard={items}>
          <Grid container spacing={widthLocation > 1440 ? 5 : 3}>
            {items.map(item => (
              <CardContainer
                in={animate}
                timeout={100}
                porcentagem={item.progresso}
              >
                <Grid item xl={4} lg={3} xs={12} md={6} sm={12}>
                  <ButtonBase
                    className="dashboardCard"
                    onClick={() => hyper(item?.codigoStatus, item.pagina)}
                  >
                    <div className="icon">
                      <div className="avatar">
                        <div className="center">
                          <BaseIcon dashboard={items} titulo={item.titulo}>
                            <div className={item.titulo}>
                              {/* @ts-ignore */}
                              {getIcon(item.titulo)}
                            </div>
                          </BaseIcon>
                        </div>
                      </div>
                      <div className="information">
                        <h4>{item.valor}</h4>
                        <p>{item.titulo}</p>
                      </div>
                      {/* {item.titulo === 'Estoque' ||
                        (item.titulo !== 'Pedidos' && (
                          <span className="total">{item.totalProgresso}</span>
                        ))} */}
                    </div>
                    {item.titulo === 'Estoque' || item.titulo === 'Pedidos' ? (
                      <ProgressBar
                        value={Number(item.progresso)}
                        label={item.valor}
                        type="value"
                        reverse={item.reverse}
                        animated
                        fontSize={1}
                        positionTextVerticaly="bottom"
                      />
                    ) : (
                      <ProgressBar
                        value={Number(item.progresso)}
                        type={widthLocation > 1200 ? 'half' : 'linear'}
                        animated
                        reverse={item.reverse}
                        fontSize={2}
                        positionTextVerticaly="bottom"
                      />
                    )}
                  </ButtonBase>
                </Grid>
              </CardContainer>
            ))}
          </Grid>
        </DashboardCard>
      </Container>
    </>
  );
};
export default Dashboard;

import React from 'react';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import { IExtractDataProps, IReportTableProps } from '../../types/index';

const ReportTable: React.FC<IReportTableProps> = ({ report }) => {
  return (
    <TableContainer>
      <Table<IExtractDataProps>
        exportList
        columns={[
          {
            title: 'Bandeira',
            type: 'string',
            orderable: true,
            props: ['bandeira'],
            cssProps: {
              width: '5%',
            },
            cssTitle: {
              width: '1%',
            },
          },
          {
            title: 'Regional',
            type: 'string',
            orderable: true,
            props: ['regional'],
            cssProps: {
              width: '5%',
            },
            cssTitle: {
              width: '1%',
            },
          },
          {
            title: 'Filial',
            type: 'string',
            orderable: true,
            props: ['filial'],
            cssProps: {
              width: '5%',
            },
            cssTitle: {
              width: '1%',
            },
          },
          {
            title: 'Data Incio',
            type: 'date',
            orderable: true,
            props: ['dataInicio'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Data Fim',
            type: 'date',
            orderable: true,
            props: ['dataFim'],
            cssProps: {
              width: '5%',
            },
          },
        ]}
        defaultNumberOfRows={10}
        detailTable
        rows={[
          ...report.map(item => ({
            ...item,
          })),
        ]}
      />
    </TableContainer>
  );
};
export default ReportTable;

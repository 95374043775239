import api from 'services/api';
import { transformToSelect } from 'utils/toSelect';
import { InsufficientData } from 'utils/interfaces/Reports';

export const getOptions = async (clientId: number) => {
  const [transportadoras] = await Promise.all([
    api.get(`Common/Transportadora?codigoCliente=${clientId}`),
  ]);

  const formatedTransportadoras = transformToSelect(transportadoras.data);

  return [formatedTransportadoras];
};

export const searchBoxRequest = async (
  data: any,
  userId: number,
  clientId: number
) => {
  const requestData = {
    CodigoCliente: clientId,
    user: userId,
    codigoPedido: data.pedido || null,
    codigoProduto: data.produto || null,
    descricaoProduto: data.descricao || null,
    prioridade: data.prioridade || null,
    codigoTransportadora: data.transportadora || null,
  };

  return api.patch<InsufficientData[]>('Relatorio/Insuficiente', {
    ...requestData,
  });
};

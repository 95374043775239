import React from 'react';
import { Fade } from '@material-ui/core';

import { TableContainer } from 'app/WMS/styles/styles';
import { InsufficientData } from 'utils/interfaces/Reports';
import { Table } from 'components/Tools/Table';
import { IInsufficientTableProps } from '../../types';

const InsufficientTable: React.FC<IInsufficientTableProps> = ({ rows }) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<InsufficientData>
        columns={[
          {
            title: 'Produto',
            type: 'stringCenter',
            orderable: true,
            props: ['codigoProduto'],
          },
          {
            title: 'Cor',
            type: 'stringCenter',
            orderable: true,
            props: ['cor'],
          },
          {
            title: 'Tamanho',
            type: 'stringCenter',
            orderable: true,
            props: ['tamanho'],
          },
          {
            title: 'Grade',
            type: 'stringCenter',
            orderable: true,
            props: ['grade'],
          },
          {
            title: 'Descrição',
            type: 'stringCenter',
            orderable: true,
            props: ['descricaoProduto'],
          },
          {
            title: 'Quantidade',
            type: 'number',
            orderable: true,
            props: ['quantidade'],
          },
        ]}
        rows={rows}
        exportList
      />
    </TableContainer>
  </Fade>
);

export default InsufficientTable;

import React, { useCallback, useRef, useLayoutEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useSnackbar } from 'notistack';

import { getCookie } from 'utils/cookies';
import PageMode from 'components/Tools/PageMode';
import { editComponentRequest } from '../../../api/components.api';
import { IEditComponentModal } from '../../../types';

const EditComponentModal: React.FC<IEditComponentModal> = ({
  isOpen,
  modalData,
  closeModal,
  clearModalData,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const inputsRef = useRef<FormHandles>(null);

  const clientId = Number(getCookie('@pdamodules::codigoCliente'));

  const setFields = useCallback(() => {
    inputsRef.current?.setFieldValue(
      'codigoComponente',
      modalData.codigoComponente
    );
    inputsRef.current?.setFieldValue(
      'descricaoComponente',
      modalData.descricaoComponente
    );
    inputsRef.current?.setFieldValue('quantidade', modalData.quantidade);
  }, [modalData]);

  const handleCloseModal = useCallback(() => {
    clearModalData();
    closeModal();
  }, [clearModalData, closeModal]);

  const handleEditComponent = useCallback(
    async (data: object) => {
      setIsLoading(true);

      await editComponentRequest(data, clientId)
        .then(res => {
          console.log(res.data);
          handleCloseModal();
          enqueueSnackbar('Componente Editado com sucesso!', {
            variant: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, enqueueSnackbar, handleCloseModal]
  );

  useLayoutEffect(() => {
    setFields();
  }, [setFields]);

  return (
    <PageMode
      isModal
      open={isOpen}
      submitButtonIcon
      modalLoading={isLoading}
      handleClose={handleCloseModal}
      modalRefObject={inputsRef}
      submitButtonTitle="Editar"
      title={`Editar ${modalData.descricaoComponente}`}
      handleSubmit={data => handleEditComponent(data)}
      inputs={[
        {
          name: 'codigoComponente',
          label: 'Componente',
          placeholder: 'Componente',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 4,
        },
        {
          name: 'descricaoComponente',
          label: 'Descrição Componente',
          placeholder: 'Descrição Componente',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 4,
        },
        {
          name: 'quantidade',
          label: 'Quantidade',
          placeholder: 'Quantidade',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 4,
        },
      ]}
    />
  );
};

export default EditComponentModal;

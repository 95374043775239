import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import notificationIcon from 'assets/svg/notificationIcon.svg';
import { useNotification } from 'hooks/Notification';
import NotificationMenu from './components';
import { NotificationContainer } from './styles';
import Icon from '../Icon';

const Notification: React.FC = () => {
  const { title } = useTheme();
  const { notifications } = useNotification();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const hasNotifications = notifications.length > 0;

  return (
    <NotificationContainer
      type="button"
      onClick={e => {
        setIsMenuOpen(hasNotifications);
        setAnchorEl(e.currentTarget);
      }}
    >
      <Icon
        icon={notificationIcon}
        color={title === 'light' ? '#6C7293' : '#FFFFFF'}
      />
      {hasNotifications && (
        <div className="round-display">{notifications.length}</div>
      )}
      <NotificationMenu
        anchor={anchorEl}
        handleClose={() => setIsMenuOpen(false)}
        isOpen={isMenuOpen && hasNotifications}
        notifications={notifications}
      />
    </NotificationContainer>
  );
};

export default Notification;

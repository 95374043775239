/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState, useContext } from 'react';

import { Link, useLocation } from 'react-router-dom';
import sidebarArrow from 'assets/svg/sidebarArrow.svg';
import Logo from 'assets/svg/logowhite.svg';
import { SidebarContext } from 'hooks/Sidebar';
import api from 'services/api';
import Cookies from 'universal-cookie/es6/Cookies';
import { getCookie } from 'utils/cookies';

import { SideMenu } from './styles';
import ItemLink from './ItemLink';
import { useAuth } from '../../../hooks/Auth';

interface SidebarProps {
  active: boolean;
}

interface LinkProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  pagina: string;
  ordem: number;
}

interface PagesProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  pagina: string;
  ordem: number;
  initialPage?: string;
  links: Array<LinkProps>;
}

const Sidebar: React.FC<SidebarProps> = ({ active }) => {
  const cookies = new Cookies();
  const sidebarFix = useContext(SidebarContext);
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(true);
  const [pages, setPages] = useState([] as PagesProps[]);

  const [itemId, setItemId] = useState<number>();
  const [closed, setClosed] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [isHovering, setIsHovering] = useState<boolean>();
  const windowResize = React.useCallback(() => {
    if (window.innerWidth <= 980 && active === true) {
      setClosed(false);
      setMobile(true);
      setExpanded(true);
      return;
    }
    setClosed(false);
    setMobile(false);
  }, [active]);

  const client = sessionStorage
    .getItem('@pdamodules::descricaoCliente')
    ?.replace(/"/g, '');
  // eslint-disable-next-line prettier/prettier
  const codigoCliente = getCookie('@pdamodules::codigoCliente');
  const codigoSistema = getCookie('@pdamodules::CodigoSistema');
  React.useEffect(() => {
    windowResize();
    window.addEventListener('resize', windowResize);
  }, [windowResize, codigoSistema]);

  const openItem = (index: any) => {
    setItemId(index);
  };

  const handleExpand = useCallback(() => {
    sidebarFix.fixSidebar();
  }, [expanded, closed]);

  const getMenu = React.useCallback(async () => {
    api
      .get('SistemaMenu', {
        params: {
          codigoCliente,
          codigoSistema,
        },
      })
      .then(response => {
        setPages([...response.data]);
      });
  }, []);

  const handleReOpenSidebar = () => {
    if (expanded === false) {
      setIsHovering(true);
    }
  };

  const handleHideSidebar = () => {
    if (isHovering && sidebarFix.isFixed) {
      setIsHovering(false);
    }
  };

  const isSelectedCheck = (page?: string) => {
    const check = page;
    let finalCheck = false;

    if (check !== undefined) {
      finalCheck = page?.split('/')[2] === pathname.split('/')[2];
      console.log('sucesso');
      console.log(finalCheck);
      console.log(page?.split('/')[2], 'page');
      console.log(pathname, 'pathname');
      return finalCheck;
    }
    return false;
  };

  React.useEffect(() => {
    getMenu();
    isSelectedCheck();
  }, [getMenu]);
  React.useEffect(() => {
    if (isHovering === true) {
      setExpanded(true);
    }
    if (isHovering === false) {
      setExpanded(false);
    }
  }, [isHovering]);
  React.useEffect(() => {
    if (sidebarFix.isFixed === false) {
      setExpanded(true);
      setClosed(false);
    } else {
      setExpanded(false);
      setClosed(true);
    }
  }, [sidebarFix.isFixed]);

  return (
    <SideMenu
      active={active}
      expand={expanded}
      fixed={sidebarFix.isFixed}
      onMouseOver={handleReOpenSidebar}
      onMouseLeave={handleHideSidebar}
      notDeskTop={window.innerWidth < 1640}
    >
      <div className="content">
        <div className="top">
          <div className="container">
            {!expanded ? (
              <Link className="logo" to="/wms">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  className="minifyLogo"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 2C7.84987 2.44222 11.4095 3.18865 15 4.63644C23.5883 8.09953 32.3526 15.5755 32 32.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <circle cx="3.5" cy="29.6" r="3" fill="#E12325" />
                  <path
                    d="M3 19.5C6.66667 19.8333 14 22.7 14 31.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M4 10C10.4969 10.1705 23.3907 14.8093 22.9909 32"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
            ) : (
              <Link className="logo" to="/extract">
                <img className="fullLogo" alt="Extrato logo" src={Logo} />
              </Link>
            )}
          </div>
        </div>
        <div className="links-list">
          {pages.map((page, index) => (
            <ItemLink
              id={index}
              select={page.pagina}
              openItem={() => openItem(index)}
              openedState={itemId || 0}
              expanded={expanded}
              setExpanded={setExpanded}
              key={page.ordem}
              links={page.links}
              closed={!expanded}
              setClosed={setClosed}
              codigoSistema={page.codigoSistema}
              codigoSistemaMenu={page.codigoSistemaMenu}
              menu={page.menu}
              ordem={page.ordem}
              pagina={page.pagina}
            />
          ))}
        </div>
      </div>
      {!mobile && (
        <div className="arrow-back" onClick={handleExpand}>
          <span className="arrow-back-span">
            <button
              type="button"
              className={`arrow-back-button ${
                !sidebarFix.isFixed ? 'rotate' : ''
              }`}
            >
              <img src={sidebarArrow} alt="Expandir sidebar" />
            </button>
          </span>
        </div>
      )}
    </SideMenu>
  );
};

export default Sidebar;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import Jspdf from 'jspdf';

import { QueryData } from 'utils/interfaces/Expedition';

export function formatDataToApi(data: any, clientId: number) {
  const formatedData = {
    CodigoCliente: clientId,
    CodigoExpedicao: data.expedicao || null,
    uf: data.uf || null,
    CodigoTransportadora: data.codigoTransportadora || null,
    CodigoPedido: data.pedido || null,
    Inicio: data.dataInicio ? new Date(data.dataInicio).toISOString() : null,
    Fim: data.dataFim ? new Date(data.dataFim).toISOString() : null,
  };

  return formatedData;
}

export function exportPdf(oc: string, logo: string, query: QueryData[]) {
  const doc = new Jspdf('p', 'pt', 'a4');
  // @ts-ignore
  doc.addImage(oc, 'png', 30, 20, 150, 50);
  doc.setFontSize(16);
  doc.text('Relatório de Expedição', 220, 50, { charSpace: 1 });
  doc.addImage(logo, 'png', 440, 30, 130, 30);
  doc.setFontSize(10);
  doc.text(`${new Date().toLocaleDateString()}`, 235, 75, { charSpace: 1 });
  doc.setFontSize(10);
  doc.text(`- Pedidos: ${query.length}`, 300, 75, { charSpace: 1 });
  // @ts-ignore
  doc.autoTable({
    html: '#table-teste',
    startY: 100,
    showHead: 'firstPage',
    pageBreak: 'auto',
    didDrawCell: (data: any) => {
      if (
        data.row.cells[0] ===
          data.table.body[data.table.body.length - 1].cells[0] &&
        data.column.index === data.table.columns.length - 1
      ) {
        if (data.row.cells[0].y > 550) {
          doc.addPage();
          doc.addImage(oc, 'png', 30, 20, 150, 50);
          doc.setFontSize(16);
          doc.text('Relatório de Expedição', 220, 50, { charSpace: 1 });
          doc.addImage(logo, 'png', 440, 30, 130, 30);
          doc.setFontSize(10);
          doc.text(`${new Date().toLocaleDateString()}`, 235, 75, {
            charSpace: 1,
          });
          doc.setFontSize(10);
          doc.text(`- Pedidos: ${query.length}`, 300, 75, {
            charSpace: 1,
          });
          doc.setFillColor(0, 0, 0);
          doc.rect(
            40,
            // @ts-ignore
            150,
            doc.internal.pageSize.width / 2 - 60,
            1,
            'FD'
          );
          doc.text(
            `Ass. Transportadora`,
            doc.internal.pageSize.width / 4 - 45,
            175,
            {
              charSpace: 1,
            }
          );
          doc.setFillColor(0, 0, 0);
          doc.rect(
            doc.internal.pageSize.width / 2 + 20,
            // @ts-ignore
            150,
            doc.internal.pageSize.width / 2 - 60,
            1,
            'FD'
          );
          doc.text(
            `Ass. e-Commerce`,
            doc.internal.pageSize.width / 2 + 95,
            175,
            {
              charSpace: 1,
            }
          );
          doc.text(
            `Página: ${doc.getNumberOfPages()}`,
            doc.internal.pageSize.width - 70,
            doc.internal.pageSize.height - 25
          );
        } else {
          doc.rect(
            40,
            // @ts-ignore
            data.row.cells[0].y + 100,
            doc.internal.pageSize.width / 2 - 60,
            1,
            'FD'
          );
          doc.text(
            `Ass. Transportadora`,
            doc.internal.pageSize.width / 4 - 45,
            data.row.cells[0].y + 125,
            {
              charSpace: 1,
            }
          );
          doc.setFillColor(0, 0, 0);
          doc.rect(
            doc.internal.pageSize.width / 2 + 20,
            // @ts-ignore
            data.row.cells[0].y + 100,
            doc.internal.pageSize.width / 2 - 60,
            1,
            'FD'
          );
          doc.text(
            `Ass. e-Commerce`,
            doc.internal.pageSize.width / 2 + 95,
            data.row.cells[0].y + 125,
            {
              charSpace: 1,
            }
          );
        }
      }
    },
    didDrawPage: () => {
      doc.text(
        `Página: ${doc.getNumberOfPages()}`,
        doc.internal.pageSize.width - 70,
        doc.internal.pageSize.height - 25
      );
    },

    margin: { horizontal: 20 },
    headStyles: {
      fillColor: '#606060',
    },
    bodyStyles: {
      lineColor: '#262626',
    },
  });

  doc.save('romaneio.pdf');
}

import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Extract from 'features/Extract';
import RegisterOrder from '../app/WMS/resources/Order/Register';
import Wave from '../features/Picking/Wave';
import Address from '../features/Storage/Addresses';
import Conference from '../features/Picking/Conference';
import Boarding from '../features/Boarding';
import Query from '../features/Expedition';
import PickingFollow from '../features/Picking/FollowPicking';
import Inventory from '../features/Inventory';
import NewFollow from '../features/Order/Follow';
import Insufficient from '../features/Insufficient';
import Transference from '../features/Transference';
import Ressuply from '../features/Resupply';
import ProdutivityConference from '../features/ProductivityConference';
import ProdutivityStorage from '../features/ProductivityStorage';
import Components from '../features/Components';
import ReportReceivement from '../features/ReportReceivement';
import NewReceivement from '../features/Receivement';
import Route from './route';

import NewLog from '../app/WMS/resources/Reports/newLog';

import Error400 from '../pages/Errors/400';
import Error401 from '../pages/Errors/401';
import Box from '../app/WMS/resources/Reports/Box';
import Structure from '../app/WMS/resources/Storage/Structure';
import Pallet from '../app/WMS/resources/Reports/Pallet';
import Log from '../app/WMS/resources/Reports/Log';

import Invoicing from '../app/WMS/resources/Picking/Invoicing';
import ConferenceFollow from '../app/WMS/resources/Conference/Follow';
import ConferenceRaizs from '../app/WMS/resources/Picking/ConferenceRaizs';
import OrderFollow from '../app/WMS/resources/Order/Follow';
import OrderReverse from '../features/Order/Reverse';
// import ProdutivityStorage from '../app/WMS/resources/Reports/ProductivityStorage';
// import ProdutivityConference from '../app/WMS/resources/Reports/ProductivityConference';
import ProdutivityPicking from '../app/WMS/resources/Picking/Picking';
import Dashboard from '../app/WMS/resources/Dashboard';
import Token from '../pages';
import ProdutivityExpedition from '../app/WMS/resources/Reports/ProductivityExpedition';
import Indic1 from '../app/WMS/resources/Indicators/Indic1';
import Indic2 from '../app/WMS/resources/Indicators/Indic2';
import Indic3 from '../app/WMS/resources/Indicators/Indic3';
import ParamsLabelPrinter from '../app/WMS/resources/Params/Label';
import Composition from '../app/WMS/resources/Composition';
import CreateComposition from '../app/WMS/resources/Composition/CreateComposition';
import StoreReceivement from '../app/Store/resources/StoreReceivement';

const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" exact component={Token} />

        <Route path="/wms/" exact isInside isPrivate component={Dashboard} />
        <Route
          path="/wms/structure_addresses"
          exact
          component={Structure}
          isPrivate
          isInside
        />
        <Route
          path="/wms/picking"
          exact
          // component={Invoicing}
          component={PickingFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/invoicing/"
          exact
          component={Invoicing}
          isPrivate
          isInside
        />
        <Route
          path="/wms/conference_follow"
          exact
          component={ConferenceFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/resupply"
          exact
          component={Ressuply}
          isPrivate
          isInside
        />
        <Route
          path="/wms/picking/:codeStatus"
          exact
          component={PickingFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/wave/create"
          exact
          component={Wave}
          isPrivate
          isInside
        />
        <Route
          path="/wms/conference"
          exact
          component={Conference}
          isPrivate
          isInside
        />
        {/* <Route

          path="/wms/newconference"
          exact
          component={NewConference}
          isPrivate
          isInside
  /> */}
        <Route
          path="/wms/conferenceRaizs"
          exact
          component={ConferenceRaizs}
          isPrivate
          isInside
        />
        <Route
          path="/wms/followOrder"
          exact
          component={NewFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/followOrder/:codeStatus"
          exact
          component={OrderFollow}
          isPrivate
          isInside
        />
        <Route
          path="/wms/reverseOrder"
          exact
          component={OrderReverse}
          isPrivate
          isInside
        />
        <Route
          path="/wms/register_order"
          exact
          component={RegisterOrder}
          isPrivate
          isInside
        />
        <Route
          path="/wms/addresses"
          exact
          component={Address}
          isPrivate
          isInside
        />
        <Route path="/wms/log" exact component={NewLog} isPrivate isInside />
        <Route
          path="/wms/receive/"
          exact
          component={NewReceivement}
          isPrivate
          isInside
        />
        <Route
          path="/receiving/receive/"
          exact
          component={StoreReceivement}
          isPrivate
          isInside
        />
        <Route
          path="/wms/Insufficient"
          exact
          component={Insufficient}
          isPrivate
          isInside
        />
        <Route
          path="/wms/transference"
          exact
          component={Transference}
          isPrivate
          isInside
        />
        <Route
          path="/wms/ProdutivityStorage"
          exact
          component={ProdutivityStorage}
          isPrivate
          isInside
        />
        <Route
          path="/wms/ProdutivityConference"
          exact
          component={ProdutivityConference}
          isPrivate
          isInside
        />
        <Route
          path="/wms/produtivityPicking"
          exact
          component={ProdutivityPicking}
          isPrivate
          isInside
        />
        <Route
          path="/wms/produtivityExpedition"
          exact
          component={ProdutivityExpedition}
          isPrivate
          isInside
        />
        <Route
          path="/wms/expedition"
          exact
          component={Query}
          isPrivate
          isInside
        />
        <Route
          path="/wms/inventory"
          exact
          component={Inventory}
          isPrivate
          isInside
        />
        <Route path="/wms/box" exact component={Box} isPrivate isInside />
        <Route path="/wms/pallet" exact component={Pallet} isPrivate isInside />
        <Route
          path="/wms/structure"
          exact
          component={Structure}
          isPrivate
          isInside
        />
        <Route
          path="/wms/storageIndicators"
          exact
          component={Indic1}
          isPrivate
          isInside
        />
        <Route
          path="/wms/orderIndicators"
          exact
          component={Indic2}
          isPrivate
          isInside
        />
        <Route
          path="/wms/separationIndicators"
          exact
          component={Indic3}
          isPrivate
          isInside
        />
        <Route
          path="/wms/labelParams"
          exact
          component={ParamsLabelPrinter}
          isPrivate
          isInside
        />
        <Route
          path="/wms/boarding"
          exact
          component={Boarding}
          isPrivate
          isInside
        />
        <Route
          path="/wms/components"
          exact
          component={Components}
          isPrivate
          isInside
        />
        <Route
          path="/wms/composition"
          exact
          component={Composition}
          isPrivate
          isInside
        />
        <Route
          path="/wms/composition/create/:id"
          exact
          component={CreateComposition}
          isPrivate
          isInside
        />
        <Route
          path="/wms/invoicereport/"
          exact
          component={ReportReceivement}
          isPrivate
          isInside
        />
        <Route path="/extract/" exact component={Extract} isPrivate isInside />

        <Route path="/401" component={Error401} />
        <Route path="*" component={Error400} />
      </Switch>
    </BrowserRouter>
  );
};

export default routes;

import styled from 'styled-components';

export const InputsContainer = styled.div<{ align?: boolean }>`
  width: 100%;
  padding: 0 1.25rem;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.15rem;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  flex: 1;
  max-width: 345px;

  input {
    width: 100%;
    min-height: 38px;
    padding: 8px 10px;
    border: 1px solid ${props => props.theme.colors.borderInput};
    background: ${props => props.theme.colors.input};
    border-radius: 4px;
    color: ${props => (props.theme.title === 'light' ? '#555555' : '#f2f2f2')};
    font-size: 13px;
    font-weight: 400;
    transition: 0.15s ease;

    &:focus,
    &:hover {
      border-color: ${props =>
        props.title === 'light' ? '#0095e8' : '#187DE4'} !important;
    }
    &::placeholder {
      color: #b5b5c3;
      font-size: 12px;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  span {
    color: #b5b5c3;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  flex: 1;
  max-width: 345px;

  .css-2b097c-container {
    width: 100%;
    .sel__control {
      transition: none;
      border-radius: 5px;
      border: 1px solid ${props => props.theme.colors.borderInput};
      background: transparent;

      .sel__value-container {
        .sel__placeholder {
          font-size: 13px;
          color: #b0b0b0;
        }
        .sel__single-value {
          font-size: 13px;
          color: ${props =>
            props.theme.title === 'light' ? '#555555' : '#ECF8FFee'};
        }
      }

      .sel__input {
        font-size: 13px;
        color: #555555;
      }
    }

    .sel__indicator-separator {
      display: none;
    }

    .sel__menu {
      background-color: ${props =>
        props.theme.title === 'light' ? '#ffffff' : '#262639'};
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      .sel__menu-list {
        .sel__option {
          color: #b5b5c3;
          background-color: ${props =>
            props.theme.title === 'light' ? '#ffffff' : '#262639'};
          transition: 0.15s ease;
          cursor: pointer;
          &.sel__option--is-focused,
          &.sel__option--is-selected {
            background-color: ${props =>
              props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};
            color: #0095e8;
          }
        }
        &:first-child {
          border-radius: 10px 10px 0 0;
        }
        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }
    }

    .sel--is-disabled {
      .sel__control--is-disabled {
        background: #f2f2f2 !important;
        border-color: #e6e6e6 !important;

        .sel__value-container {
          .sel__placeholder {
            color: #808080 !important;
          }
        }
      }
    }
  }
`;

export const InputLabel = styled.label`
  cursor: pointer;
  font-size: 12px;
  color: ${props => (props.theme.title === 'light' ? '#464e5f' : '#b5b5c3')};
  font-weight: 500;
  user-select: none;
`;

export const CustomizedCell = styled.div<{
  justify?: 'start' | 'center';
  gap?: string;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.justify === 'start' ? 'flex-start' : 'center'};
  gap: ${props => (props.gap ? props.gap : '1rem')};
  span.bold {
    font-size: 12px;
    color: #464e5f;
    font-weight: 600;
  }
  span.default-text-table {
    text-transform: uppercase;
    color: #b5b5c3;
    font-size: 14px;
    font-weight: 500;
  }
  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    user-select: none;
  }
  button {
    background-color: transparent;
    cursor: pointer;
    width: 12px;
    img {
      width: 100%;
    }
  }
`;

export const Qtd = styled.input`
  font-size: 12px;
  max-width: fit-content;
  padding: 2px 2px 2px 4px;
  width: 100%;
  max-width: 35px;
  height: 25px;
  border-radius: 5px;
  color: ${props => (props.theme.title === 'light' ? '#555555' : '#f2f2f2')};
  background-color: transparent;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border: 2px solid #b5b5c3;
  &:focus {
    border: 2px solid rgb(70, 78, 95);
  }
  &::placeholder {
    color: #e5eaee;
  }
`;

export const AddButton = styled.button`
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  border-radius: 6px;
  transition: 0.15s;
  font-size: clamp(12px, 14px, 4vmin);
  padding: 10px 1rem;

  background-color: ${props =>
    props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};
  color: #0095e8;

  &:hover {
    color: #fff;
    background-color: #0095e8;
  }
`;

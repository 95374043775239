import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 54px;
  background: ${props => props.theme.colors.header_background};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 1rem 24px;
  border-top: 1px solid ${props => props.theme.colors.line};
  z-index: 5;

  .colorDescription {
    color: ${props =>
      props.theme.title === 'light' ? '#181C32' : '#fff'} !important;
    fill: ${props => props.theme.colors.description} !important;
    stroke: ${props => props.theme.colors.description} !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    letter-spacing: 0.00938em;
    display: inline-flex;
    align-items: flex-end;
    gap: 5px;
    justify-content: center;
    &.subtitle {
      color: ${props =>
        props.theme.title === 'light' ? '#959CB6' : '#fff'} !important;
    }
    &.mainTitle {
      font-size: 14px !important;
    }
  }
`;

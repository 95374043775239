import React from 'react';
import { Fade } from '@material-ui/core';

import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import { ProdutivityExpeditionSintetic } from 'utils/interfaces/Reports';
import { ISyntheticTableProps } from '../../types';

const SyntheticTable: React.FC<ISyntheticTableProps> = ({ rows }) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<ProdutivityExpeditionSintetic>
        columns={[
          {
            title: 'Usuário',
            type: 'stringCenter',
            orderable: true,
            props: ['usuario'],
          },
          {
            title: 'Média Horas Ini.',
            type: 'stringCenter',
            orderable: true,
            props: ['mediahoraini'],
          },
          {
            title: 'Média Horas Fim',
            type: 'stringCenter',
            orderable: true,
            props: ['mediahorafim'],
          },
          {
            title: 'Tempo total',
            type: 'stringCenter',
            orderable: true,
            props: ['totalTempo'],
          },
          {
            title: 'Pedidos Hora Total',
            type: 'stringCenter',
            orderable: true,
            props: ['pedidosHoraTotal'],
          },
          {
            title: 'Qtde. Pedidos Hora T.',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdePedidosTotal'],
          },
          {
            title: 'Qtde. End. Hora T.',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdeEndHoraTotal'],
          },
          {
            title: 'Qtde. Endereços T.',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdeEnderecosTotal'],
          },
          {
            title: 'Produto Hora Total',
            type: 'stringCenter',
            orderable: true,
            props: ['produtoHoraTotal'],
          },
          {
            title: 'Qtde. Produto Total',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdeProdutoTotal'],
          },
          {
            title: 'Data Pesquisa',
            type: 'stringCenter',
            orderable: true,
            props: ['dataPesquisa'],
          },
        ]}
        rows={rows}
        exportList
      />
    </TableContainer>
  </Fade>
);

export default SyntheticTable;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, ClickAwayListener, Popper, Fade } from '@material-ui/core';

import { AboutList, MenuLinkContainer } from '../styles';

interface IAboutMenuProps {
  anchor: HTMLButtonElement | null;
  isOpen: boolean;
  handleClose(): void;
}

interface IMenuLinkProps {
  path: string;
  title: string;
}

const MenuLink: React.FC<IMenuLinkProps> = ({ path, title }) => (
  <MenuLinkContainer
    href={path}
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {title}
  </MenuLinkContainer>
);

const AboutMenu: React.FC<IAboutMenuProps> = ({
  anchor,
  handleClose,
  isOpen,
}) => {
  const { pathname } = useLocation();

  const helpUrl = String(process.env.REACT_APP_HELP_PROD_URL) + pathname;

  return (
    <>
      {/* @ts-ignore */}
      <Box className="popper-box">
        <Popper
          open={isOpen}
          anchorEl={anchor}
          className="popper"
          style={{ zIndex: 999999 }}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Fade in timeout={1000}>
              <AboutList>
                <div>
                  <MenuLink
                    title="Suporte"
                    path="mailto:suporte@pdasolucoes.com.br"
                  />
                </div>
                <div>
                  <MenuLink title="Ajuda" path={helpUrl} />
                </div>
                <div>
                  <MenuLink
                    title="Sobre"
                    path="http://pdaforms.com.br/a-pda/"
                  />
                </div>
              </AboutList>
            </Fade>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};

export default AboutMenu;

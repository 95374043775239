import React from 'react';
import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import TagStatus from 'components/Tools/TagStatus';
import CustomizedImgCell from '../CustomizedImgCell';
import { IReportDataProps, IReportTableProps } from '../../types/index';

const ReportTable: React.FC<IReportTableProps> = ({ report }) => {
  return (
    <TableContainer>
      <Table<IReportDataProps>
        exportList
        columns={[
          {
            title: 'Pedido',
            type: 'stringCenter',
            orderable: true,
            props: ['codigoPedido'],
            cssProps: {
              width: '5%',
            },
            renderItem: row => {
              return (
                <div className="code-item">
                  <CustomizedImgCell status={row.codigoStatus} />
                  <p style={{ marginLeft: '10px' }}>{row.pedido}</p>
                </div>
              );
            },
          },
          {
            title: 'Produto',
            type: 'stringCenter',
            orderable: true,
            props: ['produto'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Nota Fiscal',
            type: 'stringCenter',
            orderable: true,
            props: ['notaFiscal'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Serie',
            type: 'stringCenter',
            orderable: true,
            props: ['serie'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Data',
            type: 'datetime',
            orderable: true,
            props: ['data'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Qtde Nota Fiscal',
            type: 'number',
            orderable: true,
            props: ['quantidadeNotaFiscal'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Qtde Pedido',
            type: 'stringCenter',
            orderable: true,
            props: ['quantidadePedido'],
            cssProps: {
              width: '5%',
            },
          },
          {
            title: 'Status',
            type: 'stringCenter',
            orderable: true,
            props: ['codigoStatus'],
            cssProps: {
              width: '5%',
            },
            renderItem: row => (
              <div className="statusContainer">
                <TagStatus status={row.codigoStatus} width="fit-content" />
              </div>
            ),
          },
        ]}
        defaultNumberOfRows={10}
        rows={[
          ...report.map(item => ({
            ...item,
          })),
        ]}
      />
    </TableContainer>
  );
};
export default ReportTable;

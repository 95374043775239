import React from 'react';

export const IconCreate: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7692 14.7692H2.25641C1.91672 14.7692 1.64103 15.0449 1.64103 15.3846C1.64103 15.7243 1.91672 16 2.25641 16H15.1795C15.6144 16 16 15.6144 16 15.1795V2.25641C16 1.91672 15.7243 1.64103 15.3846 1.64103C15.0449 1.64103 14.7692 1.91672 14.7692 2.25641V14.7692ZM13.9487 0.820513C13.9487 0.428308 13.6377 0 13.1282 0H0.820513C0.311795 0 0 0.425846 0 0.820513V13.1282C0 13.6377 0.426667 13.9487 0.820513 13.9487H13.1282C13.5204 13.9487 13.9487 13.6377 13.9487 13.1282V0.820513ZM1.23077 1.23077H12.7179V12.7179H1.23077V1.23077ZM6.35897 6.35897H3.89744C3.55774 6.35897 3.28205 6.63467 3.28205 6.97436C3.28205 7.31405 3.55774 7.58974 3.89744 7.58974H6.35897V10.0513C6.35897 10.391 6.63467 10.6667 6.97436 10.6667C7.31405 10.6667 7.58974 10.391 7.58974 10.0513V7.58974H10.0513C10.391 7.58974 10.6667 7.31405 10.6667 6.97436C10.6667 6.63467 10.391 6.35897 10.0513 6.35897H7.58974V3.89744C7.58974 3.55774 7.31405 3.28205 6.97436 3.28205C6.63467 3.28205 6.35897 3.55774 6.35897 3.89744V6.35897Z"
      fill="white"
    />
  </svg>
);

import styled from 'styled-components';

export const RegisterContainer = styled.article`
  width: 100%;
  background-color: ${props => props.theme.colors.container_background};
  color: ${props => props.theme.colors.container_title};
  border-radius: 12px;
`;

export const RegisterHeader = styled.div`
  padding: 1.75rem 12px 8px 1.5rem;
  width: 100%;
  border-bottom: 1px solid #e5eaee;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px !important;
  & > h2 {
    font-weight: 600;
    font-size: clamp(15px, 1.25rem, 5vmin);
  }
`;

export const HeaderContainer = styled.div<{
  customizePadding?: boolean;
  hasMoreThanOne?: boolean;
}>`
  width: 100%;
  padding: ${props =>
    props.customizePadding ? '1.75rem 12px 8px 1.5rem' : '1rem'};
  border-bottom: 1px solid
    ${props => props.theme.colors.container_header_border};
  display: flex;
  justify-content: ${props =>
    props.hasMoreThanOne ? 'space-between' : 'flex-start'};
  align-items: ${props => (props.hasMoreThanOne ? 'center' : 'flex-end')};
  border-radius: 8px 8px 0 0;
  box-shadow: 0px !important;

  & > h2 {
    font-weight: 600;
    color: ${props => props.theme.colors.container_title};
    font-size: clamp(15px, 1.25rem, 5vmin);
  }

  & > button {
    background: none;
  }
`;

export const RegisterContent = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.colors.container_background};
  border-radius: 0 0 8px 8px;
  min-height: 350px;
  display: grid;
  place-items: center;
`;

export const RegisterButtonBox = styled.button<{
  bg?: string;
  color?: string;
  size?: 'sm' | 'lg';
}>`
  cursor: pointer;
  padding: ${props => (props.size !== 'sm' ? '14px 1.5rem' : '10px 1rem')};
  background-color: ${props => (props.bg ? props.bg : '#0095e8')};
  color: ${props => (props.color ? props.color : '#FFF5F8')};
  font-size: ${props =>
    props.size !== 'sm' ? 'clamp(14px, 18px, 4vmin)' : '14px'};
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  border-radius: 6px;
  transition: 0.2s ease-in;

  img {
    display: block;
    max-width: max-content;
    object-fit: cover;
    user-select: none;
  }

  &:hover {
    background-color: ${props => (props.bg ? `${props.bg}bb` : '#3FBAFF')};
  }

  &:disabled {
    opacity: 0.85;
    cursor: not-allowed;
  }

  &:active {
    background-color: ${props => (props.bg ? `${props.bg}bb` : '#3FBAFF')};
  }

  @media screen and (max-width: 600px) {
    padding: 7px 1rem;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const ControlsContainerTitle = styled.h3`
  color: #b5b5c3;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
`;

export const RegisterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
`;

export const TableBox = styled.div`
  max-height: 350px;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    display: block;
  }
`;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Fade } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import TagStatus from 'components/Tools/TagStatus';
import {
  TableContainer,
  TableFilterCardContainer,
  Search,
} from 'app/WMS/styles/styles';

import { Table } from 'components/Tools/Table';
import SearchBox from 'components/Tools/Search';
import DetailsItem from 'components/Tools/DetailsItem';
import ProgressBar from 'components/Tools/ProgressBar';
import CustomizedImgCell from 'features/Receivement/components/CustomizedImgCell';
import ConfirmationModal from 'components/Tools/ConfirmationModal';
import { getCookie } from 'utils/cookies';
import failureAlert from 'assets/alerts/failure.mp3';
import completedAlert from 'assets/alerts/completed.mp3';
import successAlert from 'assets/alerts/success_beep.mp3';
import { formatDate, formatDateHours } from 'utils/formatDate';
import { AxiosError, AxiosResponse } from 'axios';
import getValidationErrors from 'utils/getValidationErrors';
import { useAuth } from 'hooks/Auth';

import {
  ConferenceData,
  ConferenceTableProps,
  FilterDataProps,
  PutConferenceDataProps,
  PickingData,
} from 'features/Picking/Conference/types';

import {
  finishConference,
  getConferenceData,
  validateAmount,
  validateBarCode,
  validateBox,
} from '../apis/conference.apis';

const ConferenceTable: React.FC<ConferenceTableProps> = ({
  conference,
  orderCode,
  openConfirmationModal,
  setOpenConfirmationModal,
  detailProduct,
  cardProgress,
  returnPage,
}) => {
  const inputsBox = useRef<FormHandles>(null);
  const { token, signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const animate = true;
  const [finalized, setFinalized] = useState<boolean>(Boolean);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const [conferenceList, setConferenceList] = useState(conference);

  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const idCliente = getCookie('@pdamodules::codigoCliente');
  const idUser = sessionStorage.getItem('@pdamodules::id');

  const playFailureAlarm = () => {
    new Audio(failureAlert).play();
  };
  const playCompletedAlarm = () => {
    new Audio(completedAlert).play();
  };
  const playSuccessAlarm = () => {
    new Audio(successAlert).play();
  };

  const finishConferenceData: PutConferenceDataProps = {
    codigoCliente: Number(idCliente),
    codigoPedido: orderCode,
  };

  const handleFinishPartial = useCallback(
    async (data: any) => {
      finishConference(data).then((coletaData: AxiosResponse) => {
        returnPage();
        enqueueSnackbar(`Conferência Finalizada!`, {
          variant: 'success',
        });
        playSuccessAlarm();
      });
    },
    [enqueueSnackbar, returnPage]
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const schema = Yup.object().shape({
          codigo: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const filterData: FilterDataProps = {
          codigoCliente: Number(idCliente),
          codigoPedido: orderCode,
          ean: data.codigo || null,
          tipoCaixa: data.boxType || null,
          embalagem: data.embalagem || null,
        };

        const putData: PutConferenceDataProps = {
          codigoCliente: Number(idCliente),
          codigoPedido: orderCode,
          ean: data.codigo,
          tipoCaixa: data.boxType || null,
          user: Number(idUser),
          embalagem: inputsBox.current?.getFieldValue('embalagem'),
        };

        if (filterData.embalagem !== ' ' || filterData.embalagem !== null) {
          const validaCaixa = await validateBox(filterData)
            .then((validationData: AxiosResponse) => {
              if (validationData.data.length === 0) {
                inputsBox.current?.getFieldRef('codigo').focus();
                return true;
              }

              if (validationData.data[0].mensagem === 'Caixa já endereçada !') {
                enqueueSnackbar(validationData.data[0].mensagem, {
                  variant: 'error',
                });
                playFailureAlarm();
                inputsBox.current?.clearField('embalagem');
                inputsBox.current?.getFieldRef('embalagem').focus();
                return false;
              }
              if (validationData.data[0].mensagem === 'Caixa não existe !') {
                enqueueSnackbar(validationData.data[0].mensagem, {
                  variant: 'error',
                });
                playFailureAlarm();
                inputsBox.current?.clearField('embalagem');
                inputsBox.current?.getFieldRef('embalagem').focus();
                return false;
              }
              if (
                validationData.data[0].mensagem ===
                'Caixa não pertence ao pedido !'
              ) {
                enqueueSnackbar(validationData.data[0].mensagem, {
                  variant: 'error',
                });
                playFailureAlarm();
                inputsBox.current?.clearField('embalagem');
                inputsBox.current?.getFieldRef('embalagem').focus();
                return false;
              }

              return validationData.data.length === 0;
            })
            .catch((validationError: AxiosError<any>) => {
              if (validationError.response!.status === 401 && tokenData) {
                enqueueSnackbar('Reautenticando, tente novamente.', {
                  variant: 'warning',
                });
                signOut();
              }
            });
          if (validaCaixa && filterData.ean !== null) {
            const responseEan = await validateBarCode(filterData)
              .then((eanDataResponse: AxiosResponse) => {
                if (eanDataResponse.data === false) {
                  enqueueSnackbar(`Código de Barras inválido`, {
                    variant: 'error',
                  });
                  playFailureAlarm();
                  return false;
                }
                if (eanDataResponse.data === true) {
                  enqueueSnackbar(`Foi validado com sucesso!`, {
                    variant: 'success',
                  });
                  playCompletedAlarm();

                  return true;
                }
                return eanDataResponse.data;
              })
              .catch((eanDataError: AxiosError<any>) => {
                if (eanDataError.response!.status === 401 && tokenData) {
                  enqueueSnackbar('Reautenticando, tente novamente.', {
                    variant: 'warning',
                  });
                }
              });

            if (responseEan) {
              const responseColeta = await validateAmount(putData)
                .then((coletaData: AxiosResponse) => {
                  getConferenceData(filterData).then(
                    (conferenceDataResponse: AxiosResponse) => {
                      setConferenceList([...conferenceDataResponse.data]);
                    }
                  );
                  return coletaData.data;
                })
                .catch((coletadaDataError: AxiosError<any>) => {
                  if (coletadaDataError.response!.status === 401 && tokenData) {
                    enqueueSnackbar('Reautenticando, tente novamente.', {
                      variant: 'warning',
                    });
                  }
                });

              if (responseColeta!.excedido) {
                enqueueSnackbar(`Quantidade excedida!`, { variant: 'error' });
                playFailureAlarm();
                inputsBox.current?.setFieldValue('codigo', '');
                return;
              }

              if (!responseEan.excedido) {
                if (responseColeta!.finalizado) {
                  setFinalized(Boolean(responseColeta!.finalizado));

                  enqueueSnackbar(`Conferência Finalizada!`, {
                    variant: 'success',
                  });
                  playSuccessAlarm();
                  returnPage();

                  return;
                }

                if (finalized) {
                  setFinalized(Boolean(responseColeta!.finalizado));
                  enqueueSnackbar(`Conferência Finalizada!`, {
                    variant: 'success',
                  });
                  playSuccessAlarm();
                  returnPage();

                  return;
                }

                inputsBox.current?.setFieldValue('codigo', '');
              }
            }
          }
        } else {
          enqueueSnackbar('Favor inserir uma caixa para realizar a coleta!', {
            variant: 'error',
          });
          playFailureAlarm();
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          inputsBox.current?.setErrors(errors);
        } else {
          console.error(err);
        }
      }
    },
    [
      idCliente,
      orderCode,
      idUser,
      enqueueSnackbar,
      tokenData,
      signOut,
      finalized,
      returnPage,
    ]
  );

  const resetConfirm = useCallback(async () => {
    setOpenConfirmationModal();
    setIsConfirmed(false);
  }, [setOpenConfirmationModal]);

  const switchIconStatus = (amount: number, checked: number) => {
    if (amount === checked && checked !== 0) {
      return 'Finalizado';
    }
    if (amount !== checked && checked > 0) {
      return 'Em andamento';
    }
    return 'default';
  };

  useEffect(() => {
    const TabToSubmit = (event: any) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        inputsBox.current?.submitForm();
        inputsBox.current?.getFieldRef('codigo').focus();
      } else if (event.key === 'Enter') {
        inputsBox.current?.getFieldRef('codigo').focus();
      }
    };

    document.addEventListener('keydown', TabToSubmit);

    return () => {
      document.removeEventListener('keydown', TabToSubmit);
    };
  }, []);

  return (
    <>
      <ConfirmationModal
        confirm={() => handleFinishPartial(finishConferenceData)}
        resetConfirm={resetConfirm}
        isConfirmed={isConfirmed}
        handleCloseModal={setOpenConfirmationModal}
        openModal={openConfirmationModal}
        warningTitle="Você tem certeza que deseja finalizar a conferência?"
        doneTitle="Conferência finalizada!"
      />
      <Fade in={animate} unmountOnExit timeout={1000}>
        <>
          <div className="cardHeader">
            <div className="details">
              <div className="detailsContainer">
                <div className="detailsItemsContainer" id="firstRow">
                  <DetailsItem
                    title="Pedido"
                    value={detailProduct.codigoPedido}
                  />
                  <DetailsItem
                    title="Nota Fiscal"
                    value={
                      String(detailProduct.notaFiscal) === 'undefined'
                        ? '0000'
                        : String(detailProduct.notaFiscal)
                    }
                  />
                  <DetailsItem
                    title="Cliente"
                    value={detailProduct.usuarioOnda}
                  />
                </div>
                <div style={{ width: '40%' }}>
                  <ProgressBar
                    value={
                      detailProduct.progresso === undefined
                        ? 0
                        : detailProduct.progresso
                    }
                    showTitle
                    style={{ marginRight: '11px' }}
                  />
                </div>
              </div>
              <div className="detailsItemsContainer">
                <div className="detailsBottomRow">
                  <DetailsItem
                    title="Data do pedido:"
                    value={formatDate(detailProduct.dataPedido)}
                  />
                  <DetailsItem
                    title="Hora de início"
                    value={
                      detailProduct.dataInicioPicking
                        ? formatDateHours(detailProduct.dataInicioPicking)
                        : ''
                    }
                  />
                  <DetailsItem
                    title="Data de fim:"
                    value={formatDate(detailProduct.dataFimPicking)}
                  />
                  <DetailsItem
                    title="Hora de fim"
                    value={
                      detailProduct.dataInicioPicking
                        ? formatDateHours(detailProduct.dataFimPicking)
                        : ''
                    }
                  />
                  <div className="detailsItem" style={{ paddingTop: '10px' }}>
                    <TagStatus
                      status={detailProduct.descricaoStatus}
                      width="fit-content"
                    />
                  </div>
                </div>
                <div className="totalTimeResume">
                  <div className="detailsItem">
                    <p>Tempo total:</p>
                    <p>{String(detailProduct.tempoTotal) || '--/--/--'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TableFilterCardContainer cardProgressValue={10}>
            <div>
              <DetailsItem
                card
                title="Quantidade Total"
                value={
                  detailProduct.quantidadePedido === undefined
                    ? 0
                    : detailProduct.quantidadePedido
                }
              />
              <DetailsItem
                card
                id="divergent"
                title="Quantidade Divergente"
                value={detailProduct.quantidadeDivergente || 0}
              />
              <DetailsItem
                card
                id="done"
                title="Quantidade Recebida"
                value={detailProduct.quantidadeConferida || 0}
              />
            </div>
            <div>
              <DetailsItem
                card
                title="Progresso total"
                value={`${cardProgress.toString()}%`}
                id="totalProgress"
              >
                {' '}
                <span style={{ width: `${cardProgress}` }} />
              </DetailsItem>
            </div>
          </TableFilterCardContainer>
          <Search>
            <SearchBox
              submitButton
              noTitle
              cancelSubmit
              searchButtonTitle="Conferir"
              clearButtonTitle="Limpar"
              handleSubmit={handleSubmit}
              searchBoxRef={inputsBox}
              inputs={[
                {
                  name: 'embalagem',
                  label: 'Caixa',
                  type: 'text',
                  placeholder: 'Caixa',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
                /* {
                  name: 'pallet',
                  label: 'Pallet',
                  type: 'text',
                  placeholder: 'Pallet',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 12,
                  sm: 12,
                }, */
                {
                  isRequired: true,
                  name: 'codigo',
                  label: 'Cód. Barra',
                  type: 'text',
                  placeholder: 'Código de Barras',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
              ]}
            />
          </Search>
          <TableContainer>
            <Table<ConferenceData>
              rows={[
                ...conferenceList.map(item => ({
                  ...item,
                })),
              ]}
              columns={[
                {
                  title: 'Código',
                  orderable: true,
                  type: 'string',
                  props: ['produto'],
                  cssProps: {
                    width: '3%',
                  },
                  renderItem: row => (
                    <div className="code-item">
                      <CustomizedImgCell
                        status={switchIconStatus(
                          row.quantidadePicking,
                          row.quantidadeConferencia
                        )}
                      />
                      <p style={{ marginLeft: '10px' }}>{row.produto}</p>
                    </div>
                  ),
                },
                {
                  title: 'Produto',
                  orderable: true,
                  type: 'string',
                  props: ['descricaoProduto'],
                  cssProps: {
                    width: '1%',
                  },
                  cssTitle: {
                    width: '1%',
                  },
                },
                {
                  title: 'Caixa',
                  orderable: true,
                  type: 'stringCenter',
                  props: ['caixa'],
                  cssProps: {
                    width: '5%',
                  },
                },
                {
                  title: 'Palete',
                  orderable: true,
                  type: 'string',
                  props: ['palete'],
                  cssProps: {
                    width: '5%',
                  },
                },
                {
                  title: 'Quantidade',
                  orderable: true,
                  type: 'number',
                  props: ['quantidadePicking'],
                  cssProps: {
                    width: '5%',
                  },
                },
                {
                  title: 'Conferido',
                  orderable: true,
                  type: 'number',
                  props: ['quantidadeConferencia'],
                  cssProps: {
                    width: '5%',
                  },
                },
                {
                  title: 'Progresso',
                  orderable: false,
                  type: 'number',
                  props: ['porcentagem'],
                  formatter: row => `0%`,
                  cssProps: {
                    width: '5%',
                  },
                  renderItem: row => (
                    <div className="">
                      <ProgressBar
                        style={{
                          width: '100%',
                          display: 'inline-block',
                        }}
                        value={0}
                      />
                    </div>
                  ),
                },
                {
                  title: 'Status',
                  type: 'string',
                  orderable: true,
                  props: ['status'],
                  cssProps: {
                    width: '1%',
                    marginLeft: '8px',
                  },
                  renderItem: row => (
                    <div className="statusContainer">
                      <TagStatus status={row.status} width="fit-content" />
                    </div>
                  ),
                },
              ]}
            />
          </TableContainer>
        </>
      </Fade>
    </>
  );
};

export default ConferenceTable;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getCookie } from 'utils/cookies';
import Routes from './routes';
import GlobalStyles from './styles/global';
import Hooks from './hooks';

const App: React.FC = () => {
  React.useEffect(() => {
    const authToken = getCookie('@pdamodules::token');
    const user = getCookie('@pdamodules::user');
  }, []);

  return (
    <Hooks>
      <GlobalStyles />
      <Routes />
      <ToastContainer />
    </Hooks>
  );
};

export default App;

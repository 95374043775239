import React, {
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  memo,
} from 'react';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import { getCookie } from 'utils/cookies';

import PageMode from 'components/Tools/PageMode';
import { useNotification } from 'hooks/Notification';
import { formatFormDataToApi, formatOptions } from '../utils';
import { Header } from '../styles';
import { TableBox } from '../styles/styles';
import {
  clientRequest,
  createReceiviment,
  getOptions,
} from '../apis/registerOrder.api';
import { OrderForm, OrderTable } from './modalComponents';
import { ICreateOrderProps, IRegister, IRegisterForm } from '../types';

const CreateOrder: React.FC<ICreateOrderProps> = ({ isOpen, closePopup }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pushNotification } = useNotification();
  const inputsRef = useRef<FormHandles>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isInputLoading, setIsInputLoading] = useState(false);

  const [orderTypes, setOrderTypes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [transportadora, setTransportadoras] = useState([]);
  const [clients, setClients] = useState([]);

  const [order, setOrder] = useState<IRegister>({
    details: {} as IRegister['details'],
    items: [] as IRegister['items'],
  });

  const clientId = getCookie('@pdamodules::codigoCliente');

  const getAllOptions = useCallback(async () => {
    setIsLoading(true);

    const [suplierRes, orderTypesRes, transportadoraRes] = await getOptions(
      String(clientId)
    );

    if (
      suplierRes.status !== 200 ||
      orderTypesRes.status !== 200 ||
      transportadoraRes.status !== 200
    ) {
      enqueueSnackbar('Falha ao tentar receber as opções', {
        variant: 'error',
      });

      return;
    }

    const formatedSuplier = formatOptions(suplierRes.data);
    const formatedOrderTyes = formatOptions(orderTypesRes.data);
    const formatedTransportadoras = formatOptions(transportadoraRes.data);

    setProviders(formatedSuplier);
    setOrderTypes(formatedOrderTyes);
    setTransportadoras(formatedTransportadoras);

    setIsLoading(false);
  }, [enqueueSnackbar, clientId]);

  const getClientsErp = useCallback(
    async (query: string) => {
      setIsInputLoading(true);
      await clientRequest(query, String(clientId))
        .then(res => {
          const formatedData = formatOptions(res.data);
          setClients(formatedData);
        })
        .finally(() => setIsInputLoading(false));
    },
    [clientId]
  );

  const handleSubmit = useCallback(
    async (detailsData: IRegisterForm) => {
      if (
        !order.items.length ||
        detailsData.operacao === '' ||
        detailsData.codigoClienteErp === '' ||
        detailsData.codigoTransportadora === ''
      ) {
        enqueueSnackbar('Preencha os campos necessários', {
          variant: 'warning',
        });

        return;
      }

      const isEntry = detailsData.operacao === 'entrada';

      setIsLoading(true);

      const formatedData = formatFormDataToApi(
        detailsData,
        order.items,
        isEntry
      );

      await createReceiviment(formatedData, isEntry)
        .then(() => {
          enqueueSnackbar(
            `Recebimento: ${formatedData.codigoPedido} criado com sucesso!`,
            {
              variant: 'success',
            }
          );
          pushNotification({
            message: `Pedido: ${formatedData.codigoPedido} criado!`,
            type: 'success',
          });
          inputsRef.current?.reset();
        })
        .catch(err => {
          pushNotification({
            message: `Erro: ${String(err.message).substring(0, 15)}...`,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [order, enqueueSnackbar, inputsRef, pushNotification]
  );

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  return (
    <PageMode
      isModal
      open={isOpen}
      title="Cadastro Manual"
      handleClose={closePopup}
      submitButtonIcon
      submitButtonTitle="Salvar"
      handleSubmit={(data: IRegisterForm) => handleSubmit(data)}
      modalRefObject={inputsRef}
      modalLoading={isLoading}
      inputs={[
        {
          name: 'operacao',
          type: 'select',
          label: 'Operação',
          placeholder: 'Escolha a operação',
          isDisabled: isLoading,
          options: [
            { label: 'Entrada', value: 'entrada' },
            { label: 'Saída', value: 'saida' },
          ],
          isRequired: true,
        },
        {
          name: 'tipoPedido',
          type: 'select',
          label: 'Tipo do pedido',
          placeholder: 'Escolha um tipo',
          isDisabled: isLoading,
          options: orderTypes,
          isRequired: true,
        },
        {
          name: 'notaFiscal',
          type: 'text',
          label: 'Nota Fiscal',
          placeholder: 'Digite a nota fiscal',
          isRequired: true,
        },
        {
          name: 'fornecedor',
          type: 'select',
          label: 'Fornecedor',
          placeholder: 'Escolha o fornecedor',
          isDisabled: isLoading,
          options: providers,
        },
        {
          name: 'data',
          type: 'date',
          label: 'Data',
        },
        {
          name: 'codigoClienteErp',
          type: 'select',
          label: 'Código cliente ERP',
          isApiSearch: true,
          options: clients,
          isLoading: isInputLoading,
          isRequired: true,
          placeholder: 'Código cliente ERP',
          handleInputChange: (query, action) =>
            action === 'input-change' && getClientsErp(query),
        },
        {
          name: 'codigoTransportadora',
          type: 'select',
          label: 'Transportadora',
          placeholder: 'Código da Transportadora',
          isApiSearch: true,
          isRequired: true,
          options: transportadora,
        },
      ]}
    >
      <Header title="Itens" />
      <OrderForm setFormItemsData={setOrder} />
      <TableBox>
        {order.items.length ? (
          <OrderTable rows={order.items} setRows={setOrder} />
        ) : null}
      </TableBox>
    </PageMode>
  );
};

export default memo(CreateOrder);

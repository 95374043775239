import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Divider, Fade } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';

import { Container } from 'app/WMS/styles/styles';

import searchIcon from 'assets/svg/searchIcon.svg';

import getValidationErrors from 'utils/getValidationErrors';

import { useAuth } from 'hooks/Auth';

import { Form } from '@unform/web';
import FixedHeader from 'components/Tools/Breadcrumb';
import { Input } from 'components/Tools/Form';
import Loader from 'components/Tools/Loader';
import PageMode from 'components/Tools/PageMode';
import { getCookie } from 'utils/cookies';
import ConferenceTable from './ConferenceTable';

import { FilterDataProps, PickingData, ConferenceData } from './types';

import { getConferenceData, hendleDetailData } from './apis/conference.apis';

const Conference: React.FC = () => {
  const [conference, setConference] = useState<ConferenceData[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(Boolean);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const animate = true;
  const [detailProduct, setDetailProduct] = useState({} as PickingData);
  const [orderCode, setOrderCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const inputsBox = useRef<FormHandles>(null);
  const { token } = useAuth();
  const [cardProgress, setCardProgress] = useState<number>(0);
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const idCliente = getCookie('@pdamodules::codigoCliente');
  // const idUser = getCookie('@pdamodules::id');

  const handleProgressCard = (pickingData: PickingData[]) => {
    const quantity = pickingData.map((item: any) => {
      return Number(item.quantidadePedido);
    });
    const totalQuantitySum = quantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const receivedQuantity = pickingData.map((item: any) => {
      return Number(item.quantidadePicking);
    });
    const totalReceivedQuantitySum = receivedQuantity.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

    const progress = (totalReceivedQuantitySum / totalQuantitySum) * 100;
    const finalProgress: any = Math.floor(progress);
    return finalProgress !== Number
      ? setCardProgress(0)
      : setCardProgress(finalProgress);
  };

  const handleDetail = useCallback(
    async order => {
      const dataFilter = {
        codigoCliente: Number(idCliente),
        codigoPedido: String(order),
      };
      await hendleDetailData(dataFilter)
        .then((response: AxiosResponse) => {
          setDetailProduct(response.data);
          handleProgressCard(response.data);
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    [idCliente]
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const schema = Yup.object().shape({
          pedido: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const filterData: FilterDataProps = {
          codigoCliente: Number(idCliente),
          codigoPedido: data.pedido,
          ean: data.codigo || null,
          tipoCaixa: data.boxType || null,
          embalagem: data.embalagem || null,
        };

        setShowSearch(false);
        setIsLoading(true);
        getConferenceData(filterData)
          .then((conferenceDataResponse: AxiosResponse) => {
            if (conferenceDataResponse.data.length === 0) {
              enqueueSnackbar(`Resultados não econtrados.`, {
                variant: 'warning',
              });
              setConference([]);
              setShowSearch(true);
              setIsLoading(false);
            } else if (conferenceDataResponse.data.length > 0) {
              enqueueSnackbar(
                `Foi encontrado ${conferenceDataResponse.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              setOrderCode(data.pedido);
              setConference([...conferenceDataResponse.data]);
              handleProgressCard(conferenceDataResponse.data);
              handleDetail(data.pedido);
              setShowTable(true);
              setIsLoading(false);
            }
          })
          .catch((conferenceDataResponseError: AxiosError<any>) => {
            if (
              conferenceDataResponseError.response!.status === 401 &&
              tokenData
            ) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            }
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          inputsBox.current?.setErrors(errors);
        } else {
          console.error(err);
        }
      }
    },
    [idCliente, enqueueSnackbar, handleDetail, tokenData]
  );

  const clearFields = () => {
    inputsBox.current?.setFieldValue('pedido', '');
  };

  const returnPage = () => {
    setShowTable(false);
    setShowSearch(true);
  };

  useEffect(() => {
    const TabToSubmit = (event: any) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        inputsBox.current?.submitForm();
      }
    };

    document.addEventListener('keydown', TabToSubmit);

    return () => {
      document.removeEventListener('keydown', TabToSubmit);
    };
  }, []);

  useEffect(() => {
    console.log(cardProgress);
  }, [cardProgress]);

  return (
    <>
      <FixedHeader title="Picking" subTitle="Conferência" />
      <Container>
        {showSearch && (
          <Fade in={animate} unmountOnExit timeout={1000}>
            <Form onSubmit={handleSubmit} ref={inputsBox}>
              <div className="conferenceContainer">
                <div className="conferenceHeader">
                  <p>Conferência</p>
                </div>
                <Divider color="#EBEDF2" />
                <div className="conferenceContent">
                  <Input
                    name="pedido"
                    label="Pedido:"
                    id="pedido"
                    key="pedido"
                    type="text"
                    placeholder="Pedido"
                  />
                  <div className="conferenceButtons">
                    <Button
                      className="clearButton"
                      disableRipple
                      onClick={clearFields}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.77467 15.3334L0.195333 11.7567C0.0653334 11.6267 0 11.4567 0 11.2854C0 11.1147 0.0653334 10.944 0.195333 10.814L10.148 0.86202C10.278 0.732687 10.4487 0.666687 10.6193 0.666687C10.7893 0.666687 10.9607 0.732687 11.0907 0.86202L15.8047 5.57735C15.9353 5.70735 16 5.87802 16 6.04935C16 6.22002 15.9353 6.39002 15.8047 6.52069L8.32733 14H12.0033V15.3334H3.77467ZM6.21267 14L2.55533 10.3427L1.60933 11.2854L4.32667 14H6.21267ZM10.616 2.27935L3.498 9.39935L7.27 13.172L14.3873 6.05069L10.616 2.27935Z"
                          fill="#D9214E"
                        />
                      </svg>
                      <p>Limpar</p>
                    </Button>
                    <Button className="submitButton" type="submit">
                      <img src={searchIcon} alt="searchIcon" />
                      <p>Pesquisar</p>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Fade>
        )}
        {isLoading && <Loader />}
        {showTable && (
          <PageMode
            newPage
            title="Detalhe da conferência"
            pageActionsFooter
            submitButtonTitle="Finalizar"
            handleSubmit={() => setOpenConfirmationModal(true)}
            handleClose={returnPage}
          >
            <ConferenceTable
              conference={conference}
              orderCode={orderCode}
              detailProduct={detailProduct}
              cardProgress={cardProgress}
              setOpenConfirmationModal={() => setOpenConfirmationModal(false)}
              openConfirmationModal={openConfirmationModal}
              returnPage={returnPage}
            />
          </PageMode>
        )}
      </Container>
    </>
  );
};

export default Conference;

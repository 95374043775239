import React from 'react';
import { useSnackbar } from 'notistack';
import { Fade } from '@material-ui/core';
import { AxiosError, AxiosResponse } from 'axios';
import SearchBox from 'components/Tools/Search';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Container, Search } from 'app/WMS/styles/styles';
import Loader from 'components/Tools/Loader';
import { getCookie } from 'utils/cookies';
import {
  handleDataFilter,
  handleParamiters,
  handleDelete,
  handleEdit,
} from './api/reverse.api';
import Modal from '../../../components/Tools/_Modal';
import FixedHeader from '../../../components/Tools/Breadcrumb';
import { ReverseOrder } from '../../../utils/interfaces/Log';
import { SelectItem, transformToSelect } from './types/index';
import { useAuth } from '../../../hooks/Auth';
import getValidationErrors from '../../../utils/getValidationErrors';
import ReserveTable from './components/ReverseTable';

const OrderReverse: React.FC = () => {
  const [order, setOrder] = React.useState([] as ReverseOrder[]);
  const [status, setStatus] = React.useState({} as SelectItem[]);
  const { enqueueSnackbar } = useSnackbar();
  const [animate, setAnimate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(Boolean);
  const { token } = useAuth();
  const [deleteModal, setDeleteModal] = React.useState(Boolean);
  const [codigoDelete, setCodigoDelete] = React.useState('');
  const inputsEditMulti = React.useRef<FormHandles>(null);
  const [editStatusModal, setEditStatusModal] = React.useState(false);
  const [orderToEdit, setOrderToEdit] = React.useState('');
  const idCliente = getCookie('@pdamodules::codigoCliente');
  const idUser = getCookie('@pdamodules::id');
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const getData = React.useCallback(
    async (data: any) => {
      try {
        setLoading(false);
        setIsLoading(true);

        const dataFilter = {
          CodigoStatus: Number(data.status) || null,
          CodigoCliente: Number(idCliente),
          CodigoPedido: data.pedido || null,
          CodigoOnda: data.onda || null,
          cliente: data.cliente || null,
          user: Number(idUser),
          dataInicio: data.dataInicial || null,
          dataFim: data.dataFinal || null,
        };
        setOrder([]);
        await handleDataFilter(dataFilter).then(
          (orderReverseData: AxiosResponse) => {
            if (orderReverseData.data.length > 0) {
              setIsLoading(false);
              setOrder([...orderReverseData.data]);
              enqueueSnackbar(
                `Foi encontrado ${orderReverseData.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
            } else if (orderReverseData.data.length === 0) {
              setOrder([]);
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'warning',
              });
            }
          }
        );
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setIsLoading(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [idCliente, idUser, enqueueSnackbar]
  );

  const handleCancelDelete = React.useCallback(async () => {
    setDeleteModal(false);
  }, []);

  const getParam = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await handleParamiters(Number(idCliente)).then(
        (statusPickingData: AxiosResponse) => {
          const listStatus = transformToSelect([...statusPickingData.data]);
          setStatus([...listStatus]);
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [idCliente]);

  React.useEffect(() => {
    setAnimate(true);
    getParam();
  }, [getParam]);

  const reverseHas = React.useCallback(async (pedidoData: string) => {
    if (pedidoData) {
      setDeleteModal(true);
      setCodigoDelete(pedidoData);
    }
  }, []);

  const reverse = React.useCallback(async () => {
    await handleDelete(Number(idCliente), String(codigoDelete)).then(
      (dataC: AxiosResponse) => {
        if (!dataC?.data.badRequest) {
          enqueueSnackbar(`Pedido ${codigoDelete} estornado com sucesso!`, {
            variant: 'success',
          });
          setDeleteModal(false);
          setCodigoDelete('');
          getData((submit: null) => submit);
        } else {
          enqueueSnackbar(
            dataC.data.erros.map((item: any) => item.mensagem).join('\n'),
            {
              variant: 'warning',
            }
          );
          setDeleteModal(false);
        }
      }
    );
  }, [idCliente, codigoDelete, enqueueSnackbar, getData]);

  const detailsHas = React.useCallback(
    async (codigoPedido: string, descricaoStatus: string) => {
      try {
        setOrderToEdit(codigoPedido);
      } catch (err) {
        console.error(err);
      } finally {
        const filterStatus = status.filter(
          item => item.label === descricaoStatus
        )[0];
        inputsEditMulti.current?.setFieldValue('statusDescricao', {
          ...filterStatus,
        });
        setEditStatusModal(true);
      }
    },
    [status]
  );

  const handleEditStatus = React.useCallback(
    async (data: any) => {
      try {
        const schema = Yup.object().shape({
          statusDescricao: Yup.string().required(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const dataRequest = {
          codigoPedido: orderToEdit,
          codigoCliente: Number(idCliente),
          codigoStatus: Number(data.statusDescricao),
        };
        await handleEdit(dataRequest)
          .then(() => {
            enqueueSnackbar(
              `Status do pedido ${orderToEdit} alterado com sucesso!`,
              {
                variant: 'success',
              }
            );

            setEditStatusModal(false);
            getData((test: null) => test);
          })
          // TODO: set correct typing
          .catch((statusDataError: AxiosError<any>) => {
            statusDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'warning' });
            });
            setEditStatusModal(false);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);

          inputsEditMulti.current?.setErrors(erros);
        } else {
          console.error(err);
        }
      } finally {
        setEditStatusModal(false);
      }
    },
    [orderToEdit, idCliente, enqueueSnackbar, getData]
  );

  return (
    <>
      <Modal
        type="delete"
        title="Deseja apagar?"
        open={deleteModal}
        handleCancel={handleCancelDelete}
        handleSubmit={reverse}
      >
        Você tem certeza que deseja apagar o pedido {codigoDelete}?
      </Modal>
      <Modal
        title={`Editar Status - Pedido: ${orderToEdit}`}
        type="edit"
        open={editStatusModal}
        modalRefObject={inputsEditMulti}
        handleSubmit={handleEditStatus}
        handleCancel={() => {
          setEditStatusModal(false);
          setOrderToEdit('');
        }}
        inputs={[
          {
            name: 'statusDescricao',
            label: 'Status',
            placeholder: 'Status',
            isRequired: true,
            typeInput: 'select',
            options: status,
            xl: 12,
            lg: 12,
            xs: 12,
            md: 12,
            sm: 12,
          },
        ]}
      />
      <FixedHeader title="Pedido" subTitle="Estornar" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              // searchBoxRef={inputsEditMulti}
              handleSubmit={getData}
              inputs={[
                {
                  name: 'onda',
                  label: 'Onda',
                  placeholder: 'Onda',
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'pedido',
                  label: 'Pedido',
                  placeholder: 'Pedido',
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'status',
                  label: 'Status',
                  placeholder: 'Status',
                  type: 'select',
                  options: status,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'dataInicial',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: date,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFinal',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: date,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>

        {isLoading && <Loader />}
        {loading && (
          <ReserveTable
            detailsHas={detailsHas}
            animate={animate}
            reverseHas={reverseHas}
            order={order}
          />
        )}
      </Container>
    </>
  );
};

export default OrderReverse;

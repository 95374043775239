import React from 'react';
import { Fade } from '@material-ui/core';

import { TableContainer } from 'app/WMS/styles/styles';
import { Table } from 'components/Tools/Table';
import { ProdutivityExpeditionAnalitic } from 'utils/interfaces/Reports';
import { IAnalyticTableProps } from '../../types';

const AnalyticTable: React.FC<IAnalyticTableProps> = ({ rows }) => (
  <Fade in timeout={1000} unmountOnExit>
    <TableContainer>
      <Table<ProdutivityExpeditionAnalitic>
        columns={[
          {
            title: 'Usuário',
            type: 'stringCenter',
            orderable: true,
            props: ['usuario'],
          },
          {
            title: 'Hora início',
            type: 'stringCenter',
            orderable: true,
            props: ['horaInicio'],
          },
          {
            title: 'Hora fim',
            type: 'stringCenter',
            orderable: true,
            props: ['horaFim'],
          },
          {
            title: 'Total horas',
            type: 'stringCenter',
            orderable: true,
            props: ['totalHoras'],
          },
          {
            title: 'Pedidos hora',
            type: 'stringCenter',
            orderable: true,
            props: ['pedidosHora'],
          },
          {
            title: 'Qtde. Pedidos',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdePedidos'],
          },
          {
            title: 'Qtde. Endereços Hora',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdeEndHora'],
          },
          {
            title: 'Qtde. Endereços',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdeEnderecos'],
          },
          {
            title: 'Produto Hora',
            type: 'stringCenter',
            orderable: true,
            props: ['produtoHora'],
          },
          {
            title: 'Qtde. Produto',
            type: 'stringCenter',
            orderable: true,
            props: ['qtdeProduto'],
          },
          {
            title: 'Data Pesquisa',
            type: 'stringCenter',
            orderable: true,
            props: ['dataPesquisa'],
          },
        ]}
        rows={rows}
        exportList
      />
    </TableContainer>
  </Fade>
);

export default AnalyticTable;

import React from 'react';
import { Grid } from '@material-ui/core';
import TagStatus from 'components/Tools/TagStatus';
import ProgressBar from 'components/Tools/ProgressBar';
import { TableFilterCardContainer } from 'app/WMS/styles/styles';
import DetailsItem from 'components/Tools/DetailsItem';
import { ShowDetailsProps } from 'features/Receivement/types';
import { formatDate, formatDateHours } from 'utils/formatDate';
import DetailsTable from './DetailsTable';

const NewShowDetails: React.FC<ShowDetailsProps> = ({
  detailReceivement,
  detailReceivementItem,
  hendleSearchDetail,
}) => {
  return (
    <>
      <div className="wrapper">
        <div className="contentDetail">
          <Grid container spacing={3} className="detailHeader">
            <div className="details">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="detailsItemsContainer" id="firstRow">
                  <DetailsItem
                    title="Documento"
                    value={detailReceivement.codigoPedido}
                  />
                  <DetailsItem
                    title="Nota Fiscal"
                    value={detailReceivement.notaFiscal}
                  />
                  <DetailsItem title="Fornecedor" value="" />
                </div>
                <div style={{ width: '40%' }}>
                  <ProgressBar
                    value={detailReceivement.porcentagem}
                    showTitle
                    style={{ marginRight: '11px' }}
                  />
                </div>
              </div>
              <div className="detailsItemsContainer">
                <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap' }}>
                  <DetailsItem
                    title="Data de recebimento:"
                    value={formatDate(detailReceivement?.dataRecebimento)}
                  />
                  <DetailsItem
                    title="Hora de início"
                    value={formatDate(detailReceivement?.inicioRecebimento)}
                  />
                  <div className="detailsItem" style={{ paddingTop: '10px' }}>
                    <TagStatus
                      status={detailReceivement.status}
                      width="fit-content"
                    />
                  </div>
                  <DetailsItem
                    title="Data de fim:"
                    value={
                      detailReceivement.fimRecebimento
                        ? formatDate(detailReceivement?.fimRecebimento)
                        : ''
                    }
                  />
                  <DetailsItem
                    title="Hora de fim"
                    value={
                      detailReceivement.fimRecebimento
                        ? formatDateHours(detailReceivement?.fimRecebimento)
                        : ''
                    }
                  />
                </div>
                <div className="totalTimeResume">
                  <div className="detailsItem">
                    <p>Tempo total:</p>
                    <p>{detailReceivement.tempoTotal || ''}</p>
                  </div>
                </div>
              </div>
            </div>
            <TableFilterCardContainer withPadding>
              <div>
                <DetailsItem
                  card
                  title="Quantidade Total"
                  value={detailReceivement.quantidade}
                />
                <DetailsItem
                  card
                  id="divergent"
                  title="Quantidade Divergente"
                  value={detailReceivement.quantidadeDivergencia}
                />
                <DetailsItem
                  card
                  id="done"
                  title="Quantidade Recebida"
                  value={detailReceivement.quantidadeRecebimento}
                />
              </div>
            </TableFilterCardContainer>
          </Grid>
        </div>
      </div>
      <DetailsTable
        receivementItemData={detailReceivementItem}
        hendleSearchDetail={hendleSearchDetail}
      />
    </>
  );
};

export default NewShowDetails;

import api from 'services/api';
import { ProdutivityConferenceData } from 'utils/interfaces/Reports';

export const searchBoxRequest = (data: any, clientId: number) => {
  const requestData = {
    CodigoCliente: clientId,
    Usuario: data.nome || null,
    tipoRelatorio: 2,
  };

  const dateParams = {
    datainicio: data.dataInicio
      ? new Date(`${data.dataInicio}T01:00:00`).toISOString()
      : null,
    datafim: data.dataFim
      ? new Date(`${data.dataFim}T23:59:00`).toISOString()
      : null,
  };

  return api.get<ProdutivityConferenceData>(
    `Relatorio/RelatorioProdutividade/${dateParams.datainicio}/${dateParams.datafim}`,
    {
      params: requestData,
    }
  );
};

import React, { CSSProperties } from 'react';

import { Container } from './styles';

export interface ProgressBarProps {
  value: number;
  breakPoints?: number[];
  breakPointColors?: string[];
  style?: CSSProperties;
  showTitle?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  breakPointColors,
  breakPoints,
  style,
  showTitle,
}) => {
  return (
    <Container
      breakPoints={breakPoints}
      breakPointColors={breakPointColors}
      progress={value}
      style={style}
    >
      <div className="progress-value" style={{ justifyContent: 'flex-start' }}>
        <span>{`${Number(value).toFixed(0)}%`}</span>
        {showTitle && <span> Progresso</span>}
      </div>
      <div className="progress-bar" style={{ justifyContent: 'flex-start' }}>
        <div className="progress-bar-value" />
      </div>
    </Container>
  );
};

export default ProgressBar;

import React from 'react';
import { TableContainer } from 'app/WMS/styles/styles';
import { Fade } from '@material-ui/core';
import { Table } from 'components/Tools/Table';
import TagStatus from 'components/Tools/TagStatus';
import deleteIcon from 'assets/svg/deleteActionsIcon.svg';
import editIcon from 'assets/svg/editActionsIcon.svg';
import { ReverseOrder, IReverseTableProps } from '../../types/index';
import CustomizedImgCell from '../CustomizedImgCell';

const ReverseTable: React.FC<IReverseTableProps> = ({
  detailsHas,
  animate,
  reverseHas,
  order,
}) => {
  return (
    <Fade in={animate} unmountOnExit timeout={350}>
      <div className="wrapper" style={{ display: 'flex' }}>
        <div className="wrap" style={{ flexGrow: 1 }}>
          <TableContainer>
            <Table<ReverseOrder>
              exportList
              columns={[
                {
                  title: 'Pedido',
                  orderable: true,
                  type: 'string',
                  cssProps: {
                    width: '1%',
                  },
                  props: ['codigoPedido'],
                  renderItem: row => {
                    return (
                      <div className="code-item">
                        <CustomizedImgCell status={row.descricaoStatus} />
                        <p style={{ marginLeft: '10px' }}>{row.codigoPedido}</p>
                      </div>
                    );
                  },
                },
                {
                  title: 'Onda',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['codigoOnda'],
                  cssProps: {
                    width: '1%',
                  },
                },
                {
                  title: 'Data Pedido',
                  type: 'datetime',
                  orderable: true,
                  props: ['dataPedido'],
                  cssProps: {
                    width: '1%',
                  },
                  renderItem: row => {
                    return (
                      <>
                        {row.dataPedido ? (
                          <p className="render-string">
                            {new Date(row.dataPedido).toLocaleString()}
                          </p>
                        ) : (
                          <p className="render-string">-</p>
                        )}
                      </>
                    );
                  },
                },
                {
                  title: 'Cliente',
                  type: 'stringCenter',
                  props: ['cliente'],
                  orderable: true,
                  cssProps: {
                    width: '1%',
                  },
                },
                {
                  title: 'Transportadora',
                  type: 'string',
                  props: ['transportadora'],
                  orderable: true,
                  cssTitle: {
                    width: '1%',
                  },
                  cssProps: {
                    width: '1%',
                  },
                },
                {
                  title: 'Status',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['descricaoStatus'],
                  cssProps: {
                    width: '1%',
                  },
                  renderItem: row => (
                    <div className="statusContainer">
                      <TagStatus
                        status={row.descricaoStatus}
                        width="fit-content"
                      />
                    </div>
                  ),
                },
                {
                  title: 'Quantidade Peças',
                  type: 'number',
                  orderable: true,
                  props: ['quantidade'],
                  cssProps: {
                    width: '1%',
                  },
                },
                {
                  title: 'Ações',
                  type: undefined,
                  props: [''],
                  cssProps: {
                    width: '1%',
                  },
                  renderItem: actions => {
                    return (
                      <div className="row-actions-button-detail">
                        <button
                          type="button"
                          className="action"
                          onClick={() => {
                            reverseHas(actions.codigoPedido);
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt="edit"
                            style={{ width: '20px' }}
                          />
                          <p className="hover-item">Excluir</p>
                        </button>
                        <button
                          type="button"
                          className="action"
                          onClick={() => {
                            detailsHas(
                              actions.codigoPedido,
                              actions.descricaoStatus
                            );
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="delete"
                            style={{ width: '20px' }}
                          />
                          <p className="hover-item">Editar</p>
                        </button>
                      </div>
                    );
                  },
                },
              ]}
              defaultNumberOfRows={10}
              rows={[
                ...order.map((item: any) => ({
                  ...item,
                })),
              ]}
            />
          </TableContainer>
        </div>
      </div>
    </Fade>
  );
};
export default ReverseTable;
